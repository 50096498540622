import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "clear_account",
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.clear();
    window.location.href = "/login";
  }, [dispatch]);
  return <div>Logout</div>;
}

export default Logout;
