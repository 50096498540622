import React from "react";
import ApexCharts from "react-apexcharts";

function TreeMapChart({ data }) {
  // console.log(data);
  const settings = {
    series: [
      {
        data:
          data?.length > 0
            ? data.map((hold) => ({
                x: hold.symbol || hold.stock,
                y: hold.profit_loss === 0 ? -1 : hold.profit_loss,
              }))
            : [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: "100%",
        width: "100%",
        type: "treemap",
        toolbar: {
          show: false,
        },
      },
      // title: {
      //     text: 'Distibuted Treemap (different color for each cell)',
      //     align: 'center'
      // },
      colors: [
        "#17becf",
        "#1f77b4",
        "#2ca02c",
        "#393b79",
        "#5254a3",
        "#637939",
        "#7b4173",
        "#7f7f7f",
        "#843c39",
        "#8c564b",
        "#8c6d31",
        "#9467bd",
        "#98df8a",
        "#9edae5",
        "#aec7e8",
        "#bcbd22",
        "#c49c94",
        "#c5b0d5",
        "#c7c7c7",
        "#d62728",
        "#dbdb8d",
        "#e377c2",
        "#f7b6d2",
        "#ff7f0e",
        "#ff9896",
        "#ffbb78",
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    },
  };
  return data.length > 0 ? (
    <ApexCharts
      options={settings.options}
      series={settings.series}
      type="treemap"
      height={"100%"}
      width={"100%"}
    />
  ) : ( <div
    style={{
      display: "flex",
      alignItems: "center",
      marginBottom: "50px",
      gap: "5px",
      marginTop:"50px",
    
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.3332C14.5837 18.3332 18.3337 14.5832 18.3337 9.99984C18.3337 5.4165 14.5837 1.6665 10.0003 1.6665C5.41699 1.6665 1.66699 5.4165 1.66699 9.99984C1.66699 14.5832 5.41699 18.3332 10.0003 18.3332Z"
        stroke="#515151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6.6665V10.8332"
        stroke="#515151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99512 13.3335H10.0026"
        stroke="#515151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span style={{ fontSize: "16px", color: "#515151" }}>
      Start trading to see your positions
    </span>
  </div>);
}

export default TreeMapChart;
