import React, { useState } from "react";
import moment from "moment";

const StreakCalendar = ({ highlightedDates }) => {
  const [currentMonth, setCurrentMonth] = useState(moment());

  const redHighlightDates = [
    "January 1",
    "March 29",
    "April 1",
    "April 10",
    "April 11",
    "May 1",
    "June 12",
    "June 17",
    "June 18",
    "September 16",
    "October 1",
    "December 25",
    "December 26",
  ].map((date) => moment(date, "MMMM D").format("MM-DD"));

  // Move to the next month
  const nextMonth = () => setCurrentMonth(currentMonth.clone().add(1, "month"));

  // Move to the previous month
  const prevMonth = () =>
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));

  // Function to render only the days of the current month
  const renderDays = () => {
    const startOfMonth = currentMonth.clone().startOf("month").startOf("week");
    const endOfMonth = currentMonth.clone().endOf("month").endOf("week");

    const days = [];
    let day = startOfMonth;

    while (day.isBefore(endOfMonth, "day")) {
      const dateStr = day.format("YYYY-MM-DD");
      const isHighlighted = highlightedDates.includes(dateStr);
      const isRedHighlight = redHighlightDates.includes(day.format("MM-DD"));
      const isWeekend = day.day() === 0 || day.day() === 6;
      

      days.push(
        <div
          className={`day ${
            isHighlighted
              ? "bg-[#00ba77] text-white"
              : isWeekend || isRedHighlight
              ? "bg-[#B20F19] text-white"
              : ""
          } text-center lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] mx-auto flex rounded-full items-center justify-center my-[3px]`}
        >
          {day.date()}
        </div>
      );
      day = day.clone().add(1, "day");
    }
    return days;
  };

  return (
    <div className="bg-white border border-[#D8D8D8] rounded-md lg:mt-4 my-4">
      <div className="bg-[#00BA771A] border-b border-b-[#279D72] py-4 flex items-center justify-center">
        <p className="font-medium text-center text-[#161616] text-[1.25rem]">
          Streak Calendar
        </p>
      </div>
      <div className=" flex items-center justify-between my-3">
        <button onClick={prevMonth}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0813 14.0664L4.46459 8.46644C4.39793 8.39977 4.35059 8.32755 4.32259 8.24977C4.29459 8.17199 4.28082 8.08866 4.28126 7.99977C4.28126 7.91088 4.29504 7.82755 4.32259 7.74977C4.35015 7.67199 4.39748 7.59977 4.46459 7.53311L10.0813 1.91644C10.2368 1.76088 10.4313 1.68311 10.6646 1.68311C10.8979 1.68311 11.0979 1.76644 11.2646 1.93311C11.4313 2.09977 11.5146 2.29422 11.5146 2.51644C11.5146 2.73866 11.4313 2.93311 11.2646 3.09977L6.36459 7.99977L11.2646 12.8998C11.4201 13.0553 11.4979 13.2471 11.4979 13.4751C11.4979 13.7031 11.4146 13.9002 11.2479 14.0664C11.0813 14.2331 10.8868 14.3164 10.6646 14.3164C10.4424 14.3164 10.2479 14.2331 10.0813 14.0664Z"
              fill="#161616"
            />
          </svg>
        </button>
        <h2>{currentMonth.format("MMMM YYYY")}</h2>
        <button onClick={nextMonth}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.91874 1.93356L11.5354 7.53356C11.6021 7.60023 11.6494 7.67245 11.6774 7.75023C11.7054 7.82801 11.7192 7.91134 11.7187 8.00023C11.7187 8.08912 11.705 8.17245 11.6774 8.25023C11.6499 8.32801 11.6025 8.40023 11.5354 8.46689L5.91874 14.0836C5.76318 14.2391 5.56874 14.3169 5.33541 14.3169C5.10207 14.3169 4.90207 14.2336 4.73541 14.0669C4.56874 13.9002 4.48541 13.7058 4.48541 13.4836C4.48541 13.2613 4.56874 13.0669 4.73541 12.9002L9.63541 8.00023L4.73541 3.10023C4.57985 2.94467 4.50207 2.75289 4.50207 2.52489C4.50207 2.29689 4.58541 2.09978 4.75207 1.93356C4.91874 1.76689 5.11318 1.68356 5.33541 1.68356C5.55763 1.68356 5.75207 1.76689 5.91874 1.93356Z"
              fill="#161616"
            />
          </svg>
        </button>
      </div>
      <div className="!grid !grid-cols-7 lg:px-6 px-3">
        {["M", "T", "W", "T", "F", "S", "S"].map((day, index) => (
          <div key={index} className="text-center font-semibold mb-1">
            {day}
          </div>
        ))}
      </div>
      <div className="!grid !grid-cols-7 lg:px-6 px-3 lg:pb-6 pb-3">{renderDays()}</div>
      <div className="flex items-center gap-8 justify-center mb-4">
        <div className="flex items-center gap-1">
            <div className="w-[10px] h-[10px] bg-[#00ba77] rounded-full"></div>
            <p className="text-[10px]">Days Traded</p>
        </div>
        <div className="flex items-center gap-1">
            <div className="w-[10px] h-[10px] bg-[#B20F19] rounded-full"></div>
            <p className="text-[10px]">Market CLosed</p>
        </div>
      </div>
    </div>
  );
};

export default StreakCalendar;
