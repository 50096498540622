const initialState = {
  id:null,
  acc_type:"",
  acc_name:"",
  acc_id:null,
  activatedAvailable:false
};

const account = (state = initialState, action) => {
  switch (action.type) {
      case "select_account":
        return {
          id:action.payload.id,
          acc_type:action.payload.acc_type,
          acc_name:action.payload.acc_name,
          acc_id:action.payload.acc_id,
          activatedAvailable:action.payload.activatedAvailable
        };
      default:
        return state;
    }
}

export default account