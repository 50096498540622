/* eslint-disable no-undef */
import "./App.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useLocation,
} from "react-router-dom";
import { Header, PopupSidebar, Sidebar } from "./components";
import {
  // Alert,
  // Apps,
  Holdings,
  Home,
  Markets,
  Wallet,
  Profile,
  AlertManual,
  // MarketsAi,
  Technicals,
} from "./pages";
import AddFunds from "./pages/addfunds/AddFunds";
import BankTransfer from "./pages/banktransfer/BankTransfer";
import Withdraw from "./pages/withdraw/Withdraw";
import WithdrawOTP from "./pages/withdrawotp/WithdrawOTP";
import SavingLock from "./pages/savinglock/SavingLock";
import DurationSelection from "./pages/durationselection/DurationSelection";
import DaysLock from "./pages/dayslock/DaysLock";
import { stocks } from "./constants";
import Support from "./pages/support/Support";
import SingleSupport from "./pages/singleSupport/SingleSupport";
import Authe from "./pages/auth/Authe";
import Logout from "./pages/logout/Logout";
import Paystack from "./pages/paystack/Paystack";
import Signup from "./pages/signup/Signup";
import Signin from "./pages/signin/Signin";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector, useDispatch } from "react-redux";
import axios from "./axios";
import Accounts from "./pages/accounts/Accounts";
import LiveWithdraw from "./pages/liveWithdraw/LiveWithdraw";
import "react-tooltip/dist/react-tooltip.css";
import { Toaster } from "react-hot-toast";
import SupportChatbot from "./components/SupportChat/SupportChatbot";
// import Carousel from "./components/Carousel";

function MainContent() {
  // const location = useLocation();

  const [openMenu, setOpenMenu] = useState(false);
  const [open2, setOpen2] = useState(false);
  const liveDemo = useSelector((state) => state.page.liveDemo);
  const dispatch = useDispatch();

  useEffect(() => {
    // Define the event handler function
    const handleLocationChange = () => {
      // This function will be called whenever the pathname changes
      // or when the user navigates back/forward in the browser.
      window.location.reload();
    };

    // Check if the pathname starts with "/wallet"
    const isWalletPage = window.location.pathname.startsWith("/wallet");
    // const isAccountInformationPage = window.location.pathname.startsWith(
    //   '/accountinformation'
    // )
    // const isPaymentPage = window.location.pathname.startsWith('/payment')
    // const isPrivacySecurityPage =
    //   window.location.pathname.startsWith('/privacysecurity')

    // Conditionally add or remove the event listener
    if (!isWalletPage && window.screenWidth > 425) {
      window.addEventListener("popstate", handleLocationChange);
    }

    // if (!isAccountInformationPage && window.screenWidth > 425) {
    //   window.addEventListener('popstate', handleLocationChange)
    // }

    // if (!isPaymentPage && window.screenWidth > 425) {
    //   window.addEventListener('popstate', handleLocationChange)
    // }

    // if (!isPrivacySecurityPage && window.screenWidth > 425) {
    //   window.addEventListener('popstate', handleLocationChange)
    // }

    // Cleanup the event listener when the component unmounts
    return () => {
      // Remove the event listener only if it was added
      if (!isWalletPage) {
        window.removeEventListener("popstate", handleLocationChange);
      }
    };
  }, []);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    // Add event listener to handle scroll lock
    // const handleScroll = (event) => {
    //   if (openMenu) {
    //     event.preventDefault();
    //   }
    // };

    if (liveDemo) {
      setOpen2(true);
    }

    if (openMenu) {
      document.body.style.overflow = "hidden";
      // document.addEventListener('scroll', handleScroll, { passive: false });
    } else {
      document.body.style.overflow = "visible";
      // document.removeEventListener('scroll', handleScroll);
    }

    // Cleanup: remove event listener when component unmounts
    // return () => {
    //   document.body.style.overflow = 'visible';
    //   document.removeEventListener('scroll', handleScroll);
    // };
  }, [openMenu, liveDemo]);

  const handleOpenMenu = () => {
    setOpenMenu(false);
  };

  const topDivRef = useRef(null);
  const bottomDivRef = useRef(null);
  const [isBottomScrolled, setIsBottomScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!topDivRef.current || !bottomDivRef.current) return;

      const topDivRect = topDivRef.current.getBoundingClientRect();
      const bottomDivRect = bottomDivRef.current.getBoundingClientRect();

      // Check if the bottom div has scrolled past the top div
      if (bottomDivRect.top <= topDivRect.bottom) {
        setIsBottomScrolled(true);
      } else {
        setIsBottomScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isBottomScrolled && bottomDivRef.current) {
      // Ensure the bottom div's overflow scroll is triggered when necessary
      bottomDivRef.current.scrollTop = 1;
    }
  }, [isBottomScrolled]);

  return (
    <>
      {/* {location.pathname === "/" ? (
        <div ref={topDivRef} className="w-full mx-auto flex sticky top-0 z-10">
          <div className="lg:w-[60%] w-0 text-[3rem] font-bold">
            {window.innerWidth > 1300 && (
              <img
                className="h-[256px] w-full"
                src="https://res.cloudinary.com/ddlupbcws/image/upload/q_100,f_auto/v1729002488/6918139_MotionGraphics_MotionGraphic_3840x2160-ezgif.com-optimize_ravllg.gif"
                alt="gif"
              />
            )}
          </div>
          <Carousel />
        </div>
      ) : (
        <div></div>
      )} */}
      <div
        ref={bottomDivRef}
        className="app z-[50] relative"
        onClick={() => {
          dispatch({ type: "setNotif", payload: false });
          dispatch({ type: "setAccount", payload: false });
        }}
      >
        {openMenu && (
          <div className="overlay !z-[100]" onClick={() => setOpenMenu(false)} />
        )}
        <div className={`popup-sidebar ${openMenu ? "open" : ""} !z-[100]`}>
          <PopupSidebar handleOpenMenu={handleOpenMenu} />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content">
          <Header toggleMenu={toggleMenu} />
          <div style={{ height: "fit-content !important" }}>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Markets stocks={stocks} />} />
              <Route
                path="/technicals"
                element={<Technicals stocks={stocks} />}
              />
              <Route path="/alert" element={<AlertManual />} />
              <Route
                path="/accounts"
                element={<Accounts setOpenApp={setOpen2} openApp={open2} />}
              />
              <Route path="/positions" element={<Holdings />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/wallet/withdraw" element={<Withdraw />} />
              <Route path="/wallet/live_withdraw" element={<LiveWithdraw />} />
              <Route path="/wallet/withdraw/otp" element={<WithdrawOTP />} />
              <Route path="/wallet/addfunds" element={<AddFunds />} />
              <Route
                path="/wallet/addfunds/banktransfer"
                element={<BankTransfer />}
              />
              <Route path="/wallet/addfunds/paystack" element={<Paystack />} />
              <Route path="/wallet/savinglock" element={<SavingLock />} />
              <Route
                path="/wallet/savinglock/durationselection"
                element={<DurationSelection />}
              />
              <Route
                path="/wallet/savinglock/durationselection/dayslock"
                element={<DaysLock />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/accountinformation" element={<Profile />} />
              <Route
                path="/accountinformation/proofofid"
                element={<Profile />}
              />
              <Route
                path="/accountinformation/proofofaddress"
                element={<Profile />}
              />
              <Route
                path="/accountinformation/uploadphotograph"
                element={<Profile />}
              />
              <Route path="/privacysecurity" element={<Profile />} />
              <Route
                path="/privacysecurity/changepassword"
                element={<Profile />}
              />
              <Route path="/helpsupport" element={<Profile />} />
              <Route path="/payment" element={<Profile />} />
              <Route path="/payment/addbank" element={<Profile />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/support" element={<Support />} />
              <Route path="/support/:id" element={<SingleSupport />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [joinLoading, setJoinLoading] = useState(false);
  const [open2, setOpen2] = useState(false);
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const onCloseModal1 = () => {
    dispatch({ type: "setLiveDemo", payload: false });
    setOpen2(false);
  };
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M12.854 10.5005L16.8545 14.501C17.1144 14.7609 17.1144 15.1829 16.8545 15.4428L15.4429 16.8544C15.1831 17.1142 14.7617 17.1142 14.5012 16.8544L10.5001 12.8539L6.4989 16.8544C6.23903 17.1142 5.81771 17.1142 5.55784 16.8544L4.14559 15.4428C3.88571 15.1829 3.88571 14.7616 4.14559 14.501L8.14675 10.5005L4.14559 6.49939C3.88571 6.23952 3.88571 5.81755 4.14559 5.55767L5.5585 4.14608C5.81837 3.8862 6.23968 3.8862 6.49956 4.14608L10.5001 8.14723L14.5012 4.14608C14.7611 3.8862 15.1824 3.8862 15.4429 4.14608L16.8545 5.55833C17.1144 5.8182 17.1144 6.23952 16.8545 6.50005L12.854 10.5005Z"
        fill="#151515"
      />
    </svg>
  );

  const SendToWaitlist = () => {
    setLoading(true);
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    axios
      .post("/waitlist/", {
        email: localStorage.getItem("user_email"),
        device_type: deviceType,
      })
      .then((res) => {
        console.log(res);
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSuccess(false);
        setLoading(false);
      });
  };

  const JoinedUsers = () => {
    setJoinLoading(true);
    axios
      .get("/joinclicks/")
      .then((res) => {
        console.log(res);
        window.location.href = "https://leaderboard.gohamma.com";
        setJoinLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setSuccess(false)
        setJoinLoading(false);
      });
  };

  console.log(window.location.pathname);

  return (
    <>
      <Toaster />
      {window.location.pathname !== "/" && <SupportChatbot />}
      {window.location.pathname === "/auth" ? (
        <Authe />
      ) : window.location.pathname === "/register" ? (
        <Signup />
      ) : window.location.pathname === "/login" ? (
        <Signin />
      ) : (
        <Router>
          <MainContent />
        </Router>
      )}

      <Modal open={open2} onClose={onCloseModal1} center closeIcon={closeIcon}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "20px",
          }}
        >
          <svg
            width={`${window.innerWidth < 800 ? "250" : "297"}`}
            height="219"
            viewBox="0 0 297 219"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46.4112 157.065C46.5702 157.082 46.7308 157.052 46.8726 156.977C47.0144 156.903 47.1311 156.789 47.2079 156.649C47.2847 156.508 47.3182 156.348 47.3041 156.189C47.29 156.03 47.229 155.878 47.1288 155.753C47.0285 155.629 46.8936 155.536 46.741 155.488C46.5884 155.44 46.4249 155.439 46.2714 155.484C46.1178 155.528 45.981 155.618 45.8782 155.74C45.7754 155.863 45.7113 156.013 45.6939 156.172C45.6715 156.386 45.7344 156.599 45.8687 156.766C46.0031 156.933 46.1981 157.041 46.4112 157.065Z"
              fill="#156649"
            />
            <path
              d="M51.5897 157.624C51.7488 157.641 51.9095 157.611 52.0514 157.537C52.1933 157.463 52.31 157.348 52.3868 157.208C52.4636 157.068 52.497 156.907 52.4828 156.748C52.4686 156.589 52.4074 156.437 52.307 156.312C52.2066 156.188 52.0715 156.096 51.9188 156.048C51.766 156 51.6025 155.998 51.4489 156.044C51.2953 156.089 51.1586 156.178 51.056 156.301C50.9534 156.424 50.8895 156.575 50.8725 156.734C50.8609 156.839 50.8703 156.946 50.9002 157.048C50.93 157.15 50.9796 157.245 51.0463 157.328C51.1129 157.411 51.1952 157.479 51.2885 157.53C51.3817 157.581 51.4841 157.613 51.5897 157.624Z"
              fill="#156649"
            />
            <path
              d="M56.7684 158.184C56.9276 158.201 57.0882 158.171 57.2301 158.097C57.372 158.023 57.4887 157.908 57.5655 157.768C57.6423 157.627 57.6757 157.467 57.6615 157.308C57.6473 157.148 57.5862 156.997 57.4858 156.872C57.3853 156.747 57.2502 156.655 57.0975 156.607C56.9447 156.559 56.7812 156.558 56.6276 156.603C56.4741 156.648 56.3373 156.738 56.2347 156.861C56.1321 156.984 56.0682 157.134 56.0512 157.293C56.0396 157.399 56.049 157.506 56.0789 157.608C56.1087 157.71 56.1584 157.805 56.225 157.887C56.2916 157.97 56.3739 158.039 56.4672 158.09C56.5604 158.141 56.6628 158.173 56.7684 158.184Z"
              fill="#156649"
            />
            <path
              d="M61.9467 158.745C62.1057 158.762 62.2663 158.732 62.4081 158.658C62.5499 158.584 62.6666 158.469 62.7434 158.329C62.8202 158.189 62.8537 158.029 62.8396 157.869C62.8255 157.71 62.7645 157.558 62.6643 157.434C62.564 157.309 62.4291 157.217 62.2765 157.169C62.1239 157.121 61.9604 157.119 61.8069 157.164C61.6533 157.209 61.5165 157.298 61.4137 157.421C61.3109 157.543 61.2468 157.694 61.2294 157.853C61.2178 157.958 61.2272 158.065 61.2571 158.167C61.2869 158.269 61.3365 158.364 61.4031 158.447C61.4697 158.53 61.552 158.599 61.6453 158.65C61.7385 158.701 61.8409 158.733 61.9467 158.745Z"
              fill="#156649"
            />
            <path
              d="M67.1254 159.305C67.2845 159.322 67.4452 159.292 67.5871 159.217C67.7289 159.143 67.8457 159.029 67.9225 158.888C67.9993 158.748 68.0327 158.588 68.0185 158.428C68.0043 158.269 67.9431 158.117 67.8427 157.993C67.7423 157.868 67.6071 157.776 67.4544 157.728C67.3016 157.68 67.1381 157.679 66.9846 157.724C66.831 157.769 66.6942 157.859 66.5916 157.982C66.489 158.104 66.4251 158.255 66.4081 158.414C66.3966 158.52 66.406 158.627 66.4358 158.728C66.4656 158.83 66.5153 158.925 66.5819 159.008C66.6485 159.091 66.7308 159.16 66.8241 159.211C66.9173 159.261 67.0197 159.293 67.1254 159.305Z"
              fill="#156649"
            />
            <path
              d="M72.3036 159.864C72.4627 159.881 72.6234 159.851 72.7653 159.777C72.9072 159.703 73.0239 159.588 73.1007 159.448C73.1775 159.308 73.2109 159.147 73.1967 158.988C73.1825 158.829 73.1213 158.677 73.0209 158.552C72.9205 158.428 72.7854 158.336 72.6326 158.288C72.4799 158.24 72.3164 158.238 72.1628 158.284C72.0092 158.329 71.8725 158.418 71.7698 158.541C71.6672 158.664 71.6034 158.815 71.5863 158.974C71.5748 159.079 71.5842 159.186 71.614 159.288C71.6438 159.39 71.6935 159.485 71.7601 159.568C71.8268 159.651 71.9091 159.719 72.0023 159.77C72.0956 159.821 72.1979 159.853 72.3036 159.864Z"
              fill="#156649"
            />
            <path
              d="M77.4818 160.425C77.6409 160.443 77.8015 160.412 77.9433 160.338C78.0851 160.264 78.2018 160.15 78.2786 160.009C78.3554 159.869 78.3889 159.709 78.3748 159.55C78.3607 159.39 78.2997 159.239 78.1994 159.114C78.0992 158.989 77.9642 158.897 77.8116 158.849C77.659 158.801 77.4956 158.8 77.342 158.844C77.1885 158.889 77.0516 158.979 76.9488 159.101C76.8461 159.224 76.7819 159.374 76.7645 159.533C76.753 159.639 76.7624 159.746 76.7922 159.848C76.822 159.95 76.8716 160.045 76.9383 160.128C77.0049 160.211 77.0872 160.28 77.1804 160.331C77.2737 160.382 77.3761 160.414 77.4818 160.425Z"
              fill="#156649"
            />
            <path
              d="M82.661 160.985C82.8201 161.002 82.9808 160.972 83.1227 160.898C83.2646 160.824 83.3813 160.709 83.4581 160.569C83.5349 160.428 83.5683 160.268 83.5541 160.109C83.5399 159.949 83.4787 159.798 83.3783 159.673C83.2779 159.548 83.1428 159.456 82.99 159.409C82.8373 159.361 82.6738 159.359 82.5202 159.404C82.3666 159.449 82.2299 159.539 82.1273 159.662C82.0247 159.785 81.9608 159.935 81.9437 160.095C81.9322 160.2 81.9416 160.307 81.9714 160.409C82.0013 160.511 82.0509 160.606 82.1176 160.689C82.1842 160.771 82.2665 160.84 82.3597 160.891C82.453 160.942 82.5554 160.974 82.661 160.985Z"
              fill="#156649"
            />
            <path
              d="M87.8392 161.546C87.9984 161.564 88.159 161.533 88.3009 161.459C88.4428 161.385 88.5595 161.271 88.6363 161.13C88.7131 160.99 88.7465 160.83 88.7323 160.67C88.7181 160.511 88.6569 160.359 88.5565 160.234C88.4561 160.11 88.321 160.018 88.1683 159.97C88.0155 159.922 87.852 159.921 87.6984 159.966C87.5448 160.011 87.4081 160.1 87.3055 160.223C87.2029 160.346 87.139 160.497 87.122 160.656C87.1104 160.761 87.1198 160.868 87.1497 160.97C87.1795 161.072 87.2291 161.167 87.2958 161.25C87.3624 161.333 87.4447 161.401 87.538 161.452C87.6312 161.503 87.7336 161.535 87.8392 161.546Z"
              fill="#156649"
            />
            <path
              d="M93.017 162.106C93.176 162.124 93.3366 162.093 93.4784 162.019C93.6203 161.945 93.7369 161.831 93.8137 161.69C93.8905 161.55 93.924 161.39 93.9099 161.231C93.8958 161.071 93.8348 160.92 93.7346 160.795C93.6343 160.67 93.4994 160.578 93.3468 160.53C93.1942 160.482 93.0307 160.481 92.8772 160.525C92.7236 160.57 92.5868 160.66 92.484 160.782C92.3812 160.905 92.3171 161.055 92.2997 161.214C92.2882 161.32 92.2976 161.427 92.3274 161.529C92.3572 161.631 92.4068 161.726 92.4734 161.809C92.54 161.892 92.6223 161.961 92.7156 162.012C92.8088 162.063 92.9113 162.095 93.017 162.106Z"
              fill="#156649"
            />
            <path
              d="M98.196 162.666C98.3551 162.683 98.5158 162.653 98.6577 162.579C98.7996 162.505 98.9163 162.39 98.9931 162.25C99.0699 162.109 99.1033 161.949 99.0891 161.79C99.0749 161.63 99.0137 161.479 98.9133 161.354C98.8129 161.229 98.6778 161.137 98.525 161.089C98.3723 161.042 98.2088 161.04 98.0552 161.085C97.9016 161.13 97.7649 161.22 97.6623 161.343C97.5596 161.466 97.4958 161.616 97.4787 161.775C97.4559 161.989 97.5186 162.202 97.6531 162.369C97.7876 162.536 97.9828 162.643 98.196 162.666Z"
              fill="#156649"
            />
            <path
              d="M103.373 163.225C103.533 163.242 103.693 163.212 103.835 163.138C103.977 163.064 104.094 162.949 104.171 162.809C104.247 162.668 104.281 162.508 104.267 162.349C104.252 162.189 104.191 162.038 104.091 161.913C103.99 161.788 103.855 161.696 103.702 161.649C103.55 161.601 103.386 161.599 103.233 161.644C103.079 161.689 102.942 161.779 102.84 161.902C102.737 162.025 102.673 162.175 102.656 162.335C102.645 162.44 102.654 162.547 102.684 162.649C102.714 162.751 102.763 162.846 102.83 162.929C102.897 163.011 102.979 163.08 103.072 163.131C103.165 163.182 103.268 163.214 103.373 163.225Z"
              fill="#156649"
            />
            <path
              d="M108.552 163.787C108.711 163.804 108.872 163.774 109.014 163.7C109.155 163.626 109.272 163.511 109.349 163.371C109.426 163.231 109.459 163.071 109.445 162.911C109.431 162.752 109.37 162.6 109.27 162.476C109.17 162.351 109.035 162.259 108.882 162.211C108.729 162.163 108.566 162.161 108.412 162.206C108.259 162.251 108.122 162.34 108.019 162.463C107.916 162.585 107.852 162.736 107.835 162.895C107.823 163 107.833 163.107 107.863 163.209C107.892 163.311 107.942 163.406 108.009 163.489C108.075 163.572 108.157 163.641 108.251 163.692C108.344 163.743 108.446 163.775 108.552 163.787Z"
              fill="#156649"
            />
            <path
              d="M113.731 164.346C113.89 164.363 114.051 164.333 114.193 164.259C114.335 164.185 114.452 164.07 114.528 163.93C114.605 163.789 114.639 163.629 114.624 163.47C114.61 163.31 114.549 163.159 114.449 163.034C114.348 162.909 114.213 162.817 114.06 162.769C113.908 162.722 113.744 162.72 113.591 162.765C113.437 162.81 113.3 162.9 113.198 163.023C113.095 163.146 113.031 163.296 113.014 163.455C113.003 163.561 113.012 163.668 113.042 163.77C113.072 163.872 113.121 163.967 113.188 164.049C113.254 164.132 113.337 164.201 113.43 164.252C113.523 164.303 113.626 164.335 113.731 164.346Z"
              fill="#156649"
            />
            <path
              d="M118.91 164.906C119.069 164.923 119.229 164.893 119.371 164.819C119.513 164.745 119.63 164.63 119.707 164.49C119.783 164.349 119.817 164.189 119.803 164.03C119.788 163.87 119.727 163.719 119.627 163.594C119.526 163.469 119.391 163.377 119.239 163.329C119.086 163.282 118.922 163.28 118.769 163.325C118.615 163.37 118.478 163.46 118.376 163.583C118.273 163.706 118.209 163.856 118.192 164.015C118.181 164.121 118.19 164.228 118.22 164.33C118.25 164.432 118.299 164.527 118.366 164.61C118.433 164.692 118.515 164.761 118.608 164.812C118.702 164.863 118.804 164.895 118.91 164.906Z"
              fill="#156649"
            />
            <path
              d="M124.087 165.467C124.246 165.484 124.407 165.454 124.549 165.38C124.691 165.306 124.807 165.192 124.884 165.051C124.961 164.911 124.994 164.751 124.98 164.592C124.966 164.432 124.905 164.281 124.805 164.156C124.705 164.031 124.57 163.939 124.417 163.891C124.264 163.843 124.101 163.841 123.947 163.886C123.794 163.931 123.657 164.02 123.554 164.143C123.452 164.266 123.387 164.416 123.37 164.575C123.358 164.681 123.368 164.788 123.398 164.89C123.427 164.992 123.477 165.087 123.544 165.17C123.61 165.253 123.693 165.322 123.786 165.373C123.879 165.424 123.982 165.456 124.087 165.467Z"
              fill="#156649"
            />
            <path
              d="M129.266 166.027C129.426 166.044 129.586 166.014 129.728 165.94C129.87 165.866 129.987 165.751 130.064 165.611C130.14 165.47 130.174 165.31 130.16 165.151C130.145 164.991 130.084 164.84 129.984 164.715C129.883 164.59 129.748 164.498 129.596 164.45C129.443 164.402 129.279 164.401 129.126 164.446C128.972 164.491 128.835 164.581 128.733 164.704C128.63 164.827 128.566 164.977 128.549 165.136C128.538 165.242 128.547 165.349 128.577 165.451C128.607 165.553 128.656 165.648 128.723 165.73C128.79 165.813 128.872 165.882 128.965 165.933C129.058 165.984 129.161 166.016 129.266 166.027Z"
              fill="#156649"
            />
            <path
              d="M134.445 166.588C134.604 166.605 134.764 166.575 134.906 166.501C135.048 166.427 135.164 166.312 135.241 166.172C135.318 166.032 135.352 165.872 135.337 165.712C135.323 165.553 135.262 165.401 135.162 165.277C135.062 165.152 134.927 165.06 134.774 165.012C134.622 164.964 134.458 164.962 134.305 165.007C134.151 165.052 134.014 165.141 133.912 165.264C133.809 165.386 133.745 165.537 133.727 165.696C133.704 165.909 133.767 166.123 133.902 166.29C134.036 166.457 134.231 166.565 134.445 166.588Z"
              fill="#156649"
            />
            <path
              d="M139.624 167.148C139.783 167.165 139.944 167.135 140.085 167.06C140.227 166.986 140.344 166.872 140.421 166.732C140.497 166.591 140.531 166.431 140.517 166.272C140.503 166.113 140.442 165.961 140.342 165.836C140.241 165.712 140.106 165.619 139.954 165.571C139.801 165.523 139.638 165.522 139.484 165.567C139.331 165.611 139.194 165.701 139.091 165.823C138.988 165.946 138.924 166.096 138.907 166.255C138.895 166.361 138.904 166.468 138.934 166.57C138.964 166.672 139.014 166.767 139.08 166.85C139.147 166.933 139.229 167.002 139.323 167.053C139.416 167.104 139.518 167.136 139.624 167.148Z"
              fill="#156649"
            />
            <path
              d="M144.8 167.708C144.959 167.725 145.12 167.695 145.262 167.621C145.404 167.547 145.521 167.433 145.598 167.293C145.675 167.152 145.709 166.992 145.695 166.833C145.681 166.673 145.62 166.521 145.519 166.397C145.419 166.272 145.284 166.179 145.131 166.131C144.978 166.083 144.815 166.082 144.661 166.127C144.508 166.172 144.371 166.262 144.268 166.384C144.165 166.507 144.101 166.658 144.084 166.817C144.061 167.03 144.124 167.243 144.258 167.41C144.392 167.577 144.587 167.684 144.8 167.708Z"
              fill="#156649"
            />
            <path
              d="M149.977 168.269C150.136 168.286 150.297 168.256 150.439 168.183C150.581 168.109 150.698 167.994 150.775 167.854C150.852 167.714 150.886 167.554 150.872 167.394C150.858 167.235 150.797 167.083 150.697 166.958C150.597 166.834 150.462 166.741 150.309 166.693C150.157 166.645 149.993 166.643 149.839 166.688C149.686 166.733 149.549 166.822 149.446 166.944C149.343 167.067 149.279 167.217 149.261 167.377C149.239 167.59 149.301 167.803 149.435 167.97C149.569 168.138 149.764 168.245 149.977 168.269Z"
              fill="#156649"
            />
            <path
              d="M155.157 168.823C155.316 168.841 155.477 168.811 155.619 168.737C155.761 168.663 155.878 168.549 155.955 168.408C156.032 168.268 156.066 168.108 156.052 167.948C156.038 167.789 155.977 167.637 155.877 167.512C155.776 167.387 155.641 167.295 155.489 167.247C155.336 167.199 155.172 167.197 155.019 167.242C154.865 167.287 154.728 167.377 154.625 167.5C154.523 167.623 154.459 167.773 154.442 167.932C154.419 168.145 154.481 168.359 154.615 168.526C154.75 168.692 154.944 168.799 155.157 168.823Z"
              fill="#156649"
            />
            <path
              d="M160.335 169.388C160.494 169.405 160.655 169.375 160.797 169.3C160.939 169.226 161.055 169.112 161.132 168.971C161.209 168.831 161.242 168.671 161.228 168.511C161.214 168.352 161.153 168.2 161.052 168.076C160.952 167.951 160.817 167.859 160.664 167.811C160.511 167.763 160.348 167.762 160.194 167.807C160.041 167.852 159.904 167.942 159.801 168.065C159.699 168.187 159.635 168.338 159.618 168.497C159.595 168.71 159.658 168.924 159.792 169.091C159.927 169.258 160.122 169.365 160.335 169.388Z"
              fill="#156649"
            />
            <path
              d="M165.514 169.949C165.673 169.966 165.834 169.936 165.975 169.862C166.117 169.788 166.234 169.673 166.311 169.533C166.387 169.393 166.421 169.233 166.407 169.073C166.393 168.914 166.332 168.762 166.231 168.638C166.131 168.513 165.996 168.421 165.844 168.373C165.691 168.325 165.528 168.323 165.374 168.368C165.22 168.413 165.084 168.502 164.981 168.625C164.878 168.747 164.814 168.898 164.797 169.057C164.774 169.27 164.836 169.484 164.971 169.651C165.105 169.818 165.301 169.925 165.514 169.949Z"
              fill="#156649"
            />
            <path
              d="M170.692 170.508C170.851 170.526 171.012 170.495 171.154 170.421C171.296 170.347 171.412 170.233 171.489 170.093C171.566 169.952 171.599 169.792 171.585 169.633C171.571 169.473 171.51 169.322 171.41 169.197C171.31 169.072 171.175 168.98 171.022 168.932C170.869 168.884 170.706 168.883 170.552 168.927C170.399 168.972 170.262 169.062 170.159 169.184C170.057 169.307 169.992 169.457 169.975 169.616C169.963 169.722 169.973 169.829 170.003 169.931C170.032 170.033 170.082 170.128 170.149 170.211C170.215 170.294 170.298 170.363 170.391 170.414C170.484 170.465 170.587 170.497 170.692 170.508Z"
              fill="#156649"
            />
            <path
              d="M175.87 171.075C176.029 171.092 176.19 171.062 176.332 170.988C176.474 170.914 176.591 170.799 176.667 170.659C176.744 170.518 176.778 170.358 176.763 170.199C176.749 170.04 176.688 169.888 176.588 169.763C176.487 169.639 176.352 169.546 176.199 169.499C176.047 169.451 175.883 169.449 175.73 169.494C175.576 169.54 175.439 169.629 175.337 169.752C175.234 169.875 175.17 170.025 175.153 170.185C175.13 170.398 175.193 170.611 175.327 170.778C175.462 170.945 175.657 171.052 175.87 171.075Z"
              fill="#156649"
            />
            <path
              d="M181.049 171.63C181.208 171.647 181.369 171.616 181.51 171.542C181.652 171.468 181.769 171.354 181.846 171.214C181.923 171.073 181.956 170.913 181.942 170.754C181.928 170.595 181.867 170.443 181.767 170.318C181.666 170.194 181.531 170.101 181.379 170.053C181.226 170.005 181.063 170.004 180.909 170.049C180.756 170.093 180.619 170.183 180.516 170.305C180.413 170.428 180.349 170.578 180.332 170.737C180.309 170.951 180.372 171.164 180.506 171.332C180.64 171.499 180.836 171.606 181.049 171.63Z"
              fill="#156649"
            />
            <path
              d="M186.228 172.189C186.387 172.207 186.548 172.176 186.69 172.102C186.832 172.028 186.949 171.914 187.025 171.773C187.102 171.633 187.136 171.473 187.121 171.313C187.107 171.154 187.046 171.002 186.946 170.877C186.845 170.753 186.71 170.661 186.557 170.613C186.405 170.565 186.241 170.564 186.087 170.609C185.934 170.654 185.797 170.743 185.694 170.866C185.592 170.989 185.528 171.14 185.511 171.299C185.488 171.512 185.551 171.726 185.685 171.893C185.82 172.059 186.015 172.166 186.228 172.189Z"
              fill="#156649"
            />
            <path
              d="M191.406 172.749C191.566 172.766 191.726 172.736 191.868 172.662C192.01 172.588 192.127 172.473 192.204 172.333C192.28 172.192 192.314 172.032 192.3 171.873C192.285 171.713 192.224 171.562 192.124 171.437C192.023 171.312 191.888 171.22 191.735 171.172C191.583 171.125 191.419 171.123 191.266 171.168C191.112 171.213 190.975 171.303 190.873 171.426C190.77 171.549 190.706 171.699 190.689 171.858C190.666 172.072 190.729 172.285 190.864 172.452C190.998 172.619 191.193 172.726 191.406 172.749Z"
              fill="#156649"
            />
            <path
              d="M196.581 173.31C196.74 173.327 196.901 173.297 197.043 173.223C197.185 173.149 197.301 173.035 197.378 172.894C197.455 172.754 197.488 172.594 197.474 172.435C197.46 172.275 197.399 172.124 197.299 171.999C197.199 171.874 197.064 171.782 196.911 171.734C196.758 171.686 196.595 171.684 196.441 171.729C196.288 171.774 196.151 171.863 196.048 171.986C195.945 172.109 195.881 172.259 195.864 172.418C195.841 172.631 195.904 172.845 196.038 173.012C196.173 173.18 196.368 173.287 196.581 173.31Z"
              fill="#156649"
            />
            <path
              d="M201.763 173.87C201.922 173.887 202.083 173.857 202.225 173.783C202.367 173.709 202.484 173.594 202.56 173.454C202.637 173.313 202.671 173.153 202.656 172.994C202.642 172.834 202.581 172.683 202.481 172.558C202.38 172.433 202.245 172.341 202.092 172.293C201.94 172.245 201.776 172.244 201.623 172.289C201.469 172.334 201.332 172.424 201.23 172.547C201.127 172.67 201.063 172.82 201.046 172.979C201.023 173.192 201.086 173.406 201.22 173.573C201.355 173.74 201.55 173.847 201.763 173.87Z"
              fill="#156649"
            />
            <path
              d="M206.942 174.429C207.101 174.447 207.262 174.416 207.403 174.342C207.545 174.268 207.662 174.154 207.739 174.013C207.816 173.873 207.849 173.713 207.835 173.553C207.821 173.394 207.759 173.242 207.659 173.117C207.559 172.993 207.424 172.901 207.271 172.853C207.118 172.805 206.955 172.804 206.801 172.849C206.647 172.894 206.511 172.983 206.408 173.106C206.305 173.229 206.242 173.38 206.225 173.539C206.213 173.644 206.222 173.751 206.252 173.853C206.282 173.955 206.332 174.05 206.398 174.133C206.465 174.216 206.547 174.284 206.64 174.335C206.734 174.386 206.836 174.418 206.942 174.429Z"
              fill="#156649"
            />
            <path
              d="M212.119 174.991C212.279 175.008 212.439 174.978 212.581 174.904C212.723 174.829 212.839 174.715 212.916 174.575C212.993 174.434 213.027 174.274 213.012 174.115C212.998 173.956 212.937 173.804 212.837 173.679C212.737 173.555 212.602 173.462 212.449 173.414C212.297 173.366 212.133 173.365 211.98 173.41C211.826 173.454 211.689 173.544 211.587 173.666C211.484 173.789 211.42 173.939 211.402 174.098C211.391 174.204 211.4 174.311 211.43 174.413C211.46 174.515 211.509 174.61 211.576 174.693C211.643 174.776 211.725 174.845 211.818 174.896C211.911 174.947 212.014 174.979 212.119 174.991Z"
              fill="#156649"
            />
            <path
              d="M217.299 175.55C217.458 175.567 217.619 175.537 217.76 175.463C217.902 175.389 218.019 175.274 218.096 175.134C218.173 174.994 218.206 174.834 218.192 174.674C218.178 174.515 218.116 174.363 218.016 174.238C217.916 174.114 217.78 174.022 217.628 173.974C217.475 173.926 217.311 173.924 217.158 173.97C217.004 174.015 216.868 174.104 216.765 174.227C216.662 174.35 216.598 174.501 216.581 174.66C216.57 174.765 216.579 174.872 216.609 174.974C216.639 175.076 216.689 175.171 216.755 175.254C216.822 175.337 216.904 175.405 216.997 175.456C217.091 175.507 217.193 175.539 217.299 175.55Z"
              fill="#156649"
            />
            <path
              d="M222.477 176.11C222.636 176.127 222.797 176.097 222.939 176.023C223.081 175.949 223.197 175.834 223.274 175.694C223.351 175.553 223.384 175.393 223.37 175.234C223.356 175.074 223.295 174.923 223.194 174.798C223.094 174.673 222.959 174.581 222.806 174.533C222.653 174.485 222.49 174.484 222.336 174.529C222.183 174.574 222.046 174.664 221.943 174.787C221.841 174.91 221.777 175.06 221.76 175.219C221.748 175.325 221.758 175.432 221.787 175.534C221.817 175.636 221.867 175.731 221.933 175.813C222 175.896 222.082 175.965 222.176 176.016C222.269 176.067 222.371 176.099 222.477 176.11Z"
              fill="#156649"
            />
            <path
              d="M227.656 176.671C227.815 176.688 227.976 176.658 228.118 176.584C228.259 176.51 228.376 176.395 228.453 176.255C228.53 176.115 228.563 175.955 228.549 175.795C228.535 175.636 228.474 175.484 228.374 175.36C228.274 175.235 228.139 175.143 227.986 175.095C227.833 175.047 227.67 175.045 227.516 175.09C227.363 175.135 227.226 175.224 227.123 175.347C227.02 175.469 226.956 175.62 226.939 175.779C226.927 175.885 226.937 175.991 226.967 176.094C226.996 176.196 227.046 176.291 227.113 176.374C227.179 176.456 227.261 176.525 227.355 176.576C227.448 176.627 227.55 176.66 227.656 176.671Z"
              fill="#156649"
            />
            <path
              d="M45.8537 162.239C46.0128 162.257 46.1734 162.226 46.3152 162.152C46.457 162.078 46.5737 161.964 46.6505 161.823C46.7273 161.683 46.7608 161.523 46.7467 161.364C46.7326 161.204 46.6716 161.053 46.5713 160.928C46.4711 160.803 46.3361 160.711 46.1835 160.663C46.0309 160.615 45.8675 160.614 45.7139 160.658C45.5604 160.703 45.4235 160.793 45.3207 160.915C45.218 161.038 45.1538 161.188 45.1364 161.347C45.1136 161.561 45.1763 161.774 45.3107 161.942C45.4452 162.109 45.6404 162.216 45.8537 162.239Z"
              fill="#156649"
            />
            <path
              d="M51.0305 162.803C51.1896 162.82 51.3503 162.79 51.4922 162.716C51.634 162.641 51.7508 162.527 51.8276 162.386C51.9044 162.246 51.9378 162.086 51.9236 161.927C51.9094 161.767 51.8482 161.615 51.7478 161.491C51.6474 161.366 51.5123 161.274 51.3595 161.226C51.2068 161.178 51.0432 161.177 50.8897 161.222C50.7361 161.267 50.5993 161.357 50.4967 161.48C50.3941 161.602 50.3302 161.753 50.3132 161.912C50.2904 162.125 50.3531 162.339 50.4876 162.506C50.6221 162.673 50.8173 162.78 51.0305 162.803Z"
              fill="#156649"
            />
            <path
              d="M56.2084 163.362C56.3675 163.379 56.5282 163.349 56.6701 163.275C56.8119 163.201 56.9287 163.086 57.0055 162.946C57.0823 162.806 57.1157 162.646 57.1015 162.486C57.0873 162.327 57.0261 162.175 56.9257 162.05C56.8253 161.926 56.6902 161.834 56.5374 161.786C56.3847 161.738 56.2211 161.736 56.0676 161.782C55.914 161.827 55.7772 161.916 55.6746 162.039C55.572 162.162 55.5081 162.313 55.4911 162.472C55.4796 162.577 55.489 162.684 55.5188 162.786C55.5486 162.888 55.5983 162.983 55.6649 163.066C55.7315 163.149 55.8138 163.217 55.9071 163.268C56.0004 163.319 56.1027 163.351 56.2084 163.362Z"
              fill="#156649"
            />
            <path
              d="M61.3874 163.924C61.5464 163.941 61.707 163.91 61.8489 163.836C61.9907 163.762 62.1074 163.648 62.1842 163.508C62.261 163.367 62.2945 163.207 62.2804 163.048C62.2663 162.889 62.2053 162.737 62.105 162.612C62.0048 162.488 61.8698 162.395 61.7172 162.347C61.5646 162.299 61.4012 162.298 61.2476 162.343C61.094 162.387 60.9572 162.477 60.8544 162.599C60.7516 162.722 60.6875 162.872 60.6701 163.031C60.6473 163.245 60.71 163.458 60.8444 163.626C60.9788 163.793 61.1741 163.9 61.3874 163.924Z"
              fill="#156649"
            />
            <path
              d="M67.4177 163.924C67.561 163.501 67.3341 163.042 66.9109 162.899C66.4877 162.755 66.0284 162.982 65.885 163.405C65.7417 163.829 65.9686 164.288 66.3918 164.431C66.815 164.575 67.2743 164.348 67.4177 163.924Z"
              fill="#156649"
            />
            <path
              d="M71.7435 165.043C71.9027 165.06 72.0633 165.03 72.2052 164.956C72.3471 164.882 72.4638 164.767 72.5406 164.627C72.6174 164.486 72.6508 164.326 72.6366 164.167C72.6224 164.007 72.5613 163.856 72.4609 163.731C72.3604 163.606 72.2253 163.514 72.0726 163.466C71.9198 163.419 71.7563 163.417 71.6027 163.462C71.4492 163.507 71.3124 163.597 71.2098 163.72C71.1072 163.843 71.0433 163.993 71.0263 164.152C71.0147 164.258 71.0241 164.365 71.054 164.467C71.0838 164.569 71.1334 164.664 71.2001 164.746C71.2667 164.829 71.349 164.898 71.4423 164.949C71.5355 165 71.6379 165.032 71.7435 165.043Z"
              fill="#156649"
            />
            <path
              d="M76.9226 165.604C77.0816 165.621 77.2422 165.591 77.384 165.517C77.5258 165.443 77.6425 165.329 77.7193 165.188C77.7961 165.048 77.8296 164.888 77.8155 164.729C77.8014 164.569 77.7404 164.418 77.6402 164.293C77.5399 164.168 77.405 164.076 77.2524 164.028C77.0998 163.98 76.9363 163.978 76.7828 164.023C76.6292 164.068 76.4924 164.157 76.3896 164.28C76.2868 164.403 76.2227 164.553 76.2053 164.712C76.1824 164.925 76.2451 165.139 76.3796 165.306C76.514 165.474 76.7093 165.581 76.9226 165.604Z"
              fill="#156649"
            />
            <path
              d="M82.1008 166.163C82.2599 166.181 82.4206 166.15 82.5625 166.076C82.7044 166.002 82.8211 165.888 82.8979 165.747C82.9747 165.607 83.0081 165.447 82.9939 165.287C82.9797 165.128 82.9185 164.976 82.8181 164.852C82.7177 164.727 82.5826 164.635 82.4298 164.587C82.2771 164.539 82.1135 164.538 81.96 164.583C81.8064 164.628 81.6696 164.718 81.567 164.84C81.4644 164.963 81.4006 165.114 81.3835 165.273C81.3607 165.486 81.4234 165.7 81.5579 165.867C81.6924 166.034 81.8876 166.14 82.1008 166.163Z"
              fill="#156649"
            />
            <path
              d="M87.2797 166.723C87.4388 166.74 87.5995 166.71 87.7414 166.636C87.8832 166.562 88 166.447 88.0768 166.307C88.1536 166.166 88.187 166.006 88.1728 165.847C88.1586 165.687 88.0974 165.536 87.997 165.411C87.8966 165.287 87.7615 165.194 87.6087 165.147C87.456 165.099 87.2924 165.097 87.1389 165.142C86.9853 165.188 86.8485 165.277 86.7459 165.4C86.6433 165.523 86.5794 165.673 86.5624 165.833C86.5509 165.938 86.5603 166.045 86.5901 166.147C86.6199 166.249 86.6696 166.344 86.7362 166.427C86.8028 166.509 86.8851 166.578 86.9784 166.629C87.0717 166.68 87.174 166.712 87.2797 166.723Z"
              fill="#156649"
            />
            <path
              d="M92.4577 167.284C92.6168 167.302 92.7774 167.271 92.9192 167.197C93.061 167.123 93.1777 167.009 93.2545 166.868C93.3313 166.728 93.3648 166.568 93.3507 166.409C93.3366 166.249 93.2756 166.098 93.1753 165.973C93.0751 165.848 92.9401 165.756 92.7875 165.708C92.6349 165.66 92.4715 165.658 92.3179 165.703C92.1644 165.748 92.0275 165.838 91.9247 165.96C91.822 166.083 91.7578 166.233 91.7405 166.392C91.7176 166.605 91.7803 166.819 91.9147 166.986C92.0492 167.154 92.2444 167.261 92.4577 167.284Z"
              fill="#156649"
            />
            <path
              d="M97.6342 167.844C97.7934 167.862 97.9542 167.832 98.0963 167.758C98.2384 167.684 98.3553 167.57 98.4324 167.429C98.5094 167.289 98.5431 167.129 98.5291 166.969C98.5152 166.81 98.4541 166.658 98.3538 166.533C98.2535 166.408 98.1185 166.316 97.9657 166.268C97.8129 166.22 97.6493 166.218 97.4957 166.263C97.342 166.308 97.2051 166.398 97.1024 166.521C96.9997 166.644 96.9358 166.794 96.9187 166.953C96.8958 167.166 96.9583 167.38 97.0924 167.547C97.2266 167.713 97.4214 167.82 97.6342 167.844Z"
              fill="#156649"
            />
            <path
              d="M102.813 168.404C102.972 168.421 103.133 168.391 103.275 168.317C103.417 168.242 103.533 168.128 103.61 167.988C103.687 167.847 103.72 167.687 103.706 167.528C103.692 167.368 103.631 167.216 103.53 167.092C103.43 166.967 103.295 166.875 103.142 166.827C102.989 166.779 102.826 166.778 102.672 166.823C102.519 166.868 102.382 166.958 102.279 167.081C102.177 167.204 102.113 167.354 102.096 167.513C102.073 167.726 102.136 167.94 102.27 168.107C102.405 168.274 102.6 168.381 102.813 168.404Z"
              fill="#156649"
            />
            <path
              d="M107.991 168.965C108.15 168.982 108.311 168.952 108.453 168.878C108.595 168.804 108.711 168.69 108.788 168.549C108.865 168.409 108.898 168.249 108.884 168.09C108.87 167.93 108.809 167.779 108.709 167.654C108.609 167.529 108.474 167.437 108.321 167.389C108.169 167.341 108.005 167.339 107.852 167.384C107.698 167.429 107.561 167.518 107.458 167.641C107.356 167.764 107.292 167.914 107.274 168.073C107.251 168.286 107.314 168.5 107.448 168.667C107.583 168.835 107.778 168.942 107.991 168.965Z"
              fill="#156649"
            />
            <path
              d="M113.17 169.525C113.329 169.542 113.49 169.512 113.632 169.438C113.774 169.364 113.89 169.249 113.967 169.109C114.044 168.968 114.077 168.808 114.063 168.649C114.049 168.489 113.988 168.338 113.887 168.213C113.787 168.088 113.652 167.996 113.499 167.948C113.346 167.901 113.183 167.899 113.029 167.944C112.876 167.989 112.739 168.079 112.636 168.202C112.534 168.325 112.47 168.475 112.453 168.634C112.43 168.847 112.493 169.061 112.627 169.228C112.762 169.395 112.957 169.502 113.17 169.525Z"
              fill="#156649"
            />
            <path
              d="M118.348 170.084C118.507 170.102 118.668 170.071 118.81 169.997C118.952 169.923 119.069 169.809 119.145 169.668C119.222 169.528 119.256 169.368 119.241 169.208C119.227 169.049 119.166 168.897 119.066 168.773C118.965 168.648 118.83 168.556 118.677 168.508C118.525 168.46 118.361 168.459 118.208 168.504C118.054 168.549 117.917 168.639 117.815 168.761C117.712 168.884 117.648 169.035 117.631 169.194C117.608 169.407 117.671 169.621 117.805 169.788C117.94 169.955 118.135 170.061 118.348 170.084Z"
              fill="#156649"
            />
            <path
              d="M123.527 170.646C123.686 170.663 123.846 170.633 123.988 170.559C124.13 170.484 124.247 170.37 124.323 170.23C124.4 170.089 124.434 169.929 124.42 169.77C124.405 169.611 124.344 169.459 124.244 169.334C124.144 169.21 124.009 169.118 123.856 169.07C123.704 169.022 123.54 169.02 123.387 169.065C123.233 169.11 123.096 169.199 122.994 169.321C122.891 169.444 122.827 169.594 122.809 169.753C122.786 169.967 122.849 170.181 122.984 170.348C123.118 170.515 123.313 170.622 123.527 170.646Z"
              fill="#156649"
            />
            <path
              d="M128.705 171.205C128.864 171.223 129.025 171.192 129.167 171.118C129.309 171.044 129.426 170.929 129.502 170.789C129.579 170.649 129.613 170.489 129.598 170.329C129.584 170.17 129.523 170.018 129.423 169.893C129.322 169.769 129.187 169.677 129.034 169.629C128.882 169.581 128.718 169.579 128.564 169.625C128.411 169.67 128.274 169.759 128.172 169.882C128.069 170.005 128.005 170.156 127.988 170.315C127.965 170.528 128.028 170.741 128.162 170.908C128.297 171.075 128.492 171.182 128.705 171.205Z"
              fill="#156649"
            />
            <path
              d="M133.883 171.768C134.043 171.785 134.203 171.755 134.345 171.681C134.487 171.607 134.604 171.492 134.681 171.352C134.757 171.212 134.791 171.052 134.777 170.892C134.762 170.733 134.701 170.581 134.601 170.456C134.5 170.332 134.365 170.24 134.213 170.192C134.06 170.144 133.896 170.142 133.743 170.188C133.589 170.233 133.452 170.322 133.35 170.445C133.247 170.568 133.183 170.719 133.166 170.878C133.143 171.091 133.206 171.304 133.341 171.471C133.475 171.638 133.67 171.745 133.883 171.768Z"
              fill="#156649"
            />
            <path
              d="M139.063 172.326C139.222 172.343 139.383 172.313 139.524 172.239C139.666 172.165 139.783 172.05 139.86 171.91C139.936 171.77 139.97 171.61 139.956 171.45C139.942 171.291 139.881 171.139 139.78 171.015C139.68 170.89 139.545 170.798 139.393 170.75C139.24 170.702 139.077 170.7 138.923 170.745C138.77 170.79 138.633 170.879 138.53 171.002C138.427 171.125 138.363 171.275 138.346 171.434C138.334 171.54 138.343 171.646 138.373 171.749C138.403 171.851 138.453 171.946 138.519 172.029C138.586 172.112 138.668 172.18 138.761 172.231C138.855 172.283 138.957 172.315 139.063 172.326Z"
              fill="#156649"
            />
            <path
              d="M144.24 172.886C144.4 172.903 144.56 172.873 144.702 172.798C144.844 172.724 144.961 172.61 145.038 172.469C145.114 172.329 145.148 172.169 145.134 172.01C145.119 171.85 145.058 171.698 144.958 171.574C144.857 171.449 144.722 171.357 144.569 171.309C144.417 171.261 144.253 171.26 144.1 171.305C143.946 171.35 143.809 171.44 143.707 171.563C143.604 171.685 143.54 171.836 143.523 171.995C143.5 172.208 143.563 172.422 143.698 172.589C143.832 172.756 144.027 172.863 144.24 172.886Z"
              fill="#156649"
            />
            <path
              d="M149.419 173.445C149.578 173.462 149.739 173.432 149.881 173.358C150.022 173.284 150.139 173.169 150.216 173.029C150.293 172.889 150.326 172.729 150.312 172.569C150.298 172.41 150.237 172.258 150.136 172.133C150.036 172.009 149.901 171.917 149.748 171.869C149.595 171.821 149.432 171.819 149.278 171.865C149.124 171.91 148.988 171.999 148.885 172.122C148.782 172.245 148.719 172.396 148.702 172.555C148.69 172.66 148.699 172.767 148.729 172.869C148.759 172.971 148.809 173.066 148.875 173.149C148.942 173.232 149.024 173.3 149.118 173.351C149.211 173.402 149.313 173.434 149.419 173.445Z"
              fill="#156649"
            />
            <path
              d="M154.597 174.007C154.756 174.024 154.917 173.993 155.059 173.919C155.201 173.845 155.317 173.731 155.394 173.591C155.471 173.45 155.504 173.29 155.49 173.131C155.476 172.972 155.415 172.82 155.315 172.695C155.215 172.571 155.08 172.478 154.927 172.43C154.774 172.382 154.611 172.381 154.457 172.426C154.304 172.47 154.167 172.56 154.064 172.682C153.961 172.805 153.897 172.955 153.88 173.114C153.868 173.22 153.877 173.327 153.907 173.429C153.937 173.531 153.986 173.627 154.053 173.71C154.12 173.792 154.202 173.861 154.296 173.912C154.389 173.963 154.491 173.995 154.597 174.007Z"
              fill="#156649"
            />
            <path
              d="M159.776 174.566C159.935 174.584 160.096 174.553 160.237 174.479C160.379 174.405 160.496 174.291 160.573 174.15C160.65 174.01 160.683 173.85 160.669 173.69C160.655 173.531 160.593 173.379 160.493 173.254C160.393 173.13 160.258 173.038 160.105 172.99C159.952 172.942 159.789 172.941 159.635 172.986C159.481 173.031 159.345 173.12 159.242 173.243C159.139 173.366 159.076 173.517 159.058 173.676C159.047 173.781 159.056 173.888 159.086 173.99C159.116 174.092 159.166 174.187 159.232 174.27C159.299 174.353 159.381 174.421 159.474 174.472C159.568 174.523 159.67 174.555 159.776 174.566Z"
              fill="#156649"
            />
            <path
              d="M164.954 175.126C165.113 175.143 165.274 175.113 165.416 175.039C165.558 174.965 165.674 174.85 165.751 174.71C165.828 174.569 165.861 174.409 165.847 174.25C165.833 174.09 165.772 173.939 165.671 173.814C165.571 173.689 165.436 173.597 165.283 173.549C165.13 173.502 164.967 173.5 164.813 173.545C164.66 173.59 164.523 173.68 164.42 173.803C164.318 173.926 164.254 174.076 164.237 174.235C164.225 174.341 164.235 174.448 164.264 174.55C164.294 174.652 164.344 174.747 164.411 174.83C164.477 174.912 164.559 174.981 164.653 175.032C164.746 175.083 164.848 175.115 164.954 175.126Z"
              fill="#156649"
            />
            <path
              d="M170.133 175.687C170.292 175.705 170.453 175.674 170.595 175.6C170.737 175.526 170.853 175.412 170.93 175.271C171.007 175.131 171.04 174.971 171.026 174.812C171.012 174.652 170.951 174.501 170.851 174.376C170.751 174.251 170.616 174.159 170.463 174.111C170.311 174.063 170.147 174.062 169.994 174.106C169.84 174.151 169.703 174.241 169.6 174.363C169.498 174.486 169.433 174.636 169.416 174.795C169.404 174.901 169.414 175.008 169.443 175.11C169.473 175.212 169.523 175.308 169.589 175.39C169.656 175.473 169.738 175.542 169.832 175.593C169.925 175.644 170.028 175.676 170.133 175.687Z"
              fill="#156649"
            />
            <path
              d="M175.311 176.246C175.471 176.264 175.631 176.233 175.773 176.159C175.915 176.085 176.032 175.971 176.109 175.83C176.185 175.69 176.219 175.53 176.204 175.37C176.19 175.211 176.129 175.059 176.029 174.935C175.928 174.81 175.793 174.718 175.64 174.67C175.488 174.622 175.324 174.621 175.171 174.666C175.017 174.711 174.88 174.801 174.778 174.923C174.675 175.046 174.611 175.197 174.594 175.356C174.583 175.462 174.592 175.568 174.622 175.67C174.652 175.772 174.701 175.867 174.768 175.95C174.835 176.033 174.917 176.102 175.01 176.152C175.103 176.203 175.206 176.235 175.311 176.246Z"
              fill="#156649"
            />
            <path
              d="M180.49 176.808C180.649 176.825 180.809 176.795 180.951 176.721C181.093 176.647 181.21 176.533 181.286 176.392C181.363 176.252 181.397 176.092 181.383 175.933C181.369 175.773 181.307 175.622 181.207 175.497C181.107 175.372 180.972 175.28 180.819 175.232C180.667 175.184 180.503 175.182 180.35 175.227C180.196 175.272 180.059 175.361 179.957 175.484C179.854 175.607 179.79 175.757 179.772 175.916C179.761 176.022 179.77 176.129 179.8 176.231C179.83 176.333 179.879 176.428 179.946 176.511C180.013 176.594 180.095 176.663 180.188 176.714C180.282 176.765 180.384 176.797 180.49 176.808Z"
              fill="#156649"
            />
            <path
              d="M185.667 177.367C185.826 177.385 185.986 177.354 186.128 177.28C186.27 177.206 186.387 177.092 186.463 176.951C186.54 176.811 186.574 176.651 186.56 176.492C186.545 176.332 186.484 176.181 186.384 176.056C186.284 175.931 186.149 175.839 185.996 175.791C185.844 175.743 185.68 175.741 185.527 175.786C185.373 175.831 185.236 175.921 185.134 176.043C185.031 176.166 184.967 176.316 184.949 176.475C184.938 176.581 184.947 176.688 184.976 176.79C185.006 176.892 185.056 176.987 185.122 177.07C185.189 177.153 185.271 177.222 185.365 177.273C185.458 177.324 185.561 177.356 185.667 177.367Z"
              fill="#156649"
            />
            <path
              d="M190.847 177.927C191.006 177.945 191.166 177.914 191.308 177.84C191.45 177.766 191.567 177.652 191.644 177.511C191.72 177.371 191.754 177.211 191.74 177.051C191.725 176.892 191.664 176.74 191.564 176.616C191.463 176.491 191.328 176.399 191.176 176.351C191.023 176.303 190.859 176.302 190.706 176.347C190.552 176.392 190.415 176.482 190.313 176.604C190.21 176.727 190.146 176.878 190.129 177.037C190.118 177.143 190.127 177.249 190.157 177.351C190.187 177.453 190.236 177.548 190.303 177.631C190.37 177.714 190.452 177.783 190.545 177.833C190.639 177.884 190.741 177.916 190.847 177.927Z"
              fill="#156649"
            />
            <path
              d="M196.025 178.489C196.184 178.506 196.345 178.476 196.487 178.402C196.628 178.327 196.745 178.213 196.822 178.073C196.899 177.932 196.932 177.772 196.918 177.613C196.904 177.454 196.843 177.302 196.743 177.177C196.642 177.053 196.508 176.961 196.355 176.913C196.202 176.865 196.039 176.863 195.885 176.908C195.732 176.953 195.595 177.042 195.492 177.165C195.389 177.287 195.325 177.437 195.308 177.596C195.285 177.81 195.348 178.024 195.482 178.191C195.617 178.358 195.812 178.465 196.025 178.489Z"
              fill="#156649"
            />
            <path
              d="M201.203 179.045C201.362 179.062 201.523 179.032 201.665 178.958C201.807 178.884 201.924 178.769 202 178.629C202.077 178.488 202.111 178.328 202.096 178.169C202.082 178.009 202.021 177.858 201.921 177.733C201.82 177.608 201.685 177.516 201.532 177.468C201.38 177.421 201.216 177.419 201.062 177.464C200.909 177.509 200.772 177.599 200.67 177.722C200.567 177.845 200.503 177.995 200.486 178.154C200.463 178.368 200.526 178.581 200.66 178.748C200.795 178.915 200.99 179.022 201.203 179.045Z"
              fill="#156649"
            />
            <path
              d="M206.382 179.608C206.541 179.625 206.702 179.595 206.843 179.521C206.985 179.447 207.102 179.332 207.179 179.192C207.256 179.051 207.289 178.891 207.275 178.732C207.261 178.572 207.199 178.421 207.099 178.296C206.999 178.171 206.863 178.079 206.711 178.031C206.558 177.984 206.394 177.982 206.241 178.027C206.087 178.072 205.951 178.162 205.848 178.285C205.745 178.408 205.681 178.558 205.664 178.717C205.653 178.823 205.662 178.93 205.692 179.032C205.722 179.134 205.772 179.229 205.838 179.311C205.905 179.394 205.987 179.463 206.08 179.514C206.174 179.565 206.276 179.597 206.382 179.608Z"
              fill="#156649"
            />
            <path
              d="M211.561 180.169C211.72 180.186 211.88 180.156 212.022 180.082C212.164 180.008 212.281 179.893 212.358 179.753C212.434 179.613 212.468 179.453 212.454 179.293C212.44 179.134 212.379 178.983 212.278 178.858C212.178 178.733 212.043 178.641 211.891 178.593C211.738 178.545 211.575 178.543 211.421 178.588C211.267 178.633 211.131 178.722 211.028 178.845C210.925 178.968 210.861 179.118 210.843 179.277C210.821 179.49 210.883 179.704 211.018 179.871C211.152 180.038 211.347 180.146 211.561 180.169Z"
              fill="#156649"
            />
            <path
              d="M216.738 180.729C216.898 180.746 217.058 180.716 217.2 180.642C217.342 180.568 217.459 180.453 217.536 180.313C217.612 180.172 217.646 180.012 217.632 179.853C217.617 179.693 217.556 179.542 217.456 179.417C217.355 179.292 217.22 179.2 217.068 179.152C216.915 179.105 216.751 179.103 216.598 179.148C216.444 179.193 216.307 179.283 216.205 179.406C216.102 179.529 216.038 179.679 216.021 179.838C215.998 180.052 216.061 180.265 216.196 180.432C216.33 180.599 216.525 180.706 216.738 180.729Z"
              fill="#156649"
            />
            <path
              d="M221.917 181.297C222.076 181.314 222.237 181.284 222.379 181.21C222.52 181.136 222.637 181.021 222.714 180.881C222.791 180.74 222.824 180.58 222.81 180.421C222.796 180.261 222.735 180.11 222.634 179.985C222.534 179.861 222.399 179.768 222.246 179.721C222.093 179.673 221.93 179.671 221.776 179.716C221.622 179.761 221.486 179.851 221.383 179.974C221.281 180.097 221.217 180.247 221.2 180.407C221.188 180.512 221.197 180.619 221.227 180.721C221.257 180.823 221.307 180.918 221.373 181.001C221.44 181.083 221.522 181.152 221.616 181.203C221.709 181.254 221.811 181.286 221.917 181.297Z"
              fill="#156649"
            />
            <path
              d="M227.096 181.85C227.255 181.867 227.415 181.837 227.557 181.764C227.699 181.69 227.815 181.576 227.892 181.436C227.97 181.296 228.004 181.137 227.99 180.978C227.977 180.818 227.916 180.667 227.817 180.542C227.718 180.417 227.583 180.324 227.431 180.275C227.279 180.226 227.116 180.224 226.962 180.267C226.809 180.311 226.672 180.399 226.568 180.521C226.464 180.643 226.399 180.792 226.38 180.951C226.368 181.057 226.377 181.164 226.406 181.267C226.436 181.37 226.485 181.465 226.551 181.549C226.618 181.633 226.7 181.702 226.794 181.754C226.887 181.805 226.99 181.838 227.096 181.85Z"
              fill="#156649"
            />
            <path
              d="M45.2905 167.422C45.4496 167.439 45.6103 167.409 45.7523 167.335C45.8943 167.262 46.0113 167.147 46.0883 167.007C46.1654 166.867 46.1991 166.707 46.1853 166.547C46.1714 166.388 46.1105 166.236 46.0104 166.111C45.9103 165.986 45.7754 165.894 45.6227 165.846C45.4701 165.798 45.3066 165.796 45.1529 165.841C44.9993 165.885 44.8623 165.975 44.7595 166.097C44.6566 166.22 44.5924 166.37 44.5749 166.529C44.5521 166.742 44.6145 166.956 44.7486 167.123C44.8827 167.29 45.0775 167.398 45.2905 167.422Z"
              fill="#156649"
            />
            <path
              d="M50.4687 167.981C50.6278 167.999 50.7886 167.969 50.9307 167.895C51.0728 167.821 51.1898 167.707 51.2669 167.566C51.3439 167.426 51.3776 167.266 51.3636 167.106C51.3496 166.947 51.2886 166.795 51.1883 166.67C51.088 166.545 50.9529 166.453 50.8002 166.405C50.6474 166.357 50.4838 166.356 50.3301 166.401C50.1765 166.446 50.0396 166.535 49.9369 166.658C49.8342 166.781 49.7702 166.931 49.7532 167.091C49.7303 167.304 49.7928 167.517 49.9269 167.684C50.061 167.851 50.2559 167.958 50.4687 167.981Z"
              fill="#156649"
            />
            <path
              d="M55.6472 168.54C55.8063 168.558 55.967 168.527 56.1089 168.453C56.2507 168.379 56.3675 168.265 56.4443 168.124C56.5211 167.984 56.5545 167.824 56.5403 167.664C56.5261 167.505 56.4649 167.353 56.3645 167.229C56.2641 167.104 56.1289 167.012 55.9762 166.964C55.8234 166.916 55.6599 166.915 55.5064 166.96C55.3528 167.005 55.216 167.095 55.1134 167.217C55.0108 167.34 54.9469 167.491 54.9299 167.65C54.9071 167.863 54.9698 168.077 55.1043 168.244C55.2387 168.411 55.434 168.517 55.6472 168.54Z"
              fill="#156649"
            />
            <path
              d="M60.8259 169.102C60.9849 169.119 61.1455 169.089 61.2873 169.015C61.4292 168.941 61.5458 168.826 61.6227 168.686C61.6995 168.545 61.7329 168.385 61.7188 168.226C61.7047 168.067 61.6437 167.915 61.5435 167.79C61.4433 167.666 61.3083 167.574 61.1557 167.526C61.0031 167.478 60.8397 167.476 60.6861 167.521C60.5325 167.566 60.3957 167.655 60.2929 167.778C60.1901 167.9 60.126 168.05 60.1086 168.209C60.0858 168.423 60.1484 168.637 60.2829 168.804C60.4173 168.971 60.6126 169.078 60.8259 169.102Z"
              fill="#156649"
            />
            <path
              d="M66.0046 169.661C66.1637 169.679 66.3244 169.648 66.4663 169.574C66.6082 169.5 66.7249 169.386 66.8017 169.245C66.8785 169.105 66.9119 168.945 66.8977 168.785C66.8835 168.626 66.8223 168.474 66.7219 168.349C66.6215 168.225 66.4864 168.133 66.3336 168.085C66.1809 168.037 66.0174 168.036 65.8638 168.081C65.7102 168.126 65.5735 168.215 65.4708 168.338C65.3682 168.461 65.3044 168.612 65.2873 168.771C65.2645 168.984 65.3272 169.197 65.4617 169.364C65.5962 169.531 65.7914 169.638 66.0046 169.661Z"
              fill="#156649"
            />
            <path
              d="M71.1823 170.221C71.3414 170.239 71.5021 170.208 71.644 170.134C71.7859 170.06 71.9026 169.946 71.9794 169.805C72.0562 169.665 72.0896 169.505 72.0754 169.345C72.0612 169.186 72 169.034 71.8996 168.909C71.7992 168.785 71.6641 168.693 71.5114 168.645C71.3586 168.597 71.1951 168.596 71.0415 168.641C70.8879 168.686 70.7512 168.775 70.6486 168.898C70.546 169.021 70.4821 169.172 70.4651 169.331C70.4422 169.544 70.5049 169.758 70.6394 169.924C70.7739 170.091 70.9692 170.198 71.1823 170.221Z"
              fill="#156649"
            />
            <path
              d="M76.361 170.782C76.5201 170.8 76.6807 170.769 76.8225 170.695C76.9643 170.621 77.081 170.507 77.1578 170.366C77.2346 170.226 77.2681 170.066 77.254 169.907C77.2399 169.747 77.1789 169.596 77.0786 169.471C76.9784 169.346 76.8435 169.254 76.6908 169.206C76.5382 169.158 76.3748 169.157 76.2212 169.201C76.0677 169.246 75.9308 169.336 75.8281 169.458C75.7253 169.581 75.6611 169.731 75.6438 169.89C75.6209 170.104 75.6836 170.317 75.818 170.485C75.9525 170.652 76.1477 170.759 76.361 170.782Z"
              fill="#156649"
            />
            <path
              d="M81.5431 171.342C81.7023 171.359 81.863 171.329 82.0048 171.255C82.1467 171.181 82.2635 171.066 82.3403 170.926C82.417 170.786 82.4505 170.625 82.4363 170.466C82.4221 170.307 82.3609 170.155 82.2605 170.03C82.1601 169.906 82.0249 169.814 81.8722 169.766C81.7194 169.718 81.5559 169.716 81.4024 169.762C81.2488 169.807 81.112 169.896 81.0094 170.019C80.9068 170.142 80.8429 170.293 80.8259 170.452C80.8031 170.665 80.8658 170.878 81.0003 171.045C81.1347 171.212 81.33 171.319 81.5431 171.342Z"
              fill="#156649"
            />
            <path
              d="M86.718 171.902C86.8771 171.919 87.0378 171.889 87.1797 171.815C87.3215 171.741 87.4383 171.626 87.5151 171.486C87.5919 171.345 87.6253 171.185 87.6111 171.026C87.5969 170.866 87.5357 170.715 87.4353 170.59C87.3349 170.465 87.1998 170.373 87.047 170.325C86.8943 170.277 86.7307 170.276 86.5772 170.321C86.4236 170.366 86.2868 170.456 86.1842 170.579C86.0816 170.702 86.0177 170.852 86.0007 171.011C85.9779 171.224 86.0406 171.438 86.1751 171.605C86.3096 171.772 86.5048 171.879 86.718 171.902Z"
              fill="#156649"
            />
            <path
              d="M91.896 172.461C92.0551 172.478 92.2157 172.448 92.3575 172.374C92.4993 172.3 92.616 172.185 92.6928 172.045C92.7696 171.905 92.8031 171.745 92.789 171.585C92.7749 171.426 92.7139 171.275 92.6136 171.15C92.5134 171.025 92.3785 170.933 92.2258 170.885C92.0732 170.837 91.9098 170.835 91.7562 170.88C91.6027 170.925 91.4658 171.014 91.363 171.137C91.2603 171.26 91.1961 171.41 91.1788 171.569C91.1564 171.782 91.2192 171.996 91.3536 172.163C91.4879 172.33 91.6829 172.437 91.896 172.461Z"
              fill="#156649"
            />
            <path
              d="M97.0747 173.022C97.2338 173.04 97.3945 173.009 97.5364 172.935C97.6783 172.861 97.795 172.747 97.8718 172.606C97.9486 172.466 97.982 172.306 97.9678 172.146C97.9536 171.987 97.8924 171.835 97.792 171.71C97.6916 171.586 97.5565 171.494 97.4038 171.446C97.251 171.398 97.0875 171.397 96.9339 171.442C96.7803 171.487 96.6436 171.576 96.541 171.699C96.4384 171.822 96.3745 171.973 96.3575 172.132C96.3351 172.345 96.398 172.558 96.5324 172.725C96.6668 172.892 96.8618 172.999 97.0747 173.022Z"
              fill="#156649"
            />
            <path
              d="M102.253 173.582C102.412 173.599 102.573 173.569 102.715 173.495C102.857 173.421 102.974 173.306 103.05 173.166C103.127 173.026 103.161 172.865 103.146 172.706C103.132 172.547 103.071 172.395 102.971 172.27C102.87 172.146 102.735 172.054 102.582 172.006C102.43 171.958 102.266 171.956 102.112 172.002C101.959 172.047 101.822 172.136 101.72 172.259C101.617 172.382 101.553 172.533 101.536 172.692C101.524 172.797 101.534 172.904 101.564 173.006C101.594 173.108 101.643 173.203 101.71 173.286C101.776 173.369 101.859 173.437 101.952 173.488C102.045 173.539 102.148 173.571 102.253 173.582Z"
              fill="#156649"
            />
            <path
              d="M107.432 174.143C107.591 174.161 107.751 174.13 107.893 174.056C108.035 173.982 108.151 173.868 108.228 173.728C108.305 173.587 108.339 173.427 108.324 173.268C108.31 173.108 108.249 172.957 108.149 172.832C108.049 172.707 107.914 172.615 107.761 172.567C107.609 172.519 107.445 172.518 107.292 172.562C107.138 172.607 107.001 172.697 106.899 172.819C106.796 172.942 106.732 173.092 106.714 173.251C106.703 173.357 106.712 173.464 106.742 173.566C106.772 173.668 106.821 173.763 106.888 173.846C106.955 173.929 107.037 173.998 107.13 174.049C107.223 174.1 107.326 174.132 107.432 174.143Z"
              fill="#156649"
            />
            <path
              d="M112.61 174.703C112.769 174.72 112.93 174.69 113.072 174.616C113.214 174.542 113.331 174.427 113.407 174.287C113.484 174.146 113.518 173.986 113.503 173.827C113.489 173.667 113.428 173.516 113.328 173.391C113.227 173.266 113.092 173.174 112.939 173.127C112.787 173.079 112.623 173.077 112.469 173.122C112.316 173.168 112.179 173.257 112.076 173.38C111.974 173.503 111.91 173.653 111.893 173.813C111.881 173.918 111.891 174.025 111.921 174.127C111.95 174.229 112 174.324 112.067 174.407C112.133 174.489 112.216 174.558 112.309 174.609C112.402 174.66 112.505 174.692 112.61 174.703Z"
              fill="#156649"
            />
            <path
              d="M117.788 175.263C117.948 175.28 118.108 175.25 118.25 175.175C118.392 175.101 118.509 174.987 118.586 174.846C118.662 174.706 118.696 174.546 118.682 174.386C118.667 174.227 118.606 174.075 118.506 173.951C118.405 173.826 118.27 173.734 118.117 173.686C117.965 173.638 117.801 173.637 117.648 173.682C117.494 173.727 117.357 173.817 117.255 173.94C117.152 174.062 117.088 174.213 117.071 174.372C117.06 174.478 117.069 174.585 117.099 174.686C117.129 174.788 117.178 174.883 117.245 174.966C117.312 175.049 117.394 175.118 117.487 175.169C117.58 175.219 117.683 175.251 117.788 175.263Z"
              fill="#156649"
            />
            <path
              d="M122.968 175.824C123.127 175.841 123.287 175.811 123.429 175.737C123.571 175.663 123.688 175.548 123.764 175.408C123.841 175.268 123.875 175.108 123.861 174.948C123.847 174.789 123.786 174.637 123.685 174.513C123.585 174.388 123.45 174.296 123.297 174.248C123.145 174.2 122.981 174.198 122.828 174.243C122.674 174.288 122.537 174.377 122.435 174.5C122.332 174.622 122.268 174.773 122.25 174.932C122.239 175.037 122.248 175.144 122.278 175.246C122.308 175.348 122.357 175.444 122.424 175.526C122.491 175.609 122.573 175.678 122.666 175.729C122.76 175.78 122.862 175.812 122.968 175.824Z"
              fill="#156649"
            />
            <path
              d="M128.147 176.383C128.306 176.401 128.467 176.37 128.609 176.296C128.751 176.222 128.868 176.108 128.944 175.967C129.021 175.827 129.055 175.667 129.04 175.507C129.026 175.348 128.965 175.196 128.865 175.072C128.764 174.947 128.629 174.855 128.476 174.807C128.324 174.759 128.16 174.758 128.007 174.803C127.853 174.848 127.716 174.938 127.614 175.06C127.511 175.183 127.447 175.334 127.43 175.493C127.419 175.599 127.428 175.705 127.458 175.807C127.488 175.909 127.537 176.004 127.604 176.087C127.671 176.17 127.753 176.239 127.846 176.289C127.939 176.34 128.042 176.372 128.147 176.383Z"
              fill="#156649"
            />
            <path
              d="M133.325 176.944C133.484 176.961 133.644 176.93 133.786 176.856C133.928 176.782 134.045 176.668 134.122 176.527C134.198 176.387 134.232 176.227 134.218 176.067C134.203 175.908 134.142 175.756 134.042 175.632C133.941 175.507 133.806 175.415 133.654 175.367C133.501 175.319 133.337 175.318 133.184 175.363C133.03 175.408 132.893 175.498 132.791 175.62C132.688 175.743 132.624 175.894 132.607 176.053C132.596 176.159 132.605 176.265 132.635 176.367C132.665 176.469 132.714 176.564 132.781 176.647C132.848 176.73 132.93 176.799 133.023 176.849C133.117 176.9 133.219 176.932 133.325 176.944Z"
              fill="#156649"
            />
            <path
              d="M138.503 177.505C138.662 177.522 138.822 177.492 138.964 177.418C139.106 177.344 139.223 177.229 139.3 177.089C139.376 176.949 139.41 176.789 139.396 176.629C139.382 176.47 139.321 176.318 139.22 176.194C139.12 176.069 138.985 175.977 138.833 175.929C138.68 175.881 138.517 175.879 138.363 175.924C138.209 175.969 138.073 176.058 137.97 176.181C137.867 176.303 137.803 176.454 137.786 176.613C137.774 176.718 137.783 176.825 137.813 176.927C137.843 177.029 137.893 177.124 137.959 177.207C138.026 177.29 138.108 177.359 138.201 177.41C138.295 177.461 138.397 177.493 138.503 177.505Z"
              fill="#156649"
            />
            <path
              d="M143.681 178.064C143.84 178.082 144 178.051 144.142 177.977C144.284 177.903 144.401 177.789 144.478 177.648C144.554 177.508 144.588 177.348 144.574 177.188C144.559 177.029 144.498 176.877 144.398 176.752C144.297 176.628 144.162 176.536 144.01 176.488C143.857 176.44 143.693 176.439 143.54 176.484C143.386 176.529 143.249 176.618 143.147 176.741C143.044 176.864 142.98 177.015 142.963 177.174C142.952 177.279 142.961 177.386 142.991 177.488C143.021 177.59 143.07 177.685 143.137 177.768C143.204 177.851 143.286 177.919 143.379 177.97C143.473 178.021 143.575 178.053 143.681 178.064Z"
              fill="#156649"
            />
            <path
              d="M148.86 178.624C149.019 178.641 149.18 178.611 149.321 178.537C149.463 178.462 149.58 178.348 149.657 178.207C149.734 178.067 149.767 177.907 149.753 177.748C149.739 177.588 149.677 177.436 149.577 177.312C149.477 177.187 149.342 177.095 149.189 177.047C149.036 176.999 148.873 176.998 148.719 177.043C148.565 177.088 148.429 177.178 148.326 177.301C148.223 177.424 148.16 177.574 148.142 177.733C148.131 177.839 148.14 177.946 148.17 178.048C148.2 178.15 148.25 178.245 148.316 178.327C148.383 178.41 148.465 178.479 148.558 178.53C148.652 178.581 148.754 178.612 148.86 178.624Z"
              fill="#156649"
            />
            <path
              d="M154.038 179.185C154.197 179.202 154.358 179.172 154.499 179.098C154.641 179.024 154.758 178.909 154.835 178.769C154.912 178.629 154.945 178.469 154.931 178.309C154.917 178.15 154.856 177.998 154.756 177.874C154.655 177.749 154.52 177.657 154.368 177.609C154.215 177.561 154.052 177.559 153.898 177.604C153.745 177.649 153.608 177.738 153.505 177.861C153.402 177.983 153.338 178.134 153.321 178.293C153.309 178.398 153.319 178.505 153.348 178.607C153.378 178.709 153.428 178.805 153.494 178.888C153.561 178.97 153.643 179.039 153.737 179.09C153.83 179.141 153.932 179.174 154.038 179.185Z"
              fill="#156649"
            />
            <path
              d="M159.216 179.738C159.375 179.755 159.536 179.725 159.678 179.651C159.82 179.577 159.936 179.462 160.013 179.322C160.09 179.181 160.123 179.021 160.109 178.862C160.095 178.702 160.034 178.551 159.934 178.426C159.833 178.301 159.698 178.209 159.545 178.161C159.392 178.114 159.229 178.112 159.075 178.157C158.922 178.202 158.785 178.292 158.682 178.415C158.58 178.538 158.516 178.688 158.499 178.847C158.487 178.953 158.497 179.06 158.527 179.162C158.556 179.264 158.606 179.359 158.673 179.442C158.739 179.524 158.822 179.593 158.915 179.644C159.008 179.695 159.111 179.727 159.216 179.738Z"
              fill="#156649"
            />
            <path
              d="M164.395 180.304C164.554 180.322 164.715 180.291 164.857 180.217C164.998 180.143 165.115 180.029 165.192 179.888C165.269 179.748 165.302 179.588 165.288 179.428C165.274 179.269 165.213 179.117 165.112 178.992C165.012 178.868 164.877 178.776 164.724 178.728C164.571 178.68 164.408 178.679 164.254 178.724C164.101 178.769 163.964 178.858 163.861 178.981C163.759 179.104 163.695 179.255 163.678 179.414C163.666 179.519 163.676 179.626 163.705 179.728C163.735 179.83 163.785 179.925 163.851 180.008C163.918 180.091 164 180.159 164.094 180.21C164.187 180.261 164.289 180.293 164.395 180.304Z"
              fill="#156649"
            />
            <path
              d="M169.573 180.866C169.732 180.883 169.893 180.853 170.035 180.779C170.176 180.704 170.293 180.59 170.37 180.45C170.447 180.309 170.48 180.149 170.466 179.99C170.452 179.831 170.391 179.679 170.291 179.554C170.191 179.43 170.056 179.337 169.903 179.289C169.75 179.241 169.587 179.24 169.433 179.285C169.28 179.329 169.143 179.419 169.04 179.541C168.937 179.664 168.873 179.814 168.856 179.973C168.844 180.079 168.854 180.186 168.884 180.288C168.913 180.39 168.963 180.485 169.03 180.568C169.096 180.651 169.178 180.72 169.272 180.771C169.365 180.822 169.467 180.854 169.573 180.866Z"
              fill="#156649"
            />
            <path
              d="M174.752 181.425C174.911 181.442 175.072 181.412 175.214 181.338C175.356 181.264 175.473 181.149 175.549 181.009C175.626 180.869 175.66 180.708 175.645 180.549C175.631 180.39 175.57 180.238 175.47 180.113C175.369 179.989 175.234 179.897 175.081 179.849C174.929 179.801 174.765 179.799 174.612 179.845C174.458 179.89 174.321 179.979 174.219 180.102C174.116 180.225 174.052 180.376 174.035 180.535C174.024 180.64 174.033 180.747 174.063 180.849C174.093 180.951 174.142 181.046 174.209 181.129C174.276 181.212 174.358 181.28 174.451 181.331C174.544 181.382 174.647 181.414 174.752 181.425Z"
              fill="#156649"
            />
            <path
              d="M179.93 181.99C180.089 182.007 180.25 181.977 180.392 181.903C180.534 181.829 180.65 181.714 180.727 181.574C180.804 181.434 180.837 181.273 180.823 181.114C180.809 180.955 180.748 180.803 180.647 180.678C180.547 180.554 180.412 180.462 180.259 180.414C180.106 180.366 179.943 180.364 179.789 180.41C179.636 180.455 179.499 180.544 179.396 180.667C179.294 180.79 179.23 180.94 179.213 181.1C179.201 181.205 179.211 181.312 179.24 181.414C179.27 181.516 179.32 181.611 179.387 181.694C179.453 181.776 179.536 181.845 179.629 181.896C179.722 181.947 179.824 181.979 179.93 181.99Z"
              fill="#156649"
            />
            <path
              d="M185.108 182.546C185.267 182.563 185.428 182.533 185.57 182.459C185.712 182.385 185.828 182.27 185.905 182.13C185.982 181.99 186.015 181.83 186.001 181.67C185.987 181.511 185.926 181.359 185.826 181.235C185.726 181.11 185.591 181.018 185.438 180.97C185.285 180.922 185.122 180.92 184.968 180.965C184.815 181.01 184.678 181.099 184.575 181.222C184.473 181.344 184.408 181.495 184.391 181.654C184.379 181.76 184.389 181.866 184.419 181.969C184.448 182.071 184.498 182.166 184.565 182.249C184.631 182.331 184.714 182.4 184.807 182.451C184.9 182.502 185.003 182.535 185.108 182.546Z"
              fill="#156649"
            />
            <path
              d="M190.287 183.106C190.447 183.123 190.607 183.093 190.749 183.019C190.891 182.945 191.008 182.83 191.085 182.69C191.161 182.549 191.195 182.389 191.181 182.23C191.166 182.071 191.105 181.919 191.005 181.794C190.904 181.67 190.769 181.578 190.616 181.53C190.464 181.482 190.3 181.48 190.147 181.525C189.993 181.571 189.856 181.66 189.754 181.783C189.651 181.906 189.587 182.056 189.57 182.216C189.559 182.321 189.568 182.428 189.598 182.53C189.628 182.632 189.677 182.727 189.744 182.81C189.811 182.892 189.893 182.961 189.986 183.012C190.079 183.063 190.182 183.095 190.287 183.106Z"
              fill="#156649"
            />
            <path
              d="M195.464 183.667C195.623 183.685 195.784 183.655 195.926 183.581C196.068 183.507 196.185 183.393 196.262 183.253C196.339 183.112 196.372 182.952 196.359 182.793C196.345 182.633 196.284 182.482 196.184 182.357C196.084 182.232 195.949 182.14 195.796 182.091C195.643 182.043 195.48 182.041 195.326 182.086C195.173 182.131 195.036 182.22 194.933 182.343C194.83 182.466 194.766 182.616 194.748 182.775C194.725 182.988 194.788 183.202 194.922 183.369C195.056 183.536 195.251 183.643 195.464 183.667Z"
              fill="#156649"
            />
            <path
              d="M200.642 184.227C200.801 184.244 200.962 184.214 201.104 184.14C201.246 184.066 201.362 183.952 201.44 183.812C201.517 183.672 201.55 183.512 201.536 183.352C201.523 183.193 201.462 183.041 201.362 182.916C201.262 182.791 201.127 182.699 200.974 182.651C200.821 182.603 200.658 182.601 200.504 182.646C200.351 182.69 200.214 182.78 200.111 182.902C200.008 183.025 199.944 183.175 199.926 183.334C199.903 183.548 199.965 183.761 200.099 183.929C200.233 184.096 200.429 184.203 200.642 184.227Z"
              fill="#156649"
            />
            <path
              d="M205.821 184.786C205.98 184.804 206.141 184.774 206.283 184.7C206.425 184.626 206.542 184.512 206.619 184.372C206.696 184.231 206.73 184.071 206.716 183.912C206.702 183.752 206.641 183.6 206.54 183.475C206.44 183.351 206.305 183.258 206.152 183.21C205.999 183.162 205.836 183.161 205.682 183.206C205.529 183.251 205.392 183.34 205.289 183.463C205.186 183.586 205.122 183.737 205.105 183.896C205.082 184.109 205.145 184.322 205.279 184.489C205.413 184.656 205.608 184.763 205.821 184.786Z"
              fill="#156649"
            />
            <path
              d="M210.999 185.348C211.158 185.365 211.318 185.334 211.46 185.26C211.602 185.186 211.719 185.072 211.796 184.932C211.872 184.791 211.906 184.631 211.892 184.472C211.878 184.313 211.817 184.161 211.716 184.036C211.616 183.912 211.481 183.819 211.329 183.771C211.176 183.723 211.013 183.722 210.859 183.767C210.705 183.811 210.569 183.901 210.466 184.023C210.363 184.146 210.299 184.296 210.281 184.455C210.259 184.669 210.321 184.882 210.456 185.05C210.59 185.217 210.785 185.324 210.999 185.348Z"
              fill="#156649"
            />
            <path
              d="M216.177 185.907C216.336 185.924 216.497 185.894 216.639 185.82C216.781 185.746 216.897 185.631 216.974 185.491C217.051 185.35 217.084 185.19 217.07 185.031C217.056 184.872 216.995 184.72 216.894 184.595C216.794 184.471 216.659 184.379 216.506 184.331C216.353 184.283 216.19 184.281 216.036 184.326C215.883 184.372 215.746 184.461 215.643 184.584C215.541 184.707 215.477 184.857 215.46 185.017C215.437 185.23 215.5 185.443 215.634 185.61C215.769 185.777 215.964 185.884 216.177 185.907Z"
              fill="#156649"
            />
            <path
              d="M221.356 186.467C221.515 186.484 221.676 186.454 221.818 186.38C221.96 186.306 222.076 186.191 222.153 186.051C222.23 185.91 222.263 185.75 222.249 185.591C222.235 185.431 222.174 185.28 222.073 185.155C221.973 185.03 221.838 184.938 221.685 184.89C221.532 184.843 221.369 184.841 221.215 184.886C221.062 184.931 220.925 185.021 220.822 185.144C220.72 185.267 220.656 185.417 220.639 185.576C220.616 185.79 220.679 186.003 220.813 186.17C220.948 186.337 221.143 186.444 221.356 186.467Z"
              fill="#156649"
            />
            <path
              d="M226.535 187.028C226.694 187.045 226.855 187.015 226.996 186.941C227.138 186.867 227.255 186.753 227.332 186.612C227.408 186.472 227.442 186.312 227.428 186.153C227.414 185.993 227.353 185.842 227.252 185.717C227.152 185.592 227.017 185.5 226.865 185.452C226.712 185.404 226.549 185.402 226.395 185.447C226.241 185.492 226.105 185.581 226.002 185.704C225.899 185.827 225.835 185.977 225.818 186.136C225.795 186.349 225.857 186.563 225.992 186.73C226.126 186.898 226.322 187.005 226.535 187.028Z"
              fill="#156649"
            />
            <path
              d="M44.7313 172.6C44.8904 172.617 45.051 172.587 45.1928 172.513C45.3346 172.439 45.4513 172.324 45.5281 172.184C45.6049 172.043 45.6384 171.884 45.6243 171.724C45.6102 171.565 45.5492 171.413 45.4489 171.289C45.3487 171.164 45.2138 171.072 45.0611 171.024C44.9085 170.976 44.7451 170.974 44.5915 171.019C44.438 171.064 44.3011 171.153 44.1984 171.276C44.0956 171.398 44.0314 171.549 44.0141 171.708C44.0025 171.813 44.0119 171.92 44.0417 172.022C44.0715 172.124 44.1212 172.219 44.1878 172.302C44.2544 172.385 44.3367 172.454 44.4299 172.505C44.5232 172.556 44.6256 172.588 44.7313 172.6Z"
              fill="#156649"
            />
            <path
              d="M49.9095 173.154C50.0687 173.171 50.2293 173.141 50.3712 173.067C50.5131 172.993 50.6299 172.878 50.7066 172.738C50.7834 172.598 50.8168 172.438 50.8026 172.278C50.7884 172.119 50.7273 171.967 50.6269 171.842C50.5265 171.718 50.3913 171.626 50.2386 171.578C50.0858 171.53 49.9223 171.528 49.7687 171.574C49.6152 171.619 49.4784 171.708 49.3758 171.831C49.2732 171.954 49.2093 172.105 49.1923 172.264C49.1807 172.369 49.1902 172.476 49.22 172.578C49.2498 172.68 49.2995 172.775 49.3661 172.858C49.4327 172.941 49.515 173.009 49.6083 173.06C49.7015 173.111 49.8039 173.143 49.9095 173.154Z"
              fill="#156649"
            />
            <path
              d="M55.0873 173.719C55.2464 173.736 55.4071 173.706 55.549 173.632C55.6909 173.558 55.8076 173.443 55.8844 173.303C55.9612 173.163 55.9946 173.002 55.9804 172.843C55.9662 172.684 55.905 172.532 55.8046 172.407C55.7042 172.283 55.5691 172.191 55.4163 172.143C55.2636 172.095 55.1001 172.093 54.9465 172.139C54.7929 172.184 54.6561 172.273 54.5535 172.396C54.4509 172.519 54.3871 172.669 54.37 172.829C54.3585 172.934 54.3679 173.041 54.3977 173.143C54.4275 173.245 54.4772 173.34 54.5438 173.423C54.6105 173.505 54.6928 173.574 54.786 173.625C54.8793 173.676 54.9816 173.708 55.0873 173.719Z"
              fill="#156649"
            />
            <path
              d="M60.2665 174.281C60.4255 174.298 60.5861 174.268 60.7279 174.194C60.8698 174.12 60.9865 174.005 61.0633 173.865C61.1401 173.724 61.1735 173.564 61.1594 173.405C61.1454 173.246 61.0843 173.094 60.9841 172.969C60.8839 172.845 60.7489 172.753 60.5963 172.705C60.4437 172.657 60.2803 172.655 60.1267 172.7C59.9731 172.745 59.8363 172.834 59.7335 172.956C59.6307 173.079 59.5666 173.229 59.5492 173.388C59.5377 173.494 59.5471 173.601 59.5769 173.703C59.6067 173.805 59.6563 173.9 59.7229 173.983C59.7895 174.066 59.8718 174.135 59.9651 174.186C60.0584 174.237 60.1608 174.269 60.2665 174.281Z"
              fill="#156649"
            />
            <path
              d="M65.4447 174.84C65.6038 174.858 65.7645 174.827 65.9064 174.753C66.0483 174.679 66.165 174.564 66.2418 174.424C66.3186 174.284 66.352 174.124 66.3378 173.964C66.3236 173.805 66.2624 173.653 66.162 173.528C66.0616 173.404 65.9265 173.312 65.7737 173.264C65.621 173.216 65.4575 173.214 65.3039 173.26C65.1503 173.305 65.0136 173.394 64.911 173.517C64.8083 173.64 64.7445 173.791 64.7274 173.95C64.7159 174.055 64.7253 174.162 64.7551 174.264C64.785 174.366 64.8346 174.461 64.9012 174.544C64.9679 174.627 65.0502 174.695 65.1434 174.746C65.2367 174.797 65.3391 174.829 65.4447 174.84Z"
              fill="#156649"
            />
            <path
              d="M70.6288 175.407C70.7879 175.424 70.9486 175.394 71.0905 175.32C71.2324 175.245 71.3491 175.131 71.4259 174.99C71.5027 174.85 71.5361 174.69 71.5219 174.531C71.5077 174.371 71.4465 174.219 71.3461 174.095C71.2457 173.97 71.1106 173.878 70.9578 173.83C70.8051 173.782 70.6416 173.781 70.488 173.826C70.3344 173.871 70.1977 173.961 70.095 174.084C69.9924 174.206 69.9286 174.357 69.9115 174.516C69.9 174.622 69.9094 174.729 69.9392 174.831C69.969 174.932 70.0187 175.028 70.0853 175.11C70.152 175.193 70.2343 175.262 70.3275 175.313C70.4208 175.363 70.5231 175.395 70.6288 175.407Z"
              fill="#156649"
            />
            <path
              d="M75.8016 175.961C75.9607 175.978 76.1213 175.948 76.2631 175.873C76.4049 175.799 76.5216 175.685 76.5984 175.545C76.6752 175.404 76.7087 175.244 76.6946 175.085C76.6805 174.926 76.6195 174.774 76.5193 174.649C76.419 174.525 76.2841 174.432 76.1315 174.384C75.9788 174.336 75.8154 174.335 75.6618 174.38C75.5083 174.424 75.3715 174.514 75.2687 174.636C75.1659 174.759 75.1017 174.909 75.0844 175.068C75.0728 175.174 75.0822 175.281 75.112 175.383C75.1418 175.485 75.1915 175.58 75.2581 175.663C75.3247 175.746 75.407 175.815 75.5003 175.866C75.5935 175.917 75.6959 175.949 75.8016 175.961Z"
              fill="#156649"
            />
            <path
              d="M80.9798 176.521C81.139 176.538 81.2997 176.508 81.4415 176.434C81.5834 176.359 81.7002 176.245 81.777 176.104C81.8537 175.964 81.8872 175.804 81.873 175.645C81.8588 175.485 81.7976 175.333 81.6972 175.209C81.5968 175.084 81.4616 174.992 81.3089 174.944C81.1561 174.896 80.9926 174.895 80.8391 174.94C80.6855 174.985 80.5487 175.075 80.4461 175.198C80.3435 175.32 80.2796 175.471 80.2626 175.63C80.2511 175.736 80.2605 175.843 80.2903 175.945C80.3201 176.046 80.3698 176.142 80.4364 176.224C80.503 176.307 80.5853 176.376 80.6786 176.427C80.7718 176.477 80.8742 176.509 80.9798 176.521Z"
              fill="#156649"
            />
            <path
              d="M86.159 177.08C86.3182 177.098 86.4789 177.067 86.6207 176.993C86.7626 176.919 86.8794 176.804 86.9562 176.664C87.0329 176.524 87.0664 176.364 87.0522 176.204C87.038 176.045 86.9768 175.893 86.8764 175.768C86.776 175.644 86.6408 175.552 86.4881 175.504C86.3353 175.456 86.1718 175.454 86.0182 175.5C85.8647 175.545 85.7279 175.634 85.6253 175.757C85.5227 175.88 85.4588 176.031 85.4418 176.19C85.4303 176.295 85.4397 176.402 85.4695 176.504C85.4993 176.606 85.549 176.701 85.6156 176.784C85.6822 176.867 85.7645 176.935 85.8578 176.986C85.951 177.037 86.0534 177.069 86.159 177.08Z"
              fill="#156649"
            />
            <path
              d="M91.3366 177.642C91.4957 177.659 91.6563 177.628 91.7981 177.554C91.9399 177.48 92.0566 177.366 92.1334 177.226C92.2102 177.085 92.2437 176.925 92.2296 176.766C92.2155 176.607 92.1545 176.455 92.0542 176.33C91.954 176.206 91.819 176.113 91.6664 176.065C91.5138 176.017 91.3504 176.016 91.1968 176.061C91.0433 176.105 90.9064 176.195 90.8036 176.317C90.7009 176.44 90.6367 176.59 90.6194 176.749C90.5965 176.963 90.6592 177.176 90.7936 177.344C90.9281 177.511 91.1233 177.618 91.3366 177.642Z"
              fill="#156649"
            />
            <path
              d="M96.5153 178.201C96.6744 178.218 96.8351 178.188 96.977 178.114C97.1189 178.04 97.2356 177.925 97.3124 177.785C97.3892 177.644 97.4226 177.484 97.4084 177.325C97.3942 177.166 97.333 177.014 97.2326 176.889C97.1322 176.765 96.9971 176.672 96.8444 176.625C96.6916 176.577 96.5281 176.575 96.3745 176.62C96.2209 176.666 96.0842 176.755 95.9816 176.878C95.879 177.001 95.8151 177.151 95.7981 177.311C95.7752 177.524 95.8379 177.737 95.9724 177.904C96.1069 178.071 96.3022 178.178 96.5153 178.201Z"
              fill="#156649"
            />
            <path
              d="M101.694 178.761C101.853 178.778 102.014 178.748 102.156 178.674C102.298 178.599 102.415 178.485 102.491 178.344C102.568 178.204 102.602 178.044 102.587 177.885C102.573 177.725 102.512 177.573 102.412 177.449C102.311 177.324 102.176 177.232 102.023 177.184C101.87 177.136 101.707 177.135 101.553 177.18C101.4 177.225 101.263 177.315 101.16 177.438C101.058 177.56 100.994 177.711 100.977 177.87C100.965 177.976 100.975 178.083 101.005 178.185C101.034 178.286 101.084 178.382 101.151 178.464C101.217 178.547 101.3 178.616 101.393 178.667C101.486 178.717 101.589 178.749 101.694 178.761Z"
              fill="#156649"
            />
            <path
              d="M106.872 179.322C107.031 179.339 107.192 179.309 107.334 179.235C107.476 179.161 107.592 179.046 107.669 178.906C107.746 178.766 107.779 178.606 107.765 178.446C107.751 178.287 107.69 178.135 107.59 178.011C107.49 177.886 107.355 177.794 107.202 177.746C107.049 177.698 106.886 177.696 106.732 177.741C106.579 177.786 106.442 177.875 106.339 177.998C106.237 178.12 106.172 178.271 106.155 178.43C106.132 178.643 106.195 178.857 106.329 179.024C106.464 179.191 106.659 179.298 106.872 179.322Z"
              fill="#156649"
            />
            <path
              d="M112.051 179.882C112.21 179.899 112.371 179.869 112.513 179.795C112.655 179.72 112.771 179.606 112.848 179.466C112.925 179.325 112.958 179.165 112.944 179.006C112.93 178.846 112.869 178.695 112.768 178.57C112.668 178.445 112.533 178.353 112.38 178.305C112.227 178.257 112.064 178.256 111.91 178.301C111.757 178.346 111.62 178.436 111.517 178.559C111.415 178.682 111.351 178.832 111.334 178.991C111.311 179.204 111.374 179.418 111.508 179.585C111.643 179.752 111.838 179.859 112.051 179.882Z"
              fill="#156649"
            />
            <path
              d="M117.232 180.441C117.391 180.458 117.552 180.428 117.694 180.354C117.836 180.28 117.953 180.165 118.029 180.025C118.106 179.884 118.14 179.724 118.125 179.565C118.111 179.406 118.05 179.254 117.95 179.129C117.849 179.005 117.714 178.912 117.561 178.865C117.409 178.817 117.245 178.815 117.092 178.86C116.938 178.906 116.801 178.995 116.699 179.118C116.596 179.241 116.532 179.391 116.515 179.551C116.503 179.656 116.513 179.763 116.543 179.865C116.573 179.967 116.622 180.062 116.689 180.145C116.755 180.227 116.838 180.296 116.931 180.347C117.024 180.398 117.127 180.43 117.232 180.441Z"
              fill="#156649"
            />
            <path
              d="M122.407 181.002C122.566 181.02 122.727 180.989 122.869 180.915C123.011 180.841 123.127 180.727 123.204 180.586C123.281 180.446 123.314 180.286 123.3 180.127C123.286 179.967 123.225 179.816 123.125 179.691C123.025 179.566 122.89 179.474 122.737 179.426C122.585 179.378 122.421 179.377 122.268 179.421C122.114 179.466 121.977 179.556 121.874 179.678C121.772 179.801 121.708 179.951 121.69 180.11C121.667 180.324 121.73 180.537 121.864 180.704C121.999 180.872 122.194 180.979 122.407 181.002Z"
              fill="#156649"
            />
            <path
              d="M127.586 181.562C127.745 181.579 127.906 181.549 128.048 181.475C128.19 181.401 128.306 181.286 128.383 181.146C128.46 181.005 128.493 180.845 128.479 180.686C128.465 180.526 128.404 180.375 128.303 180.25C128.203 180.125 128.068 180.033 127.915 179.985C127.762 179.938 127.599 179.936 127.445 179.981C127.292 180.026 127.155 180.116 127.052 180.239C126.95 180.362 126.886 180.512 126.869 180.671C126.846 180.885 126.909 181.098 127.043 181.265C127.178 181.432 127.373 181.539 127.586 181.562Z"
              fill="#156649"
            />
            <path
              d="M132.765 182.122C132.924 182.139 133.084 182.108 133.226 182.034C133.368 181.96 133.485 181.846 133.562 181.705C133.638 181.565 133.672 181.405 133.658 181.245C133.643 181.086 133.582 180.934 133.482 180.81C133.381 180.685 133.246 180.593 133.094 180.545C132.941 180.497 132.777 180.496 132.624 180.541C132.47 180.586 132.333 180.676 132.231 180.798C132.128 180.921 132.064 181.072 132.047 181.231C132.036 181.337 132.045 181.443 132.075 181.545C132.105 181.647 132.154 181.742 132.221 181.825C132.288 181.908 132.37 181.977 132.463 182.027C132.557 182.078 132.659 182.11 132.765 182.122Z"
              fill="#156649"
            />
            <path
              d="M137.944 182.683C138.103 182.7 138.263 182.67 138.405 182.596C138.547 182.522 138.664 182.407 138.74 182.267C138.817 182.126 138.851 181.967 138.837 181.807C138.822 181.648 138.761 181.496 138.661 181.371C138.561 181.247 138.426 181.155 138.273 181.107C138.121 181.059 137.957 181.057 137.804 181.102C137.65 181.147 137.513 181.236 137.411 181.359C137.308 181.481 137.244 181.632 137.226 181.791C137.203 182.004 137.266 182.218 137.401 182.385C137.535 182.552 137.73 182.659 137.944 182.683Z"
              fill="#156649"
            />
            <path
              d="M143.122 183.243C143.281 183.26 143.442 183.23 143.583 183.156C143.725 183.082 143.842 182.967 143.919 182.827C143.996 182.686 144.029 182.526 144.015 182.367C144.001 182.207 143.94 182.056 143.839 181.931C143.739 181.806 143.604 181.714 143.451 181.666C143.298 181.619 143.135 181.617 142.981 181.662C142.827 181.707 142.691 181.797 142.588 181.92C142.485 182.043 142.422 182.193 142.405 182.352C142.382 182.565 142.444 182.779 142.579 182.946C142.713 183.113 142.909 183.22 143.122 183.243Z"
              fill="#156649"
            />
            <path
              d="M148.298 183.802C148.457 183.82 148.618 183.79 148.76 183.716C148.902 183.642 149.019 183.528 149.096 183.388C149.173 183.247 149.207 183.087 149.193 182.928C149.179 182.768 149.118 182.616 149.017 182.492C148.917 182.367 148.782 182.274 148.629 182.226C148.477 182.178 148.313 182.177 148.159 182.222C148.006 182.267 147.869 182.356 147.766 182.479C147.663 182.602 147.599 182.753 147.582 182.912C147.559 183.125 147.622 183.338 147.756 183.505C147.89 183.672 148.085 183.779 148.298 183.802Z"
              fill="#156649"
            />
            <path
              d="M153.476 184.364C153.635 184.381 153.796 184.351 153.938 184.277C154.08 184.203 154.196 184.088 154.273 183.948C154.35 183.807 154.383 183.647 154.369 183.488C154.355 183.329 154.294 183.177 154.194 183.052C154.094 182.928 153.959 182.836 153.806 182.788C153.653 182.74 153.49 182.738 153.336 182.783C153.183 182.828 153.046 182.917 152.943 183.04C152.841 183.162 152.776 183.312 152.759 183.471C152.736 183.685 152.799 183.899 152.933 184.066C153.068 184.233 153.263 184.34 153.476 184.364Z"
              fill="#156649"
            />
            <path
              d="M158.655 184.923C158.815 184.941 158.975 184.91 159.117 184.836C159.259 184.762 159.376 184.648 159.453 184.507C159.529 184.367 159.563 184.207 159.549 184.047C159.534 183.888 159.473 183.736 159.373 183.611C159.272 183.487 159.137 183.395 158.985 183.347C158.832 183.299 158.668 183.297 158.515 183.343C158.361 183.388 158.224 183.477 158.122 183.6C158.019 183.723 157.955 183.874 157.938 184.033C157.915 184.246 157.978 184.459 158.113 184.626C158.247 184.793 158.442 184.9 158.655 184.923Z"
              fill="#156649"
            />
            <path
              d="M163.838 185.483C163.997 185.5 164.157 185.47 164.299 185.396C164.441 185.322 164.558 185.207 164.635 185.067C164.711 184.926 164.745 184.766 164.731 184.607C164.716 184.447 164.655 184.296 164.555 184.171C164.454 184.046 164.319 183.954 164.167 183.906C164.014 183.859 163.85 183.857 163.697 183.902C163.543 183.947 163.406 184.037 163.304 184.16C163.201 184.283 163.137 184.433 163.12 184.592C163.097 184.806 163.16 185.019 163.295 185.186C163.429 185.353 163.624 185.46 163.838 185.483Z"
              fill="#156649"
            />
            <path
              d="M169.012 186.044C169.171 186.061 169.332 186.031 169.474 185.957C169.615 185.883 169.732 185.768 169.809 185.628C169.886 185.488 169.919 185.328 169.905 185.168C169.891 185.009 169.83 184.857 169.73 184.733C169.629 184.608 169.495 184.516 169.342 184.468C169.189 184.42 169.026 184.418 168.872 184.463C168.719 184.508 168.582 184.597 168.479 184.72C168.376 184.842 168.312 184.993 168.295 185.152C168.283 185.257 168.293 185.364 168.322 185.466C168.352 185.568 168.402 185.664 168.469 185.746C168.535 185.829 168.617 185.898 168.711 185.949C168.804 186 168.906 186.032 169.012 186.044Z"
              fill="#156649"
            />
            <path
              d="M174.191 186.604C174.35 186.621 174.51 186.591 174.652 186.517C174.794 186.442 174.911 186.328 174.988 186.187C175.065 186.047 175.098 185.887 175.084 185.728C175.07 185.568 175.008 185.416 174.908 185.292C174.808 185.167 174.672 185.075 174.52 185.027C174.367 184.979 174.203 184.978 174.05 185.023C173.896 185.068 173.759 185.158 173.657 185.281C173.554 185.403 173.49 185.554 173.473 185.713C173.451 185.926 173.513 186.14 173.648 186.307C173.782 186.474 173.977 186.581 174.191 186.604Z"
              fill="#156649"
            />
            <path
              d="M179.368 187.163C179.527 187.181 179.688 187.15 179.83 187.076C179.972 187.002 180.089 186.887 180.165 186.747C180.242 186.607 180.276 186.447 180.261 186.287C180.247 186.128 180.186 185.976 180.086 185.851C179.985 185.727 179.85 185.635 179.697 185.587C179.545 185.539 179.381 185.537 179.228 185.583C179.074 185.628 178.937 185.717 178.835 185.84C178.732 185.963 178.668 186.114 178.651 186.273C178.628 186.486 178.691 186.699 178.825 186.866C178.96 187.033 179.155 187.14 179.368 187.163Z"
              fill="#156649"
            />
            <path
              d="M184.547 187.725C184.706 187.742 184.867 187.711 185.009 187.637C185.151 187.563 185.267 187.449 185.344 187.309C185.421 187.168 185.454 187.008 185.44 186.849C185.426 186.69 185.365 186.538 185.265 186.413C185.165 186.289 185.03 186.196 184.877 186.148C184.724 186.1 184.561 186.099 184.407 186.144C184.254 186.188 184.117 186.278 184.014 186.4C183.911 186.523 183.847 186.673 183.83 186.832C183.818 186.938 183.828 187.045 183.858 187.147C183.887 187.249 183.937 187.344 184.004 187.427C184.07 187.51 184.153 187.579 184.246 187.63C184.339 187.681 184.442 187.713 184.547 187.725Z"
              fill="#156649"
            />
            <path
              d="M189.726 188.284C189.885 188.301 190.046 188.271 190.187 188.197C190.329 188.123 190.446 188.008 190.523 187.868C190.6 187.727 190.633 187.567 190.619 187.408C190.605 187.249 190.543 187.097 190.443 186.972C190.343 186.848 190.208 186.755 190.055 186.708C189.902 186.66 189.739 186.658 189.585 186.703C189.431 186.749 189.295 186.838 189.192 186.961C189.089 187.084 189.026 187.234 189.009 187.394C188.986 187.607 189.048 187.82 189.183 187.987C189.317 188.154 189.513 188.261 189.726 188.284Z"
              fill="#156649"
            />
            <path
              d="M194.903 188.844C195.063 188.861 195.223 188.831 195.365 188.757C195.507 188.683 195.624 188.568 195.701 188.428C195.777 188.288 195.811 188.127 195.797 187.968C195.782 187.809 195.721 187.657 195.621 187.532C195.52 187.408 195.385 187.316 195.233 187.268C195.08 187.22 194.916 187.218 194.763 187.264C194.609 187.309 194.472 187.398 194.37 187.521C194.267 187.644 194.203 187.794 194.186 187.954C194.163 188.167 194.226 188.38 194.361 188.547C194.495 188.714 194.69 188.821 194.903 188.844Z"
              fill="#156649"
            />
            <path
              d="M200.083 189.405C200.242 189.422 200.402 189.392 200.544 189.318C200.686 189.244 200.803 189.129 200.879 188.989C200.956 188.849 200.99 188.689 200.976 188.529C200.961 188.37 200.9 188.218 200.8 188.094C200.7 187.969 200.565 187.877 200.412 187.829C200.26 187.781 200.096 187.779 199.943 187.824C199.789 187.869 199.652 187.958 199.55 188.081C199.447 188.203 199.383 188.354 199.365 188.513C199.354 188.618 199.363 188.725 199.392 188.828C199.422 188.93 199.472 189.025 199.538 189.108C199.605 189.191 199.688 189.26 199.781 189.311C199.874 189.362 199.977 189.394 200.083 189.405Z"
              fill="#156649"
            />
            <path
              d="M205.261 189.96C205.42 189.977 205.581 189.947 205.723 189.872C205.865 189.798 205.981 189.684 206.058 189.543C206.135 189.403 206.168 189.243 206.154 189.083C206.14 188.924 206.079 188.772 205.978 188.648C205.878 188.523 205.743 188.431 205.59 188.383C205.437 188.335 205.274 188.334 205.12 188.379C204.967 188.424 204.83 188.514 204.727 188.637C204.625 188.759 204.561 188.91 204.544 189.069C204.532 189.175 204.542 189.282 204.572 189.384C204.601 189.485 204.651 189.581 204.718 189.663C204.784 189.746 204.867 189.815 204.96 189.866C205.053 189.916 205.155 189.948 205.261 189.96Z"
              fill="#156649"
            />
            <path
              d="M210.441 190.526C210.6 190.544 210.761 190.513 210.903 190.439C211.045 190.365 211.161 190.251 211.238 190.11C211.315 189.97 211.348 189.81 211.334 189.651C211.32 189.491 211.259 189.34 211.159 189.215C211.059 189.09 210.924 188.998 210.771 188.95C210.618 188.902 210.455 188.9 210.302 188.945C210.148 188.99 210.011 189.079 209.908 189.202C209.806 189.325 209.741 189.475 209.724 189.634C209.712 189.74 209.722 189.847 209.752 189.949C209.781 190.051 209.831 190.146 209.898 190.229C209.964 190.312 210.047 190.381 210.14 190.432C210.233 190.483 210.336 190.515 210.441 190.526Z"
              fill="#156649"
            />
            <path
              d="M215.618 191.086C215.777 191.103 215.937 191.073 216.079 190.999C216.221 190.925 216.338 190.81 216.415 190.67C216.491 190.529 216.525 190.369 216.511 190.21C216.496 190.05 216.435 189.899 216.335 189.774C216.234 189.649 216.099 189.557 215.947 189.509C215.794 189.462 215.63 189.46 215.477 189.505C215.323 189.55 215.186 189.64 215.084 189.763C214.981 189.886 214.917 190.036 214.9 190.195C214.889 190.301 214.898 190.408 214.928 190.51C214.958 190.612 215.007 190.707 215.074 190.789C215.141 190.872 215.223 190.941 215.316 190.992C215.41 191.043 215.512 191.075 215.618 191.086Z"
              fill="#156649"
            />
            <path
              d="M220.796 191.645C220.955 191.662 221.116 191.632 221.258 191.558C221.4 191.484 221.517 191.369 221.593 191.229C221.67 191.088 221.704 190.928 221.689 190.769C221.675 190.609 221.614 190.458 221.514 190.333C221.413 190.208 221.278 190.116 221.125 190.068C220.973 190.021 220.809 190.019 220.655 190.064C220.502 190.109 220.365 190.199 220.263 190.322C220.16 190.445 220.096 190.595 220.079 190.754C220.067 190.86 220.077 190.967 220.107 191.069C220.137 191.171 220.186 191.266 220.253 191.349C220.319 191.431 220.402 191.5 220.495 191.551C220.588 191.602 220.691 191.634 220.796 191.645Z"
              fill="#156649"
            />
            <path
              d="M225.975 192.212C226.134 192.229 226.295 192.198 226.436 192.124C226.578 192.05 226.695 191.936 226.772 191.796C226.849 191.655 226.882 191.495 226.868 191.336C226.854 191.177 226.793 191.025 226.693 190.9C226.592 190.776 226.457 190.683 226.305 190.635C226.152 190.587 225.989 190.586 225.835 190.631C225.682 190.675 225.545 190.765 225.442 190.887C225.339 191.01 225.275 191.16 225.258 191.319C225.246 191.425 225.256 191.532 225.285 191.634C225.315 191.736 225.365 191.831 225.431 191.914C225.498 191.997 225.58 192.066 225.674 192.117C225.767 192.168 225.869 192.2 225.975 192.212Z"
              fill="#156649"
            />
            <path
              d="M44.1711 177.778C44.3301 177.796 44.4907 177.765 44.6326 177.691C44.7744 177.617 44.8911 177.503 44.9679 177.363C45.0447 177.222 45.0782 177.062 45.0641 176.903C45.05 176.744 44.9889 176.592 44.8887 176.467C44.7885 176.343 44.6535 176.25 44.5009 176.202C44.3483 176.154 44.1849 176.153 44.0313 176.197C43.8777 176.242 43.7409 176.332 43.6381 176.454C43.5353 176.577 43.4712 176.727 43.4538 176.886C43.431 177.1 43.4937 177.313 43.6281 177.481C43.7625 177.648 43.9578 177.755 44.1711 177.778Z"
              fill="#156649"
            />
            <path
              d="M49.3503 178.338C49.5094 178.355 49.6701 178.325 49.812 178.251C49.9539 178.177 50.0706 178.062 50.1474 177.922C50.2242 177.781 50.2576 177.621 50.2434 177.462C50.2292 177.302 50.168 177.151 50.0676 177.026C49.9672 176.901 49.8321 176.809 49.6793 176.761C49.5266 176.714 49.3631 176.712 49.2095 176.757C49.0559 176.802 48.9192 176.892 48.8165 177.015C48.7139 177.138 48.6501 177.288 48.633 177.447C48.6102 177.66 48.6729 177.874 48.8074 178.041C48.9419 178.208 49.1371 178.315 49.3503 178.338Z"
              fill="#156649"
            />
            <path
              d="M54.5287 178.898C54.6878 178.915 54.8485 178.885 54.9904 178.81C55.1323 178.736 55.249 178.622 55.3258 178.481C55.4026 178.341 55.436 178.181 55.4218 178.021C55.4076 177.862 55.3464 177.71 55.246 177.586C55.1456 177.461 55.0105 177.369 54.8577 177.321C54.705 177.273 54.5415 177.272 54.3879 177.317C54.2343 177.362 54.0976 177.452 53.9949 177.575C53.8923 177.697 53.8285 177.848 53.8114 178.007C53.7999 178.113 53.8093 178.22 53.8391 178.321C53.8689 178.423 53.9186 178.519 53.9852 178.601C54.0519 178.684 54.1342 178.753 54.2274 178.804C54.3207 178.854 54.423 178.886 54.5287 178.898Z"
              fill="#156649"
            />
            <path
              d="M59.7141 179.459C59.8731 179.476 60.0337 179.446 60.1755 179.372C60.3173 179.297 60.434 179.183 60.5108 179.043C60.5876 178.902 60.6211 178.742 60.607 178.583C60.5929 178.424 60.5319 178.272 60.4317 178.147C60.3314 178.023 60.1965 177.931 60.0439 177.883C59.8913 177.834 59.7278 177.833 59.5743 177.878C59.4207 177.923 59.2839 178.012 59.1811 178.134C59.0783 178.257 59.0142 178.407 58.9968 178.566C58.974 178.78 59.0366 178.994 59.1711 179.161C59.3055 179.328 59.5008 179.435 59.7141 179.459Z"
              fill="#156649"
            />
            <path
              d="M64.8854 180.018C65.0446 180.036 65.2052 180.005 65.3471 179.931C65.489 179.857 65.6057 179.743 65.6825 179.602C65.7593 179.462 65.7927 179.302 65.7785 179.142C65.7643 178.983 65.7032 178.831 65.6028 178.707C65.5023 178.582 65.3672 178.49 65.2145 178.442C65.0617 178.394 64.8982 178.393 64.7446 178.438C64.5911 178.483 64.4543 178.573 64.3517 178.695C64.2491 178.818 64.1852 178.969 64.1682 179.128C64.1453 179.341 64.2081 179.555 64.3425 179.722C64.477 179.889 64.6723 179.995 64.8854 180.018Z"
              fill="#156649"
            />
            <path
              d="M70.0638 180.578C70.223 180.595 70.3836 180.565 70.5255 180.491C70.6674 180.417 70.7842 180.302 70.8609 180.162C70.9377 180.021 70.9711 179.861 70.9569 179.702C70.9427 179.542 70.8816 179.391 70.7812 179.266C70.6808 179.141 70.5456 179.049 70.3929 179.002C70.2401 178.954 70.0766 178.952 69.923 178.997C69.7695 179.043 69.6327 179.132 69.5301 179.255C69.4275 179.378 69.3636 179.528 69.3466 179.688C69.335 179.793 69.3445 179.9 69.3743 180.002C69.4041 180.104 69.4538 180.199 69.5204 180.282C69.587 180.364 69.6693 180.433 69.7626 180.484C69.8558 180.535 69.9582 180.567 70.0638 180.578Z"
              fill="#156649"
            />
            <path
              d="M75.2414 181.139C75.4005 181.157 75.561 181.126 75.7029 181.052C75.8447 180.978 75.9614 180.864 76.0382 180.723C76.115 180.583 76.1485 180.423 76.1344 180.264C76.1203 180.104 76.0593 179.953 75.959 179.828C75.8588 179.703 75.7238 179.611 75.5712 179.563C75.4186 179.515 75.2552 179.513 75.1016 179.558C74.948 179.603 74.8112 179.693 74.7084 179.815C74.6056 179.938 74.5415 180.088 74.5241 180.247C74.5013 180.46 74.564 180.674 74.6984 180.841C74.8329 181.009 75.0281 181.116 75.2414 181.139Z"
              fill="#156649"
            />
            <path
              d="M80.4206 181.699C80.5797 181.716 80.7404 181.686 80.8823 181.612C81.0242 181.538 81.1409 181.423 81.2177 181.283C81.2945 181.142 81.3279 180.982 81.3137 180.823C81.2995 180.663 81.2383 180.512 81.1379 180.387C81.0375 180.262 80.9024 180.17 80.7496 180.122C80.5969 180.075 80.4334 180.073 80.2798 180.118C80.1262 180.163 79.9895 180.253 79.8869 180.376C79.7842 180.499 79.7204 180.649 79.7033 180.808C79.6805 181.022 79.7432 181.235 79.8777 181.402C80.0122 181.569 80.2074 181.676 80.4206 181.699Z"
              fill="#156649"
            />
            <path
              d="M85.599 182.258C85.7581 182.276 85.9188 182.245 86.0607 182.171C86.2026 182.097 86.3193 181.983 86.3961 181.842C86.4729 181.702 86.5063 181.542 86.4921 181.382C86.4779 181.223 86.4167 181.071 86.3163 180.947C86.2159 180.822 86.0808 180.73 85.928 180.682C85.7753 180.634 85.6118 180.633 85.4582 180.678C85.3046 180.723 85.1679 180.813 85.0652 180.935C84.9626 181.058 84.8988 181.209 84.8817 181.368C84.8702 181.474 84.8796 181.58 84.9094 181.682C84.9393 181.784 84.9889 181.879 85.0555 181.962C85.1222 182.045 85.2045 182.114 85.2977 182.164C85.391 182.215 85.4933 182.247 85.599 182.258Z"
              fill="#156649"
            />
            <path
              d="M90.7763 182.82C90.9354 182.838 91.0961 182.808 91.2381 182.734C91.3802 182.66 91.4971 182.546 91.5742 182.405C91.6512 182.265 91.685 182.105 91.6711 181.946C91.6572 181.786 91.5964 181.635 91.4963 181.51C91.3961 181.385 91.2612 181.292 91.1086 181.244C90.9559 181.196 90.7925 181.194 90.6388 181.239C90.4851 181.284 90.3482 181.373 90.2453 181.496C90.1424 181.618 90.0782 181.769 90.0608 181.928C90.0379 182.141 90.1004 182.354 90.2344 182.522C90.3685 182.689 90.5633 182.796 90.7763 182.82Z"
              fill="#156649"
            />
            <path
              d="M95.954 183.376C96.1132 183.394 96.274 183.364 96.4161 183.29C96.5582 183.216 96.6752 183.102 96.7522 182.961C96.8293 182.821 96.8629 182.661 96.849 182.501C96.835 182.342 96.774 182.19 96.6737 182.065C96.5734 181.94 96.4383 181.848 96.2855 181.8C96.1328 181.752 95.9692 181.751 95.8155 181.796C95.6618 181.841 95.525 181.93 95.4223 182.053C95.3195 182.176 95.2556 182.326 95.2385 182.486C95.2157 182.699 95.2781 182.912 95.4123 183.079C95.5464 183.246 95.7412 183.353 95.954 183.376Z"
              fill="#156649"
            />
            <path
              d="M101.132 183.939C101.291 183.957 101.452 183.927 101.594 183.853C101.736 183.779 101.853 183.665 101.93 183.524C102.007 183.384 102.041 183.224 102.027 183.064C102.013 182.905 101.952 182.753 101.852 182.628C101.752 182.503 101.617 182.411 101.464 182.363C101.311 182.315 101.147 182.314 100.994 182.359C100.84 182.404 100.703 182.493 100.6 182.616C100.498 182.739 100.434 182.889 100.417 183.049C100.394 183.262 100.456 183.475 100.59 183.642C100.725 183.809 100.919 183.916 101.132 183.939Z"
              fill="#156649"
            />
            <path
              d="M106.318 184.5C106.477 184.518 106.638 184.487 106.78 184.413C106.921 184.339 107.038 184.225 107.115 184.084C107.192 183.944 107.225 183.784 107.211 183.625C107.197 183.465 107.136 183.314 107.036 183.189C106.935 183.064 106.8 182.972 106.648 182.924C106.495 182.876 106.332 182.875 106.178 182.919C106.025 182.964 105.888 183.054 105.785 183.176C105.682 183.299 105.618 183.449 105.601 183.608C105.578 183.822 105.641 184.035 105.775 184.203C105.91 184.37 106.105 184.477 106.318 184.5Z"
              fill="#156649"
            />
            <path
              d="M111.489 185.06C111.649 185.077 111.809 185.047 111.951 184.973C112.093 184.899 112.21 184.784 112.287 184.644C112.363 184.503 112.397 184.343 112.383 184.184C112.368 184.024 112.307 183.873 112.207 183.748C112.106 183.623 111.971 183.531 111.818 183.484C111.666 183.436 111.502 183.434 111.349 183.479C111.195 183.524 111.058 183.614 110.956 183.737C110.853 183.86 110.789 184.01 110.772 184.169C110.749 184.383 110.812 184.596 110.947 184.763C111.081 184.93 111.276 185.037 111.489 185.06Z"
              fill="#156649"
            />
            <path
              d="M116.668 185.628C116.827 185.645 116.987 185.615 117.129 185.541C117.271 185.467 117.388 185.352 117.465 185.212C117.542 185.071 117.575 184.911 117.561 184.752C117.547 184.593 117.485 184.441 117.385 184.316C117.285 184.192 117.149 184.099 116.997 184.052C116.844 184.004 116.68 184.002 116.527 184.047C116.373 184.093 116.237 184.182 116.134 184.305C116.031 184.428 115.967 184.578 115.95 184.738C115.928 184.951 115.99 185.164 116.125 185.331C116.259 185.498 116.455 185.605 116.668 185.628Z"
              fill="#156649"
            />
            <path
              d="M121.846 186.181C122.005 186.198 122.166 186.168 122.308 186.094C122.45 186.02 122.566 185.905 122.643 185.765C122.72 185.625 122.753 185.465 122.739 185.305C122.725 185.146 122.664 184.994 122.564 184.87C122.464 184.745 122.329 184.653 122.176 184.605C122.024 184.557 121.86 184.555 121.707 184.6C121.553 184.645 121.416 184.734 121.313 184.857C121.211 184.979 121.146 185.13 121.129 185.289C121.106 185.502 121.169 185.716 121.303 185.883C121.438 186.05 121.633 186.158 121.846 186.181Z"
              fill="#156649"
            />
            <path
              d="M127.025 186.741C127.184 186.758 127.344 186.728 127.486 186.653C127.628 186.579 127.745 186.465 127.822 186.324C127.898 186.184 127.932 186.024 127.918 185.864C127.904 185.705 127.842 185.553 127.742 185.429C127.642 185.304 127.506 185.212 127.354 185.164C127.201 185.116 127.037 185.115 126.884 185.16C126.73 185.205 126.593 185.295 126.491 185.418C126.388 185.54 126.324 185.691 126.307 185.85C126.285 186.063 126.347 186.277 126.482 186.444C126.616 186.611 126.811 186.718 127.025 186.741Z"
              fill="#156649"
            />
            <path
              d="M132.203 187.3C132.362 187.317 132.523 187.287 132.665 187.213C132.806 187.139 132.923 187.024 133 186.884C133.077 186.743 133.11 186.583 133.096 186.424C133.082 186.264 133.021 186.113 132.92 185.988C132.82 185.863 132.685 185.771 132.532 185.724C132.379 185.676 132.216 185.674 132.062 185.719C131.908 185.764 131.772 185.854 131.669 185.977C131.566 186.1 131.503 186.25 131.486 186.409C131.463 186.623 131.525 186.836 131.66 187.003C131.794 187.17 131.99 187.277 132.203 187.3Z"
              fill="#156649"
            />
            <path
              d="M137.382 187.862C137.541 187.879 137.701 187.848 137.843 187.774C137.985 187.7 138.102 187.586 138.178 187.446C138.255 187.305 138.289 187.145 138.275 186.986C138.261 186.826 138.2 186.675 138.099 186.55C137.999 186.426 137.864 186.333 137.712 186.285C137.559 186.237 137.395 186.236 137.242 186.28C137.088 186.325 136.952 186.415 136.849 186.537C136.746 186.66 136.682 186.81 136.664 186.969C136.653 187.075 136.662 187.182 136.692 187.284C136.722 187.386 136.772 187.481 136.838 187.564C136.905 187.647 136.987 187.716 137.08 187.767C137.174 187.818 137.276 187.85 137.382 187.862Z"
              fill="#156649"
            />
            <path
              d="M142.56 188.421C142.719 188.438 142.879 188.408 143.021 188.334C143.163 188.26 143.28 188.145 143.357 188.005C143.433 187.864 143.467 187.704 143.453 187.545C143.438 187.385 143.377 187.234 143.277 187.109C143.177 186.984 143.041 186.892 142.889 186.844C142.736 186.797 142.572 186.795 142.419 186.84C142.265 186.885 142.128 186.975 142.026 187.098C141.923 187.221 141.859 187.371 141.842 187.53C141.82 187.743 141.883 187.957 142.017 188.124C142.152 188.29 142.347 188.397 142.56 188.421Z"
              fill="#156649"
            />
            <path
              d="M147.738 188.981C147.897 188.998 148.058 188.968 148.2 188.893C148.342 188.819 148.458 188.705 148.535 188.564C148.612 188.424 148.645 188.264 148.631 188.104C148.617 187.945 148.556 187.793 148.455 187.669C148.355 187.544 148.22 187.452 148.067 187.404C147.914 187.356 147.751 187.355 147.597 187.4C147.444 187.445 147.307 187.535 147.204 187.658C147.102 187.78 147.038 187.931 147.021 188.09C146.998 188.303 147.061 188.517 147.195 188.684C147.33 188.851 147.525 188.958 147.738 188.981Z"
              fill="#156649"
            />
            <path
              d="M152.922 189.542C153.081 189.559 153.241 189.529 153.383 189.455C153.525 189.381 153.642 189.267 153.719 189.126C153.795 188.986 153.829 188.826 153.815 188.667C153.801 188.507 153.74 188.356 153.639 188.231C153.539 188.106 153.404 188.014 153.252 187.966C153.099 187.918 152.936 187.916 152.782 187.961C152.628 188.006 152.492 188.095 152.389 188.218C152.286 188.341 152.222 188.491 152.204 188.65C152.193 188.756 152.202 188.863 152.232 188.965C152.262 189.067 152.312 189.162 152.378 189.245C152.445 189.328 152.527 189.396 152.62 189.448C152.714 189.499 152.816 189.531 152.922 189.542Z"
              fill="#156649"
            />
            <path
              d="M158.096 190.102C158.255 190.119 158.416 190.089 158.558 190.015C158.7 189.94 158.816 189.826 158.893 189.686C158.97 189.545 159.003 189.385 158.989 189.226C158.975 189.066 158.914 188.914 158.813 188.79C158.713 188.665 158.578 188.573 158.425 188.525C158.272 188.477 158.109 188.476 157.955 188.521C157.802 188.566 157.665 188.656 157.562 188.779C157.46 188.902 157.396 189.052 157.379 189.211C157.367 189.317 157.377 189.424 157.407 189.526C157.436 189.628 157.486 189.723 157.553 189.805C157.619 189.888 157.702 189.957 157.795 190.008C157.888 190.059 157.99 190.09 158.096 190.102Z"
              fill="#156649"
            />
            <path
              d="M163.273 190.661C163.432 190.679 163.593 190.648 163.735 190.574C163.877 190.5 163.994 190.386 164.07 190.245C164.147 190.105 164.181 189.945 164.166 189.785C164.152 189.626 164.091 189.474 163.991 189.349C163.89 189.225 163.755 189.133 163.602 189.085C163.45 189.037 163.286 189.036 163.133 189.081C162.979 189.126 162.842 189.215 162.74 189.338C162.637 189.461 162.573 189.612 162.556 189.771C162.545 189.876 162.554 189.983 162.584 190.085C162.614 190.187 162.663 190.282 162.73 190.365C162.796 190.448 162.879 190.516 162.972 190.567C163.065 190.618 163.168 190.65 163.273 190.661Z"
              fill="#156649"
            />
            <path
              d="M168.453 191.223C168.612 191.24 168.772 191.209 168.914 191.135C169.056 191.061 169.172 190.947 169.249 190.807C169.326 190.666 169.36 190.506 169.345 190.347C169.331 190.188 169.27 190.036 169.17 189.911C169.07 189.787 168.935 189.694 168.782 189.646C168.63 189.598 168.466 189.597 168.313 189.642C168.159 189.686 168.022 189.776 167.92 189.898C167.817 190.021 167.753 190.171 167.735 190.33C167.724 190.436 167.733 190.543 167.763 190.645C167.793 190.747 167.842 190.842 167.909 190.925C167.976 191.008 168.058 191.077 168.151 191.128C168.244 191.179 168.347 191.211 168.453 191.223Z"
              fill="#156649"
            />
            <path
              d="M173.631 191.782C173.79 191.799 173.951 191.769 174.093 191.695C174.235 191.621 174.352 191.506 174.428 191.366C174.505 191.226 174.539 191.065 174.524 190.906C174.51 190.747 174.449 190.595 174.349 190.47C174.248 190.346 174.113 190.254 173.96 190.206C173.807 190.158 173.644 190.156 173.49 190.201C173.337 190.247 173.2 190.336 173.097 190.459C172.995 190.582 172.931 190.732 172.914 190.892C172.902 190.997 172.912 191.104 172.942 191.206C172.971 191.308 173.021 191.403 173.088 191.486C173.154 191.568 173.237 191.637 173.33 191.688C173.423 191.739 173.526 191.771 173.631 191.782Z"
              fill="#156649"
            />
            <path
              d="M178.809 192.342C178.968 192.359 179.129 192.329 179.271 192.255C179.413 192.181 179.529 192.066 179.606 191.926C179.683 191.785 179.716 191.625 179.702 191.466C179.688 191.306 179.627 191.155 179.526 191.03C179.426 190.905 179.291 190.813 179.138 190.765C178.985 190.718 178.822 190.716 178.668 190.761C178.515 190.806 178.378 190.896 178.275 191.019C178.173 191.142 178.109 191.292 178.092 191.451C178.08 191.557 178.09 191.664 178.119 191.766C178.149 191.868 178.199 191.963 178.265 192.046C178.332 192.128 178.414 192.197 178.508 192.248C178.601 192.299 178.703 192.331 178.809 192.342Z"
              fill="#156649"
            />
            <path
              d="M183.987 192.905C184.146 192.922 184.307 192.892 184.449 192.818C184.591 192.744 184.707 192.629 184.784 192.489C184.861 192.348 184.894 192.188 184.88 192.029C184.866 191.87 184.805 191.718 184.705 191.593C184.605 191.469 184.47 191.377 184.317 191.329C184.165 191.281 184.001 191.279 183.848 191.324C183.694 191.369 183.557 191.458 183.454 191.581C183.352 191.703 183.287 191.853 183.27 192.012C183.258 192.118 183.268 192.225 183.297 192.327C183.327 192.43 183.377 192.525 183.443 192.608C183.51 192.691 183.592 192.76 183.686 192.811C183.779 192.861 183.882 192.894 183.987 192.905Z"
              fill="#156649"
            />
            <path
              d="M189.166 193.463C189.325 193.48 189.486 193.449 189.628 193.375C189.77 193.301 189.887 193.187 189.963 193.046C190.04 192.906 190.074 192.746 190.059 192.586C190.045 192.427 189.984 192.275 189.884 192.151C189.783 192.026 189.648 191.934 189.495 191.886C189.343 191.838 189.179 191.837 189.026 191.882C188.872 191.927 188.735 192.017 188.633 192.14C188.53 192.262 188.466 192.413 188.449 192.572C188.438 192.678 188.447 192.784 188.477 192.886C188.507 192.988 188.556 193.083 188.623 193.166C188.69 193.249 188.772 193.318 188.865 193.369C188.958 193.419 189.061 193.451 189.166 193.463Z"
              fill="#156649"
            />
            <path
              d="M194.345 194.022C194.504 194.039 194.664 194.009 194.806 193.935C194.948 193.861 195.065 193.746 195.142 193.606C195.219 193.465 195.252 193.305 195.238 193.146C195.224 192.987 195.162 192.835 195.062 192.71C194.962 192.586 194.826 192.494 194.674 192.446C194.521 192.398 194.357 192.396 194.204 192.441C194.05 192.487 193.913 192.576 193.811 192.699C193.708 192.822 193.644 192.972 193.627 193.132C193.616 193.237 193.625 193.344 193.655 193.446C193.685 193.548 193.735 193.643 193.801 193.726C193.868 193.808 193.95 193.877 194.043 193.928C194.137 193.979 194.239 194.011 194.345 194.022Z"
              fill="#156649"
            />
            <path
              d="M199.526 194.583C199.685 194.601 199.846 194.57 199.988 194.496C200.13 194.422 200.246 194.308 200.323 194.167C200.4 194.027 200.433 193.867 200.419 193.708C200.405 193.548 200.344 193.397 200.244 193.272C200.144 193.147 200.009 193.055 199.856 193.007C199.703 192.959 199.54 192.958 199.386 193.002C199.233 193.047 199.096 193.137 198.993 193.259C198.89 193.382 198.826 193.532 198.809 193.691C198.797 193.797 198.807 193.904 198.837 194.006C198.866 194.108 198.916 194.203 198.983 194.286C199.049 194.369 199.132 194.438 199.225 194.489C199.318 194.54 199.421 194.572 199.526 194.583Z"
              fill="#156649"
            />
            <path
              d="M204.702 195.143C204.861 195.16 205.021 195.13 205.163 195.056C205.305 194.982 205.422 194.867 205.499 194.727C205.575 194.587 205.609 194.427 205.595 194.267C205.58 194.108 205.519 193.956 205.419 193.831C205.318 193.707 205.183 193.615 205.031 193.567C204.878 193.519 204.714 193.517 204.561 193.563C204.407 193.608 204.27 193.697 204.168 193.82C204.065 193.943 204.001 194.094 203.984 194.253C203.973 194.358 203.982 194.465 204.012 194.567C204.042 194.669 204.091 194.764 204.158 194.847C204.225 194.93 204.307 194.998 204.4 195.049C204.494 195.1 204.596 195.132 204.702 195.143Z"
              fill="#156649"
            />
            <path
              d="M209.88 195.703C210.039 195.72 210.2 195.69 210.341 195.616C210.483 195.542 210.6 195.427 210.677 195.287C210.754 195.146 210.787 194.986 210.773 194.827C210.759 194.667 210.697 194.516 210.597 194.391C210.497 194.266 210.362 194.174 210.209 194.126C210.056 194.078 209.893 194.077 209.739 194.122C209.585 194.167 209.449 194.257 209.346 194.38C209.243 194.503 209.18 194.653 209.162 194.812C209.151 194.918 209.16 195.025 209.19 195.127C209.22 195.229 209.27 195.324 209.336 195.406C209.403 195.489 209.485 195.558 209.578 195.609C209.672 195.66 209.774 195.692 209.88 195.703Z"
              fill="#156649"
            />
            <path
              d="M215.058 196.264C215.217 196.282 215.378 196.251 215.519 196.177C215.661 196.103 215.778 195.989 215.855 195.848C215.932 195.708 215.965 195.548 215.951 195.389C215.937 195.229 215.876 195.078 215.776 194.953C215.675 194.828 215.54 194.736 215.388 194.688C215.235 194.64 215.072 194.638 214.918 194.683C214.765 194.728 214.628 194.818 214.525 194.94C214.422 195.063 214.358 195.213 214.341 195.372C214.329 195.478 214.339 195.585 214.368 195.687C214.398 195.789 214.448 195.884 214.514 195.967C214.581 196.05 214.663 196.119 214.757 196.17C214.85 196.221 214.952 196.253 215.058 196.264Z"
              fill="#156649"
            />
            <path
              d="M220.237 196.824C220.396 196.841 220.556 196.811 220.698 196.737C220.84 196.663 220.957 196.548 221.034 196.408C221.111 196.267 221.144 196.107 221.13 195.948C221.116 195.788 221.054 195.637 220.954 195.512C220.854 195.387 220.718 195.295 220.566 195.247C220.413 195.2 220.249 195.198 220.096 195.243C219.942 195.288 219.806 195.378 219.703 195.501C219.6 195.624 219.536 195.774 219.519 195.933C219.508 196.039 219.517 196.146 219.547 196.248C219.577 196.35 219.627 196.445 219.693 196.527C219.76 196.61 219.842 196.679 219.935 196.73C220.029 196.781 220.131 196.813 220.237 196.824Z"
              fill="#156649"
            />
            <path
              d="M225.415 197.385C225.574 197.402 225.735 197.372 225.877 197.298C226.019 197.224 226.135 197.11 226.212 196.969C226.289 196.829 226.322 196.669 226.308 196.51C226.294 196.35 226.233 196.199 226.133 196.074C226.033 195.949 225.898 195.857 225.745 195.809C225.592 195.761 225.429 195.759 225.275 195.804C225.122 195.849 224.985 195.938 224.882 196.061C224.779 196.184 224.715 196.334 224.698 196.493C224.675 196.706 224.738 196.92 224.872 197.087C225.007 197.255 225.202 197.362 225.415 197.385Z"
              fill="#156649"
            />
            <path
              d="M43.6103 182.955C43.7694 182.973 43.9302 182.943 44.0723 182.869C44.2144 182.795 44.3314 182.681 44.4085 182.54C44.4855 182.4 44.5192 182.24 44.5052 182.08C44.4912 181.921 44.4302 181.769 44.3299 181.644C44.2296 181.52 44.0945 181.427 43.9418 181.379C43.789 181.331 43.6254 181.33 43.4717 181.375C43.3181 181.42 43.1812 181.509 43.0785 181.632C42.9758 181.755 42.9118 181.906 42.8948 182.065C42.8719 182.278 42.9344 182.491 43.0685 182.658C43.2026 182.825 43.3974 182.932 43.6103 182.955Z"
              fill="#156649"
            />
            <path
              d="M48.7884 183.517C48.9475 183.534 49.1081 183.503 49.2499 183.429C49.3917 183.355 49.5084 183.241 49.5852 183.101C49.662 182.96 49.6955 182.8 49.6814 182.641C49.6673 182.482 49.6063 182.33 49.5061 182.205C49.4058 182.081 49.2709 181.988 49.1183 181.94C48.9657 181.892 48.8022 181.891 48.6487 181.936C48.4951 181.98 48.3583 182.07 48.2555 182.192C48.1527 182.315 48.0886 182.465 48.0712 182.624C48.0596 182.73 48.069 182.837 48.0989 182.939C48.1287 183.041 48.1783 183.136 48.2449 183.219C48.3115 183.302 48.3938 183.371 48.4871 183.422C48.5803 183.473 48.6827 183.505 48.7884 183.517Z"
              fill="#156649"
            />
            <path
              d="M53.967 184.069C54.1261 184.086 54.2868 184.056 54.4287 183.982C54.5706 183.908 54.6873 183.793 54.7641 183.653C54.8409 183.512 54.8743 183.352 54.8601 183.193C54.8459 183.033 54.7847 182.882 54.6843 182.757C54.5839 182.632 54.4488 182.54 54.296 182.493C54.1433 182.445 53.9798 182.443 53.8262 182.488C53.6726 182.533 53.5359 182.623 53.4332 182.746C53.3306 182.869 53.2668 183.019 53.2497 183.179C53.2269 183.392 53.2896 183.605 53.4241 183.772C53.5586 183.939 53.7538 184.046 53.967 184.069Z"
              fill="#156649"
            />
            <path
              d="M59.1447 184.637C59.3038 184.655 59.4644 184.624 59.6062 184.55C59.748 184.476 59.8647 184.362 59.9415 184.221C60.0183 184.081 60.0518 183.921 60.0377 183.762C60.0236 183.602 59.9626 183.451 59.8623 183.326C59.7621 183.201 59.6271 183.109 59.4745 183.061C59.3219 183.013 59.1585 183.012 59.0049 183.056C58.8514 183.101 58.7145 183.191 58.6118 183.313C58.509 183.436 58.4448 183.586 58.4275 183.745C58.4046 183.959 58.4673 184.172 58.6017 184.339C58.7362 184.507 58.9314 184.614 59.1447 184.637Z"
              fill="#156649"
            />
            <path
              d="M64.3239 185.196C64.483 185.214 64.6437 185.183 64.7856 185.109C64.9275 185.035 65.0442 184.921 65.121 184.78C65.1978 184.64 65.2312 184.48 65.217 184.32C65.2028 184.161 65.1416 184.009 65.0412 183.885C64.9408 183.76 64.8057 183.668 64.653 183.62C64.5002 183.572 64.3367 183.571 64.1831 183.616C64.0295 183.661 63.8928 183.751 63.7902 183.873C63.6876 183.996 63.6237 184.147 63.6067 184.306C63.5838 184.519 63.6465 184.733 63.781 184.9C63.9155 185.067 64.1108 185.173 64.3239 185.196Z"
              fill="#156649"
            />
            <path
              d="M69.5021 185.757C69.6613 185.774 69.8219 185.743 69.9638 185.669C70.1057 185.595 70.2224 185.481 70.2992 185.34C70.376 185.2 70.4094 185.04 70.3952 184.88C70.381 184.721 70.3199 184.569 70.2195 184.445C70.1191 184.32 69.9839 184.228 69.8312 184.18C69.6784 184.132 69.5149 184.131 69.3613 184.176C69.2078 184.221 69.071 184.311 68.9684 184.433C68.8658 184.556 68.8019 184.707 68.7849 184.866C68.762 185.079 68.8248 185.293 68.9592 185.46C69.0937 185.627 69.289 185.733 69.5021 185.757Z"
              fill="#156649"
            />
            <path
              d="M74.6799 186.321C74.8389 186.338 74.9995 186.308 75.1413 186.234C75.2832 186.16 75.3998 186.046 75.4767 185.905C75.5535 185.765 75.5869 185.605 75.5728 185.446C75.5588 185.286 75.4977 185.135 75.3975 185.01C75.2973 184.885 75.1623 184.793 75.0097 184.745C74.8571 184.697 74.6937 184.695 74.5401 184.74C74.3865 184.785 74.2497 184.874 74.1469 184.997C74.0441 185.12 73.98 185.27 73.9626 185.429C73.9398 185.642 74.0024 185.856 74.1369 186.023C74.2713 186.191 74.4666 186.298 74.6799 186.321Z"
              fill="#156649"
            />
            <path
              d="M79.8591 186.877C80.0182 186.895 80.1789 186.864 80.3208 186.79C80.4627 186.716 80.5794 186.602 80.6562 186.461C80.733 186.321 80.7664 186.161 80.7522 186.001C80.738 185.842 80.6768 185.69 80.5764 185.565C80.476 185.441 80.3409 185.349 80.1881 185.301C80.0354 185.253 79.8718 185.252 79.7183 185.297C79.5647 185.342 79.4279 185.431 79.3253 185.554C79.2227 185.677 79.1589 185.828 79.1418 185.987C79.119 186.2 79.1817 186.414 79.3162 186.581C79.4507 186.747 79.6459 186.854 79.8591 186.877Z"
              fill="#156649"
            />
            <path
              d="M85.0373 187.437C85.1964 187.454 85.3571 187.424 85.499 187.35C85.6409 187.276 85.7576 187.161 85.8344 187.021C85.9112 186.88 85.9446 186.72 85.9304 186.561C85.9162 186.401 85.855 186.25 85.7546 186.125C85.6542 186 85.5191 185.908 85.3663 185.86C85.2136 185.813 85.0501 185.811 84.8965 185.856C84.7429 185.901 84.6062 185.991 84.5035 186.114C84.4009 186.237 84.3371 186.387 84.32 186.546C84.2972 186.76 84.3599 186.973 84.4944 187.14C84.6289 187.307 84.8241 187.414 85.0373 187.437Z"
              fill="#156649"
            />
            <path
              d="M90.2163 187.998C90.3754 188.015 90.536 187.985 90.6778 187.911C90.8196 187.837 90.9363 187.723 91.0131 187.582C91.0899 187.442 91.1234 187.282 91.1093 187.123C91.0952 186.963 91.0342 186.812 90.934 186.687C90.8337 186.562 90.6988 186.47 90.5462 186.422C90.3935 186.374 90.2301 186.372 90.0765 186.417C89.923 186.462 89.7861 186.551 89.6834 186.674C89.5806 186.797 89.5164 186.947 89.4991 187.106C89.4767 187.319 89.5395 187.533 89.6739 187.7C89.8083 187.867 90.0032 187.974 90.2163 187.998Z"
              fill="#156649"
            />
            <path
              d="M95.4027 188.558C95.5618 188.576 95.7225 188.545 95.8644 188.471C96.0063 188.397 96.123 188.283 96.1998 188.142C96.2766 188.002 96.31 187.842 96.2958 187.682C96.2816 187.523 96.2204 187.371 96.12 187.246C96.0196 187.122 95.8845 187.03 95.7317 186.982C95.579 186.934 95.4155 186.933 95.2619 186.978C95.1083 187.023 94.9716 187.112 94.869 187.235C94.7664 187.358 94.7025 187.509 94.6854 187.668C94.6739 187.773 94.6833 187.88 94.7131 187.982C94.743 188.084 94.7926 188.179 94.8593 188.262C94.9259 188.345 95.0082 188.413 95.1014 188.464C95.1947 188.515 95.2971 188.547 95.4027 188.558Z"
              fill="#156649"
            />
            <path
              d="M100.573 189.118C100.732 189.135 100.893 189.105 101.035 189.031C101.177 188.957 101.293 188.842 101.37 188.702C101.447 188.561 101.48 188.401 101.466 188.242C101.452 188.082 101.391 187.931 101.29 187.806C101.19 187.681 101.055 187.589 100.902 187.541C100.749 187.494 100.586 187.492 100.432 187.537C100.279 187.582 100.142 187.672 100.039 187.795C99.9368 187.918 99.8729 188.068 99.8558 188.227C99.8443 188.333 99.8537 188.44 99.8836 188.542C99.9134 188.644 99.963 188.739 100.03 188.821C100.096 188.904 100.179 188.973 100.272 189.024C100.365 189.075 100.467 189.107 100.573 189.118Z"
              fill="#156649"
            />
            <path
              d="M105.751 189.679C105.91 189.696 106.071 189.666 106.213 189.592C106.355 189.518 106.471 189.404 106.548 189.263C106.625 189.123 106.658 188.963 106.644 188.803C106.63 188.644 106.569 188.493 106.469 188.368C106.369 188.243 106.234 188.151 106.081 188.103C105.929 188.055 105.765 188.053 105.612 188.098C105.458 188.143 105.321 188.232 105.218 188.355C105.116 188.478 105.051 188.628 105.034 188.787C105.023 188.893 105.032 189 105.062 189.102C105.092 189.204 105.141 189.299 105.208 189.382C105.274 189.465 105.357 189.533 105.45 189.584C105.543 189.636 105.646 189.668 105.751 189.679Z"
              fill="#156649"
            />
            <path
              d="M110.93 190.238C111.089 190.255 111.249 190.225 111.391 190.151C111.533 190.077 111.65 189.962 111.727 189.822C111.803 189.682 111.837 189.521 111.823 189.362C111.808 189.203 111.747 189.051 111.647 188.926C111.546 188.802 111.411 188.71 111.259 188.662C111.106 188.614 110.942 188.612 110.789 188.658C110.635 188.703 110.498 188.792 110.396 188.915C110.293 189.038 110.229 189.189 110.212 189.348C110.201 189.453 110.21 189.56 110.24 189.662C110.27 189.764 110.319 189.859 110.386 189.942C110.453 190.025 110.535 190.093 110.628 190.144C110.722 190.195 110.824 190.227 110.93 190.238Z"
              fill="#156649"
            />
            <path
              d="M116.108 190.798C116.267 190.815 116.428 190.785 116.57 190.711C116.712 190.637 116.829 190.522 116.905 190.382C116.982 190.241 117.016 190.081 117.001 189.922C116.987 189.762 116.926 189.611 116.826 189.486C116.725 189.361 116.59 189.269 116.437 189.221C116.285 189.173 116.121 189.172 115.967 189.217C115.814 189.262 115.677 189.352 115.575 189.475C115.472 189.598 115.408 189.748 115.391 189.907C115.379 190.013 115.389 190.12 115.419 190.222C115.449 190.324 115.498 190.419 115.565 190.501C115.631 190.584 115.714 190.653 115.807 190.704C115.9 190.755 116.003 190.787 116.108 190.798Z"
              fill="#156649"
            />
            <path
              d="M121.287 191.359C121.446 191.377 121.607 191.346 121.748 191.272C121.89 191.198 122.007 191.084 122.084 190.943C122.161 190.803 122.194 190.643 122.18 190.484C122.166 190.324 122.105 190.173 122.005 190.048C121.904 189.923 121.769 189.831 121.617 189.783C121.464 189.735 121.301 189.733 121.147 189.778C120.994 189.823 120.857 189.913 120.754 190.035C120.651 190.158 120.587 190.308 120.57 190.467C120.558 190.573 120.568 190.68 120.597 190.782C120.627 190.884 120.677 190.979 120.743 191.062C120.81 191.145 120.892 191.214 120.986 191.265C121.079 191.316 121.181 191.348 121.287 191.359Z"
              fill="#156649"
            />
            <path
              d="M126.465 191.919C126.624 191.936 126.785 191.906 126.926 191.832C127.068 191.758 127.185 191.643 127.262 191.503C127.339 191.362 127.372 191.202 127.358 191.043C127.344 190.884 127.282 190.732 127.182 190.607C127.082 190.483 126.947 190.39 126.794 190.343C126.641 190.295 126.477 190.293 126.324 190.338C126.17 190.384 126.034 190.473 125.931 190.596C125.828 190.719 125.764 190.869 125.747 191.029C125.736 191.134 125.745 191.241 125.775 191.343C125.805 191.445 125.855 191.54 125.921 191.623C125.988 191.705 126.07 191.774 126.163 191.825C126.257 191.876 126.359 191.908 126.465 191.919Z"
              fill="#156649"
            />
            <path
              d="M131.643 192.479C131.803 192.496 131.963 192.466 132.105 192.392C132.247 192.317 132.364 192.203 132.441 192.062C132.517 191.922 132.551 191.762 132.537 191.603C132.522 191.443 132.461 191.291 132.361 191.167C132.26 191.042 132.125 190.95 131.972 190.902C131.82 190.854 131.656 190.853 131.503 190.898C131.349 190.943 131.212 191.033 131.11 191.156C131.007 191.278 130.943 191.429 130.926 191.588C130.915 191.694 130.924 191.801 130.954 191.903C130.984 192.005 131.033 192.1 131.1 192.182C131.167 192.265 131.249 192.334 131.342 192.385C131.435 192.435 131.538 192.467 131.643 192.479Z"
              fill="#156649"
            />
            <path
              d="M136.822 193.04C136.981 193.057 137.142 193.027 137.283 192.953C137.425 192.879 137.542 192.764 137.619 192.624C137.696 192.484 137.729 192.324 137.715 192.164C137.701 192.005 137.64 191.853 137.54 191.729C137.439 191.604 137.304 191.512 137.152 191.464C136.999 191.416 136.836 191.414 136.682 191.459C136.529 191.504 136.392 191.593 136.289 191.716C136.186 191.838 136.122 191.989 136.105 192.148C136.082 192.361 136.145 192.575 136.279 192.742C136.413 192.909 136.609 193.016 136.822 193.04Z"
              fill="#156649"
            />
            <path
              d="M142.008 193.598C142.167 193.615 142.327 193.585 142.469 193.51C142.611 193.436 142.728 193.322 142.805 193.181C142.881 193.041 142.915 192.881 142.901 192.721C142.886 192.562 142.825 192.41 142.725 192.286C142.624 192.161 142.489 192.069 142.337 192.021C142.184 191.973 142.02 191.972 141.867 192.017C141.713 192.062 141.576 192.152 141.474 192.275C141.371 192.397 141.307 192.548 141.29 192.707C141.267 192.92 141.33 193.134 141.465 193.301C141.599 193.468 141.794 193.574 142.008 193.598Z"
              fill="#156649"
            />
            <path
              d="M147.179 194.159C147.338 194.176 147.498 194.146 147.64 194.072C147.782 193.998 147.899 193.883 147.976 193.743C148.052 193.602 148.086 193.442 148.072 193.283C148.057 193.124 147.996 192.972 147.896 192.847C147.796 192.723 147.66 192.63 147.508 192.583C147.355 192.535 147.191 192.533 147.038 192.578C146.884 192.624 146.747 192.713 146.645 192.836C146.542 192.959 146.478 193.109 146.461 193.269C146.45 193.374 146.459 193.481 146.489 193.583C146.519 193.685 146.569 193.78 146.635 193.863C146.702 193.945 146.784 194.014 146.877 194.065C146.971 194.116 147.073 194.148 147.179 194.159Z"
              fill="#156649"
            />
            <path
              d="M152.357 194.72C152.516 194.738 152.677 194.707 152.819 194.633C152.96 194.559 153.077 194.445 153.154 194.304C153.231 194.164 153.264 194.004 153.25 193.845C153.236 193.685 153.175 193.534 153.075 193.409C152.975 193.284 152.84 193.192 152.687 193.144C152.534 193.096 152.371 193.095 152.217 193.139C152.064 193.184 151.927 193.274 151.824 193.396C151.721 193.519 151.657 193.669 151.64 193.828C151.617 194.042 151.68 194.255 151.814 194.422C151.949 194.59 152.144 194.697 152.357 194.72Z"
              fill="#156649"
            />
            <path
              d="M157.536 195.28C157.695 195.297 157.856 195.267 157.998 195.193C158.139 195.119 158.256 195.004 158.333 194.864C158.41 194.723 158.443 194.563 158.429 194.404C158.415 194.244 158.354 194.093 158.253 193.968C158.153 193.843 158.018 193.751 157.865 193.703C157.712 193.656 157.549 193.654 157.395 193.699C157.241 193.744 157.105 193.834 157.002 193.957C156.899 194.08 156.836 194.23 156.819 194.389C156.796 194.603 156.858 194.816 156.993 194.983C157.127 195.15 157.323 195.257 157.536 195.28Z"
              fill="#156649"
            />
            <path
              d="M162.714 195.84C162.873 195.857 163.034 195.826 163.176 195.752C163.318 195.678 163.435 195.564 163.511 195.423C163.588 195.283 163.622 195.123 163.607 194.963C163.593 194.804 163.532 194.652 163.432 194.528C163.331 194.403 163.196 194.311 163.043 194.263C162.891 194.215 162.727 194.214 162.573 194.259C162.42 194.304 162.283 194.394 162.18 194.516C162.078 194.639 162.014 194.79 161.997 194.949C161.985 195.055 161.995 195.161 162.025 195.263C162.054 195.365 162.104 195.46 162.171 195.543C162.237 195.626 162.32 195.695 162.413 195.745C162.506 195.796 162.609 195.828 162.714 195.84Z"
              fill="#156649"
            />
            <path
              d="M167.893 196.401C168.052 196.418 168.212 196.388 168.354 196.314C168.496 196.24 168.613 196.125 168.69 195.985C168.766 195.845 168.8 195.685 168.786 195.525C168.772 195.366 168.711 195.214 168.61 195.09C168.51 194.965 168.375 194.873 168.223 194.825C168.07 194.777 167.907 194.775 167.753 194.82C167.599 194.865 167.463 194.954 167.36 195.077C167.257 195.199 167.193 195.35 167.176 195.509C167.153 195.722 167.215 195.936 167.35 196.103C167.484 196.27 167.679 196.377 167.893 196.401Z"
              fill="#156649"
            />
            <path
              d="M173.071 196.96C173.23 196.978 173.391 196.947 173.533 196.873C173.675 196.799 173.791 196.685 173.868 196.544C173.945 196.404 173.978 196.244 173.964 196.084C173.95 195.925 173.889 195.773 173.788 195.648C173.688 195.524 173.553 195.432 173.4 195.384C173.247 195.336 173.084 195.335 172.93 195.38C172.777 195.425 172.64 195.514 172.537 195.637C172.435 195.76 172.371 195.911 172.354 196.07C172.331 196.283 172.394 196.497 172.528 196.664C172.663 196.83 172.858 196.937 173.071 196.96Z"
              fill="#156649"
            />
            <path
              d="M178.25 197.52C178.409 197.538 178.57 197.507 178.712 197.433C178.854 197.359 178.971 197.245 179.047 197.104C179.124 196.964 179.158 196.804 179.143 196.644C179.129 196.485 179.068 196.333 178.968 196.209C178.867 196.084 178.732 195.992 178.579 195.944C178.427 195.896 178.263 195.895 178.11 195.94C177.956 195.985 177.819 196.075 177.717 196.197C177.614 196.32 177.55 196.471 177.533 196.63C177.522 196.736 177.531 196.842 177.561 196.944C177.591 197.046 177.64 197.141 177.707 197.224C177.774 197.307 177.856 197.376 177.949 197.426C178.042 197.477 178.145 197.509 178.25 197.52Z"
              fill="#156649"
            />
            <path
              d="M183.428 198.081C183.587 198.099 183.748 198.068 183.889 197.994C184.031 197.92 184.148 197.806 184.225 197.666C184.302 197.525 184.335 197.365 184.321 197.206C184.307 197.046 184.246 196.895 184.146 196.77C184.045 196.645 183.91 196.553 183.758 196.505C183.605 196.457 183.442 196.456 183.288 196.5C183.135 196.545 182.998 196.635 182.895 196.757C182.792 196.88 182.728 197.03 182.711 197.189C182.688 197.403 182.75 197.616 182.885 197.784C183.019 197.951 183.215 198.058 183.428 198.081Z"
              fill="#156649"
            />
            <path
              d="M188.612 198.641C188.771 198.659 188.931 198.628 189.073 198.554C189.215 198.48 189.332 198.366 189.409 198.225C189.485 198.085 189.519 197.925 189.505 197.765C189.49 197.606 189.429 197.454 189.329 197.329C189.228 197.205 189.093 197.113 188.941 197.065C188.788 197.017 188.624 197.016 188.471 197.061C188.317 197.106 188.18 197.195 188.078 197.318C187.975 197.441 187.911 197.592 187.894 197.751C187.871 197.964 187.934 198.177 188.069 198.344C188.203 198.511 188.398 198.618 188.612 198.641Z"
              fill="#156649"
            />
            <path
              d="M193.784 199.201C193.943 199.218 194.104 199.188 194.246 199.115C194.388 199.041 194.505 198.926 194.582 198.786C194.659 198.646 194.693 198.486 194.679 198.326C194.665 198.167 194.604 198.015 194.503 197.89C194.403 197.765 194.268 197.673 194.115 197.625C193.962 197.577 193.799 197.575 193.645 197.62C193.491 197.665 193.355 197.755 193.252 197.878C193.149 198.001 193.085 198.151 193.068 198.31C193.045 198.523 193.108 198.737 193.242 198.904C193.376 199.07 193.571 199.177 193.784 199.201Z"
              fill="#156649"
            />
            <path
              d="M198.962 199.762C199.121 199.78 199.281 199.749 199.423 199.676C199.565 199.602 199.682 199.488 199.759 199.347C199.836 199.207 199.87 199.047 199.856 198.888C199.842 198.728 199.782 198.576 199.681 198.452C199.581 198.327 199.446 198.234 199.294 198.186C199.141 198.138 198.978 198.136 198.824 198.181C198.67 198.226 198.533 198.315 198.431 198.438C198.328 198.56 198.263 198.711 198.246 198.87C198.223 199.083 198.285 199.297 198.419 199.464C198.553 199.631 198.748 199.738 198.962 199.762Z"
              fill="#156649"
            />
            <path
              d="M204.14 200.321C204.299 200.339 204.46 200.308 204.602 200.234C204.743 200.16 204.86 200.046 204.937 199.905C205.014 199.765 205.047 199.605 205.033 199.445C205.019 199.286 204.958 199.134 204.857 199.01C204.757 198.885 204.622 198.793 204.469 198.745C204.316 198.697 204.153 198.696 203.999 198.741C203.845 198.786 203.709 198.876 203.606 198.998C203.503 199.121 203.44 199.272 203.423 199.431C203.4 199.644 203.462 199.858 203.597 200.025C203.731 200.192 203.927 200.298 204.14 200.321Z"
              fill="#156649"
            />
            <path
              d="M209.319 200.874C209.478 200.892 209.639 200.861 209.781 200.787C209.923 200.713 210.039 200.599 210.116 200.458C210.193 200.318 210.226 200.158 210.212 199.998C210.198 199.839 210.137 199.687 210.036 199.563C209.936 199.438 209.801 199.346 209.648 199.298C209.495 199.25 209.332 199.249 209.178 199.294C209.025 199.339 208.888 199.429 208.785 199.551C208.683 199.674 208.619 199.825 208.602 199.984C208.579 200.197 208.642 200.411 208.776 200.578C208.911 200.745 209.106 200.851 209.319 200.874Z"
              fill="#156649"
            />
            <path
              d="M214.497 201.443C214.656 201.46 214.816 201.429 214.958 201.355C215.1 201.281 215.217 201.167 215.294 201.027C215.37 200.886 215.404 200.726 215.39 200.567C215.376 200.408 215.315 200.256 215.214 200.131C215.114 200.007 214.979 199.914 214.827 199.866C214.674 199.818 214.511 199.817 214.357 199.862C214.203 199.906 214.067 199.996 213.964 200.118C213.861 200.241 213.797 200.391 213.78 200.55C213.757 200.764 213.819 200.977 213.954 201.145C214.088 201.312 214.283 201.419 214.497 201.443Z"
              fill="#156649"
            />
            <path
              d="M219.675 202.002C219.834 202.019 219.995 201.989 220.137 201.915C220.279 201.841 220.395 201.726 220.472 201.586C220.549 201.445 220.582 201.285 220.568 201.126C220.554 200.967 220.493 200.815 220.392 200.69C220.292 200.566 220.157 200.473 220.004 200.426C219.851 200.378 219.688 200.376 219.534 200.421C219.381 200.467 219.244 200.556 219.141 200.679C219.039 200.802 218.975 200.952 218.958 201.112C218.935 201.325 218.998 201.538 219.132 201.705C219.267 201.872 219.462 201.979 219.675 202.002Z"
              fill="#156649"
            />
            <path
              d="M224.854 202.562C225.013 202.579 225.174 202.549 225.316 202.475C225.458 202.4 225.574 202.286 225.651 202.145C225.728 202.005 225.761 201.845 225.747 201.686C225.733 201.526 225.672 201.374 225.572 201.25C225.471 201.125 225.336 201.033 225.183 200.985C225.03 200.937 224.867 200.936 224.713 200.981C224.56 201.026 224.423 201.116 224.32 201.239C224.218 201.361 224.154 201.512 224.137 201.671C224.114 201.884 224.177 202.098 224.311 202.265C224.446 202.432 224.641 202.539 224.854 202.562Z"
              fill="#156649"
            />
            <path
              d="M43.0502 188.133C43.2093 188.151 43.37 188.12 43.5119 188.046C43.6537 187.972 43.7705 187.858 43.8473 187.717C43.9241 187.577 43.9575 187.417 43.9433 187.257C43.9291 187.098 43.8679 186.946 43.7675 186.822C43.6671 186.697 43.532 186.605 43.3792 186.557C43.2265 186.509 43.0629 186.508 42.9094 186.553C42.7558 186.598 42.619 186.688 42.5164 186.81C42.4138 186.933 42.3499 187.084 42.3329 187.243C42.3214 187.349 42.3308 187.455 42.3606 187.557C42.3904 187.659 42.4401 187.754 42.5067 187.837C42.5733 187.92 42.6556 187.989 42.7489 188.039C42.8422 188.09 42.9445 188.122 43.0502 188.133Z"
              fill="#156649"
            />
            <path
              d="M48.2291 188.695C48.3881 188.712 48.5487 188.682 48.6905 188.608C48.8323 188.534 48.949 188.419 49.0258 188.279C49.1026 188.138 49.1361 187.979 49.122 187.819C49.1079 187.66 49.0469 187.508 48.9467 187.383C48.8464 187.259 48.7115 187.167 48.5589 187.119C48.4063 187.071 48.2428 187.069 48.0893 187.114C47.9357 187.159 47.7989 187.248 47.6961 187.371C47.5933 187.493 47.5292 187.643 47.5118 187.803C47.5 187.908 47.5093 188.015 47.539 188.117C47.5687 188.22 47.6183 188.315 47.6849 188.398C47.7516 188.481 47.834 188.55 47.9274 188.601C48.0207 188.652 48.1233 188.684 48.2291 188.695Z"
              fill="#156649"
            />
            <path
              d="M53.4076 189.254C53.5667 189.272 53.7274 189.241 53.8693 189.167C54.0112 189.093 54.1279 188.979 54.2047 188.838C54.2815 188.698 54.3149 188.538 54.3007 188.378C54.2865 188.219 54.2253 188.067 54.1249 187.942C54.0245 187.818 53.8894 187.726 53.7366 187.678C53.5839 187.63 53.4204 187.629 53.2668 187.674C53.1132 187.719 52.9765 187.808 52.8739 187.931C52.7712 188.054 52.7074 188.205 52.6903 188.364C52.6788 188.469 52.6882 188.576 52.718 188.678C52.7479 188.78 52.7975 188.875 52.8641 188.958C52.9308 189.041 53.0131 189.109 53.1063 189.16C53.1996 189.211 53.302 189.243 53.4076 189.254Z"
              fill="#156649"
            />
            <path
              d="M58.5853 189.814C58.7444 189.831 58.9051 189.801 59.047 189.727C59.1889 189.653 59.3056 189.538 59.3824 189.398C59.4592 189.257 59.4926 189.097 59.4784 188.938C59.4642 188.778 59.4031 188.627 59.3026 188.502C59.2022 188.377 59.0671 188.285 58.9144 188.237C58.7616 188.19 58.5981 188.188 58.4445 188.233C58.2909 188.278 58.1542 188.368 58.0516 188.491C57.949 188.614 57.8851 188.764 57.8681 188.923C57.8565 189.029 57.8659 189.136 57.8958 189.238C57.9256 189.34 57.9752 189.435 58.0419 189.517C58.1085 189.6 58.1908 189.669 58.2841 189.72C58.3773 189.771 58.4797 189.803 58.5853 189.814Z"
              fill="#156649"
            />
            <path
              d="M63.7642 190.375C63.9233 190.393 64.0839 190.362 64.2257 190.288C64.3675 190.214 64.4842 190.1 64.561 189.959C64.6378 189.819 64.6713 189.659 64.6572 189.5C64.6431 189.34 64.5821 189.189 64.4818 189.064C64.3816 188.939 64.2467 188.847 64.094 188.799C63.9414 188.751 63.778 188.75 63.6244 188.794C63.4709 188.839 63.334 188.929 63.2312 189.051C63.1285 189.174 63.0643 189.324 63.047 189.483C63.0352 189.589 63.0444 189.696 63.0741 189.798C63.1038 189.9 63.1534 189.995 63.2201 190.078C63.2868 190.161 63.3691 190.23 63.4625 190.281C63.5559 190.332 63.6584 190.364 63.7642 190.375Z"
              fill="#156649"
            />
            <path
              d="M68.9427 190.935C69.1019 190.952 69.2625 190.922 69.4044 190.848C69.5463 190.774 69.663 190.659 69.7398 190.519C69.8166 190.379 69.85 190.219 69.8358 190.059C69.8216 189.9 69.7605 189.748 69.6601 189.623C69.5597 189.499 69.4245 189.407 69.2718 189.359C69.119 189.311 68.9555 189.309 68.8019 189.355C68.6484 189.4 68.5116 189.489 68.409 189.612C68.3064 189.735 68.2425 189.886 68.2255 190.045C68.2139 190.15 68.2234 190.257 68.2532 190.359C68.283 190.461 68.3327 190.556 68.3993 190.639C68.4659 190.722 68.5482 190.79 68.6415 190.841C68.7347 190.892 68.8371 190.924 68.9427 190.935Z"
              fill="#156649"
            />
            <path
              d="M74.121 191.496C74.28 191.513 74.4406 191.483 74.5824 191.409C74.7243 191.335 74.8409 191.221 74.9177 191.08C74.9946 190.94 75.028 190.78 75.0139 190.621C74.9999 190.461 74.9388 190.31 74.8386 190.185C74.7384 190.06 74.6034 189.968 74.4508 189.92C74.2982 189.872 74.1348 189.87 73.9812 189.915C73.8276 189.96 73.6908 190.049 73.588 190.172C73.4852 190.295 73.4211 190.445 73.4037 190.604C73.3922 190.71 73.4016 190.817 73.4314 190.919C73.4612 191.021 73.5108 191.116 73.5774 191.199C73.644 191.282 73.7263 191.351 73.8196 191.402C73.9129 191.453 74.0153 191.485 74.121 191.496Z"
              fill="#156649"
            />
            <path
              d="M79.299 192.056C79.4581 192.073 79.6188 192.043 79.7607 191.969C79.9026 191.895 80.0193 191.78 80.0961 191.64C80.1729 191.499 80.2063 191.339 80.1921 191.18C80.1779 191.02 80.1167 190.869 80.0163 190.744C79.9159 190.62 79.7808 190.527 79.6281 190.48C79.4753 190.432 79.3118 190.43 79.1582 190.475C79.0046 190.521 78.8679 190.61 78.7653 190.733C78.6627 190.856 78.5988 191.006 78.5817 191.166C78.5589 191.379 78.6216 191.592 78.7561 191.759C78.8906 191.926 79.0859 192.033 79.299 192.056Z"
              fill="#156649"
            />
            <path
              d="M84.4779 192.615C84.637 192.633 84.7977 192.602 84.9396 192.528C85.0815 192.454 85.1982 192.34 85.275 192.199C85.3518 192.059 85.3852 191.899 85.371 191.739C85.3568 191.58 85.2956 191.428 85.1952 191.304C85.0948 191.179 84.9597 191.087 84.8069 191.039C84.6542 190.991 84.4907 190.99 84.3371 191.035C84.1835 191.08 84.0468 191.17 83.9442 191.292C83.8415 191.415 83.7777 191.566 83.7606 191.725C83.7491 191.83 83.7585 191.937 83.7883 192.039C83.8182 192.141 83.8678 192.236 83.9344 192.319C84.0011 192.402 84.0834 192.47 84.1766 192.521C84.2699 192.572 84.3723 192.604 84.4779 192.615Z"
              fill="#156649"
            />
            <path
              d="M89.6569 193.177C89.816 193.194 89.9766 193.164 90.1184 193.09C90.2602 193.015 90.3769 192.901 90.4537 192.761C90.5305 192.62 90.564 192.46 90.5499 192.301C90.5358 192.142 90.4748 191.99 90.3746 191.865C90.2743 191.741 90.1394 191.649 89.9867 191.601C89.8341 191.553 89.6707 191.551 89.5171 191.596C89.3636 191.641 89.2267 191.73 89.124 191.853C89.0212 191.975 88.957 192.125 88.9397 192.284C88.9168 192.498 88.9795 192.712 89.1139 192.879C89.2484 193.046 89.4436 193.153 89.6569 193.177Z"
              fill="#156649"
            />
            <path
              d="M94.8342 193.736C94.9933 193.754 95.154 193.723 95.2959 193.649C95.4378 193.575 95.5545 193.46 95.6313 193.32C95.7081 193.18 95.7415 193.02 95.7273 192.86C95.7131 192.701 95.6519 192.549 95.5515 192.424C95.4511 192.3 95.316 192.208 95.1632 192.16C95.0105 192.112 94.8469 192.11 94.6934 192.156C94.5398 192.201 94.403 192.29 94.3004 192.413C94.1978 192.536 94.1339 192.687 94.1169 192.846C94.0941 193.059 94.1568 193.272 94.2913 193.439C94.4258 193.606 94.621 193.713 94.8342 193.736Z"
              fill="#156649"
            />
            <path
              d="M100.013 194.291C100.172 194.308 100.333 194.278 100.475 194.204C100.617 194.13 100.733 194.015 100.81 193.875C100.887 193.734 100.92 193.574 100.906 193.415C100.892 193.255 100.831 193.104 100.73 192.979C100.63 192.854 100.495 192.762 100.342 192.714C100.189 192.667 100.026 192.665 99.8723 192.71C99.7187 192.755 99.5819 192.845 99.4793 192.968C99.3767 193.091 99.3128 193.241 99.2958 193.4C99.2843 193.506 99.2937 193.613 99.3235 193.715C99.3533 193.817 99.403 193.912 99.4696 193.995C99.5362 194.077 99.6185 194.146 99.7118 194.197C99.8051 194.248 99.9074 194.28 100.013 194.291Z"
              fill="#156649"
            />
            <path
              d="M105.192 194.857C105.351 194.875 105.512 194.844 105.654 194.77C105.795 194.696 105.912 194.582 105.989 194.441C106.066 194.301 106.099 194.141 106.085 193.982C106.071 193.822 106.01 193.671 105.91 193.546C105.809 193.421 105.675 193.329 105.522 193.281C105.369 193.233 105.206 193.231 105.052 193.276C104.899 193.321 104.762 193.411 104.659 193.533C104.556 193.656 104.492 193.806 104.475 193.965C104.452 194.178 104.515 194.392 104.649 194.559C104.784 194.727 104.979 194.834 105.192 194.857Z"
              fill="#156649"
            />
            <path
              d="M110.37 195.417C110.529 195.434 110.69 195.404 110.832 195.33C110.973 195.256 111.09 195.141 111.167 195.001C111.244 194.861 111.277 194.7 111.263 194.541C111.249 194.382 111.188 194.23 111.087 194.105C110.987 193.981 110.852 193.889 110.699 193.841C110.546 193.793 110.383 193.791 110.229 193.837C110.075 193.882 109.939 193.971 109.836 194.094C109.733 194.217 109.67 194.367 109.653 194.527C109.63 194.74 109.692 194.953 109.827 195.12C109.961 195.287 110.157 195.394 110.37 195.417Z"
              fill="#156649"
            />
            <path
              d="M115.549 195.977C115.708 195.994 115.869 195.964 116.01 195.89C116.152 195.815 116.269 195.701 116.346 195.561C116.423 195.42 116.456 195.26 116.442 195.101C116.428 194.941 116.366 194.79 116.266 194.665C116.166 194.54 116.03 194.448 115.878 194.4C115.725 194.352 115.561 194.351 115.408 194.396C115.254 194.441 115.118 194.531 115.015 194.654C114.912 194.777 114.848 194.927 114.831 195.086C114.82 195.192 114.829 195.299 114.859 195.401C114.889 195.503 114.939 195.598 115.005 195.68C115.072 195.763 115.154 195.832 115.247 195.883C115.341 195.934 115.443 195.965 115.549 195.977Z"
              fill="#156649"
            />
            <path
              d="M120.727 196.543C120.886 196.56 121.047 196.53 121.189 196.456C121.331 196.382 121.447 196.268 121.524 196.127C121.601 195.987 121.634 195.827 121.62 195.668C121.606 195.508 121.545 195.357 121.445 195.232C121.345 195.107 121.21 195.015 121.057 194.967C120.904 194.919 120.741 194.917 120.587 194.962C120.434 195.007 120.297 195.096 120.194 195.219C120.091 195.342 120.027 195.492 120.01 195.651C119.987 195.864 120.05 196.078 120.184 196.245C120.319 196.412 120.514 196.52 120.727 196.543Z"
              fill="#156649"
            />
            <path
              d="M125.906 197.098C126.065 197.115 126.226 197.084 126.368 197.01C126.51 196.936 126.626 196.822 126.703 196.681C126.78 196.541 126.813 196.381 126.799 196.221C126.785 196.062 126.724 195.91 126.623 195.786C126.523 195.661 126.388 195.569 126.235 195.521C126.082 195.473 125.919 195.472 125.765 195.517C125.612 195.562 125.475 195.652 125.372 195.775C125.27 195.897 125.206 196.048 125.189 196.207C125.166 196.42 125.229 196.634 125.363 196.801C125.498 196.968 125.693 197.074 125.906 197.098Z"
              fill="#156649"
            />
            <path
              d="M131.092 197.657C131.251 197.674 131.412 197.644 131.554 197.57C131.696 197.496 131.812 197.381 131.889 197.241C131.966 197.101 131.999 196.94 131.985 196.781C131.971 196.622 131.91 196.47 131.809 196.345C131.709 196.221 131.574 196.129 131.421 196.081C131.268 196.033 131.105 196.031 130.951 196.076C130.798 196.122 130.661 196.211 130.558 196.334C130.456 196.457 130.392 196.607 130.375 196.767C130.363 196.872 130.373 196.979 130.403 197.081C130.432 197.183 130.482 197.278 130.549 197.361C130.615 197.443 130.698 197.512 130.791 197.563C130.884 197.614 130.987 197.646 131.092 197.657Z"
              fill="#156649"
            />
            <path
              d="M136.262 198.218C136.421 198.236 136.582 198.205 136.724 198.131C136.866 198.057 136.982 197.943 137.059 197.802C137.136 197.662 137.169 197.502 137.155 197.343C137.141 197.183 137.08 197.032 136.98 196.907C136.88 196.782 136.745 196.69 136.592 196.642C136.44 196.594 136.276 196.593 136.123 196.637C135.969 196.682 135.832 196.772 135.729 196.894C135.627 197.017 135.563 197.167 135.545 197.326C135.522 197.54 135.585 197.753 135.719 197.921C135.854 198.088 136.049 198.195 136.262 198.218Z"
              fill="#156649"
            />
            <path
              d="M141.439 198.778C141.599 198.795 141.759 198.765 141.901 198.692C142.044 198.618 142.161 198.503 142.238 198.363C142.315 198.223 142.348 198.063 142.334 197.903C142.32 197.743 142.259 197.592 142.159 197.467C142.059 197.342 141.924 197.25 141.771 197.202C141.618 197.154 141.455 197.152 141.301 197.197C141.147 197.242 141.01 197.332 140.908 197.455C140.805 197.578 140.741 197.728 140.724 197.887C140.701 198.1 140.763 198.313 140.898 198.48C141.032 198.647 141.227 198.754 141.439 198.778Z"
              fill="#156649"
            />
            <path
              d="M146.617 199.338C146.776 199.355 146.937 199.325 147.079 199.251C147.221 199.178 147.338 199.063 147.415 198.923C147.492 198.782 147.526 198.622 147.512 198.463C147.498 198.303 147.437 198.151 147.337 198.027C147.236 197.902 147.101 197.81 146.949 197.762C146.796 197.714 146.632 197.712 146.479 197.757C146.325 197.802 146.188 197.892 146.085 198.014C145.983 198.137 145.919 198.288 145.902 198.447C145.879 198.66 145.941 198.873 146.075 199.04C146.209 199.207 146.404 199.314 146.617 199.338Z"
              fill="#156649"
            />
            <path
              d="M151.796 199.899C151.955 199.916 152.116 199.886 152.258 199.812C152.399 199.738 152.516 199.623 152.593 199.483C152.67 199.343 152.703 199.183 152.689 199.023C152.675 198.864 152.614 198.712 152.514 198.588C152.413 198.463 152.279 198.371 152.126 198.323C151.973 198.275 151.81 198.273 151.656 198.318C151.503 198.363 151.366 198.452 151.263 198.575C151.16 198.697 151.096 198.848 151.079 199.007C151.056 199.22 151.119 199.434 151.253 199.601C151.388 199.768 151.583 199.875 151.796 199.899Z"
              fill="#156649"
            />
            <path
              d="M156.974 200.458C157.133 200.476 157.294 200.445 157.436 200.371C157.578 200.297 157.695 200.183 157.771 200.042C157.848 199.902 157.882 199.742 157.867 199.582C157.853 199.423 157.792 199.271 157.692 199.147C157.591 199.022 157.456 198.93 157.303 198.882C157.151 198.834 156.987 198.833 156.834 198.878C156.68 198.923 156.543 199.013 156.441 199.135C156.338 199.258 156.274 199.409 156.257 199.568C156.234 199.781 156.297 199.995 156.431 200.162C156.566 200.329 156.761 200.435 156.974 200.458Z"
              fill="#156649"
            />
            <path
              d="M162.153 201.018C162.312 201.035 162.473 201.005 162.615 200.931C162.757 200.857 162.873 200.742 162.95 200.602C163.027 200.462 163.06 200.302 163.046 200.142C163.032 199.983 162.971 199.831 162.87 199.706C162.77 199.582 162.635 199.49 162.482 199.442C162.329 199.394 162.166 199.392 162.012 199.438C161.859 199.483 161.722 199.572 161.619 199.695C161.517 199.818 161.453 199.969 161.436 200.128C161.413 200.341 161.476 200.554 161.61 200.721C161.745 200.888 161.94 200.995 162.153 201.018Z"
              fill="#156649"
            />
            <path
              d="M167.331 201.58C167.49 201.597 167.651 201.567 167.793 201.493C167.935 201.419 168.051 201.304 168.128 201.164C168.205 201.023 168.238 200.864 168.224 200.704C168.21 200.545 168.149 200.393 168.049 200.268C167.949 200.144 167.814 200.052 167.661 200.004C167.508 199.956 167.345 199.954 167.191 199.999C167.038 200.044 166.901 200.133 166.798 200.256C166.695 200.378 166.631 200.529 166.614 200.688C166.591 200.901 166.654 201.115 166.788 201.282C166.923 201.449 167.118 201.556 167.331 201.58Z"
              fill="#156649"
            />
            <path
              d="M172.51 202.139C172.67 202.157 172.83 202.126 172.972 202.052C173.114 201.978 173.231 201.864 173.308 201.723C173.384 201.583 173.418 201.423 173.404 201.263C173.389 201.104 173.328 200.952 173.228 200.827C173.127 200.703 172.992 200.611 172.839 200.563C172.687 200.515 172.523 200.514 172.37 200.559C172.216 200.604 172.079 200.693 171.977 200.816C171.874 200.939 171.81 201.09 171.793 201.249C171.77 201.462 171.833 201.675 171.968 201.842C172.102 202.009 172.297 202.116 172.51 202.139Z"
              fill="#156649"
            />
            <path
              d="M177.697 202.698C177.856 202.716 178.017 202.685 178.159 202.611C178.301 202.537 178.417 202.423 178.494 202.282C178.571 202.142 178.604 201.982 178.59 201.822C178.576 201.663 178.515 201.511 178.414 201.387C178.314 201.262 178.179 201.17 178.026 201.122C177.873 201.074 177.71 201.073 177.556 201.118C177.403 201.163 177.266 201.253 177.163 201.375C177.061 201.498 176.997 201.649 176.98 201.808C176.957 202.021 177.02 202.235 177.154 202.402C177.289 202.569 177.484 202.675 177.697 202.698Z"
              fill="#156649"
            />
            <path
              d="M182.866 203.26C183.025 203.277 183.186 203.247 183.328 203.173C183.47 203.099 183.586 202.984 183.663 202.844C183.74 202.703 183.773 202.543 183.759 202.384C183.745 202.225 183.684 202.073 183.584 201.948C183.484 201.824 183.349 201.732 183.196 201.684C183.044 201.636 182.88 201.634 182.727 201.679C182.573 201.724 182.436 201.813 182.333 201.935C182.231 202.058 182.167 202.208 182.149 202.367C182.126 202.581 182.189 202.795 182.323 202.962C182.458 203.129 182.653 203.236 182.866 203.26Z"
              fill="#156649"
            />
            <path
              d="M188.045 203.819C188.204 203.837 188.365 203.806 188.506 203.732C188.648 203.658 188.765 203.543 188.842 203.403C188.919 203.263 188.952 203.103 188.938 202.943C188.924 202.784 188.863 202.632 188.762 202.507C188.662 202.383 188.527 202.291 188.374 202.243C188.221 202.195 188.058 202.193 187.904 202.239C187.75 202.284 187.614 202.373 187.511 202.496C187.408 202.619 187.345 202.77 187.328 202.929C187.316 203.034 187.325 203.141 187.355 203.243C187.385 203.345 187.435 203.44 187.501 203.523C187.568 203.606 187.65 203.674 187.744 203.725C187.837 203.776 187.939 203.808 188.045 203.819Z"
              fill="#156649"
            />
            <path
              d="M193.224 204.379C193.383 204.397 193.544 204.366 193.686 204.292C193.828 204.218 193.944 204.104 194.021 203.963C194.098 203.823 194.131 203.663 194.117 203.503C194.103 203.344 194.042 203.192 193.941 203.067C193.841 202.943 193.706 202.851 193.553 202.803C193.4 202.755 193.237 202.754 193.083 202.799C192.93 202.844 192.793 202.933 192.69 203.056C192.588 203.179 192.524 203.33 192.507 203.489C192.495 203.594 192.505 203.701 192.534 203.803C192.564 203.905 192.614 204 192.681 204.083C192.747 204.166 192.829 204.234 192.923 204.285C193.016 204.336 193.118 204.368 193.224 204.379Z"
              fill="#156649"
            />
            <path
              d="M198.402 204.941C198.561 204.958 198.721 204.927 198.863 204.853C199.005 204.779 199.122 204.665 199.199 204.525C199.275 204.384 199.309 204.224 199.295 204.065C199.281 203.906 199.22 203.754 199.119 203.629C199.019 203.505 198.884 203.412 198.732 203.364C198.579 203.316 198.416 203.315 198.262 203.36C198.108 203.404 197.972 203.494 197.869 203.616C197.766 203.739 197.702 203.889 197.684 204.048C197.673 204.154 197.682 204.261 197.712 204.363C197.742 204.465 197.792 204.56 197.858 204.643C197.925 204.726 198.007 204.795 198.1 204.846C198.194 204.897 198.296 204.929 198.402 204.941Z"
              fill="#156649"
            />
            <path
              d="M203.581 205.5C203.74 205.517 203.901 205.487 204.043 205.413C204.185 205.339 204.301 205.224 204.378 205.084C204.455 204.944 204.488 204.783 204.474 204.624C204.46 204.465 204.399 204.313 204.298 204.188C204.198 204.064 204.063 203.972 203.91 203.924C203.757 203.876 203.594 203.874 203.44 203.92C203.287 203.965 203.15 204.054 203.047 204.177C202.945 204.3 202.881 204.451 202.864 204.61C202.852 204.715 202.862 204.822 202.891 204.924C202.921 205.026 202.971 205.121 203.037 205.204C203.104 205.286 203.186 205.355 203.28 205.406C203.373 205.457 203.475 205.489 203.581 205.5Z"
              fill="#156649"
            />
            <path
              d="M208.759 206.06C208.918 206.077 209.079 206.047 209.221 205.973C209.363 205.898 209.479 205.784 209.556 205.644C209.633 205.503 209.666 205.343 209.652 205.184C209.638 205.024 209.577 204.873 209.476 204.748C209.376 204.623 209.241 204.531 209.088 204.483C208.935 204.435 208.772 204.434 208.618 204.479C208.465 204.524 208.328 204.614 208.225 204.737C208.123 204.86 208.059 205.01 208.042 205.169C208.03 205.275 208.04 205.382 208.07 205.484C208.099 205.586 208.149 205.681 208.216 205.763C208.282 205.846 208.365 205.915 208.458 205.966C208.551 206.017 208.654 206.049 208.759 206.06Z"
              fill="#156649"
            />
            <path
              d="M213.937 206.621C214.096 206.637 214.256 206.605 214.397 206.53C214.538 206.455 214.653 206.341 214.729 206.2C214.805 206.06 214.837 205.9 214.823 205.741C214.808 205.582 214.747 205.431 214.646 205.307C214.546 205.183 214.411 205.092 214.259 205.044C214.107 204.996 213.944 204.995 213.79 205.039C213.637 205.084 213.501 205.173 213.398 205.295C213.295 205.417 213.231 205.567 213.213 205.725C213.201 205.832 213.21 205.94 213.24 206.043C213.27 206.145 213.321 206.241 213.388 206.325C213.455 206.408 213.539 206.477 213.633 206.528C213.727 206.579 213.831 206.61 213.937 206.621Z"
              fill="#156649"
            />
            <path
              d="M219.116 207.181C219.275 207.198 219.436 207.168 219.578 207.094C219.72 207.02 219.836 206.905 219.913 206.765C219.99 206.624 220.023 206.464 220.009 206.305C219.995 206.145 219.934 205.994 219.833 205.869C219.733 205.744 219.598 205.652 219.445 205.604C219.292 205.557 219.129 205.555 218.975 205.6C218.822 205.645 218.685 205.735 218.582 205.858C218.48 205.981 218.416 206.131 218.399 206.29C218.387 206.396 218.397 206.503 218.427 206.605C218.456 206.707 218.506 206.802 218.573 206.884C218.639 206.967 218.722 207.036 218.815 207.087C218.908 207.138 219.01 207.17 219.116 207.181Z"
              fill="#156649"
            />
            <path
              d="M224.301 207.74C224.46 207.757 224.62 207.727 224.762 207.653C224.904 207.579 225.021 207.464 225.098 207.324C225.175 207.184 225.208 207.023 225.194 206.864C225.18 206.705 225.118 206.553 225.018 206.428C224.918 206.304 224.782 206.212 224.63 206.164C224.477 206.116 224.313 206.114 224.16 206.16C224.006 206.205 223.87 206.294 223.767 206.417C223.664 206.54 223.6 206.69 223.583 206.85C223.572 206.955 223.581 207.062 223.611 207.164C223.641 207.266 223.691 207.361 223.757 207.444C223.824 207.526 223.906 207.595 223.999 207.646C224.093 207.697 224.195 207.729 224.301 207.74Z"
              fill="#156649"
            />
            <path
              d="M42.4906 193.312C42.6497 193.329 42.8104 193.299 42.9523 193.225C43.0942 193.151 43.2109 193.036 43.2877 192.896C43.3645 192.756 43.3979 192.595 43.3837 192.436C43.3695 192.277 43.3083 192.125 43.2079 192C43.1075 191.876 42.9724 191.784 42.8196 191.736C42.6669 191.688 42.5034 191.686 42.3498 191.732C42.1962 191.777 42.0595 191.866 41.9569 191.989C41.8542 192.112 41.7904 192.262 41.7733 192.422C41.7618 192.527 41.7712 192.634 41.801 192.736C41.8309 192.838 41.8805 192.933 41.9471 193.016C42.0138 193.098 42.0961 193.167 42.1893 193.218C42.2826 193.269 42.385 193.301 42.4906 193.312Z"
              fill="#156649"
            />
            <path
              d="M47.6693 193.873C47.8284 193.89 47.989 193.86 48.1308 193.786C48.2726 193.712 48.3893 193.598 48.4661 193.457C48.5429 193.317 48.5764 193.157 48.5623 192.998C48.5482 192.838 48.4872 192.687 48.3869 192.562C48.2867 192.437 48.1517 192.345 47.9991 192.297C47.8465 192.249 47.6831 192.247 47.5295 192.292C47.376 192.337 47.2391 192.426 47.1363 192.549C47.0336 192.672 46.9694 192.822 46.952 192.981C46.9405 193.087 46.9499 193.194 46.9797 193.296C47.0095 193.398 47.0591 193.493 47.1258 193.576C47.1924 193.659 47.2747 193.728 47.3679 193.779C47.4612 193.83 47.5636 193.862 47.6693 193.873Z"
              fill="#156649"
            />
            <path
              d="M52.8475 194.433C53.0067 194.45 53.1673 194.42 53.3092 194.346C53.4511 194.272 53.5678 194.157 53.6446 194.017C53.7214 193.876 53.7548 193.716 53.7406 193.557C53.7264 193.397 53.6653 193.246 53.5648 193.121C53.4644 192.996 53.3293 192.904 53.1766 192.856C53.0238 192.808 52.8603 192.807 52.7067 192.852C52.5531 192.897 52.4164 192.987 52.3138 193.11C52.2112 193.233 52.1473 193.383 52.1303 193.542C52.1187 193.648 52.1281 193.755 52.158 193.857C52.1878 193.959 52.2374 194.054 52.3041 194.136C52.3707 194.219 52.453 194.288 52.5463 194.339C52.6395 194.39 52.7419 194.422 52.8475 194.433Z"
              fill="#156649"
            />
            <path
              d="M58.0257 194.992C58.1849 195.01 58.3456 194.979 58.4874 194.905C58.6293 194.831 58.7461 194.717 58.8229 194.576C58.8996 194.436 58.9331 194.276 58.9189 194.116C58.9047 193.957 58.8435 193.805 58.7431 193.68C58.6427 193.556 58.5075 193.464 58.3548 193.416C58.202 193.368 58.0385 193.367 57.8849 193.412C57.7314 193.457 57.5946 193.546 57.492 193.669C57.3894 193.792 57.3255 193.943 57.3085 194.102C57.297 194.207 57.3064 194.314 57.3362 194.416C57.366 194.518 57.4157 194.613 57.4823 194.696C57.5489 194.779 57.6312 194.847 57.7245 194.898C57.8177 194.949 57.9201 194.981 58.0257 194.992Z"
              fill="#156649"
            />
            <path
              d="M63.2045 195.554C63.3635 195.571 63.5241 195.541 63.6659 195.466C63.8078 195.392 63.9244 195.278 64.0012 195.138C64.078 194.997 64.1115 194.837 64.0974 194.678C64.0833 194.519 64.0223 194.367 63.9221 194.242C63.8218 194.118 63.6869 194.025 63.5343 193.977C63.3817 193.929 63.2183 193.928 63.0647 193.973C62.9111 194.017 62.7743 194.107 62.6715 194.229C62.5687 194.352 62.5046 194.502 62.4872 194.661C62.4757 194.767 62.4851 194.874 62.5149 194.976C62.5447 195.078 62.5943 195.173 62.6609 195.256C62.7275 195.339 62.8098 195.408 62.9031 195.459C62.9964 195.51 63.0988 195.542 63.2045 195.554Z"
              fill="#156649"
            />
            <path
              d="M68.3758 196.113C68.535 196.13 68.6957 196.1 68.8375 196.026C68.9794 195.952 69.0962 195.837 69.1729 195.697C69.2497 195.557 69.2832 195.396 69.269 195.237C69.2548 195.078 69.1936 194.926 69.0932 194.801C68.9928 194.677 68.8576 194.585 68.7049 194.537C68.5521 194.489 68.3886 194.487 68.2351 194.533C68.0815 194.578 67.9447 194.667 67.8421 194.79C67.7395 194.913 67.6756 195.064 67.6586 195.223C67.647 195.328 67.6565 195.435 67.6863 195.537C67.7161 195.639 67.7658 195.734 67.8324 195.817C67.899 195.9 67.9813 195.968 68.0746 196.019C68.1678 196.07 68.2702 196.102 68.3758 196.113Z"
              fill="#156649"
            />
            <path
              d="M73.5619 196.673C73.721 196.691 73.8817 196.66 74.0236 196.586C74.1655 196.512 74.2822 196.398 74.359 196.257C74.4358 196.117 74.4692 195.957 74.455 195.797C74.4408 195.638 74.3796 195.486 74.2792 195.361C74.1788 195.237 74.0437 195.145 73.8909 195.097C73.7382 195.049 73.5747 195.047 73.4211 195.093C73.2675 195.138 73.1308 195.227 73.0281 195.35C72.9255 195.473 72.8617 195.624 72.8446 195.783C72.8331 195.888 72.8425 195.995 72.8723 196.097C72.9022 196.199 72.9518 196.294 73.0184 196.377C73.0851 196.46 73.1674 196.528 73.2606 196.579C73.3539 196.63 73.4563 196.662 73.5619 196.673Z"
              fill="#156649"
            />
            <path
              d="M78.7396 197.236C78.8987 197.253 79.0593 197.223 79.2011 197.149C79.3429 197.075 79.4596 196.96 79.5364 196.82C79.6132 196.68 79.6467 196.52 79.6326 196.36C79.6185 196.201 79.5575 196.049 79.4572 195.925C79.357 195.8 79.2221 195.708 79.0694 195.66C78.9168 195.612 78.7534 195.61 78.5998 195.655C78.4463 195.7 78.3094 195.789 78.2067 195.912C78.1039 196.034 78.0397 196.185 78.0224 196.344C78.0108 196.449 78.0202 196.556 78.05 196.658C78.0798 196.761 78.1295 196.856 78.1961 196.939C78.2627 197.021 78.345 197.09 78.4382 197.141C78.5315 197.192 78.6339 197.225 78.7396 197.236Z"
              fill="#156649"
            />
            <path
              d="M83.9164 197.794C84.0756 197.812 84.2364 197.781 84.3785 197.708C84.5206 197.634 84.6376 197.519 84.7146 197.379C84.7917 197.239 84.8253 197.079 84.8114 196.919C84.7974 196.76 84.7364 196.608 84.6361 196.483C84.5358 196.358 84.4007 196.266 84.2479 196.218C84.0952 196.17 83.9316 196.168 83.7779 196.213C83.6242 196.258 83.4874 196.348 83.3847 196.471C83.2819 196.594 83.218 196.744 83.2009 196.903C83.1781 197.116 83.2405 197.33 83.3747 197.497C83.5088 197.663 83.7036 197.77 83.9164 197.794Z"
              fill="#156649"
            />
            <path
              d="M89.0952 198.355C89.2542 198.373 89.4149 198.343 89.557 198.269C89.699 198.195 89.8159 198.081 89.893 197.941C89.9701 197.8 90.0038 197.64 89.9899 197.481C89.9761 197.321 89.9152 197.17 89.8151 197.045C89.715 196.92 89.58 196.828 89.4274 196.779C89.2748 196.731 89.1113 196.729 88.9576 196.774C88.804 196.819 88.667 196.908 88.5641 197.031C88.4613 197.154 88.397 197.304 88.3796 197.463C88.3568 197.676 88.4192 197.89 88.5533 198.057C88.6874 198.224 88.8822 198.331 89.0952 198.355Z"
              fill="#156649"
            />
            <path
              d="M94.2734 198.915C94.4325 198.933 94.5933 198.903 94.7354 198.829C94.8775 198.755 94.9945 198.641 95.0716 198.5C95.1486 198.36 95.1823 198.2 95.1683 198.04C95.1543 197.881 95.0933 197.729 94.993 197.604C94.8927 197.479 94.7576 197.387 94.6049 197.339C94.4521 197.291 94.2885 197.289 94.1348 197.334C93.9812 197.379 93.8443 197.469 93.7416 197.592C93.6389 197.715 93.5749 197.865 93.5578 198.025C93.535 198.237 93.5975 198.451 93.7316 198.618C93.8657 198.785 94.0605 198.891 94.2734 198.915Z"
              fill="#156649"
            />
            <path
              d="M99.4523 199.474C99.6115 199.492 99.7721 199.461 99.914 199.387C100.056 199.313 100.173 199.199 100.249 199.058C100.326 198.918 100.36 198.758 100.345 198.598C100.331 198.439 100.27 198.287 100.17 198.162C100.069 198.038 99.9341 197.946 99.7814 197.898C99.6286 197.85 99.4651 197.849 99.3115 197.894C99.158 197.939 99.0212 198.028 98.9186 198.151C98.816 198.274 98.7521 198.425 98.7351 198.584C98.7122 198.797 98.775 199.01 98.9095 199.177C99.0439 199.344 99.2392 199.451 99.4523 199.474Z"
              fill="#156649"
            />
            <path
              d="M104.631 200.036C104.79 200.053 104.95 200.022 105.092 199.948C105.234 199.874 105.351 199.76 105.427 199.62C105.504 199.479 105.538 199.319 105.524 199.16C105.509 199.001 105.448 198.849 105.348 198.724C105.248 198.6 105.113 198.507 104.96 198.459C104.808 198.411 104.644 198.41 104.491 198.455C104.337 198.499 104.2 198.589 104.098 198.711C103.995 198.834 103.931 198.984 103.913 199.143C103.89 199.357 103.953 199.57 104.088 199.738C104.222 199.905 104.417 200.012 104.631 200.036Z"
              fill="#156649"
            />
            <path
              d="M109.808 200.595C109.967 200.613 110.128 200.582 110.27 200.508C110.412 200.434 110.529 200.32 110.605 200.179C110.682 200.039 110.716 199.879 110.701 199.719C110.687 199.56 110.626 199.408 110.526 199.284C110.425 199.159 110.29 199.067 110.137 199.019C109.985 198.971 109.821 198.97 109.667 199.015C109.514 199.06 109.377 199.149 109.275 199.272C109.172 199.395 109.108 199.546 109.091 199.705C109.068 199.918 109.131 200.132 109.265 200.299C109.4 200.466 109.595 200.572 109.808 200.595Z"
              fill="#156649"
            />
            <path
              d="M114.98 201.155C115.139 201.172 115.299 201.142 115.441 201.068C115.583 200.994 115.7 200.879 115.777 200.739C115.854 200.598 115.887 200.438 115.873 200.279C115.859 200.119 115.797 199.968 115.697 199.843C115.597 199.718 115.461 199.626 115.309 199.578C115.156 199.531 114.992 199.529 114.839 199.574C114.685 199.619 114.549 199.709 114.446 199.832C114.343 199.955 114.279 200.105 114.262 200.264C114.24 200.478 114.302 200.691 114.437 200.858C114.571 201.025 114.767 201.132 114.98 201.155Z"
              fill="#156649"
            />
            <path
              d="M120.166 201.716C120.325 201.733 120.485 201.703 120.627 201.629C120.769 201.555 120.886 201.441 120.963 201.3C121.039 201.16 121.073 201 121.059 200.841C121.045 200.681 120.984 200.53 120.883 200.405C120.783 200.28 120.648 200.188 120.496 200.14C120.343 200.092 120.18 200.09 120.026 200.135C119.872 200.18 119.736 200.269 119.633 200.392C119.53 200.515 119.466 200.665 119.448 200.824C119.426 201.037 119.488 201.251 119.623 201.418C119.757 201.586 119.952 201.693 120.166 201.716Z"
              fill="#156649"
            />
            <path
              d="M125.345 202.276C125.504 202.293 125.665 202.263 125.807 202.189C125.948 202.115 126.065 202 126.142 201.86C126.219 201.719 126.252 201.559 126.238 201.4C126.224 201.24 126.163 201.089 126.062 200.964C125.962 200.839 125.827 200.747 125.674 200.699C125.521 200.651 125.358 200.65 125.204 200.695C125.051 200.74 124.914 200.83 124.811 200.953C124.709 201.076 124.645 201.226 124.628 201.385C124.605 201.598 124.668 201.812 124.802 201.979C124.937 202.146 125.132 202.253 125.345 202.276Z"
              fill="#156649"
            />
            <path
              d="M130.523 202.835C130.682 202.853 130.842 202.822 130.984 202.748C131.126 202.674 131.243 202.56 131.32 202.419C131.397 202.279 131.43 202.119 131.416 201.959C131.402 201.8 131.34 201.648 131.24 201.523C131.14 201.399 131.004 201.307 130.852 201.259C130.699 201.211 130.535 201.21 130.382 201.255C130.228 201.3 130.092 201.389 129.989 201.512C129.886 201.635 129.822 201.786 129.805 201.945C129.783 202.158 129.845 202.372 129.98 202.539C130.114 202.705 130.309 202.812 130.523 202.835Z"
              fill="#156649"
            />
            <path
              d="M135.702 203.397C135.861 203.414 136.021 203.384 136.163 203.31C136.305 203.235 136.422 203.121 136.498 202.981C136.575 202.84 136.609 202.68 136.595 202.521C136.581 202.362 136.52 202.21 136.419 202.085C136.319 201.961 136.184 201.868 136.031 201.82C135.879 201.772 135.715 201.771 135.562 201.816C135.408 201.86 135.271 201.95 135.169 202.072C135.066 202.195 135.002 202.345 134.984 202.504C134.962 202.718 135.025 202.931 135.159 203.098C135.294 203.265 135.489 203.373 135.702 203.397Z"
              fill="#156649"
            />
            <path
              d="M140.88 203.957C141.039 203.974 141.2 203.944 141.342 203.87C141.483 203.795 141.6 203.681 141.677 203.541C141.754 203.4 141.787 203.24 141.773 203.081C141.759 202.921 141.698 202.769 141.597 202.645C141.497 202.52 141.362 202.428 141.209 202.38C141.056 202.332 140.893 202.331 140.739 202.376C140.586 202.421 140.449 202.511 140.346 202.634C140.244 202.757 140.18 202.907 140.163 203.066C140.14 203.279 140.203 203.493 140.338 203.659C140.472 203.826 140.667 203.933 140.88 203.957Z"
              fill="#156649"
            />
            <path
              d="M146.058 204.513C146.217 204.53 146.378 204.5 146.52 204.426C146.662 204.352 146.778 204.237 146.855 204.097C146.932 203.956 146.965 203.796 146.951 203.637C146.937 203.477 146.876 203.326 146.775 203.201C146.675 203.076 146.54 202.984 146.387 202.936C146.234 202.889 146.071 202.887 145.917 202.932C145.764 202.977 145.627 203.067 145.524 203.19C145.422 203.313 145.358 203.463 145.341 203.622C145.329 203.728 145.339 203.835 145.368 203.937C145.398 204.039 145.448 204.134 145.515 204.216C145.581 204.299 145.663 204.368 145.757 204.419C145.85 204.47 145.952 204.502 146.058 204.513Z"
              fill="#156649"
            />
            <path
              d="M151.236 205.078C151.395 205.095 151.556 205.064 151.698 204.99C151.839 204.916 151.956 204.802 152.033 204.662C152.11 204.521 152.143 204.361 152.129 204.202C152.115 204.043 152.054 203.891 151.954 203.766C151.854 203.642 151.719 203.549 151.566 203.501C151.413 203.453 151.25 203.452 151.096 203.497C150.943 203.541 150.806 203.631 150.703 203.753C150.6 203.876 150.536 204.026 150.519 204.185C150.507 204.291 150.517 204.398 150.547 204.5C150.576 204.602 150.626 204.697 150.693 204.78C150.759 204.863 150.842 204.932 150.935 204.983C151.028 205.034 151.131 205.066 151.236 205.078Z"
              fill="#156649"
            />
            <path
              d="M156.415 205.637C156.575 205.654 156.735 205.624 156.877 205.55C157.019 205.476 157.136 205.361 157.212 205.221C157.289 205.08 157.323 204.92 157.309 204.761C157.294 204.601 157.233 204.45 157.133 204.325C157.032 204.2 156.897 204.108 156.744 204.06C156.592 204.013 156.428 204.011 156.275 204.056C156.121 204.101 155.984 204.191 155.882 204.314C155.779 204.437 155.715 204.587 155.698 204.746C155.687 204.852 155.696 204.959 155.726 205.061C155.756 205.163 155.805 205.258 155.872 205.34C155.939 205.423 156.021 205.492 156.114 205.543C156.207 205.594 156.31 205.626 156.415 205.637Z"
              fill="#156649"
            />
            <path
              d="M161.593 206.196C161.752 206.214 161.913 206.183 162.055 206.109C162.197 206.035 162.313 205.921 162.39 205.78C162.467 205.64 162.5 205.48 162.486 205.32C162.472 205.161 162.411 205.009 162.31 204.885C162.21 204.76 162.075 204.668 161.922 204.62C161.769 204.572 161.606 204.571 161.452 204.616C161.299 204.661 161.162 204.751 161.059 204.873C160.957 204.996 160.893 205.147 160.876 205.306C160.864 205.412 160.874 205.518 160.904 205.62C160.933 205.722 160.983 205.817 161.05 205.9C161.116 205.983 161.199 206.052 161.292 206.102C161.385 206.153 161.487 206.185 161.593 206.196Z"
              fill="#156649"
            />
            <path
              d="M166.771 206.765C166.93 206.782 167.091 206.751 167.233 206.677C167.375 206.603 167.491 206.489 167.568 206.349C167.645 206.208 167.678 206.048 167.664 205.889C167.65 205.73 167.589 205.578 167.489 205.453C167.389 205.329 167.254 205.236 167.101 205.188C166.949 205.14 166.785 205.139 166.632 205.184C166.478 205.228 166.341 205.318 166.238 205.44C166.136 205.563 166.071 205.713 166.054 205.872C166.043 205.978 166.052 206.085 166.082 206.187C166.112 206.289 166.161 206.384 166.228 206.467C166.294 206.55 166.377 206.619 166.47 206.67C166.563 206.721 166.666 206.753 166.771 206.765Z"
              fill="#156649"
            />
            <path
              d="M171.951 207.318C172.11 207.335 172.27 207.304 172.412 207.23C172.554 207.156 172.671 207.042 172.748 206.901C172.824 206.761 172.858 206.601 172.844 206.441C172.829 206.282 172.768 206.13 172.668 206.006C172.567 205.881 172.432 205.789 172.28 205.741C172.127 205.693 171.963 205.692 171.81 205.737C171.656 205.782 171.519 205.872 171.417 205.995C171.314 206.117 171.25 206.268 171.233 206.427C171.222 206.533 171.231 206.639 171.261 206.741C171.291 206.843 171.34 206.938 171.407 207.021C171.474 207.104 171.556 207.173 171.649 207.224C171.743 207.274 171.845 207.306 171.951 207.318Z"
              fill="#156649"
            />
            <path
              d="M177.129 207.877C177.288 207.894 177.449 207.864 177.59 207.79C177.732 207.716 177.849 207.601 177.926 207.461C178.003 207.32 178.036 207.16 178.022 207.001C178.008 206.842 177.947 206.69 177.846 206.565C177.746 206.441 177.611 206.348 177.458 206.301C177.305 206.253 177.142 206.251 176.988 206.296C176.834 206.342 176.698 206.431 176.595 206.554C176.492 206.677 176.429 206.827 176.412 206.987C176.4 207.092 176.409 207.199 176.439 207.301C176.469 207.403 176.519 207.498 176.585 207.581C176.652 207.663 176.734 207.732 176.828 207.783C176.921 207.834 177.023 207.866 177.129 207.877Z"
              fill="#156649"
            />
            <path
              d="M182.307 208.438C182.466 208.456 182.626 208.425 182.768 208.351C182.91 208.277 183.026 208.163 183.103 208.022C183.18 207.882 183.214 207.722 183.199 207.563C183.185 207.403 183.124 207.252 183.024 207.127C182.924 207.002 182.789 206.91 182.636 206.862C182.484 206.814 182.32 206.813 182.167 206.857C182.013 206.902 181.876 206.992 181.774 207.114C181.671 207.237 181.607 207.387 181.589 207.546C181.578 207.652 181.587 207.759 181.617 207.861C181.647 207.963 181.696 208.058 181.763 208.141C181.83 208.224 181.912 208.293 182.005 208.344C182.098 208.395 182.201 208.427 182.307 208.438Z"
              fill="#156649"
            />
            <path
              d="M187.486 208.998C187.645 209.015 187.805 208.985 187.947 208.911C188.089 208.837 188.206 208.722 188.283 208.582C188.359 208.441 188.393 208.281 188.379 208.122C188.364 207.962 188.303 207.811 188.203 207.686C188.102 207.561 187.967 207.469 187.815 207.422C187.662 207.374 187.498 207.372 187.345 207.417C187.191 207.462 187.054 207.552 186.952 207.675C186.849 207.798 186.785 207.948 186.768 208.107C186.745 208.321 186.808 208.534 186.943 208.701C187.077 208.868 187.272 208.975 187.486 208.998Z"
              fill="#156649"
            />
            <path
              d="M192.664 209.558C192.823 209.575 192.984 209.544 193.126 209.47C193.268 209.396 193.384 209.282 193.461 209.141C193.538 209.001 193.571 208.841 193.557 208.681C193.543 208.522 193.482 208.37 193.381 208.246C193.281 208.121 193.146 208.029 192.993 207.981C192.84 207.933 192.677 207.932 192.523 207.977C192.37 208.022 192.233 208.112 192.13 208.234C192.028 208.357 191.964 208.508 191.947 208.667C191.935 208.773 191.945 208.879 191.974 208.981C192.004 209.083 192.054 209.178 192.12 209.261C192.187 209.344 192.269 209.413 192.363 209.463C192.456 209.514 192.558 209.546 192.664 209.558Z"
              fill="#156649"
            />
            <path
              d="M197.842 210.119C198.002 210.136 198.162 210.105 198.304 210.031C198.446 209.957 198.562 209.843 198.639 209.703C198.716 209.562 198.75 209.402 198.735 209.243C198.721 209.084 198.66 208.932 198.56 208.807C198.46 208.683 198.325 208.59 198.172 208.542C198.02 208.494 197.856 208.493 197.703 208.538C197.549 208.582 197.412 208.672 197.31 208.794C197.207 208.917 197.143 209.067 197.125 209.226C197.102 209.44 197.165 209.653 197.299 209.821C197.434 209.988 197.629 210.095 197.842 210.119Z"
              fill="#156649"
            />
            <path
              d="M203.021 210.679C203.18 210.696 203.34 210.666 203.482 210.591C203.624 210.517 203.741 210.403 203.818 210.262C203.895 210.122 203.928 209.962 203.914 209.802C203.9 209.643 203.838 209.491 203.738 209.367C203.638 209.242 203.502 209.15 203.35 209.102C203.197 209.054 203.033 209.053 202.88 209.098C202.726 209.143 202.59 209.233 202.487 209.356C202.384 209.478 202.32 209.629 202.303 209.788C202.281 210.001 202.343 210.215 202.478 210.382C202.612 210.549 202.808 210.656 203.021 210.679Z"
              fill="#156649"
            />
            <path
              d="M208.199 211.238C208.358 211.255 208.519 211.225 208.661 211.151C208.803 211.077 208.919 210.962 208.996 210.822C209.073 210.682 209.106 210.521 209.092 210.362C209.078 210.203 209.017 210.051 208.916 209.926C208.816 209.802 208.681 209.71 208.528 209.662C208.375 209.614 208.212 209.612 208.058 209.658C207.905 209.703 207.768 209.792 207.665 209.915C207.563 210.038 207.499 210.189 207.482 210.348C207.47 210.453 207.48 210.56 207.51 210.662C207.539 210.764 207.589 210.859 207.656 210.942C207.722 211.025 207.805 211.093 207.898 211.144C207.991 211.195 208.093 211.227 208.199 211.238Z"
              fill="#156649"
            />
            <path
              d="M213.386 211.8C213.545 211.817 213.706 211.786 213.848 211.712C213.99 211.638 214.106 211.524 214.183 211.384C214.26 211.243 214.293 211.083 214.279 210.924C214.265 210.765 214.204 210.613 214.104 210.488C214.004 210.364 213.869 210.271 213.716 210.223C213.564 210.175 213.4 210.174 213.247 210.218C213.093 210.263 212.956 210.353 212.853 210.475C212.751 210.598 212.687 210.748 212.669 210.907C212.646 211.121 212.709 211.334 212.843 211.502C212.978 211.669 213.173 211.776 213.386 211.8Z"
              fill="#156649"
            />
            <path
              d="M218.556 212.359C218.715 212.376 218.876 212.346 219.018 212.272C219.159 212.198 219.276 212.083 219.353 211.943C219.43 211.802 219.463 211.642 219.449 211.483C219.435 211.323 219.374 211.172 219.273 211.047C219.173 210.922 219.038 210.83 218.885 210.783C218.732 210.735 218.569 210.733 218.415 210.778C218.261 210.824 218.125 210.913 218.022 211.036C217.919 211.159 217.856 211.309 217.839 211.469C217.816 211.682 217.878 211.895 218.013 212.062C218.147 212.229 218.343 212.336 218.556 212.359Z"
              fill="#156649"
            />
            <path
              d="M223.735 212.919C223.894 212.936 224.055 212.906 224.196 212.832C224.338 212.758 224.455 212.643 224.532 212.503C224.609 212.362 224.642 212.202 224.628 212.043C224.614 211.883 224.552 211.732 224.452 211.607C224.352 211.482 224.217 211.39 224.064 211.342C223.911 211.295 223.748 211.293 223.594 211.338C223.44 211.383 223.304 211.473 223.201 211.596C223.098 211.719 223.035 211.869 223.017 212.028C223.006 212.134 223.015 212.241 223.045 212.343C223.075 212.445 223.125 212.54 223.191 212.622C223.258 212.705 223.34 212.774 223.433 212.825C223.527 212.876 223.629 212.908 223.735 212.919Z"
              fill="#156649"
            />
            <path
              d="M41.9294 198.49C42.0885 198.508 42.2492 198.477 42.3911 198.403C42.533 198.329 42.6497 198.215 42.7265 198.074C42.8033 197.934 42.8367 197.774 42.8225 197.614C42.8083 197.455 42.7471 197.303 42.6467 197.179C42.5463 197.054 42.4112 196.962 42.2584 196.914C42.1057 196.866 41.9422 196.865 41.7886 196.91C41.635 196.955 41.4983 197.045 41.3956 197.167C41.293 197.29 41.2292 197.441 41.2121 197.6C41.1893 197.813 41.252 198.027 41.3865 198.194C41.521 198.361 41.7162 198.467 41.9294 198.49Z"
              fill="#156649"
            />
            <path
              d="M47.1076 199.052C47.2667 199.069 47.4273 199.039 47.5691 198.965C47.7109 198.89 47.8276 198.776 47.9044 198.636C47.9812 198.495 48.0147 198.335 48.0006 198.176C47.9865 198.017 47.9255 197.865 47.8252 197.74C47.725 197.616 47.59 197.524 47.4374 197.476C47.2848 197.428 47.1214 197.426 46.9678 197.471C46.8143 197.516 46.6774 197.605 46.5746 197.728C46.4719 197.85 46.4077 198 46.3904 198.159C46.3675 198.373 46.4302 198.587 46.5646 198.754C46.6991 198.921 46.8943 199.028 47.1076 199.052Z"
              fill="#156649"
            />
            <path
              d="M52.2868 199.611C52.4459 199.629 52.6066 199.598 52.7485 199.524C52.8904 199.45 53.0071 199.336 53.0839 199.195C53.1607 199.055 53.1941 198.895 53.1799 198.735C53.1657 198.576 53.1045 198.424 53.0041 198.3C52.9037 198.175 52.7686 198.083 52.6158 198.035C52.4631 197.987 52.2996 197.986 52.146 198.031C51.9924 198.076 51.8557 198.166 51.7531 198.288C51.6505 198.411 51.5866 198.562 51.5695 198.721C51.5467 198.934 51.6094 199.148 51.7439 199.315C51.8784 199.482 52.0737 199.588 52.2868 199.611Z"
              fill="#156649"
            />
            <path
              d="M57.4611 200.171C57.6203 200.188 57.7809 200.158 57.9228 200.084C58.0647 200.01 58.1814 199.895 58.2582 199.755C58.335 199.614 58.3684 199.454 58.3542 199.295C58.34 199.135 58.2789 198.984 58.1785 198.859C58.078 198.735 57.9429 198.642 57.7902 198.595C57.6374 198.547 57.4739 198.545 57.3203 198.59C57.1668 198.636 57.03 198.725 56.9274 198.848C56.8248 198.971 56.7609 199.121 56.7439 199.281C56.721 199.494 56.7837 199.707 56.9182 199.874C57.0527 200.041 57.248 200.148 57.4611 200.171Z"
              fill="#156649"
            />
            <path
              d="M62.6429 200.732C62.802 200.75 62.9626 200.719 63.1044 200.645C63.2462 200.571 63.3629 200.457 63.4397 200.316C63.5165 200.176 63.55 200.016 63.5359 199.857C63.5218 199.697 63.4608 199.546 63.3606 199.421C63.2603 199.296 63.1254 199.204 62.9728 199.156C62.8201 199.108 62.6567 199.106 62.5032 199.151C62.3496 199.196 62.2128 199.286 62.11 199.408C62.0072 199.531 61.9431 199.681 61.9257 199.84C61.9141 199.946 61.9235 200.053 61.9533 200.155C61.9831 200.257 62.0328 200.352 62.0994 200.435C62.166 200.518 62.2483 200.587 62.3416 200.638C62.4348 200.689 62.5372 200.721 62.6429 200.732Z"
              fill="#156649"
            />
            <path
              d="M67.822 201.292C67.9811 201.309 68.1418 201.279 68.2837 201.204C68.4255 201.13 68.5423 201.016 68.6191 200.875C68.6959 200.735 68.7293 200.575 68.7151 200.416C68.7009 200.256 68.6397 200.104 68.5393 199.98C68.4389 199.855 68.3038 199.763 68.151 199.715C67.9983 199.667 67.8347 199.666 67.6812 199.711C67.5276 199.756 67.3908 199.846 67.2882 199.969C67.1856 200.091 67.1217 200.242 67.1047 200.401C67.0819 200.614 67.1446 200.828 67.2791 200.995C67.4136 201.162 67.6088 201.269 67.822 201.292Z"
              fill="#156649"
            />
            <path
              d="M72.9997 201.851C73.1588 201.868 73.3195 201.838 73.4614 201.764C73.6033 201.69 73.72 201.575 73.7968 201.435C73.8736 201.295 73.907 201.135 73.8928 200.975C73.8786 200.816 73.8174 200.664 73.717 200.539C73.6166 200.415 73.4815 200.323 73.3287 200.275C73.176 200.227 73.0125 200.225 72.8589 200.271C72.7053 200.316 72.5686 200.405 72.466 200.528C72.3633 200.651 72.2995 200.802 72.2824 200.961C72.2596 201.174 72.3223 201.387 72.4568 201.554C72.5913 201.721 72.7865 201.828 72.9997 201.851Z"
              fill="#156649"
            />
            <path
              d="M78.1781 202.413C78.3372 202.43 78.4977 202.399 78.6396 202.325C78.7814 202.251 78.8981 202.137 78.9749 201.997C79.0517 201.856 79.0852 201.696 79.0711 201.537C79.057 201.378 78.996 201.226 78.8957 201.101C78.7955 200.977 78.6605 200.884 78.5079 200.836C78.3553 200.788 78.1919 200.787 78.0383 200.832C77.8847 200.876 77.7479 200.966 77.6451 201.088C77.5423 201.211 77.4782 201.361 77.4608 201.52C77.4493 201.626 77.4587 201.733 77.4885 201.835C77.5183 201.937 77.5679 202.032 77.6345 202.115C77.7012 202.198 77.7835 202.267 77.8767 202.318C77.97 202.369 78.0724 202.401 78.1781 202.413Z"
              fill="#156649"
            />
            <path
              d="M83.3565 202.973C83.5156 202.99 83.6763 202.96 83.8182 202.885C83.9601 202.811 84.0768 202.697 84.1536 202.556C84.2304 202.416 84.2638 202.256 84.2496 202.096C84.2354 201.937 84.1742 201.785 84.0738 201.661C83.9734 201.536 83.8383 201.444 83.6855 201.396C83.5328 201.348 83.3693 201.347 83.2157 201.392C83.0621 201.437 82.9254 201.527 82.8228 201.65C82.7201 201.772 82.6563 201.923 82.6392 202.082C82.6275 202.188 82.6367 202.295 82.6664 202.397C82.6962 202.499 82.7458 202.594 82.8125 202.677C82.8792 202.759 82.9615 202.828 83.0549 202.879C83.1483 202.93 83.2508 202.962 83.3565 202.973Z"
              fill="#156649"
            />
            <path
              d="M88.5355 203.533C88.6946 203.551 88.8552 203.52 88.997 203.446C89.1388 203.372 89.2555 203.258 89.3323 203.117C89.4091 202.977 89.4426 202.817 89.4285 202.658C89.4144 202.498 89.3534 202.347 89.2531 202.222C89.1529 202.097 89.018 202.005 88.8653 201.957C88.7127 201.909 88.5493 201.908 88.3957 201.952C88.2422 201.997 88.1053 202.087 88.0026 202.209C87.8998 202.332 87.8356 202.482 87.8183 202.641C87.8067 202.747 87.8161 202.854 87.8459 202.956C87.8757 203.058 87.9254 203.153 87.992 203.236C88.0586 203.319 88.1409 203.388 88.2341 203.439C88.3274 203.49 88.4298 203.522 88.5355 203.533Z"
              fill="#156649"
            />
            <path
              d="M93.7134 204.093C93.8725 204.111 94.0331 204.08 94.1749 204.006C94.3167 203.932 94.4334 203.818 94.5102 203.677C94.587 203.537 94.6205 203.377 94.6064 203.218C94.5923 203.058 94.5313 202.907 94.4311 202.782C94.3308 202.657 94.1959 202.565 94.0433 202.517C93.8906 202.469 93.7272 202.468 93.5737 202.512C93.4201 202.557 93.2833 202.647 93.1805 202.769C93.0777 202.892 93.0135 203.042 92.9962 203.201C92.9844 203.307 92.9936 203.414 93.0233 203.516C93.0531 203.618 93.1027 203.714 93.1693 203.796C93.236 203.879 93.3184 203.948 93.4117 203.999C93.5051 204.05 93.6076 204.082 93.7134 204.093Z"
              fill="#156649"
            />
            <path
              d="M98.8924 204.653C99.0516 204.67 99.2123 204.64 99.3541 204.566C99.496 204.492 99.6128 204.377 99.6895 204.237C99.7663 204.096 99.7998 203.936 99.7856 203.777C99.7714 203.617 99.7102 203.466 99.6098 203.341C99.5094 203.216 99.3742 203.124 99.2215 203.077C99.0687 203.029 98.9052 203.027 98.7516 203.072C98.5981 203.117 98.4613 203.207 98.3587 203.33C98.2561 203.453 98.1922 203.603 98.1752 203.762C98.1636 203.868 98.1731 203.975 98.2029 204.077C98.2327 204.179 98.2824 204.274 98.349 204.357C98.4156 204.439 98.4979 204.508 98.5912 204.559C98.6844 204.61 98.7868 204.642 98.8924 204.653Z"
              fill="#156649"
            />
            <path
              d="M104.065 205.214C104.224 205.232 104.385 205.201 104.527 205.127C104.669 205.053 104.785 204.939 104.862 204.798C104.939 204.658 104.972 204.498 104.958 204.339C104.944 204.179 104.883 204.028 104.783 203.903C104.683 203.778 104.548 203.686 104.395 203.638C104.243 203.59 104.079 203.588 103.926 203.633C103.772 203.678 103.635 203.768 103.532 203.89C103.43 204.013 103.365 204.163 103.348 204.322C103.337 204.428 103.346 204.535 103.376 204.637C103.406 204.739 103.455 204.834 103.522 204.917C103.588 205 103.671 205.069 103.764 205.12C103.857 205.171 103.96 205.203 104.065 205.214Z"
              fill="#156649"
            />
            <path
              d="M109.249 205.774C109.409 205.791 109.569 205.761 109.711 205.687C109.853 205.613 109.97 205.498 110.046 205.358C110.123 205.217 110.157 205.057 110.142 204.898C110.128 204.738 110.067 204.587 109.967 204.462C109.866 204.337 109.731 204.245 109.578 204.197C109.426 204.15 109.262 204.148 109.109 204.193C108.955 204.238 108.818 204.328 108.716 204.451C108.613 204.574 108.549 204.724 108.532 204.883C108.521 204.989 108.53 205.096 108.56 205.198C108.59 205.3 108.639 205.395 108.706 205.477C108.773 205.56 108.855 205.629 108.948 205.68C109.041 205.731 109.144 205.763 109.249 205.774Z"
              fill="#156649"
            />
            <path
              d="M114.428 206.334C114.587 206.351 114.747 206.321 114.889 206.246C115.031 206.172 115.148 206.058 115.225 205.917C115.301 205.777 115.335 205.617 115.321 205.458C115.307 205.298 115.245 205.146 115.145 205.022C115.045 204.897 114.909 204.805 114.757 204.757C114.604 204.709 114.44 204.708 114.287 204.753C114.133 204.798 113.996 204.888 113.894 205.011C113.791 205.133 113.727 205.284 113.71 205.443C113.699 205.549 113.708 205.656 113.738 205.758C113.768 205.859 113.818 205.955 113.884 206.037C113.951 206.12 114.033 206.189 114.126 206.24C114.22 206.29 114.322 206.322 114.428 206.334Z"
              fill="#156649"
            />
            <path
              d="M119.606 206.895C119.765 206.912 119.925 206.882 120.067 206.808C120.209 206.734 120.326 206.619 120.403 206.479C120.479 206.339 120.513 206.179 120.499 206.019C120.485 205.86 120.424 205.708 120.323 205.584C120.223 205.459 120.088 205.367 119.936 205.319C119.783 205.271 119.62 205.269 119.466 205.314C119.312 205.359 119.176 205.448 119.073 205.571C118.97 205.693 118.906 205.844 118.889 206.003C118.877 206.108 118.886 206.215 118.916 206.317C118.946 206.419 118.996 206.515 119.062 206.597C119.129 206.68 119.211 206.749 119.304 206.8C119.398 206.851 119.5 206.884 119.606 206.895Z"
              fill="#156649"
            />
            <path
              d="M124.785 207.458C124.944 207.475 125.105 207.445 125.247 207.371C125.389 207.296 125.505 207.182 125.582 207.041C125.659 206.901 125.692 206.741 125.678 206.582C125.664 206.422 125.603 206.27 125.502 206.146C125.402 206.021 125.267 205.929 125.114 205.881C124.961 205.833 124.798 205.832 124.644 205.877C124.491 205.922 124.354 206.012 124.251 206.135C124.149 206.257 124.085 206.408 124.068 206.567C124.056 206.673 124.066 206.78 124.095 206.882C124.125 206.984 124.175 207.079 124.242 207.161C124.308 207.244 124.391 207.313 124.484 207.364C124.577 207.415 124.679 207.446 124.785 207.458Z"
              fill="#156649"
            />
            <path
              d="M129.963 208.014C130.122 208.031 130.283 208.001 130.424 207.927C130.566 207.853 130.683 207.738 130.76 207.598C130.837 207.457 130.87 207.297 130.856 207.138C130.842 206.978 130.78 206.827 130.68 206.702C130.58 206.577 130.445 206.485 130.292 206.437C130.139 206.39 129.976 206.388 129.822 206.433C129.668 206.478 129.532 206.568 129.429 206.691C129.326 206.814 129.263 206.964 129.245 207.123C129.234 207.229 129.243 207.336 129.273 207.438C129.303 207.54 129.353 207.635 129.419 207.717C129.486 207.8 129.568 207.869 129.661 207.92C129.755 207.971 129.857 208.003 129.963 208.014Z"
              fill="#156649"
            />
            <path
              d="M135.142 208.575C135.301 208.592 135.461 208.562 135.603 208.488C135.745 208.414 135.862 208.3 135.939 208.159C136.015 208.019 136.049 207.859 136.035 207.7C136.021 207.54 135.96 207.389 135.859 207.264C135.759 207.139 135.624 207.047 135.472 206.999C135.319 206.951 135.156 206.949 135.002 206.994C134.848 207.039 134.712 207.128 134.609 207.251C134.506 207.374 134.442 207.524 134.425 207.683C134.402 207.896 134.464 208.11 134.599 208.277C134.733 208.444 134.929 208.552 135.142 208.575Z"
              fill="#156649"
            />
            <path
              d="M140.32 209.135C140.479 209.152 140.64 209.122 140.782 209.048C140.924 208.973 141.04 208.859 141.117 208.718C141.194 208.578 141.227 208.418 141.213 208.259C141.199 208.099 141.138 207.947 141.037 207.823C140.937 207.698 140.802 207.606 140.649 207.558C140.496 207.51 140.333 207.509 140.179 207.554C140.026 207.599 139.889 207.689 139.786 207.812C139.684 207.934 139.62 208.085 139.603 208.244C139.58 208.457 139.643 208.671 139.777 208.838C139.912 209.005 140.107 209.112 140.32 209.135Z"
              fill="#156649"
            />
            <path
              d="M145.498 209.695C145.657 209.712 145.818 209.682 145.96 209.608C146.102 209.533 146.218 209.419 146.295 209.279C146.372 209.138 146.405 208.978 146.391 208.819C146.377 208.659 146.316 208.508 146.215 208.383C146.115 208.258 145.98 208.166 145.827 208.118C145.674 208.07 145.511 208.069 145.357 208.114C145.204 208.159 145.067 208.249 144.964 208.372C144.862 208.495 144.798 208.645 144.781 208.804C144.769 208.91 144.779 209.017 144.808 209.119C144.838 209.221 144.888 209.316 144.954 209.398C145.021 209.481 145.103 209.55 145.197 209.601C145.29 209.652 145.392 209.684 145.498 209.695Z"
              fill="#156649"
            />
            <path
              d="M150.67 210.256C150.829 210.273 150.99 210.243 151.132 210.169C151.273 210.095 151.39 209.98 151.467 209.84C151.544 209.7 151.577 209.54 151.563 209.38C151.549 209.221 151.488 209.069 151.388 208.945C151.287 208.82 151.153 208.728 151 208.68C150.847 208.632 150.684 208.63 150.53 208.675C150.377 208.72 150.24 208.809 150.137 208.932C150.034 209.054 149.97 209.205 149.953 209.364C149.93 209.577 149.993 209.791 150.127 209.958C150.262 210.125 150.457 210.232 150.67 210.256Z"
              fill="#156649"
            />
            <path
              d="M155.855 210.816C156.014 210.833 156.175 210.803 156.317 210.728C156.459 210.654 156.575 210.54 156.652 210.399C156.729 210.259 156.762 210.099 156.748 209.939C156.734 209.78 156.673 209.628 156.572 209.504C156.472 209.379 156.337 209.287 156.184 209.239C156.031 209.191 155.868 209.19 155.714 209.235C155.561 209.28 155.424 209.37 155.321 209.493C155.219 209.615 155.155 209.766 155.138 209.925C155.115 210.138 155.178 210.352 155.312 210.519C155.447 210.686 155.642 210.792 155.855 210.816Z"
              fill="#156649"
            />
            <path
              d="M161.034 211.375C161.193 211.392 161.354 211.362 161.496 211.288C161.638 211.213 161.754 211.099 161.831 210.958C161.908 210.818 161.941 210.658 161.927 210.499C161.913 210.339 161.852 210.187 161.751 210.063C161.651 209.938 161.516 209.846 161.363 209.798C161.21 209.75 161.047 209.749 160.893 209.794C160.74 209.839 160.603 209.929 160.5 210.052C160.398 210.174 160.334 210.325 160.317 210.484C160.305 210.59 160.315 210.697 160.344 210.799C160.374 210.9 160.424 210.996 160.491 211.078C160.557 211.161 160.64 211.23 160.733 211.281C160.826 211.331 160.928 211.363 161.034 211.375Z"
              fill="#156649"
            />
            <path
              d="M166.212 211.936C166.371 211.954 166.532 211.923 166.674 211.849C166.815 211.775 166.932 211.661 167.009 211.521C167.086 211.38 167.119 211.22 167.105 211.061C167.091 210.901 167.03 210.75 166.93 210.625C166.83 210.5 166.695 210.408 166.542 210.36C166.389 210.312 166.226 210.311 166.072 210.355C165.919 210.4 165.782 210.49 165.679 210.612C165.576 210.735 165.512 210.885 165.495 211.044C165.472 211.258 165.535 211.471 165.669 211.639C165.804 211.806 165.999 211.913 166.212 211.936Z"
              fill="#156649"
            />
            <path
              d="M171.39 212.496C171.549 212.513 171.71 212.483 171.852 212.409C171.994 212.335 172.111 212.22 172.187 212.08C172.264 211.939 172.298 211.779 172.283 211.62C172.269 211.46 172.208 211.309 172.108 211.184C172.007 211.059 171.872 210.967 171.719 210.919C171.567 210.871 171.403 210.87 171.25 210.915C171.096 210.96 170.959 211.05 170.857 211.173C170.754 211.296 170.69 211.446 170.673 211.605C170.65 211.818 170.713 212.032 170.847 212.199C170.982 212.366 171.177 212.473 171.39 212.496Z"
              fill="#156649"
            />
            <path
              d="M176.569 213.056C176.728 213.073 176.889 213.042 177.031 212.968C177.173 212.894 177.29 212.78 177.366 212.639C177.443 212.499 177.477 212.339 177.462 212.179C177.448 212.02 177.387 211.868 177.287 211.744C177.186 211.619 177.051 211.527 176.898 211.479C176.746 211.431 176.582 211.43 176.428 211.475C176.275 211.52 176.138 211.61 176.035 211.733C175.933 211.855 175.869 212.006 175.852 212.165C175.84 212.271 175.85 212.378 175.88 212.479C175.909 212.581 175.959 212.676 176.026 212.759C176.092 212.842 176.175 212.911 176.268 212.962C176.361 213.012 176.464 213.044 176.569 213.056Z"
              fill="#156649"
            />
            <path
              d="M181.747 213.617C181.906 213.634 182.067 213.604 182.209 213.53C182.351 213.456 182.467 213.341 182.544 213.201C182.621 213.061 182.654 212.901 182.64 212.741C182.626 212.582 182.565 212.43 182.465 212.306C182.365 212.181 182.23 212.089 182.077 212.041C181.924 211.993 181.761 211.991 181.607 212.036C181.454 212.081 181.317 212.17 181.214 212.293C181.112 212.415 181.047 212.566 181.03 212.725C181.007 212.938 181.07 213.152 181.204 213.319C181.339 213.486 181.534 213.593 181.747 213.617Z"
              fill="#156649"
            />
            <path
              d="M186.925 214.176C187.085 214.194 187.245 214.163 187.387 214.089C187.529 214.015 187.646 213.901 187.723 213.76C187.799 213.62 187.833 213.46 187.819 213.3C187.804 213.141 187.743 212.989 187.643 212.865C187.542 212.74 187.407 212.648 187.255 212.6C187.102 212.552 186.938 212.551 186.785 212.596C186.631 212.641 186.494 212.731 186.392 212.853C186.289 212.976 186.225 213.127 186.208 213.286C186.185 213.499 186.248 213.713 186.383 213.88C186.517 214.047 186.712 214.153 186.925 214.176Z"
              fill="#156649"
            />
            <path
              d="M192.103 214.735C192.262 214.752 192.423 214.721 192.564 214.647C192.706 214.573 192.823 214.459 192.9 214.318C192.977 214.178 193.01 214.018 192.996 213.858C192.982 213.699 192.92 213.547 192.82 213.423C192.72 213.298 192.585 213.206 192.432 213.158C192.279 213.11 192.116 213.109 191.962 213.154C191.808 213.199 191.672 213.289 191.569 213.412C191.466 213.534 191.403 213.685 191.385 213.844C191.363 214.057 191.425 214.271 191.56 214.438C191.694 214.605 191.89 214.711 192.103 214.735Z"
              fill="#156649"
            />
            <path
              d="M197.274 215.297C197.433 215.315 197.594 215.284 197.736 215.21C197.877 215.136 197.994 215.022 198.071 214.881C198.148 214.741 198.181 214.581 198.167 214.422C198.153 214.262 198.092 214.111 197.992 213.986C197.891 213.861 197.757 213.769 197.604 213.721C197.451 213.673 197.288 213.671 197.134 213.716C196.981 213.761 196.844 213.85 196.741 213.973C196.638 214.096 196.574 214.246 196.557 214.405C196.534 214.618 196.597 214.832 196.731 214.999C196.866 215.167 197.061 215.274 197.274 215.297Z"
              fill="#156649"
            />
            <path
              d="M202.46 215.857C202.619 215.875 202.779 215.844 202.921 215.77C203.063 215.696 203.18 215.582 203.257 215.441C203.334 215.301 203.367 215.141 203.353 214.981C203.339 214.822 203.277 214.67 203.177 214.545C203.077 214.421 202.941 214.329 202.789 214.281C202.636 214.233 202.472 214.232 202.319 214.277C202.165 214.322 202.029 214.411 201.926 214.534C201.823 214.657 201.759 214.808 201.742 214.967C201.72 215.18 201.782 215.394 201.917 215.56C202.051 215.727 202.247 215.834 202.46 215.857Z"
              fill="#156649"
            />
            <path
              d="M207.638 216.417C207.797 216.434 207.958 216.404 208.1 216.33C208.241 216.256 208.358 216.141 208.435 216.001C208.512 215.86 208.545 215.7 208.531 215.541C208.517 215.381 208.456 215.23 208.355 215.105C208.255 214.98 208.12 214.888 207.967 214.84C207.814 214.793 207.651 214.791 207.497 214.836C207.343 214.881 207.207 214.971 207.104 215.094C207.002 215.217 206.938 215.367 206.921 215.526C206.898 215.74 206.961 215.953 207.095 216.12C207.229 216.287 207.425 216.394 207.638 216.417Z"
              fill="#156649"
            />
            <path
              d="M212.817 216.987C212.976 217.004 213.137 216.973 213.279 216.899C213.42 216.825 213.537 216.711 213.614 216.571C213.691 216.43 213.724 216.27 213.71 216.111C213.696 215.951 213.635 215.8 213.535 215.675C213.434 215.551 213.3 215.458 213.147 215.41C212.994 215.362 212.831 215.361 212.677 215.405C212.524 215.45 212.387 215.54 212.284 215.662C212.181 215.785 212.117 215.935 212.1 216.094C212.077 216.308 212.14 216.521 212.274 216.689C212.409 216.856 212.604 216.963 212.817 216.987Z"
              fill="#156649"
            />
            <path
              d="M217.995 217.537C218.154 217.555 218.315 217.524 218.457 217.45C218.598 217.376 218.715 217.262 218.792 217.121C218.869 216.981 218.902 216.821 218.888 216.661C218.874 216.502 218.813 216.35 218.712 216.225C218.612 216.101 218.477 216.009 218.324 215.961C218.171 215.913 218.008 215.912 217.854 215.957C217.7 216.002 217.564 216.091 217.461 216.214C217.358 216.337 217.295 216.488 217.278 216.647C217.255 216.86 217.317 217.073 217.452 217.24C217.586 217.407 217.782 217.514 217.995 217.537Z"
              fill="#156649"
            />
            <path
              d="M223.174 218.097C223.333 218.114 223.493 218.084 223.635 218.01C223.777 217.936 223.894 217.821 223.971 217.681C224.047 217.54 224.081 217.38 224.067 217.221C224.052 217.061 223.991 216.91 223.891 216.785C223.79 216.66 223.655 216.568 223.503 216.52C223.35 216.473 223.186 216.471 223.033 216.516C222.879 216.561 222.742 216.651 222.64 216.774C222.537 216.897 222.473 217.047 222.456 217.206C222.433 217.42 222.496 217.633 222.631 217.8C222.765 217.967 222.96 218.074 223.174 218.097Z"
              fill="#156649"
            />
            <path
              d="M37.4638 25.0004C37.6859 24.9803 37.8971 24.8948 38.0707 24.7547C38.2443 24.6146 38.3724 24.4262 38.4388 24.2133C38.5053 24.0004 38.5072 23.7725 38.4442 23.5586C38.3812 23.3446 38.2561 23.1541 38.0849 23.0112C37.9136 22.8683 37.7038 22.7793 37.4821 22.7557C37.2603 22.732 37.0364 22.7746 36.8389 22.8781C36.6413 22.9816 36.4788 23.1413 36.372 23.3372C36.2653 23.533 36.2189 23.7561 36.2389 23.9782C36.2662 24.2761 36.4104 24.5509 36.6401 24.7425C36.8697 24.9341 37.1659 25.0269 37.4638 25.0004Z"
              fill="#00BA77"
            />
            <path
              d="M44.6999 24.3509C44.9221 24.3311 45.1335 24.2459 45.3073 24.106C45.4811 23.9661 45.6095 23.7777 45.6763 23.5648C45.743 23.3519 45.7451 23.124 45.6822 22.9099C45.6194 22.6958 45.4945 22.5051 45.3233 22.362C45.1521 22.219 44.9422 22.1299 44.7204 22.1061C44.4985 22.0822 44.2746 22.1248 44.0769 22.2283C43.8792 22.3317 43.7167 22.4915 43.6099 22.6874C43.503 22.8833 43.4567 23.1065 43.4767 23.3287C43.5036 23.6264 43.6474 23.9013 43.8768 24.0929C44.1061 24.2846 44.4022 24.3774 44.6999 24.3509Z"
              fill="#00BA77"
            />
            <path
              d="M51.9355 23.7011C52.1576 23.681 52.3688 23.5955 52.5424 23.4554C52.7159 23.3153 52.844 23.1269 52.9105 22.914C52.977 22.701 52.9789 22.4732 52.9158 22.2592C52.8528 22.0453 52.7278 21.8548 52.5566 21.7119C52.3853 21.569 52.1755 21.48 51.9537 21.4563C51.7319 21.4326 51.5081 21.4752 51.3105 21.5787C51.113 21.6822 50.9505 21.842 50.8437 22.0379C50.7369 22.2337 50.6906 22.4568 50.7106 22.6789C50.7239 22.8265 50.7661 22.97 50.8348 23.1012C50.9036 23.2324 50.9975 23.3488 51.1113 23.4438C51.225 23.5387 51.3564 23.6103 51.4978 23.6544C51.6392 23.6986 51.7879 23.7144 51.9355 23.7011Z"
              fill="#00BA77"
            />
            <path
              d="M59.1747 23.0516C59.3973 23.0319 59.6091 22.9466 59.7832 22.8064C59.9573 22.6663 60.0859 22.4777 60.1527 22.2645C60.2196 22.0512 60.2217 21.823 60.1588 21.6085C60.0959 21.3941 59.9708 21.2031 59.7994 21.0598C59.6279 20.9165 59.4178 20.8272 59.1956 20.8033C58.9734 20.7795 58.7491 20.822 58.5511 20.9256C58.3531 21.0293 58.1903 21.1893 58.0833 21.3855C57.9763 21.5816 57.9298 21.8052 57.9499 22.0277C57.9629 22.1754 58.005 22.3191 58.0736 22.4505C58.1423 22.582 58.2362 22.6986 58.35 22.7937C58.4637 22.8888 58.5951 22.9605 58.7367 23.0048C58.8782 23.049 59.0271 23.065 59.1747 23.0516Z"
              fill="#00BA77"
            />
            <path
              d="M66.414 22.402C66.6366 22.3822 66.8483 22.2969 67.0224 22.1568C67.1965 22.0167 67.3251 21.828 67.392 21.6148C67.4589 21.4016 67.461 21.1733 67.3981 20.9589C67.3352 20.7444 67.2101 20.5535 67.0386 20.4101C66.8672 20.2668 66.6571 20.1776 66.4349 20.1537C66.2127 20.1298 65.9884 20.1724 65.7904 20.276C65.5924 20.3796 65.4296 20.5396 65.3225 20.7358C65.2155 20.932 65.1691 21.1555 65.1891 21.3781C65.216 21.6762 65.36 21.9515 65.5897 22.1435C65.8194 22.3355 66.1158 22.4284 66.414 22.402Z"
              fill="#00BA77"
            />
            <path
              d="M73.6498 21.7502C73.872 21.7301 74.0832 21.6446 74.2567 21.5045C74.4303 21.3644 74.5584 21.1759 74.6249 20.963C74.6913 20.7501 74.6932 20.5223 74.6302 20.3083C74.5672 20.0943 74.4422 19.9038 74.2709 19.7609C74.0997 19.618 73.8899 19.5291 73.6681 19.5054C73.4463 19.4817 73.2225 19.5243 73.0249 19.6278C72.8273 19.7313 72.6648 19.8911 72.5581 20.0869C72.4513 20.2827 72.405 20.5058 72.425 20.728C72.438 20.8756 72.4801 21.0192 72.5488 21.1506C72.6174 21.2819 72.7114 21.3984 72.8252 21.4934C72.939 21.5883 73.0704 21.6599 73.2119 21.704C73.3534 21.748 73.5022 21.7637 73.6498 21.7502Z"
              fill="#00BA77"
            />
            <path
              d="M80.8862 21.1005C81.1083 21.0804 81.3195 20.9949 81.4931 20.8548C81.6666 20.7147 81.7947 20.5263 81.8612 20.3134C81.9277 20.1004 81.9295 19.8726 81.8665 19.6586C81.8035 19.4447 81.6785 19.2542 81.5072 19.1113C81.336 18.9684 81.1262 18.8794 80.9044 18.8557C80.6826 18.8321 80.4588 18.8747 80.2612 18.9782C80.0636 19.0817 79.9012 19.2414 79.7944 19.4373C79.6876 19.6331 79.6413 19.8562 79.6613 20.0783C79.6746 20.2259 79.7168 20.3694 79.7855 20.5006C79.8543 20.6318 79.9482 20.7482 80.062 20.8432C80.1757 20.9381 80.307 21.0097 80.4485 21.0538C80.5899 21.098 80.7386 21.1138 80.8862 21.1005Z"
              fill="#00BA77"
            />
            <path
              d="M88.1254 20.4508C88.3476 20.4308 88.5588 20.3453 88.7323 20.2052C88.9059 20.065 89.034 19.8766 89.1005 19.6637C89.1669 19.4508 89.1688 19.2229 89.1058 19.009C89.0428 18.795 88.9177 18.6045 88.7465 18.4616C88.5752 18.3187 88.3655 18.2298 88.1437 18.2061C87.9219 18.1824 87.6981 18.225 87.5005 18.3285C87.3029 18.432 87.1404 18.5918 87.0337 18.7876C86.9269 18.9834 86.8805 19.2065 86.9005 19.4287C86.9138 19.5762 86.956 19.7197 87.0248 19.8509C87.0935 19.9822 87.1875 20.0986 87.3012 20.1935C87.415 20.2884 87.5463 20.36 87.6877 20.4042C87.8291 20.4483 87.9779 20.4642 88.1254 20.4508Z"
              fill="#00BA77"
            />
            <path
              d="M95.3622 19.8012C95.5844 19.7811 95.7956 19.6956 95.9691 19.5555C96.1427 19.4154 96.2708 19.227 96.3373 19.014C96.4038 18.8011 96.4056 18.5733 96.3426 18.3593C96.2796 18.1454 96.1546 17.9549 95.9833 17.812C95.8121 17.669 95.6023 17.5801 95.3805 17.5564C95.1587 17.5327 94.9349 17.5753 94.7373 17.6788C94.5397 17.7823 94.3773 17.9421 94.2705 18.1379C94.1637 18.3338 94.1174 18.5569 94.1374 18.779C94.1506 18.9266 94.1929 19.07 94.2616 19.2013C94.3304 19.3325 94.4243 19.4489 94.538 19.5439C94.6518 19.6388 94.7831 19.7104 94.9245 19.7545C95.0659 19.7987 95.2147 19.8145 95.3622 19.8012Z"
              fill="#00BA77"
            />
            <path
              d="M102.6 19.1517C102.823 19.132 103.034 19.0466 103.208 18.9065C103.383 18.7664 103.511 18.5778 103.578 18.3646C103.645 18.1513 103.647 17.9231 103.584 17.7086C103.521 17.4942 103.396 17.3032 103.225 17.1599C103.053 17.0166 102.843 16.9273 102.621 16.9034C102.399 16.8796 102.174 16.9221 101.976 17.0257C101.778 17.1294 101.616 17.2894 101.509 17.4856C101.402 17.6817 101.355 17.9053 101.375 18.1278C101.388 18.2755 101.43 18.4192 101.499 18.5506C101.568 18.6821 101.661 18.7987 101.775 18.8938C101.889 18.9889 102.02 19.0606 102.162 19.1049C102.303 19.1491 102.452 19.1651 102.6 19.1517Z"
              fill="#00BA77"
            />
            <path
              d="M109.836 18.5021C110.059 18.4823 110.271 18.397 110.445 18.2569C110.619 18.1168 110.747 17.9282 110.814 17.7149C110.881 17.5017 110.883 17.2734 110.82 17.059C110.758 16.8445 110.632 16.6536 110.461 16.5102C110.29 16.3669 110.079 16.2777 109.857 16.2538C109.635 16.2299 109.411 16.2725 109.213 16.3761C109.015 16.4797 108.852 16.6397 108.745 16.8359C108.638 17.0321 108.591 17.2556 108.611 17.4782C108.625 17.6259 108.667 17.7696 108.735 17.901C108.804 18.0324 108.898 18.149 109.012 18.2441C109.125 18.3392 109.257 18.411 109.398 18.4552C109.54 18.4995 109.689 18.5154 109.836 18.5021Z"
              fill="#00BA77"
            />
            <path
              d="M117.077 17.8504C117.299 17.8307 117.51 17.7454 117.684 17.6055C117.858 17.4656 117.986 17.2772 118.053 17.0643C118.12 16.8514 118.122 16.6235 118.059 16.4094C117.996 16.1953 117.871 16.0046 117.7 15.8615C117.529 15.7185 117.319 15.6294 117.097 15.6056C116.875 15.5818 116.652 15.6243 116.454 15.7278C116.256 15.8313 116.094 15.9911 115.987 16.1869C115.88 16.3828 115.834 16.606 115.854 16.8282C115.881 17.1259 116.024 17.4008 116.254 17.5924C116.483 17.7841 116.779 17.8769 117.077 17.8504Z"
              fill="#00BA77"
            />
            <path
              d="M124.312 17.2011C124.534 17.181 124.745 17.0955 124.919 16.9554C125.092 16.8153 125.221 16.6269 125.287 16.414C125.353 16.201 125.355 15.9732 125.292 15.7592C125.229 15.5453 125.104 15.3548 124.933 15.2119C124.762 15.069 124.552 14.98 124.33 14.9563C124.108 14.9326 123.885 14.9752 123.687 15.0787C123.489 15.1822 123.327 15.342 123.22 15.5378C123.113 15.7337 123.067 15.9568 123.087 16.1789C123.1 16.3265 123.143 16.47 123.211 16.6012C123.28 16.7324 123.374 16.8488 123.488 16.9438C123.601 17.0387 123.733 17.1103 123.874 17.1544C124.016 17.1986 124.164 17.2144 124.312 17.2011Z"
              fill="#00BA77"
            />
            <path
              d="M131.548 16.5517C131.77 16.5316 131.982 16.4461 132.155 16.306C132.329 16.1659 132.457 15.9775 132.523 15.7645C132.59 15.5516 132.592 15.3238 132.529 15.1098C132.466 14.8958 132.341 14.7054 132.169 14.5624C131.998 14.4195 131.788 14.3306 131.567 14.3069C131.345 14.2832 131.121 14.3258 130.923 14.4293C130.726 14.5328 130.563 14.6926 130.456 14.8884C130.35 15.0843 130.303 15.3073 130.323 15.5295C130.337 15.6771 130.379 15.8205 130.448 15.9518C130.516 16.083 130.61 16.1994 130.724 16.2943C130.838 16.3893 130.969 16.4608 131.111 16.505C131.252 16.5492 131.401 16.565 131.548 16.5517Z"
              fill="#00BA77"
            />
            <path
              d="M138.787 15.9013C139.009 15.8812 139.22 15.7957 139.394 15.6556C139.567 15.5155 139.696 15.3271 139.762 15.1141C139.829 14.9012 139.83 14.6734 139.767 14.4594C139.704 14.2455 139.579 14.055 139.408 13.9121C139.237 13.7691 139.027 13.6802 138.805 13.6565C138.584 13.6328 138.36 13.6754 138.162 13.7789C137.965 13.8824 137.802 14.0422 137.695 14.238C137.588 14.4339 137.542 14.657 137.562 14.8791C137.575 15.0267 137.618 15.1702 137.686 15.3014C137.755 15.4326 137.849 15.549 137.963 15.6439C138.077 15.7389 138.208 15.8105 138.349 15.8546C138.491 15.8988 138.639 15.9146 138.787 15.9013Z"
              fill="#00BA77"
            />
            <path
              d="M146.023 15.2518C146.246 15.2321 146.458 15.1467 146.632 15.0066C146.806 14.8665 146.935 14.6779 147.001 14.4647C147.068 14.2514 147.07 14.0232 147.007 13.8087C146.945 13.5943 146.819 13.4033 146.648 13.26C146.477 13.1167 146.266 13.0274 146.044 13.0035C145.822 12.9797 145.598 13.0222 145.4 13.1258C145.202 13.2295 145.039 13.3895 144.932 13.5857C144.825 13.7818 144.778 14.0053 144.798 14.2279C144.825 14.5261 144.969 14.8013 145.199 14.9933C145.429 15.1853 145.725 15.2783 146.023 15.2518Z"
              fill="#00BA77"
            />
            <path
              d="M153.26 14.6023C153.483 14.5829 153.695 14.4978 153.869 14.3579C154.044 14.218 154.173 14.0294 154.24 13.8162C154.307 13.603 154.309 13.3746 154.247 13.16C154.184 12.9455 154.059 12.7543 153.887 12.6108C153.716 12.4673 153.506 12.3779 153.284 12.3539C153.061 12.3299 152.837 12.3724 152.639 12.476C152.441 12.5796 152.278 12.7397 152.171 12.9359C152.064 13.1322 152.017 13.3558 152.037 13.5784C152.064 13.8762 152.208 14.1512 152.437 14.3432C152.667 14.5351 152.963 14.6283 153.26 14.6023Z"
              fill="#00BA77"
            />
            <path
              d="M160.498 13.9525C160.721 13.9328 160.933 13.8474 161.107 13.7073C161.281 13.5672 161.41 13.3786 161.476 13.1653C161.543 12.9521 161.545 12.7238 161.483 12.5094C161.42 12.295 161.295 12.104 161.123 11.9607C160.952 11.8174 160.742 11.7281 160.519 11.7042C160.297 11.6803 160.073 11.7229 159.875 11.8265C159.677 11.9301 159.514 12.0902 159.407 12.2863C159.3 12.4825 159.254 12.706 159.274 12.9286C159.3 13.2267 159.444 13.502 159.674 13.694C159.904 13.886 160.2 13.979 160.498 13.9525Z"
              fill="#00BA77"
            />
            <path
              d="M167.736 13.3012C167.958 13.2811 168.169 13.1956 168.343 13.0555C168.516 12.9154 168.644 12.727 168.711 12.514C168.777 12.3011 168.779 12.0733 168.716 11.8593C168.653 11.6454 168.528 11.4549 168.357 11.312C168.186 11.1691 167.976 11.0801 167.754 11.0564C167.532 11.0327 167.308 11.0753 167.111 11.1788C166.913 11.2823 166.751 11.4421 166.644 11.6379C166.537 11.8338 166.491 12.0569 166.511 12.279C166.538 12.5768 166.682 12.8517 166.912 13.0433C167.142 13.2349 167.438 13.3277 167.736 13.3012Z"
              fill="#00BA77"
            />
            <path
              d="M174.974 12.6508C175.196 12.6307 175.407 12.5452 175.58 12.4051C175.754 12.265 175.882 12.0766 175.949 11.8637C176.015 11.6507 176.017 11.4229 175.954 11.2089C175.891 10.995 175.766 10.8045 175.595 10.6616C175.423 10.5187 175.214 10.4297 174.992 10.406C174.77 10.3823 174.546 10.4249 174.349 10.5285C174.151 10.632 173.989 10.7917 173.882 10.9875C173.775 11.1834 173.729 11.4065 173.749 11.6286C173.762 11.7762 173.804 11.9197 173.873 12.0509C173.942 12.1821 174.036 12.2985 174.149 12.3935C174.263 12.4884 174.394 12.56 174.536 12.6041C174.677 12.6483 174.826 12.6641 174.974 12.6508Z"
              fill="#00BA77"
            />
            <path
              d="M182.219 12.0011C182.441 11.9811 182.653 11.8956 182.826 11.7554C183 11.6153 183.128 11.4269 183.194 11.214C183.261 11.0011 183.263 10.7732 183.2 10.5593C183.137 10.3453 183.011 10.1548 182.84 10.0119C182.669 9.86901 182.459 9.78008 182.237 9.75638C182.016 9.73269 181.792 9.77529 181.594 9.87879C181.397 9.9823 181.234 10.1421 181.127 10.3379C181.021 10.5337 180.974 10.7568 180.994 10.979C181.008 11.1265 181.05 11.27 181.119 11.4012C181.187 11.5325 181.281 11.6489 181.395 11.7438C181.509 11.8387 181.64 11.9103 181.781 11.9545C181.923 11.9986 182.072 12.0145 182.219 12.0011Z"
              fill="#00BA77"
            />
            <path
              d="M189.447 11.3517C189.669 11.3319 189.881 11.2466 190.055 11.1065C190.229 10.9664 190.358 10.7778 190.425 10.5645C190.492 10.3513 190.494 10.123 190.431 9.90858C190.368 9.69414 190.243 9.50317 190.071 9.35985C189.9 9.21652 189.69 9.12727 189.468 9.10339C189.245 9.07951 189.021 9.12207 188.823 9.2257C188.625 9.32932 188.462 9.48934 188.355 9.68552C188.248 9.88169 188.202 10.1052 188.222 10.3278C188.235 10.4755 188.277 10.6192 188.346 10.7506C188.414 10.882 188.508 10.9986 188.622 11.0937C188.736 11.1888 188.867 11.2606 189.009 11.3048C189.15 11.3491 189.299 11.365 189.447 11.3517Z"
              fill="#00BA77"
            />
            <path
              d="M196.685 10.702C196.908 10.6823 197.12 10.5969 197.294 10.4568C197.468 10.3167 197.597 10.1281 197.663 9.91487C197.73 9.70163 197.732 9.47336 197.67 9.25892C197.607 9.04448 197.482 8.85351 197.31 8.71019C197.139 8.56686 196.929 8.47761 196.706 8.45373C196.484 8.42985 196.26 8.47242 196.062 8.57604C195.864 8.67966 195.701 8.83968 195.594 9.03586C195.487 9.23203 195.441 9.45555 195.461 9.67812C195.487 9.97626 195.631 10.2515 195.861 10.4435C196.091 10.6355 196.387 10.7285 196.685 10.702Z"
              fill="#00BA77"
            />
            <path
              d="M203.922 10.0509C204.144 10.0309 204.355 9.94536 204.529 9.80525C204.702 9.66514 204.83 9.47672 204.897 9.2638C204.963 9.05089 204.965 8.82305 204.902 8.60908C204.839 8.39512 204.714 8.20463 204.543 8.06172C204.372 7.91881 204.162 7.82988 203.94 7.80619C203.718 7.78249 203.494 7.82509 203.297 7.9286C203.099 8.0321 202.937 8.19187 202.83 8.3877C202.723 8.58352 202.677 8.80662 202.697 9.02877C202.71 9.17632 202.752 9.31982 202.821 9.45105C202.89 9.58228 202.984 9.69869 203.098 9.79361C203.211 9.88854 203.343 9.96011 203.484 10.0043C203.626 10.0484 203.774 10.0643 203.922 10.0509Z"
              fill="#00BA77"
            />
            <path
              d="M211.16 9.40129C211.382 9.3812 211.593 9.2957 211.767 9.15559C211.941 9.01548 212.069 8.82706 212.135 8.61415C212.202 8.40123 212.203 8.17339 212.14 7.95942C212.077 7.74546 211.952 7.55498 211.781 7.41206C211.61 7.26915 211.4 7.18023 211.178 7.15653C210.957 7.13283 210.733 7.17543 210.535 7.27894C210.338 7.38244 210.175 7.54221 210.068 7.73804C209.962 7.93387 209.915 8.15696 209.935 8.37911C209.948 8.52667 209.991 8.67016 210.059 8.80139C210.128 8.93263 210.222 9.04903 210.336 9.14395C210.45 9.23888 210.581 9.31046 210.722 9.35461C210.864 9.39876 211.013 9.41463 211.16 9.40129Z"
              fill="#00BA77"
            />
            <path
              d="M218.397 8.75163C218.62 8.73154 218.831 8.64604 219.004 8.50593C219.178 8.36582 219.306 8.1774 219.372 7.96449C219.439 7.75157 219.441 7.52373 219.378 7.30977C219.315 7.0958 219.19 6.90532 219.018 6.76241C218.847 6.61949 218.637 6.53057 218.416 6.50687C218.194 6.48318 217.97 6.52577 217.772 6.62928C217.575 6.73279 217.412 6.89256 217.306 7.08838C217.199 7.28421 217.153 7.5073 217.173 7.72945C217.186 7.87701 217.228 8.0205 217.297 8.15173C217.366 8.28297 217.459 8.39937 217.573 8.4943C217.687 8.58922 217.818 8.6608 217.96 8.70495C218.101 8.74911 218.25 8.76497 218.397 8.75163Z"
              fill="#00BA77"
            />
            <path
              d="M225.634 8.10139C225.856 8.08164 226.068 7.99641 226.241 7.85648C226.415 7.71655 226.544 7.52821 226.61 7.3153C226.677 7.10238 226.679 6.87445 226.616 6.66035C226.553 6.44625 226.429 6.2556 226.257 6.11252C226.086 5.96944 225.876 5.88035 225.654 5.85654C225.433 5.83273 225.209 5.87525 225.011 5.97874C224.813 6.08223 224.651 6.24203 224.544 6.43792C224.437 6.63381 224.391 6.85699 224.411 7.07922C224.438 7.37689 224.582 7.65174 224.811 7.8434C225.04 8.03506 225.336 8.12785 225.634 8.10139Z"
              fill="#00BA77"
            />
            <path
              d="M232.872 7.45252C233.095 7.43277 233.307 7.34744 233.481 7.20732C233.655 7.0672 233.784 6.87859 233.85 6.66535C233.917 6.45212 233.919 6.22384 233.857 6.00941C233.794 5.79497 233.669 5.604 233.497 5.46068C233.326 5.31735 233.116 5.2281 232.893 5.20422C232.671 5.18034 232.447 5.22291 232.249 5.32653C232.051 5.43015 231.888 5.59017 231.781 5.78635C231.674 5.98252 231.628 6.20604 231.648 6.42861C231.661 6.57632 231.703 6.72 231.771 6.85143C231.84 6.98286 231.934 7.09947 232.048 7.19457C232.161 7.28967 232.293 7.36141 232.434 7.40567C232.576 7.44993 232.725 7.46585 232.872 7.45252Z"
              fill="#00BA77"
            />
            <path
              d="M240.109 6.80213C240.331 6.78238 240.543 6.69705 240.717 6.55693C240.891 6.41681 241.02 6.2282 241.087 6.01496C241.154 5.80173 241.156 5.57345 241.093 5.35902C241.03 5.14458 240.905 4.95361 240.733 4.81029C240.562 4.66696 240.352 4.57771 240.13 4.55383C239.908 4.52995 239.683 4.57252 239.485 4.67614C239.287 4.77976 239.124 4.93978 239.017 5.13595C238.91 5.33213 238.864 5.55565 238.884 5.77822C238.911 6.07635 239.055 6.35165 239.285 6.54363C239.514 6.73561 239.811 6.82859 240.109 6.80213Z"
              fill="#00BA77"
            />
            <path
              d="M247.348 6.15272C247.57 6.13296 247.782 6.04763 247.956 5.90751C248.13 5.76739 248.259 5.57878 248.326 5.36555C248.392 5.15232 248.395 4.92404 248.332 4.7096C248.269 4.49516 248.144 4.3042 247.972 4.16087C247.801 4.01755 247.591 3.9283 247.368 3.90442C247.146 3.88054 246.922 3.9231 246.724 4.02673C246.526 4.13035 246.363 4.29036 246.256 4.48654C246.149 4.68272 246.103 4.90623 246.123 5.12881C246.15 5.42694 246.294 5.70223 246.523 5.89421C246.753 6.0862 247.049 6.17917 247.348 6.15272Z"
              fill="#00BA77"
            />
            <path
              d="M254.584 5.50113C254.806 5.48105 255.017 5.39555 255.191 5.25544C255.364 5.11533 255.492 4.92691 255.559 4.714C255.625 4.50109 255.627 4.27324 255.564 4.05927C255.501 3.84531 255.376 3.65482 255.205 3.51191C255.034 3.369 254.824 3.28008 254.602 3.25638C254.38 3.23269 254.157 3.27529 253.959 3.37879C253.761 3.4823 253.599 3.64206 253.492 3.83789C253.385 4.03371 253.339 4.25681 253.359 4.47896C253.386 4.77678 253.531 5.05163 253.76 5.24325C253.99 5.43487 254.286 5.52761 254.584 5.50113Z"
              fill="#00BA77"
            />
            <path
              d="M261.82 4.8509C262.042 4.83115 262.254 4.74591 262.427 4.60598C262.601 4.46606 262.73 4.27772 262.796 4.06481C262.863 3.85189 262.865 3.62396 262.802 3.40986C262.74 3.19576 262.615 3.00511 262.443 2.86202C262.272 2.71894 262.062 2.62986 261.841 2.60605C261.619 2.58224 261.395 2.62477 261.197 2.72825C260.999 2.83174 260.837 2.99154 260.73 3.18742C260.623 3.38331 260.577 3.60649 260.597 3.82872C260.624 4.1264 260.768 4.40125 260.997 4.5929C261.226 4.78456 261.522 4.87736 261.82 4.8509Z"
              fill="#00BA77"
            />
            <path
              d="M269.059 4.20182C269.281 4.18173 269.492 4.09623 269.666 3.95612C269.839 3.81601 269.968 3.6276 270.034 3.41468C270.101 3.20177 270.102 2.97392 270.039 2.75996C269.976 2.54599 269.851 2.35551 269.68 2.2126C269.509 2.06968 269.299 1.98076 269.077 1.95707C268.855 1.93337 268.632 1.97597 268.434 2.07948C268.236 2.18298 268.074 2.34275 267.967 2.53857C267.86 2.7344 267.814 2.95749 267.834 3.17964C267.847 3.3272 267.89 3.47069 267.958 3.60192C268.027 3.73316 268.121 3.84956 268.235 3.94449C268.349 4.03941 268.48 4.11099 268.621 4.15515C268.763 4.1993 268.911 4.21516 269.059 4.20182Z"
              fill="#00BA77"
            />
            <path
              d="M276.294 3.55164C276.517 3.53189 276.729 3.44655 276.903 3.30643C277.077 3.16631 277.205 2.9777 277.272 2.76447C277.339 2.55124 277.341 2.32296 277.278 2.10852C277.216 1.89408 277.09 1.70312 276.919 1.5598C276.748 1.41647 276.537 1.32722 276.315 1.30334C276.093 1.27946 275.869 1.32203 275.671 1.42565C275.473 1.52927 275.31 1.68929 275.203 1.88547C275.096 2.08164 275.049 2.30515 275.069 2.52773C275.096 2.82586 275.24 3.10115 275.47 3.29314C275.7 3.48512 275.996 3.57809 276.294 3.55164Z"
              fill="#00BA77"
            />
            <path
              d="M283.534 2.90222C283.756 2.88247 283.968 2.79714 284.142 2.65702C284.316 2.5169 284.445 2.32829 284.512 2.11506C284.579 1.90182 284.581 1.67355 284.518 1.45911C284.455 1.24467 284.33 1.05371 284.158 0.910384C283.987 0.767058 283.777 0.677809 283.554 0.653929C283.332 0.63005 283.108 0.672612 282.91 0.776232C282.712 0.879853 282.549 1.03988 282.442 1.23605C282.335 1.43223 282.289 1.65574 282.309 1.87831C282.322 2.02602 282.364 2.1697 282.433 2.30113C282.501 2.43257 282.595 2.54917 282.709 2.64427C282.823 2.73937 282.954 2.81111 283.096 2.85537C283.237 2.89963 283.386 2.91555 283.534 2.90222Z"
              fill="#00BA77"
            />
            <path
              d="M290.77 2.25113C290.992 2.23105 291.203 2.14555 291.377 2.00544C291.55 1.86534 291.679 1.67691 291.745 1.464C291.811 1.25109 291.813 1.02324 291.75 0.809278C291.687 0.595312 291.562 0.40483 291.391 0.261918C291.22 0.119006 291.01 0.0300791 290.788 0.00638333C290.566 -0.0173124 290.343 0.0252859 290.145 0.128792C289.947 0.232298 289.785 0.392062 289.678 0.587888C289.571 0.783714 289.525 1.00681 289.545 1.22896C289.558 1.37651 289.601 1.52001 289.669 1.65124C289.738 1.78247 289.832 1.89888 289.946 1.9938C290.059 2.08872 290.191 2.16031 290.332 2.20446C290.474 2.24862 290.622 2.26447 290.77 2.25113Z"
              fill="#00BA77"
            />
            <path
              d="M38.1132 32.2389C38.3358 32.2191 38.5476 32.1338 38.7216 31.9937C38.8957 31.8536 39.0243 31.665 39.0912 31.4517C39.1581 31.2385 39.1602 31.0102 39.0973 30.7958C39.0344 30.5813 38.9093 30.3904 38.7379 30.2471C38.5664 30.1037 38.3563 30.0145 38.1341 29.9906C37.9119 29.9667 37.6876 30.0093 37.4896 30.1129C37.2916 30.2165 37.1288 30.3766 37.0218 30.5727C36.9147 30.7689 36.8683 30.9924 36.8883 31.215C36.9156 31.5129 37.0598 31.788 37.2894 31.9798C37.5189 32.1717 37.8152 32.2649 38.1132 32.2389Z"
              fill="#00BA77"
            />
            <path
              d="M45.351 31.5892C45.5736 31.5695 45.7853 31.4841 45.9594 31.344C46.1335 31.2039 46.2621 31.0153 46.329 30.8021C46.3959 30.5888 46.398 30.3606 46.3351 30.1461C46.2722 29.9317 46.1471 29.7407 45.9756 29.5974C45.8042 29.4541 45.5941 29.3648 45.3719 29.3409C45.1497 29.3171 44.9254 29.3596 44.7274 29.4632C44.5294 29.5669 44.3666 29.7269 44.2596 29.9231C44.1525 30.1192 44.1061 30.3428 44.1261 30.5653C44.153 30.8635 44.297 31.1388 44.5267 31.3307C44.7564 31.5227 45.0528 31.6157 45.351 31.5892Z"
              fill="#00BA77"
            />
            <path
              d="M52.5878 30.9382C52.81 30.9181 53.0212 30.8326 53.1947 30.6925C53.3683 30.5523 53.4964 30.3639 53.5629 30.151C53.6293 29.9381 53.6312 29.7103 53.5682 29.4963C53.5052 29.2823 53.3801 29.0918 53.2089 28.9489C53.0376 28.806 52.8279 28.7171 52.6061 28.6934C52.3843 28.6697 52.1605 28.7123 51.9629 28.8158C51.7653 28.9193 51.6028 29.0791 51.4961 29.2749C51.3893 29.4707 51.3429 29.6938 51.3629 29.916C51.376 30.0636 51.4181 30.2072 51.4867 30.3386C51.5554 30.4699 51.6494 30.5864 51.7632 30.6814C51.8769 30.7763 52.0084 30.8479 52.1499 30.892C52.2914 30.936 52.4402 30.9517 52.5878 30.9382Z"
              fill="#00BA77"
            />
            <path
              d="M59.8241 30.288C60.0463 30.2679 60.2575 30.1824 60.431 30.0423C60.6046 29.9022 60.7327 29.7138 60.7992 29.5009C60.8657 29.288 60.8675 29.0601 60.8045 28.8461C60.7415 28.6322 60.6165 28.4417 60.4452 28.2988C60.274 28.1559 60.0642 28.0669 59.8424 28.0432C59.6206 28.0196 59.3968 28.0622 59.1992 28.1657C59.0016 28.2692 58.8392 28.4289 58.7324 28.6248C58.6256 28.8206 58.5793 29.0437 58.5993 29.2658C58.6125 29.4134 58.6548 29.5569 58.7235 29.6881C58.7923 29.8193 58.8862 29.9357 58.9999 30.0307C59.1137 30.1256 59.245 30.1972 59.3864 30.2413C59.5279 30.2855 59.6766 30.3013 59.8241 30.288Z"
              fill="#00BA77"
            />
            <path
              d="M67.0634 29.6383C67.2855 29.6183 67.4967 29.5328 67.6703 29.3927C67.8439 29.2525 67.972 29.0641 68.0384 28.8512C68.1049 28.6383 68.1068 28.4104 68.0438 28.1965C67.9808 27.9825 67.8557 27.792 67.6845 27.6491C67.5132 27.5062 67.3034 27.4173 67.0817 27.3936C66.8599 27.3699 66.636 27.4125 66.4385 27.516C66.2409 27.6195 66.0784 27.7793 65.9716 27.9751C65.8649 28.1709 65.8185 28.394 65.8385 28.6162C65.8518 28.7637 65.894 28.9072 65.9628 29.0385C66.0315 29.1697 66.1255 29.2861 66.2392 29.381C66.353 29.4759 66.4843 29.5475 66.6257 29.5917C66.7671 29.6358 66.9159 29.6517 67.0634 29.6383Z"
              fill="#00BA77"
            />
            <path
              d="M74.3002 28.9889C74.5228 28.9691 74.7346 28.8838 74.9086 28.7437C75.0827 28.6036 75.2114 28.415 75.2782 28.2017C75.3451 27.9885 75.3472 27.7602 75.2843 27.5458C75.2214 27.3313 75.0963 27.1404 74.9249 26.9971C74.7534 26.8537 74.5433 26.7645 74.3211 26.7406C74.0989 26.7167 73.8746 26.7593 73.6766 26.8629C73.4786 26.9665 73.3158 27.1266 73.2088 27.3227C73.1017 27.5189 73.0553 27.7424 73.0753 27.965C73.1022 28.2631 73.2463 28.5384 73.4759 28.7304C73.7056 28.9224 74.0021 29.0154 74.3002 28.9889Z"
              fill="#00BA77"
            />
            <path
              d="M81.5381 28.3393C81.7605 28.3196 81.9721 28.2345 82.1461 28.0947C82.3202 27.9549 82.4489 27.7666 82.516 27.5536C82.5831 27.3407 82.5856 27.1126 82.5232 26.8983C82.4608 26.6839 82.3363 26.4928 82.1653 26.3492C81.9944 26.2056 81.7847 26.1158 81.5628 26.0913C81.3408 26.0668 81.1166 26.1086 80.9184 26.2114C80.7203 26.3142 80.557 26.4735 80.4493 26.6691C80.3415 26.8646 80.2942 27.0877 80.3132 27.3102C80.3256 27.4583 80.3672 27.6026 80.4356 27.7346C80.5039 27.8666 80.5977 27.9838 80.7116 28.0795C80.8254 28.1751 80.957 28.2473 81.0989 28.2919C81.2407 28.3365 81.39 28.3526 81.5381 28.3393Z"
              fill="#00BA77"
            />
            <path
              d="M88.7753 27.6896C88.9979 27.6698 89.2097 27.5845 89.3838 27.4444C89.5578 27.3043 89.6865 27.1156 89.7533 26.9024C89.8202 26.6892 89.8223 26.4609 89.7594 26.2465C89.6965 26.032 89.5714 25.8411 89.4 25.6977C89.2285 25.5544 89.0184 25.4652 88.7962 25.4413C88.574 25.4174 88.3497 25.46 88.1517 25.5636C87.9537 25.6672 87.7909 25.8272 87.6839 26.0234C87.5768 26.2196 87.5304 26.4431 87.5504 26.6657C87.5635 26.8134 87.6056 26.9571 87.6742 27.0885C87.7429 27.2199 87.8368 27.3365 87.9506 27.4316C88.0643 27.5267 88.1957 27.5985 88.3373 27.6427C88.4788 27.687 88.6276 27.7029 88.7753 27.6896Z"
              fill="#00BA77"
            />
            <path
              d="M96.0116 27.0378C96.2338 27.0177 96.445 26.9322 96.6185 26.7921C96.7921 26.652 96.9202 26.4635 96.9867 26.2506C97.0532 26.0377 97.055 25.8099 96.992 25.5959C96.929 25.3819 96.804 25.1915 96.6327 25.0485C96.4615 24.9056 96.2517 24.8167 96.0299 24.793C95.8081 24.7693 95.5843 24.8119 95.3867 24.9154C95.1891 25.0189 95.0267 25.1787 94.9199 25.3745C94.8131 25.5703 94.7668 25.7934 94.7868 26.0156C94.8 26.1631 94.8423 26.3066 94.911 26.4379C94.9798 26.5691 95.0737 26.6855 95.1874 26.7804C95.3012 26.8753 95.4325 26.9469 95.5739 26.9911C95.7154 27.0352 95.8641 27.0511 96.0116 27.0378Z"
              fill="#00BA77"
            />
            <path
              d="M103.25 26.3881C103.472 26.368 103.683 26.2825 103.857 26.1424C104.03 26.0023 104.158 25.8139 104.225 25.601C104.291 25.3881 104.293 25.1602 104.23 24.9462C104.167 24.7323 104.042 24.5418 103.871 24.3989C103.7 24.256 103.49 24.167 103.268 24.1433C103.046 24.1197 102.823 24.1622 102.625 24.2658C102.427 24.3693 102.265 24.529 102.158 24.7249C102.051 24.9207 102.005 25.1438 102.025 25.3659C102.038 25.5135 102.081 25.657 102.149 25.7882C102.218 25.9194 102.312 26.0358 102.426 26.1308C102.539 26.2257 102.671 26.2973 102.812 26.3414C102.954 26.3856 103.102 26.4014 103.25 26.3881Z"
              fill="#00BA77"
            />
            <path
              d="M110.493 25.7384C110.715 25.7184 110.926 25.6329 111.1 25.4927C111.274 25.3526 111.402 25.1642 111.468 24.9513C111.535 24.7384 111.536 24.5106 111.473 24.2966C111.41 24.0826 111.285 23.8921 111.114 23.7492C110.943 23.6063 110.733 23.5174 110.511 23.4937C110.29 23.47 110.066 23.5126 109.868 23.6161C109.671 23.7196 109.508 23.8794 109.401 24.0752C109.295 24.271 109.248 24.4941 109.268 24.7163C109.281 24.8638 109.324 25.0073 109.392 25.1386C109.461 25.2698 109.555 25.3862 109.669 25.4811C109.783 25.576 109.914 25.6476 110.055 25.6918C110.197 25.7359 110.346 25.7518 110.493 25.7384Z"
              fill="#00BA77"
            />
            <path
              d="M117.725 25.0888C117.947 25.0688 118.159 24.9833 118.332 24.8433C118.506 24.7033 118.634 24.515 118.7 24.3022C118.767 24.0894 118.769 23.8616 118.706 23.6476C118.643 23.4337 118.518 23.2432 118.347 23.1002C118.176 22.9572 117.967 22.8681 117.745 22.8442C117.523 22.8202 117.299 22.8626 117.102 22.9658C116.904 23.0691 116.742 23.2286 116.634 23.4242C116.527 23.6198 116.481 23.8428 116.5 24.0649C116.514 24.2126 116.556 24.3563 116.624 24.4877C116.693 24.6191 116.787 24.7357 116.901 24.8308C117.014 24.926 117.146 24.9977 117.287 25.042C117.429 25.0862 117.578 25.1021 117.725 25.0888Z"
              fill="#00BA77"
            />
            <path
              d="M124.961 24.4393C125.184 24.4196 125.396 24.3343 125.57 24.1941C125.744 24.054 125.872 23.8654 125.939 23.6522C126.006 23.4389 126.008 23.2107 125.945 22.9962C125.883 22.7818 125.757 22.5908 125.586 22.4475C125.415 22.3042 125.204 22.2149 124.982 22.191C124.76 22.1672 124.536 22.2097 124.338 22.3134C124.14 22.417 123.977 22.577 123.87 22.7732C123.763 22.9693 123.716 23.1929 123.736 23.4154C123.75 23.5631 123.792 23.7068 123.86 23.8382C123.929 23.9697 124.023 24.0863 124.137 24.1814C124.25 24.2765 124.382 24.3482 124.523 24.3925C124.665 24.4368 124.814 24.4527 124.961 24.4393Z"
              fill="#00BA77"
            />
            <path
              d="M132.198 23.7898C132.421 23.7704 132.633 23.6853 132.807 23.5454C132.982 23.4055 133.111 23.2169 133.178 23.0037C133.245 22.7905 133.247 22.5621 133.185 22.3475C133.122 22.133 132.997 21.9418 132.825 21.7983C132.654 21.6548 132.444 21.5654 132.222 21.5414C131.999 21.5174 131.775 21.5599 131.577 21.6635C131.379 21.7671 131.216 21.9272 131.109 22.1234C131.002 22.3197 130.955 22.5433 130.975 22.7659C131.002 23.0637 131.146 23.3387 131.375 23.5307C131.605 23.7226 131.901 23.8158 132.198 23.7898Z"
              fill="#00BA77"
            />
            <path
              d="M139.436 23.138C139.659 23.1184 139.87 23.0333 140.044 22.8935C140.217 22.7538 140.346 22.5657 140.413 22.353C140.48 22.1402 140.482 21.9125 140.419 21.6984C140.357 21.4843 140.232 21.2936 140.062 21.1503C139.891 21.0071 139.681 20.9177 139.459 20.8934C139.238 20.8692 139.014 20.9112 138.816 21.0141C138.618 21.1171 138.455 21.2764 138.348 21.4719C138.241 21.6673 138.194 21.8902 138.213 22.1124C138.226 22.2601 138.268 22.4038 138.336 22.5353C138.405 22.6668 138.499 22.7836 138.612 22.8788C138.726 22.9741 138.857 23.046 138.998 23.0905C139.14 23.135 139.289 23.1511 139.436 23.138Z"
              fill="#00BA77"
            />
            <path
              d="M146.674 22.4882C146.896 22.4681 147.107 22.3826 147.281 22.2425C147.454 22.1024 147.582 21.914 147.649 21.7011C147.715 21.4882 147.717 21.2603 147.654 21.0463C147.591 20.8324 147.466 20.6419 147.295 20.499C147.124 20.3561 146.914 20.2671 146.692 20.2434C146.47 20.2197 146.246 20.2623 146.049 20.3659C145.851 20.4694 145.689 20.6291 145.582 20.825C145.475 21.0208 145.429 21.2439 145.449 21.466C145.476 21.7639 145.62 22.0387 145.85 22.2303C146.08 22.4219 146.376 22.5147 146.674 22.4882Z"
              fill="#00BA77"
            />
            <path
              d="M153.912 21.8393C154.134 21.8192 154.345 21.7337 154.518 21.5936C154.692 21.4535 154.82 21.2651 154.887 21.0521C154.953 20.8392 154.955 20.6114 154.892 20.3974C154.829 20.1835 154.704 19.993 154.533 19.8501C154.361 19.7071 154.152 19.6182 153.93 19.5945C153.708 19.5708 153.484 19.6134 153.287 19.7169C153.089 19.8204 152.927 19.9802 152.82 20.176C152.713 20.3719 152.667 20.595 152.687 20.8171C152.7 20.9647 152.742 21.1081 152.811 21.2394C152.88 21.3706 152.974 21.487 153.087 21.5819C153.201 21.6769 153.332 21.7484 153.474 21.7926C153.615 21.8368 153.764 21.8526 153.912 21.8393Z"
              fill="#00BA77"
            />
            <path
              d="M161.148 21.1888C161.371 21.1691 161.583 21.0838 161.757 20.9436C161.931 20.8035 162.059 20.6149 162.126 20.4017C162.193 20.1884 162.195 19.9602 162.132 19.7457C162.07 19.5313 161.944 19.3403 161.773 19.197C161.602 19.0537 161.391 18.9644 161.169 18.9406C160.947 18.9167 160.723 18.9592 160.525 19.0629C160.327 19.1665 160.164 19.3265 160.057 19.5227C159.95 19.7188 159.903 19.9424 159.923 20.1649C159.937 20.3126 159.979 20.4563 160.047 20.5877C160.116 20.7192 160.21 20.8358 160.324 20.9309C160.437 21.026 160.569 21.0977 160.71 21.142C160.852 21.1863 161.001 21.2022 161.148 21.1888Z"
              fill="#00BA77"
            />
            <path
              d="M168.386 20.5394C168.608 20.5197 168.82 20.4343 168.994 20.2942C169.168 20.1541 169.297 19.9655 169.364 19.7523C169.431 19.539 169.433 19.3107 169.37 19.0963C169.307 18.8819 169.182 18.6909 169.01 18.5476C168.839 18.4043 168.629 18.315 168.407 18.2911C168.184 18.2673 167.96 18.3098 167.762 18.4134C167.564 18.5171 167.401 18.6771 167.294 18.8733C167.187 19.0694 167.141 19.2929 167.161 19.5155C167.174 19.6632 167.216 19.8069 167.285 19.9383C167.353 20.0698 167.447 20.1864 167.561 20.2815C167.675 20.3766 167.806 20.4483 167.948 20.4926C168.089 20.5368 168.238 20.5528 168.386 20.5394Z"
              fill="#00BA77"
            />
            <path
              d="M175.623 19.8898C175.846 19.87 176.058 19.7847 176.232 19.6446C176.406 19.5044 176.535 19.3158 176.601 19.1026C176.668 18.8894 176.67 18.6611 176.608 18.4467C176.545 18.2322 176.42 18.0413 176.248 17.8979C176.077 17.7546 175.867 17.6654 175.644 17.6415C175.422 17.6176 175.198 17.6602 175 17.7638C174.802 17.8674 174.639 18.0274 174.532 18.2236C174.425 18.4198 174.379 18.6433 174.399 18.8659C174.425 19.164 174.57 19.4393 174.799 19.6313C175.029 19.8232 175.325 19.9162 175.623 19.8898Z"
              fill="#00BA77"
            />
            <path
              d="M182.86 19.2384C183.082 19.2183 183.293 19.1329 183.467 18.9927C183.64 18.8526 183.768 18.6642 183.835 18.4513C183.901 18.2384 183.903 18.0105 183.84 17.7966C183.777 17.5826 183.652 17.3921 183.481 17.2492C183.31 17.1063 183.1 17.0174 182.878 16.9937C182.656 16.97 182.432 17.0126 182.235 17.1161C182.037 17.2196 181.875 17.3794 181.768 17.5752C181.661 17.771 181.615 17.9941 181.635 18.2163C181.662 18.5141 181.806 18.7889 182.036 18.9806C182.266 19.1722 182.562 19.2649 182.86 19.2384Z"
              fill="#00BA77"
            />
            <path
              d="M190.099 18.5888C190.321 18.5687 190.532 18.4832 190.705 18.3431C190.879 18.203 191.007 18.0146 191.074 17.8016C191.14 17.5887 191.142 17.3609 191.079 17.1469C191.016 16.933 190.891 16.7425 190.72 16.5996C190.548 16.4567 190.339 16.3677 190.117 16.344C189.895 16.3203 189.671 16.3629 189.474 16.4664C189.276 16.5699 189.114 16.7297 189.007 16.9255C188.9 17.1214 188.854 17.3445 188.874 17.5666C188.887 17.7142 188.929 17.8577 188.998 17.9889C189.067 18.1201 189.161 18.2365 189.274 18.3315C189.388 18.4264 189.519 18.498 189.661 18.5421C189.802 18.5863 189.951 18.6021 190.099 18.5888Z"
              fill="#00BA77"
            />
            <path
              d="M197.335 17.9384C197.557 17.9183 197.768 17.8328 197.942 17.6927C198.115 17.5526 198.243 17.3642 198.31 17.1513C198.376 16.9383 198.378 16.7105 198.315 16.4965C198.252 16.2826 198.127 16.0921 197.956 15.9492C197.785 15.8063 197.575 15.7173 197.353 15.6936C197.131 15.6699 196.908 15.7125 196.71 15.816C196.512 15.9196 196.35 16.0793 196.243 16.2751C196.136 16.471 196.09 16.6941 196.11 16.9162C196.123 17.0638 196.166 17.2073 196.234 17.3385C196.303 17.4697 196.397 17.5861 196.511 17.6811C196.624 17.776 196.756 17.8476 196.897 17.8917C197.039 17.9359 197.187 17.9517 197.335 17.9384Z"
              fill="#00BA77"
            />
            <path
              d="M204.569 17.2887C204.791 17.2687 205.002 17.1833 205.175 17.0433C205.349 16.9032 205.477 16.7149 205.544 16.5021C205.61 16.2893 205.612 16.0615 205.549 15.8476C205.486 15.6336 205.362 15.4431 205.191 15.3001C205.019 15.1571 204.81 15.068 204.588 15.0441C204.366 15.0202 204.143 15.0625 203.945 15.1658C203.747 15.269 203.585 15.4285 203.478 15.6241C203.371 15.8198 203.324 16.0427 203.344 16.2648C203.357 16.4125 203.399 16.5562 203.468 16.6877C203.536 16.8191 203.63 16.9357 203.744 17.0308C203.858 17.1259 203.989 17.1976 204.131 17.2419C204.272 17.2862 204.421 17.3021 204.569 17.2887Z"
              fill="#00BA77"
            />
            <path
              d="M211.81 16.6393C212.033 16.6195 212.245 16.5342 212.419 16.3941C212.593 16.254 212.722 16.0654 212.788 15.8521C212.855 15.6389 212.857 15.4106 212.795 15.1962C212.732 14.9817 212.607 14.7908 212.435 14.6474C212.264 14.5041 212.054 14.4149 211.831 14.391C211.609 14.3671 211.385 14.4097 211.187 14.5133C210.989 14.6169 210.826 14.7769 210.719 14.9731C210.612 15.1693 210.566 15.3928 210.586 15.6154C210.599 15.7631 210.641 15.9068 210.709 16.0382C210.778 16.1696 210.872 16.2862 210.986 16.3813C211.099 16.4764 211.231 16.5482 211.372 16.5924C211.514 16.6367 211.663 16.6526 211.81 16.6393Z"
              fill="#00BA77"
            />
            <path
              d="M219.047 15.9896C219.27 15.9699 219.482 15.8845 219.656 15.7444C219.83 15.6043 219.958 15.4157 220.025 15.2025C220.092 14.9892 220.094 14.761 220.031 14.5465C219.968 14.3321 219.843 14.1411 219.672 13.9978C219.5 13.8545 219.29 13.7652 219.068 13.7413C218.846 13.7175 218.622 13.76 218.424 13.8636C218.226 13.9673 218.063 14.1273 217.956 14.3235C217.849 14.5196 217.802 14.7431 217.822 14.9657C217.849 15.2639 217.993 15.5391 218.223 15.7311C218.453 15.9231 218.749 16.0161 219.047 15.9896Z"
              fill="#00BA77"
            />
            <path
              d="M226.286 15.3385C226.508 15.3185 226.719 15.2329 226.892 15.0928C227.066 14.9527 227.194 14.7643 227.261 14.5514C227.327 14.3385 227.329 14.1106 227.266 13.8967C227.203 13.6827 227.078 13.4922 226.907 13.3493C226.735 13.2064 226.526 13.1175 226.304 13.0938C226.082 13.0701 225.858 13.1127 225.661 13.2162C225.463 13.3197 225.301 13.4795 225.194 13.6753C225.087 13.8711 225.041 14.0942 225.061 14.3164C225.074 14.4639 225.116 14.6074 225.185 14.7386C225.254 14.8699 225.348 14.9863 225.461 15.0812C225.575 15.1761 225.706 15.2477 225.848 15.2919C225.989 15.336 226.138 15.3519 226.286 15.3385Z"
              fill="#00BA77"
            />
            <path
              d="M233.522 14.6889C233.744 14.6688 233.955 14.5833 234.129 14.4432C234.302 14.3031 234.43 14.1147 234.497 13.9017C234.563 13.6888 234.565 13.461 234.502 13.247C234.439 13.0331 234.314 12.8426 234.143 12.6997C233.972 12.5567 233.762 12.4678 233.54 12.4441C233.318 12.4204 233.095 12.463 232.897 12.5665C232.699 12.67 232.537 12.8298 232.43 13.0256C232.323 13.2215 232.277 13.4446 232.297 13.6667C232.31 13.8143 232.352 13.958 232.421 14.0893C232.49 14.2206 232.583 14.3371 232.697 14.4321C232.811 14.5271 232.942 14.5986 233.084 14.6427C233.225 14.6868 233.374 14.7025 233.522 14.6889Z"
              fill="#00BA77"
            />
            <path
              d="M240.759 14.0394C240.981 14.0196 241.193 13.9344 241.366 13.7945C241.54 13.6545 241.669 13.4662 241.735 13.2533C241.802 13.0404 241.804 12.8124 241.741 12.5983C241.679 12.3842 241.554 12.1936 241.382 12.0505C241.211 11.9074 241.001 11.8183 240.779 11.7945C240.558 11.7707 240.334 11.8132 240.136 11.9167C239.938 12.0202 239.776 12.18 239.669 12.3759C239.562 12.5718 239.516 12.795 239.536 13.0172C239.563 13.3149 239.707 13.5897 239.936 13.7814C240.165 13.973 240.461 14.0658 240.759 14.0394Z"
              fill="#00BA77"
            />
            <path
              d="M247.997 13.3896C248.219 13.3695 248.43 13.2841 248.604 13.1441C248.777 13.0041 248.905 12.8158 248.972 12.603C249.038 12.3901 249.04 12.1624 248.978 11.9484C248.915 11.7345 248.79 11.544 248.619 11.4009C248.448 11.2579 248.238 11.1688 248.016 11.1449C247.795 11.121 247.571 11.1634 247.373 11.2666C247.176 11.3698 247.013 11.5294 246.906 11.725C246.799 11.9206 246.752 12.1436 246.772 12.3657C246.785 12.5134 246.827 12.6571 246.896 12.7885C246.964 12.9199 247.058 13.0365 247.172 13.1316C247.286 13.2267 247.417 13.2985 247.559 13.3427C247.7 13.387 247.849 13.4029 247.997 13.3896Z"
              fill="#00BA77"
            />
            <path
              d="M255.234 12.7394C255.456 12.7196 255.668 12.6343 255.842 12.4942C256.016 12.3541 256.145 12.1654 256.212 11.9522C256.279 11.739 256.281 11.5107 256.218 11.2963C256.155 11.0818 256.03 10.8909 255.858 10.7475C255.687 10.6042 255.477 10.515 255.255 10.4911C255.032 10.4672 254.808 10.5098 254.61 10.6134C254.412 10.717 254.249 10.877 254.142 11.0732C254.035 11.2694 253.989 11.4929 254.009 11.7155C254.036 12.0136 254.18 12.2889 254.41 12.4809C254.639 12.6729 254.936 12.7658 255.234 12.7394Z"
              fill="#00BA77"
            />
            <path
              d="M262.472 12.0905C262.694 12.0707 262.906 11.9854 263.08 11.8453C263.254 11.7051 263.383 11.5165 263.45 11.3033C263.516 11.0901 263.519 10.8618 263.456 10.6473C263.393 10.4329 263.268 10.2419 263.096 10.0986C262.925 9.95529 262.715 9.86604 262.492 9.84216C262.27 9.81828 262.046 9.86084 261.848 9.96446C261.65 10.0681 261.487 10.2281 261.38 10.4243C261.273 10.6205 261.227 10.844 261.247 11.0665C261.274 11.3647 261.418 11.64 261.647 11.832C261.877 12.0239 262.173 12.1169 262.472 12.0905Z"
              fill="#00BA77"
            />
            <path
              d="M269.711 11.4386C269.933 11.4186 270.144 11.3331 270.318 11.1929C270.491 11.0528 270.619 10.8644 270.686 10.6515C270.752 10.4386 270.754 10.2107 270.691 9.99678C270.628 9.78281 270.503 9.59233 270.332 9.44942C270.161 9.30651 269.951 9.21758 269.729 9.19388C269.507 9.17019 269.283 9.21279 269.086 9.31629C268.888 9.4198 268.726 9.57956 268.619 9.77539C268.512 9.97121 268.466 10.1943 268.486 10.4165C268.499 10.5641 268.541 10.7077 268.61 10.8391C268.678 10.9704 268.772 11.0869 268.886 11.1819C269 11.2768 269.131 11.3484 269.273 11.3925C269.414 11.4365 269.563 11.4522 269.711 11.4386Z"
              fill="#00BA77"
            />
            <path
              d="M276.945 10.789C277.167 10.7689 277.379 10.6834 277.552 10.5433C277.726 10.4032 277.854 10.2148 277.92 10.0018C277.987 9.78893 277.989 9.56109 277.926 9.34712C277.863 9.13315 277.738 8.94267 277.566 8.79976C277.395 8.65685 277.185 8.56792 276.964 8.54423C276.742 8.52053 276.518 8.56313 276.32 8.66663C276.123 8.77014 275.96 8.9299 275.853 9.12573C275.747 9.32156 275.7 9.54465 275.72 9.7668C275.734 9.91436 275.776 10.0578 275.845 10.1891C275.913 10.3203 276.007 10.4367 276.121 10.5316C276.235 10.6266 276.366 10.6982 276.508 10.7423C276.649 10.7865 276.798 10.8023 276.945 10.789Z"
              fill="#00BA77"
            />
            <path
              d="M284.184 10.1393C284.406 10.1192 284.617 10.0337 284.791 9.89363C284.964 9.75352 285.093 9.5651 285.159 9.35218C285.226 9.13927 285.227 8.91143 285.164 8.69746C285.101 8.4835 284.976 8.29301 284.805 8.1501C284.634 8.00719 284.424 7.91826 284.202 7.89457C283.98 7.87087 283.757 7.91347 283.559 8.01698C283.361 8.12048 283.199 8.28025 283.092 8.47607C282.985 8.6719 282.939 8.89499 282.959 9.11714C282.972 9.2647 283.015 9.40819 283.083 9.53942C283.152 9.67066 283.246 9.78706 283.36 9.88199C283.474 9.97691 283.605 10.0485 283.746 10.0926C283.888 10.1368 284.036 10.1527 284.184 10.1393Z"
              fill="#00BA77"
            />
            <path
              d="M291.42 9.48987C291.642 9.47012 291.854 9.38479 292.028 9.24467C292.202 9.10455 292.331 8.91594 292.398 8.70271C292.465 8.48947 292.467 8.2612 292.404 8.04676C292.341 7.83232 292.216 7.64136 292.044 7.49803C291.873 7.3547 291.663 7.26546 291.441 7.24158C291.219 7.2177 290.994 7.26026 290.796 7.36388C290.598 7.4675 290.435 7.62752 290.328 7.8237C290.221 8.01988 290.175 8.24339 290.195 8.46596C290.222 8.7641 290.366 9.03939 290.596 9.23137C290.825 9.42335 291.122 9.51633 291.42 9.48987Z"
              fill="#00BA77"
            />
            <path
              d="M38.7675 39.4755C38.9897 39.4554 39.2009 39.3699 39.3744 39.2298C39.548 39.0897 39.6761 38.9013 39.7426 38.6884C39.809 38.4754 39.8109 38.2476 39.7479 38.0336C39.6849 37.8197 39.5598 37.6292 39.3886 37.4863C39.2173 37.3434 39.0076 37.2544 38.7858 37.2307C38.564 37.2071 38.3401 37.2497 38.1426 37.3532C37.945 37.4567 37.7825 37.6164 37.6757 37.8123C37.569 38.0081 37.5226 38.2312 37.5426 38.4533C37.5559 38.6009 37.5981 38.7444 37.6669 38.8756C37.7356 39.0068 37.8296 39.1232 37.9433 39.2182C38.0571 39.3131 38.1884 39.3847 38.3298 39.4288C38.4712 39.473 38.62 39.4888 38.7675 39.4755Z"
              fill="#00BA77"
            />
            <path
              d="M46.0014 38.8258C46.2235 38.8058 46.4347 38.7203 46.6083 38.5802C46.7818 38.44 46.91 38.2516 46.9764 38.0387C47.0429 37.8258 47.0448 37.5979 46.9818 37.384C46.9188 37.17 46.7937 36.9795 46.6225 36.8366C46.4512 36.6937 46.2414 36.6048 46.0197 36.5811C45.7979 36.5574 45.574 36.6 45.3764 36.7035C45.1789 36.807 45.0164 36.9668 44.9096 37.1626C44.8028 37.3584 44.7565 37.5815 44.7765 37.8037C44.7898 37.9512 44.832 38.0947 44.9008 38.2259C44.9695 38.3572 45.0634 38.4736 45.1772 38.5685C45.2909 38.6634 45.4223 38.735 45.5637 38.7792C45.7051 38.8233 45.8538 38.8392 46.0014 38.8258Z"
              fill="#00BA77"
            />
            <path
              d="M53.2382 38.1764C53.4608 38.1566 53.6726 38.0713 53.8466 37.9312C54.0207 37.7911 54.1493 37.6025 54.2162 37.3892C54.2831 37.176 54.2852 36.9477 54.2223 36.7333C54.1594 36.5188 54.0343 36.3279 53.8629 36.1845C53.6914 36.0412 53.4813 35.952 53.2591 35.9281C53.0369 35.9042 52.8126 35.9468 52.6146 36.0504C52.4166 36.154 52.2538 36.314 52.1468 36.5102C52.0397 36.7064 51.9933 36.9299 52.0133 37.1525C52.0402 37.4506 52.1842 37.7259 52.4139 37.9179C52.6436 38.1099 52.94 38.2028 53.2382 38.1764Z"
              fill="#00BA77"
            />
            <path
              d="M60.476 37.5316C60.6986 37.5119 60.9103 37.4265 61.0844 37.2864C61.2585 37.1463 61.3871 36.9577 61.454 36.7445C61.5209 36.5312 61.523 36.3029 61.4601 36.0885C61.3972 35.8741 61.2721 35.6831 61.1006 35.5398C60.9292 35.3965 60.7191 35.3072 60.4969 35.2833C60.2747 35.2594 60.0504 35.302 59.8524 35.4056C59.6544 35.5092 59.4916 35.6693 59.3846 35.8655C59.2775 36.0616 59.2311 36.2851 59.2511 36.5077C59.278 36.8059 59.422 37.0811 59.6517 37.2731C59.8814 37.4651 60.1778 37.5581 60.476 37.5316Z"
              fill="#00BA77"
            />
            <path
              d="M67.7133 36.8749C67.9355 36.8548 68.1466 36.7693 68.3202 36.6292C68.4938 36.4891 68.6219 36.3007 68.6883 36.0878C68.7548 35.8749 68.7567 35.647 68.6937 35.4331C68.6307 35.2191 68.5056 35.0286 68.3344 34.8857C68.1631 34.7428 67.9533 34.6539 67.7316 34.6302C67.5098 34.6065 67.2859 34.6491 67.0884 34.7526C66.8908 34.8561 66.7283 35.0158 66.6215 35.2117C66.5148 35.4075 66.4684 35.6306 66.4884 35.8527C66.5015 36.0004 66.5436 36.144 66.6122 36.2753C66.6809 36.4067 66.7748 36.5232 66.8886 36.6181C67.0024 36.7131 67.1339 36.7847 67.2754 36.8287C67.4169 36.8728 67.5657 36.8885 67.7133 36.8749Z"
              fill="#00BA77"
            />
            <path
              d="M74.9496 36.2257C75.1718 36.2057 75.383 36.1202 75.5565 35.9801C75.7301 35.8399 75.8582 35.6515 75.9247 35.4386C75.9912 35.2257 75.993 34.9978 75.93 34.7839C75.867 34.5699 75.742 34.3794 75.5707 34.2365C75.3995 34.0936 75.1897 34.0047 74.9679 33.981C74.7461 33.9573 74.5223 33.9999 74.3247 34.1034C74.1271 34.2069 73.9647 34.3667 73.8579 34.5625C73.7511 34.7583 73.7048 34.9814 73.7248 35.2036C73.738 35.3511 73.7803 35.4946 73.849 35.6259C73.9178 35.7571 74.0117 35.8735 74.1254 35.9684C74.2392 36.0633 74.3705 36.1349 74.5119 36.1791C74.6534 36.2232 74.8021 36.2391 74.9496 36.2257Z"
              fill="#00BA77"
            />
            <path
              d="M82.1874 35.5761C82.4096 35.556 82.6208 35.4705 82.7943 35.3304C82.9679 35.1903 83.096 35.0019 83.1625 34.7889C83.2289 34.576 83.2308 34.3482 83.1678 34.1342C83.1048 33.9203 82.9798 33.7298 82.8085 33.5869C82.6373 33.444 82.4275 33.355 82.2057 33.3313C81.9839 33.3076 81.7601 33.3502 81.5625 33.4537C81.3649 33.5572 81.2025 33.717 81.0957 33.9128C80.9889 34.1087 80.9426 34.3318 80.9625 34.5539C80.9758 34.7015 81.018 34.845 81.0868 34.9762C81.1555 35.1074 81.2495 35.2238 81.3632 35.3188C81.477 35.4137 81.6083 35.4853 81.7497 35.5294C81.8911 35.5736 82.0399 35.5894 82.1874 35.5761Z"
              fill="#00BA77"
            />
            <path
              d="M89.4241 34.933C89.6462 34.913 89.8573 34.8276 90.0309 34.6876C90.2044 34.5475 90.3326 34.3592 90.3991 34.1464C90.4657 33.9336 90.4677 33.7058 90.4048 33.4919C90.342 33.2779 90.2171 33.0874 90.046 32.9444C89.875 32.8014 89.6653 32.7123 89.4436 32.6884C89.2219 32.6645 88.9981 32.7068 88.8005 32.8101C88.6028 32.9133 88.4402 33.0728 88.3332 33.2684C88.2262 33.4641 88.1796 33.687 88.1992 33.9091C88.226 34.2073 88.3701 34.4826 88.5998 34.6745C88.8294 34.8665 89.1259 34.9595 89.4241 34.933Z"
              fill="#00BA77"
            />
            <path
              d="M96.663 34.2763C96.8856 34.2565 97.0974 34.1712 97.2714 34.031C97.4455 33.8909 97.5742 33.7023 97.641 33.4891C97.7079 33.2759 97.71 33.0476 97.6471 32.8331C97.5842 32.6187 97.4591 32.4277 97.2877 32.2844C97.1162 32.1411 96.9061 32.0518 96.6839 32.028C96.4617 32.0041 96.2374 32.0466 96.0394 32.1503C95.8414 32.2539 95.6786 32.4139 95.5716 32.6101C95.4646 32.8062 95.4181 33.0298 95.4381 33.2523C95.4512 33.4 95.4933 33.5437 95.5619 33.6752C95.6306 33.8066 95.7245 33.9232 95.8383 34.0183C95.952 34.1134 96.0834 34.1851 96.225 34.2294C96.3665 34.2737 96.5153 34.2896 96.663 34.2763Z"
              fill="#00BA77"
            />
            <path
              d="M103.899 33.6266C104.122 33.6068 104.334 33.5215 104.508 33.3814C104.682 33.2413 104.81 33.0527 104.877 32.8394C104.944 32.6262 104.946 32.3979 104.883 32.1835C104.821 31.969 104.695 31.7781 104.524 31.6347C104.353 31.4914 104.142 31.4022 103.92 31.3783C103.698 31.3544 103.474 31.397 103.276 31.5006C103.078 31.6042 102.915 31.7642 102.808 31.9604C102.701 32.1566 102.654 32.3801 102.674 32.6027C102.688 32.7504 102.73 32.8941 102.798 33.0255C102.867 33.1569 102.961 33.2735 103.075 33.3686C103.188 33.4637 103.32 33.5355 103.461 33.5797C103.603 33.624 103.752 33.6399 103.899 33.6266Z"
              fill="#00BA77"
            />
            <path
              d="M111.136 32.9753C111.358 32.9552 111.569 32.8697 111.743 32.7296C111.916 32.5895 112.044 32.401 112.111 32.1881C112.177 31.9752 112.179 31.7474 112.116 31.5334C112.053 31.3194 111.928 31.1289 111.757 30.986C111.585 30.8431 111.376 30.7542 111.154 30.7305C110.932 30.7068 110.708 30.7494 110.511 30.8529C110.313 30.9564 110.151 31.1162 110.044 31.312C109.937 31.5078 109.891 31.7309 109.911 31.9531C109.924 32.1006 109.966 32.2441 110.035 32.3754C110.104 32.5066 110.198 32.623 110.311 32.7179C110.425 32.8128 110.557 32.8844 110.698 32.9286C110.839 32.9727 110.988 32.9886 111.136 32.9753Z"
              fill="#00BA77"
            />
            <path
              d="M118.375 32.3344C118.598 32.3143 118.809 32.2288 118.982 32.0887C119.156 31.9486 119.284 31.7602 119.35 31.5473C119.417 31.3343 119.419 31.1065 119.356 30.8925C119.293 30.6786 119.168 30.4881 118.997 30.3452C118.825 30.2023 118.615 30.1133 118.394 30.0896C118.172 30.0659 117.948 30.1085 117.75 30.212C117.553 30.3156 117.39 30.4753 117.284 30.6711C117.177 30.867 117.131 31.0901 117.151 31.3122C117.164 31.4598 117.206 31.6033 117.275 31.7345C117.344 31.8657 117.437 31.9821 117.551 32.0771C117.665 32.172 117.796 32.2436 117.938 32.2877C118.079 32.3319 118.228 32.3477 118.375 32.3344Z"
              fill="#00BA77"
            />
            <path
              d="M125.611 31.6757C125.833 31.6556 126.045 31.5701 126.218 31.43C126.392 31.2899 126.52 31.1015 126.586 30.8886C126.653 30.6756 126.655 30.4478 126.592 30.2338C126.529 30.0199 126.404 29.8294 126.232 29.6865C126.061 29.5436 125.851 29.4546 125.63 29.4309C125.408 29.4072 125.184 29.4498 124.986 29.5534C124.789 29.6569 124.626 29.8166 124.519 30.0124C124.413 30.2083 124.366 30.4314 124.386 30.6535C124.414 30.9513 124.558 31.2262 124.788 31.4178C125.017 31.6094 125.313 31.7022 125.611 31.6757Z"
              fill="#00BA77"
            />
            <path
              d="M132.843 31.026C133.065 31.006 133.276 30.9205 133.45 30.7803C133.623 30.6402 133.751 30.4518 133.818 30.2389C133.884 30.026 133.886 29.7981 133.823 29.5842C133.76 29.3702 133.635 29.1797 133.464 29.0368C133.293 28.8939 133.083 28.805 132.861 28.7813C132.639 28.7576 132.415 28.8002 132.218 28.9037C132.02 29.0072 131.858 29.167 131.751 29.3628C131.644 29.5586 131.598 29.7817 131.618 30.0039C131.631 30.1514 131.673 30.2949 131.742 30.4261C131.811 30.5574 131.905 30.6738 132.019 30.7687C132.132 30.8636 132.264 30.9352 132.405 30.9794C132.546 31.0235 132.695 31.0394 132.843 31.026Z"
              fill="#00BA77"
            />
            <path
              d="M140.086 30.3766C140.309 30.3568 140.521 30.2715 140.695 30.1314C140.869 29.9913 140.997 29.8027 141.064 29.5894C141.131 29.3762 141.133 29.1479 141.07 28.9335C141.008 28.719 140.882 28.5281 140.711 28.3848C140.54 28.2414 140.329 28.1522 140.107 28.1283C139.885 28.1044 139.661 28.147 139.463 28.2506C139.265 28.3542 139.102 28.5142 138.995 28.7104C138.888 28.9066 138.841 29.1301 138.861 29.3527C138.875 29.5004 138.917 29.6441 138.985 29.7755C139.054 29.9069 139.148 30.0235 139.262 30.1186C139.375 30.2137 139.507 30.2855 139.648 30.3297C139.79 30.374 139.939 30.3899 140.086 30.3766Z"
              fill="#00BA77"
            />
            <path
              d="M147.324 29.7355C147.546 29.7157 147.758 29.6304 147.932 29.4903C148.106 29.3502 148.235 29.1615 148.302 28.9483C148.369 28.7351 148.371 28.5068 148.308 28.2924C148.245 28.0779 148.12 27.887 147.948 27.7436C147.777 27.6003 147.567 27.5111 147.345 27.4872C147.122 27.4633 146.898 27.5059 146.7 27.6095C146.502 27.7131 146.339 27.8731 146.232 28.0693C146.125 28.2655 146.079 28.489 146.099 28.7116C146.126 29.0097 146.27 29.285 146.499 29.477C146.729 29.669 147.026 29.7619 147.324 29.7355Z"
              fill="#00BA77"
            />
            <path
              d="M154.561 29.0756C154.784 29.0555 154.995 28.97 155.168 28.8299C155.342 28.6898 155.47 28.5014 155.536 28.2885C155.603 28.0756 155.605 27.8477 155.542 27.6337C155.479 27.4198 155.354 27.2293 155.183 27.0864C155.011 26.9435 154.801 26.8545 154.58 26.8308C154.358 26.8072 154.134 26.8497 153.936 26.9533C153.739 27.0568 153.576 27.2165 153.47 27.4124C153.363 27.6082 153.317 27.8313 153.337 28.0534C153.35 28.2011 153.392 28.3447 153.46 28.476C153.529 28.6074 153.623 28.7239 153.737 28.8188C153.851 28.9138 153.982 28.9853 154.124 29.0294C154.265 29.0735 154.414 29.0892 154.561 29.0756Z"
              fill="#00BA77"
            />
            <path
              d="M161.798 28.4255C162.02 28.4054 162.231 28.3199 162.405 28.1798C162.578 28.0397 162.706 27.8512 162.773 27.6383C162.839 27.4254 162.841 27.1976 162.778 26.9836C162.715 26.7696 162.59 26.5792 162.419 26.4362C162.248 26.2933 162.038 26.2044 161.816 26.1807C161.594 26.157 161.37 26.1996 161.173 26.3031C160.975 26.4066 160.813 26.5664 160.706 26.7622C160.599 26.958 160.553 27.1811 160.573 27.4033C160.6 27.7011 160.744 27.976 160.974 28.1676C161.204 28.3592 161.5 28.4519 161.798 28.4255Z"
              fill="#00BA77"
            />
            <path
              d="M169.037 27.7758C169.259 27.7557 169.47 27.6702 169.643 27.5301C169.817 27.39 169.945 27.2016 170.012 26.9887C170.078 26.7758 170.08 26.5479 170.017 26.3339C169.954 26.12 169.829 25.9295 169.658 25.7866C169.486 25.6437 169.277 25.5547 169.055 25.531C168.833 25.5073 168.609 25.5499 168.412 25.6535C168.214 25.757 168.052 25.9167 167.945 26.1125C167.838 26.3084 167.792 26.5315 167.812 26.7536C167.825 26.9012 167.867 27.0447 167.936 27.1759C168.005 27.3071 168.099 27.4235 168.212 27.5185C168.326 27.6134 168.457 27.685 168.599 27.7291C168.74 27.7733 168.889 27.7891 169.037 27.7758Z"
              fill="#00BA77"
            />
            <path
              d="M176.273 27.1262C176.495 27.1061 176.706 27.0207 176.88 26.8807C177.053 26.7407 177.181 26.5523 177.248 26.3395C177.314 26.1267 177.316 25.8989 177.253 25.685C177.191 25.4711 177.066 25.2805 176.895 25.1375C176.724 24.9945 176.514 24.9054 176.292 24.8815C176.071 24.8576 175.847 24.8999 175.649 25.0032C175.451 25.1064 175.289 25.2659 175.182 25.4616C175.075 25.6572 175.028 25.8801 175.048 26.1023C175.061 26.25 175.103 26.3936 175.172 26.5251C175.24 26.6565 175.334 26.7731 175.448 26.8682C175.562 26.9633 175.693 27.035 175.835 27.0793C175.976 27.1236 176.125 27.1395 176.273 27.1262Z"
              fill="#00BA77"
            />
            <path
              d="M183.51 26.4764C183.732 26.4567 183.944 26.3714 184.118 26.2312C184.292 26.0911 184.421 25.9025 184.488 25.6893C184.555 25.476 184.557 25.2478 184.494 25.0333C184.431 24.8189 184.306 24.6279 184.134 24.4846C183.963 24.3413 183.753 24.252 183.531 24.2281C183.308 24.2043 183.084 24.2468 182.886 24.3505C182.688 24.4541 182.525 24.6141 182.418 24.8103C182.311 25.0065 182.265 25.23 182.285 25.4525C182.298 25.6002 182.34 25.7439 182.409 25.8754C182.477 26.0068 182.571 26.1234 182.685 26.2185C182.799 26.3136 182.93 26.3853 183.072 26.4296C183.213 26.4739 183.362 26.4898 183.51 26.4764Z"
              fill="#00BA77"
            />
            <path
              d="M190.747 25.827C190.97 25.8073 191.182 25.7219 191.356 25.5818C191.53 25.4417 191.659 25.2531 191.725 25.0399C191.792 24.8266 191.794 24.5984 191.732 24.3839C191.669 24.1695 191.544 23.9785 191.372 23.8352C191.201 23.6919 190.991 23.6026 190.768 23.5787C190.546 23.5549 190.322 23.5974 190.124 23.701C189.926 23.8047 189.763 23.9647 189.656 24.1609C189.549 24.357 189.503 24.5806 189.523 24.8031C189.549 25.1013 189.694 25.3765 189.923 25.5685C190.153 25.7605 190.449 25.8535 190.747 25.827Z"
              fill="#00BA77"
            />
            <path
              d="M197.985 25.1757C198.207 25.1556 198.418 25.0701 198.592 24.93C198.765 24.7899 198.893 24.6015 198.96 24.3886C199.026 24.1757 199.028 23.9478 198.965 23.7338C198.902 23.5199 198.777 23.3294 198.606 23.1865C198.435 23.0436 198.225 22.9546 198.003 22.9309C197.781 22.9072 197.557 22.9498 197.36 23.0534C197.162 23.1569 197 23.3166 196.893 23.5125C196.786 23.7083 196.74 23.9314 196.76 24.1535C196.787 24.4514 196.931 24.7262 197.161 24.9178C197.391 25.1094 197.687 25.2022 197.985 25.1757Z"
              fill="#00BA77"
            />
            <path
              d="M205.224 24.526C205.446 24.506 205.657 24.4205 205.83 24.2803C206.004 24.1402 206.132 23.9518 206.199 23.7389C206.265 23.526 206.267 23.2982 206.204 23.0842C206.141 22.8702 206.016 22.6797 205.845 22.5368C205.673 22.3939 205.464 22.305 205.242 22.2813C205.02 22.2576 204.796 22.3002 204.599 22.4037C204.401 22.5072 204.239 22.667 204.132 22.8628C204.025 23.0586 203.979 23.2817 203.999 23.5039C204.012 23.6514 204.054 23.7949 204.123 23.9261C204.192 24.0574 204.286 24.1738 204.399 24.2687C204.513 24.3636 204.644 24.4352 204.786 24.4794C204.927 24.5235 205.076 24.5394 205.224 24.526Z"
              fill="#00BA77"
            />
            <path
              d="M212.46 23.8764C212.682 23.8563 212.893 23.7708 213.067 23.6307C213.24 23.4906 213.368 23.3022 213.435 23.0892C213.501 22.8763 213.503 22.6485 213.44 22.4345C213.377 22.2206 213.252 22.0301 213.081 21.8872C212.91 21.7443 212.7 21.6553 212.478 21.6316C212.256 21.6079 212.033 21.6505 211.835 21.754C211.637 21.8575 211.475 22.0173 211.368 22.2131C211.261 22.409 211.215 22.6321 211.235 22.8542C211.248 23.0018 211.291 23.1453 211.359 23.2765C211.428 23.4077 211.522 23.5241 211.636 23.619C211.749 23.714 211.881 23.7856 212.022 23.8297C212.164 23.8739 212.312 23.8897 212.46 23.8764Z"
              fill="#00BA77"
            />
            <path
              d="M219.697 23.2267C219.919 23.2066 220.131 23.1211 220.304 22.981C220.478 22.8409 220.606 22.6525 220.672 22.4396C220.739 22.2267 220.741 21.9988 220.678 21.7849C220.615 21.5709 220.49 21.3804 220.318 21.2375C220.147 21.0946 219.937 21.0057 219.715 20.982C219.494 20.9583 219.27 21.0009 219.072 21.1044C218.875 21.2079 218.712 21.3677 218.605 21.5635C218.499 21.7593 218.452 21.9824 218.472 22.2046C218.486 22.3521 218.528 22.4956 218.597 22.6268C218.665 22.7581 218.759 22.8745 218.873 22.9694C218.987 23.0643 219.118 23.1359 219.259 23.18C219.401 23.2242 219.55 23.2401 219.697 23.2267Z"
              fill="#00BA77"
            />
            <path
              d="M226.935 22.5765C227.158 22.5568 227.369 22.4715 227.543 22.3313C227.718 22.1912 227.846 22.0026 227.913 21.7894C227.98 21.5761 227.982 21.3479 227.919 21.1334C227.856 20.919 227.731 20.728 227.56 20.5847C227.388 20.4414 227.178 20.3521 226.956 20.3282C226.734 20.3044 226.509 20.3469 226.311 20.4506C226.113 20.5542 225.951 20.7142 225.844 20.9104C225.737 21.1065 225.69 21.3301 225.71 21.5526C225.723 21.7003 225.765 21.844 225.834 21.9755C225.903 22.1069 225.996 22.2235 226.11 22.3186C226.224 22.4137 226.355 22.4854 226.497 22.5297C226.638 22.574 226.787 22.5899 226.935 22.5765Z"
              fill="#00BA77"
            />
            <path
              d="M234.172 21.9269C234.394 21.9071 234.606 21.8218 234.78 21.6817C234.954 21.5416 235.083 21.353 235.15 21.1397C235.217 20.9265 235.219 20.6982 235.156 20.4838C235.093 20.2693 234.968 20.0784 234.796 19.935C234.625 19.7917 234.415 19.7025 234.193 19.6786C233.97 19.6547 233.746 19.6973 233.548 19.8009C233.35 19.9045 233.187 20.0645 233.08 20.2607C232.973 20.4569 232.927 20.6804 232.947 20.903C232.974 21.2009 233.118 21.4759 233.348 21.6678C233.578 21.8597 233.874 21.9529 234.172 21.9269Z"
              fill="#00BA77"
            />
            <path
              d="M241.41 21.2758C241.632 21.2557 241.843 21.1702 242.017 21.0301C242.19 20.89 242.318 20.7016 242.385 20.4887C242.451 20.2758 242.453 20.0479 242.39 19.8339C242.327 19.62 242.202 19.4295 242.031 19.2866C241.859 19.1437 241.65 19.0547 241.428 19.031C241.206 19.0073 240.982 19.0499 240.785 19.1535C240.587 19.257 240.425 19.4167 240.318 19.6126C240.211 19.8084 240.165 20.0315 240.185 20.2536C240.198 20.4013 240.24 20.5449 240.309 20.6762C240.377 20.8076 240.471 20.9241 240.585 21.019C240.699 21.114 240.83 21.1856 240.972 21.2296C241.113 21.2737 241.262 21.2894 241.41 21.2758Z"
              fill="#00BA77"
            />
            <path
              d="M248.647 20.6261C248.869 20.6061 249.08 20.5206 249.254 20.3804C249.427 20.2403 249.555 20.0519 249.622 19.839C249.688 19.6261 249.69 19.3983 249.627 19.1843C249.564 18.9703 249.439 18.7798 249.268 18.6369C249.097 18.494 248.887 18.4051 248.665 18.3814C248.443 18.3577 248.22 18.4003 248.022 18.5038C247.824 18.6073 247.662 18.7671 247.555 18.9629C247.448 19.1587 247.402 19.3818 247.422 19.604C247.435 19.7516 247.477 19.8952 247.546 20.0266C247.615 20.1579 247.708 20.2744 247.822 20.3694C247.936 20.4643 248.067 20.5359 248.209 20.58C248.35 20.624 248.499 20.6397 248.647 20.6261Z"
              fill="#00BA77"
            />
            <path
              d="M255.883 19.9757C256.105 19.9557 256.317 19.8702 256.49 19.7301C256.664 19.5899 256.792 19.4015 256.858 19.1886C256.925 18.9757 256.927 18.7479 256.864 18.5339C256.801 18.3199 256.676 18.1294 256.504 17.9865C256.333 17.8436 256.123 17.7547 255.901 17.731C255.68 17.7073 255.456 17.7499 255.258 17.8534C255.061 17.9569 254.898 18.1167 254.791 18.3125C254.685 18.5083 254.638 18.7314 254.658 18.9536C254.672 19.1011 254.714 19.2446 254.783 19.3759C254.851 19.5071 254.945 19.6235 255.059 19.7184C255.173 19.8133 255.304 19.8849 255.446 19.9291C255.587 19.9732 255.736 19.9891 255.883 19.9757Z"
              fill="#00BA77"
            />
            <path
              d="M263.127 19.3268C263.349 19.3068 263.56 19.2214 263.734 19.0813C263.908 18.9413 264.036 18.753 264.102 18.5402C264.169 18.3274 264.171 18.0996 264.108 17.8857C264.045 17.6717 263.92 17.4812 263.749 17.3382C263.578 17.1952 263.368 17.1061 263.147 17.0822C262.925 17.0583 262.701 17.1006 262.504 17.2039C262.306 17.3071 262.143 17.4666 262.036 17.6622C261.929 17.8578 261.883 18.0808 261.902 18.3029C261.915 18.4506 261.957 18.5943 262.026 18.7257C262.095 18.8572 262.189 18.9738 262.302 19.0689C262.416 19.164 262.548 19.2357 262.689 19.28C262.831 19.3242 262.98 19.3402 263.127 19.3268Z"
              fill="#00BA77"
            />
            <path
              d="M270.358 18.6766C270.581 18.6569 270.793 18.5716 270.967 18.4314C271.141 18.2913 271.269 18.1027 271.336 17.8895C271.403 17.6762 271.405 17.448 271.342 17.2335C271.28 17.0191 271.154 16.8281 270.983 16.6848C270.812 16.5415 270.601 16.4522 270.379 16.4283C270.157 16.4045 269.933 16.447 269.735 16.5506C269.537 16.6543 269.374 16.8143 269.267 17.0105C269.16 17.2066 269.113 17.4302 269.133 17.6527C269.16 17.9509 269.304 18.2262 269.534 18.4181C269.764 18.6101 270.06 18.7031 270.358 18.6766Z"
              fill="#00BA77"
            />
            <path
              d="M277.597 18.027C277.82 18.0072 278.031 17.9219 278.206 17.7818C278.38 17.6417 278.508 17.453 278.575 17.2398C278.642 17.0266 278.644 16.7983 278.581 16.5839C278.518 16.3694 278.393 16.1785 278.222 16.0351C278.05 15.8918 277.84 15.8026 277.618 15.7787C277.396 15.7548 277.171 15.7974 276.974 15.901C276.776 16.0046 276.613 16.1646 276.506 16.3608C276.399 16.557 276.352 16.7805 276.372 17.0031C276.399 17.3012 276.543 17.5765 276.773 17.7685C277.002 17.9605 277.299 18.0534 277.597 18.027Z"
              fill="#00BA77"
            />
            <path
              d="M284.833 17.3759C285.056 17.3558 285.267 17.2703 285.44 17.1302C285.614 16.9901 285.742 16.8017 285.808 16.5888C285.875 16.3758 285.877 16.148 285.814 15.934C285.751 15.7201 285.626 15.5296 285.455 15.3867C285.283 15.2438 285.073 15.1548 284.852 15.1311C284.63 15.1074 284.406 15.15 284.208 15.2535C284.011 15.3571 283.848 15.5168 283.742 15.7126C283.635 15.9085 283.589 16.1316 283.609 16.3537C283.622 16.5014 283.664 16.645 283.732 16.7763C283.801 16.9077 283.895 17.0241 284.009 17.1191C284.123 17.2141 284.254 17.2856 284.396 17.3297C284.537 17.3738 284.686 17.3895 284.833 17.3759Z"
              fill="#00BA77"
            />
            <path
              d="M292.07 16.7264C292.293 16.7067 292.504 16.6215 292.678 16.4817C292.851 16.3419 292.98 16.1536 293.047 15.9408C293.114 15.728 293.116 15.5001 293.053 15.2861C292.99 15.072 292.866 14.8813 292.695 14.7381C292.524 14.5949 292.314 14.5057 292.092 14.4817C291.87 14.4576 291.646 14.4999 291.449 14.6031C291.251 14.7063 291.088 14.8659 290.981 15.0616C290.874 15.2573 290.827 15.4803 290.847 15.7025C290.86 15.85 290.902 15.9936 290.971 16.1249C291.039 16.2562 291.133 16.3727 291.247 16.4678C291.36 16.5629 291.491 16.6347 291.633 16.679C291.774 16.7234 291.923 16.7395 292.07 16.7264Z"
              fill="#00BA77"
            />
            <path
              d="M39.4145 46.714C39.6371 46.6942 39.8488 46.6089 40.0229 46.4688C40.197 46.3287 40.3256 46.1401 40.3925 45.9268C40.4594 45.7136 40.4615 45.4853 40.3986 45.2709C40.3357 45.0564 40.2106 44.8655 40.0391 44.7222C39.8677 44.5788 39.6575 44.4896 39.4354 44.4657C39.2132 44.4418 38.9889 44.4844 38.7909 44.588C38.5929 44.6916 38.4301 44.8516 38.323 45.0478C38.216 45.244 38.1696 45.4675 38.1896 45.6901C38.2164 45.9882 38.3605 46.2635 38.5902 46.4555C38.8198 46.6475 39.1163 46.7405 39.4145 46.714Z"
              fill="#00BA77"
            />
            <path
              d="M46.6513 46.0629C46.8734 46.0428 47.0846 45.9573 47.2582 45.8172C47.4318 45.6771 47.5599 45.4887 47.6263 45.2758C47.6928 45.0629 47.6947 44.835 47.6317 44.6211C47.5687 44.4071 47.4436 44.2166 47.2724 44.0737C47.1011 43.9308 46.8913 43.8418 46.6696 43.8182C46.4478 43.7945 46.2239 43.8371 46.0264 43.9406C45.8288 44.0441 45.6663 44.2038 45.5595 44.3997C45.4528 44.5955 45.4064 44.8186 45.4264 45.0407C45.4395 45.1884 45.4816 45.332 45.5502 45.4633C45.6189 45.5947 45.7128 45.7112 45.8266 45.8061C45.9404 45.9011 46.0719 45.9727 46.2134 46.0167C46.3549 46.0608 46.5037 46.0765 46.6513 46.0629Z"
              fill="#00BA77"
            />
            <path
              d="M53.8876 45.4128C54.1098 45.3927 54.321 45.3072 54.4945 45.1671C54.6681 45.027 54.7962 44.8385 54.8627 44.6256C54.9291 44.4127 54.931 44.1849 54.868 43.9709C54.805 43.7569 54.68 43.5665 54.5087 43.4235C54.3374 43.2806 54.1277 43.1917 53.9059 43.168C53.6841 43.1443 53.4603 43.1869 53.2627 43.2904C53.0651 43.3939 52.9026 43.5537 52.7959 43.7495C52.6891 43.9453 52.6428 44.1684 52.6627 44.3906C52.676 44.5381 52.7182 44.6816 52.787 44.8129C52.8557 44.9441 52.9497 45.0605 53.0634 45.1554C53.1772 45.2503 53.3085 45.3219 53.4499 45.3661C53.5913 45.4102 53.7401 45.4261 53.8876 45.4128Z"
              fill="#00BA77"
            />
            <path
              d="M61.1254 44.7631C61.3476 44.743 61.5588 44.6575 61.7323 44.5174C61.9059 44.3773 62.034 44.1889 62.1005 43.976C62.1669 43.7631 62.1688 43.5352 62.1058 43.3212C62.0428 43.1073 61.9177 42.9168 61.7465 42.7739C61.5752 42.631 61.3655 42.542 61.1437 42.5183C60.9219 42.4947 60.6981 42.5372 60.5005 42.6408C60.3029 42.7443 60.1404 42.904 60.0337 43.0999C59.9269 43.2957 59.8805 43.5188 59.9005 43.7409C59.9138 43.8885 59.956 44.032 60.0248 44.1632C60.0935 44.2944 60.1875 44.4108 60.3012 44.5058C60.415 44.6007 60.5463 44.6723 60.6877 44.7164C60.8291 44.7606 60.9779 44.7764 61.1254 44.7631Z"
              fill="#00BA77"
            />
            <path
              d="M68.3627 44.1154C68.5853 44.0956 68.7971 44.0103 68.9712 43.8702C69.1452 43.73 69.2739 43.5414 69.3407 43.3282C69.4076 43.115 69.4097 42.8867 69.3468 42.6722C69.2839 42.4578 69.1588 42.2669 68.9874 42.1235C68.8159 41.9802 68.6058 41.8909 68.3836 41.8671C68.1614 41.8432 67.9371 41.8857 67.7391 41.9894C67.5411 42.093 67.3783 42.253 67.2713 42.4492C67.1642 42.6454 67.1178 42.8689 67.1378 43.0915C67.1647 43.3896 67.3088 43.6649 67.5384 43.8569C67.7681 44.0488 68.0646 44.1418 68.3627 44.1154Z"
              fill="#00BA77"
            />
            <path
              d="M75.6005 43.464C75.8231 43.4442 76.0349 43.3589 76.2089 43.2188C76.383 43.0787 76.5116 42.8901 76.5785 42.6768C76.6454 42.4636 76.6475 42.2353 76.5846 42.0209C76.5217 41.8064 76.3966 41.6155 76.2252 41.4722C76.0537 41.3288 75.8436 41.2396 75.6214 41.2157C75.3992 41.1918 75.1749 41.2344 74.9769 41.338C74.7789 41.4416 74.6161 41.6016 74.5091 41.7978C74.402 41.994 74.3556 42.2175 74.3756 42.4401C74.3887 42.5878 74.4308 42.7315 74.4994 42.8629C74.5681 42.9943 74.662 43.1109 74.7757 43.206C74.8895 43.3011 75.0209 43.3729 75.1624 43.4171C75.304 43.4614 75.4528 43.4773 75.6005 43.464Z"
              fill="#00BA77"
            />
            <path
              d="M82.8373 42.8124C83.0595 42.7923 83.2707 42.7068 83.4442 42.5667C83.6178 42.4266 83.7459 42.2382 83.8124 42.0253C83.8788 41.8124 83.8807 41.5845 83.8177 41.3706C83.7547 41.1566 83.6297 40.9661 83.4584 40.8232C83.2872 40.6803 83.0774 40.5914 82.8556 40.5677C82.6338 40.544 82.41 40.5866 82.2124 40.6901C82.0148 40.7936 81.8524 40.9533 81.7456 41.1492C81.6388 41.345 81.5925 41.5681 81.6125 41.7902C81.6255 41.9379 81.6676 42.0815 81.7363 42.2128C81.8049 42.3442 81.8989 42.4607 82.0127 42.5556C82.1265 42.6506 82.2579 42.7222 82.3994 42.7662C82.5409 42.8103 82.6897 42.826 82.8373 42.8124Z"
              fill="#00BA77"
            />
            <path
              d="M90.0737 42.1625C90.2958 42.1424 90.507 42.0569 90.6806 41.9168C90.8541 41.7767 90.9822 41.5883 91.0487 41.3754C91.1152 41.1625 91.117 40.9346 91.054 40.7207C90.991 40.5067 90.866 40.3162 90.6947 40.1733C90.5235 40.0304 90.3137 39.9415 90.0919 39.9178C89.8701 39.8941 89.6463 39.9367 89.4487 40.0402C89.2511 40.1437 89.0887 40.3034 88.9819 40.4993C88.8751 40.6951 88.8288 40.9182 88.8488 41.1403C88.8621 41.2879 88.9043 41.4314 88.973 41.5626C89.0418 41.6938 89.1357 41.8103 89.2495 41.9052C89.3632 42.0001 89.4945 42.0717 89.636 42.1158C89.7774 42.16 89.9261 42.1758 90.0737 42.1625Z"
              fill="#00BA77"
            />
            <path
              d="M97.3124 41.5163C97.5346 41.4962 97.7458 41.4107 97.9193 41.2706C98.0929 41.1305 98.221 40.9421 98.2875 40.7291C98.354 40.5162 98.3558 40.2884 98.2928 40.0744C98.2298 39.8605 98.1048 39.67 97.9335 39.5271C97.7623 39.3841 97.5525 39.2952 97.3307 39.2715C97.1089 39.2478 96.8851 39.2904 96.6875 39.3939C96.4899 39.4974 96.3275 39.6572 96.2207 39.853C96.1139 40.0489 96.0676 40.2719 96.0875 40.4941C96.1008 40.6417 96.1431 40.7851 96.2118 40.9164C96.2806 41.0476 96.3745 41.164 96.4882 41.2589C96.602 41.3539 96.7333 41.4254 96.8747 41.4696C97.0162 41.5138 97.1649 41.5296 97.3124 41.5163Z"
              fill="#00BA77"
            />
            <path
              d="M104.549 40.8637C104.771 40.8436 104.982 40.7581 105.156 40.618C105.329 40.4779 105.457 40.2895 105.524 40.0765C105.59 39.8636 105.592 39.6358 105.529 39.4218C105.466 39.2079 105.341 39.0174 105.17 38.8745C104.999 38.7315 104.789 38.6426 104.567 38.6189C104.345 38.5952 104.121 38.6378 103.924 38.7413C103.726 38.8448 103.564 39.0046 103.457 39.2004C103.35 39.3963 103.304 39.6194 103.324 39.8415C103.351 40.1393 103.495 40.4142 103.725 40.6058C103.955 40.7974 104.251 40.8902 104.549 40.8637Z"
              fill="#00BA77"
            />
            <path
              d="M111.788 40.2137C112.01 40.194 112.222 40.1087 112.396 39.9685C112.57 39.8284 112.699 39.6398 112.766 39.4266C112.832 39.2133 112.835 38.9851 112.772 38.7706C112.709 38.5562 112.584 38.3652 112.412 38.2219C112.241 38.0786 112.031 37.9893 111.808 37.9655C111.586 37.9416 111.362 37.9841 111.164 38.0878C110.966 38.1914 110.803 38.3514 110.696 38.5476C110.589 38.7437 110.543 38.9673 110.563 39.1898C110.576 39.3375 110.618 39.4812 110.686 39.6127C110.755 39.7441 110.849 39.8607 110.963 39.9558C111.077 40.0509 111.208 40.1226 111.349 40.1669C111.491 40.2112 111.64 40.2271 111.788 40.2137Z"
              fill="#00BA77"
            />
            <path
              d="M119.025 39.5638C119.247 39.5441 119.459 39.4588 119.633 39.3186C119.807 39.1785 119.936 38.9899 120.003 38.7767C120.07 38.5634 120.072 38.3352 120.009 38.1207C119.946 37.9063 119.821 37.7153 119.649 37.572C119.478 37.4287 119.268 37.3394 119.046 37.3156C118.824 37.2917 118.599 37.3342 118.401 37.4379C118.203 37.5415 118.04 37.7015 117.933 37.8977C117.826 38.0938 117.78 38.3174 117.8 38.5399C117.813 38.6876 117.855 38.8313 117.924 38.9627C117.992 39.0942 118.086 39.2108 118.2 39.3059C118.314 39.401 118.445 39.4727 118.587 39.517C118.728 39.5613 118.877 39.5772 119.025 39.5638Z"
              fill="#00BA77"
            />
            <path
              d="M126.259 38.9179C126.482 38.8981 126.694 38.8128 126.868 38.6726C127.042 38.5325 127.17 38.3439 127.237 38.1307C127.304 37.9175 127.306 37.6892 127.243 37.4747C127.18 37.2603 127.055 37.0693 126.884 36.926C126.712 36.7827 126.502 36.6934 126.28 36.6696C126.058 36.6457 125.834 36.6882 125.636 36.7919C125.438 36.8955 125.275 37.0555 125.168 37.2517C125.061 37.4479 125.014 37.6714 125.034 37.8939C125.061 38.1921 125.205 38.4674 125.435 38.6594C125.665 38.8513 125.961 38.9443 126.259 38.9179Z"
              fill="#00BA77"
            />
            <path
              d="M133.499 38.2628C133.722 38.2428 133.933 38.1573 134.106 38.0172C134.28 37.8771 134.408 37.6886 134.474 37.4757C134.541 37.2628 134.543 37.035 134.48 36.821C134.417 36.607 134.292 36.4165 134.121 36.2736C133.949 36.1307 133.739 36.0418 133.518 36.0181C133.296 35.9944 133.072 36.037 132.874 36.1405C132.677 36.244 132.514 36.4038 132.408 36.5996C132.301 36.7954 132.255 37.0185 132.275 37.2407C132.288 37.3882 132.33 37.5317 132.399 37.663C132.468 37.7942 132.561 37.9106 132.675 38.0055C132.789 38.1004 132.92 38.172 133.062 38.2162C133.203 38.2603 133.352 38.2762 133.499 38.2628Z"
              fill="#00BA77"
            />
            <path
              d="M140.736 37.6132C140.958 37.5931 141.169 37.5076 141.343 37.3675C141.516 37.2274 141.644 37.039 141.711 36.8261C141.777 36.6131 141.779 36.3853 141.716 36.1713C141.653 35.9574 141.528 35.7669 141.357 35.624C141.186 35.4811 140.976 35.3921 140.754 35.3684C140.532 35.3447 140.308 35.3873 140.111 35.4909C139.913 35.5944 139.751 35.7541 139.644 35.9499C139.537 36.1458 139.491 36.3689 139.511 36.591C139.538 36.8888 139.682 37.1637 139.912 37.3553C140.142 37.5469 140.438 37.6397 140.736 37.6132Z"
              fill="#00BA77"
            />
            <path
              d="M147.975 36.9633C148.197 36.9432 148.408 36.8577 148.581 36.7176C148.755 36.5775 148.883 36.3891 148.95 36.1762C149.016 35.9632 149.018 35.7354 148.955 35.5214C148.892 35.3075 148.767 35.117 148.596 34.9741C148.424 34.8312 148.215 34.7422 147.993 34.7185C147.771 34.6948 147.547 34.7374 147.35 34.841C147.152 34.9445 146.99 35.1042 146.883 35.3C146.776 35.4959 146.73 35.719 146.75 35.9411C146.763 36.0887 146.805 36.2322 146.874 36.3634C146.943 36.4946 147.037 36.611 147.15 36.706C147.264 36.8009 147.395 36.8725 147.537 36.9166C147.678 36.9608 147.827 36.9766 147.975 36.9633Z"
              fill="#00BA77"
            />
            <path
              d="M155.211 36.319C155.433 36.2992 155.645 36.2139 155.819 36.0738C155.993 35.9336 156.122 35.745 156.189 35.5318C156.256 35.3186 156.258 35.0903 156.195 34.8759C156.132 34.6614 156.007 34.4705 155.836 34.3271C155.664 34.1838 155.454 34.0946 155.232 34.0707C155.01 34.0468 154.785 34.0894 154.587 34.193C154.389 34.2966 154.226 34.4566 154.119 34.6528C154.012 34.849 153.966 35.0725 153.986 35.2951C153.999 35.4428 154.041 35.5865 154.11 35.7179C154.178 35.8493 154.272 35.9659 154.386 36.061C154.5 36.1561 154.631 36.2279 154.773 36.2721C154.914 36.3164 155.063 36.3323 155.211 36.319Z"
              fill="#00BA77"
            />
            <path
              d="M162.448 35.6642C162.67 35.6444 162.882 35.5591 163.056 35.419C163.23 35.2789 163.359 35.0903 163.426 34.877C163.493 34.6638 163.495 34.4355 163.432 34.2211C163.369 34.0066 163.244 33.8157 163.072 33.6723C162.901 33.529 162.691 33.4398 162.469 33.4159C162.246 33.392 162.022 33.4346 161.824 33.5382C161.626 33.6418 161.463 33.8018 161.356 33.998C161.249 34.1942 161.203 34.4177 161.223 34.6403C161.236 34.788 161.278 34.9317 161.347 35.0631C161.415 35.1945 161.509 35.3111 161.623 35.4062C161.737 35.5013 161.868 35.5731 162.01 35.6173C162.151 35.6616 162.3 35.6775 162.448 35.6642Z"
              fill="#00BA77"
            />
            <path
              d="M169.686 35.0145C169.909 34.9948 170.12 34.9094 170.294 34.7693C170.468 34.6292 170.597 34.4406 170.664 34.2274C170.731 34.0141 170.733 33.7859 170.67 33.5714C170.607 33.357 170.482 33.166 170.311 33.0227C170.139 32.8794 169.929 32.7901 169.707 32.7662C169.485 32.7424 169.26 32.7849 169.062 32.8885C168.864 32.9922 168.702 33.1522 168.595 33.3484C168.487 33.5445 168.441 33.768 168.461 33.9906C168.488 34.2888 168.632 34.564 168.862 34.756C169.091 34.948 169.388 35.041 169.686 35.0145Z"
              fill="#00BA77"
            />
            <path
              d="M176.923 34.363C177.145 34.3429 177.356 34.2574 177.53 34.1173C177.703 33.9772 177.831 33.7887 177.898 33.5758C177.964 33.3629 177.966 33.1351 177.903 32.9211C177.84 32.7071 177.715 32.5166 177.544 32.3737C177.373 32.2308 177.163 32.1419 176.941 32.1182C176.719 32.0945 176.495 32.1371 176.298 32.2406C176.1 32.3441 175.938 32.5039 175.831 32.6997C175.724 32.8955 175.678 33.1186 175.698 33.3408C175.725 33.6386 175.869 33.9135 176.099 34.1051C176.329 34.2967 176.625 34.3894 176.923 34.363Z"
              fill="#00BA77"
            />
            <path
              d="M184.162 33.7201C184.384 33.7 184.595 33.6145 184.768 33.4744C184.942 33.3343 185.07 33.1459 185.137 32.933C185.203 32.7201 185.205 32.4922 185.142 32.2783C185.079 32.0643 184.954 31.8738 184.783 31.7309C184.611 31.588 184.402 31.4991 184.18 31.4754C183.958 31.4517 183.734 31.4943 183.537 31.5978C183.339 31.7013 183.177 31.8611 183.07 32.0569C182.963 32.2527 182.917 32.4758 182.937 32.698C182.95 32.8455 182.992 32.989 183.061 33.1202C183.13 33.2515 183.224 33.3679 183.337 33.4628C183.451 33.5577 183.582 33.6293 183.724 33.6735C183.865 33.7176 184.014 33.7335 184.162 33.7201Z"
              fill="#00BA77"
            />
            <path
              d="M191.401 33.0636C191.623 33.0436 191.835 32.9581 192.008 32.8179C192.182 32.6778 192.31 32.4894 192.376 32.2765C192.443 32.0636 192.445 31.8357 192.382 31.6218C192.319 31.4078 192.194 31.2173 192.022 31.0744C191.851 30.9315 191.641 30.8426 191.42 30.8189C191.198 30.7952 190.974 30.8378 190.776 30.9413C190.579 31.0448 190.416 31.2046 190.31 31.4004C190.203 31.5962 190.156 31.8193 190.176 32.0415C190.19 32.189 190.232 32.3325 190.301 32.4637C190.369 32.595 190.463 32.7114 190.577 32.8063C190.691 32.9012 190.822 32.9728 190.964 33.017C191.105 33.0611 191.254 33.077 191.401 33.0636Z"
              fill="#00BA77"
            />
            <path
              d="M198.635 32.4137C198.858 32.394 199.07 32.3086 199.244 32.1685C199.418 32.0284 199.546 31.8398 199.613 31.6265C199.68 31.4133 199.682 31.185 199.619 30.9706C199.556 30.7561 199.431 30.5652 199.26 30.4219C199.088 30.2785 198.878 30.1893 198.656 30.1654C198.434 30.1415 198.21 30.1841 198.012 30.2877C197.814 30.3913 197.651 30.5513 197.544 30.7475C197.437 30.9437 197.39 31.1672 197.41 31.3898C197.423 31.5375 197.465 31.6812 197.534 31.8126C197.603 31.944 197.697 32.0606 197.81 32.1558C197.924 32.2509 198.056 32.3226 198.197 32.3668C198.339 32.4111 198.487 32.427 198.635 32.4137Z"
              fill="#00BA77"
            />
            <path
              d="M205.873 31.764C206.096 31.7443 206.307 31.659 206.481 31.5188C206.656 31.3787 206.784 31.1901 206.851 30.9769C206.918 30.7636 206.92 30.5354 206.857 30.3209C206.794 30.1065 206.669 29.9155 206.498 29.7722C206.326 29.6289 206.116 29.5396 205.894 29.5157C205.672 29.4919 205.447 29.5344 205.249 29.638C205.051 29.7417 204.889 29.9017 204.782 30.0979C204.675 30.294 204.628 30.5176 204.648 30.7401C204.661 30.8878 204.703 31.0315 204.772 31.163C204.841 31.2944 204.934 31.411 205.048 31.5061C205.162 31.6012 205.293 31.6729 205.435 31.7172C205.576 31.7615 205.725 31.7774 205.873 31.764Z"
              fill="#00BA77"
            />
            <path
              d="M213.109 31.1213C213.331 31.1012 213.543 31.0157 213.716 30.8756C213.89 30.7355 214.018 30.547 214.084 30.3341C214.151 30.1212 214.153 29.8934 214.09 29.6794C214.027 29.4654 213.902 29.275 213.73 29.132C213.559 28.9891 213.349 28.9002 213.128 28.8765C212.906 28.8528 212.682 28.8954 212.484 28.9989C212.287 29.1024 212.124 29.2622 212.018 29.458C211.911 29.6538 211.864 29.8769 211.884 30.0991C211.898 30.2466 211.94 30.3901 212.009 30.5214C212.077 30.6526 212.171 30.769 212.285 30.8639C212.399 30.9588 212.53 31.0304 212.672 31.0746C212.813 31.1187 212.962 31.1346 213.109 31.1213Z"
              fill="#00BA77"
            />
            <path
              d="M220.348 30.4631C220.57 30.443 220.781 30.3575 220.955 30.2174C221.129 30.0773 221.257 29.8888 221.323 29.6759C221.39 29.463 221.391 29.2352 221.328 29.0212C221.265 28.8072 221.14 28.6167 220.969 28.4738C220.798 28.3309 220.588 28.242 220.366 28.2183C220.145 28.1946 219.921 28.2372 219.723 28.3407C219.526 28.4442 219.363 28.604 219.256 28.7998C219.15 28.9956 219.103 29.2187 219.123 29.4409C219.136 29.5884 219.179 29.7319 219.247 29.8632C219.316 29.9944 219.41 30.1108 219.524 30.2057C219.638 30.3006 219.769 30.3722 219.91 30.4164C220.052 30.4605 220.201 30.4764 220.348 30.4631Z"
              fill="#00BA77"
            />
            <path
              d="M227.585 29.8134C227.807 29.7933 228.018 29.7078 228.192 29.5677C228.365 29.4276 228.493 29.2392 228.56 29.0263C228.626 28.8133 228.628 28.5855 228.565 28.3715C228.502 28.1576 228.377 27.9671 228.206 27.8242C228.035 27.6813 227.825 27.5923 227.603 27.5686C227.381 27.5449 227.158 27.5875 226.96 27.691C226.762 27.7946 226.6 27.9543 226.493 28.1501C226.386 28.346 226.34 28.5691 226.36 28.7912C226.373 28.9389 226.415 29.0825 226.484 29.2138C226.552 29.3452 226.646 29.4616 226.76 29.5566C226.874 29.6516 227.005 29.7231 227.147 29.7672C227.288 29.8113 227.437 29.827 227.585 29.8134Z"
              fill="#00BA77"
            />
            <path
              d="M234.821 29.164C235.043 29.1439 235.255 29.0584 235.428 28.9183C235.602 28.7782 235.73 28.5897 235.796 28.3768C235.863 28.1639 235.865 27.9361 235.802 27.7221C235.739 27.5081 235.614 27.3177 235.442 27.1748C235.271 27.0318 235.061 26.9429 234.839 26.9192C234.618 26.8955 234.394 26.9381 234.196 27.0416C233.999 27.1451 233.836 27.3049 233.729 27.5007C233.623 27.6966 233.576 27.9196 233.596 28.1418C233.61 28.2894 233.652 28.4329 233.721 28.5641C233.789 28.6953 233.883 28.8117 233.997 28.9066C234.111 29.0016 234.242 29.0732 234.384 29.1173C234.525 29.1615 234.674 29.1773 234.821 29.164Z"
              fill="#00BA77"
            />
            <path
              d="M242.059 28.5228C242.282 28.5031 242.493 28.4177 242.667 28.2776C242.842 28.1375 242.97 27.9489 243.037 27.7357C243.104 27.5224 243.106 27.2942 243.043 27.0797C242.98 26.8653 242.855 26.6743 242.684 26.531C242.512 26.3877 242.302 26.2984 242.08 26.2745C241.858 26.2507 241.633 26.2932 241.435 26.3968C241.237 26.5005 241.075 26.6605 240.968 26.8567C240.861 27.0528 240.814 27.2764 240.834 27.4989C240.847 27.6466 240.889 27.7903 240.958 27.9217C241.027 28.0532 241.12 28.1698 241.234 28.2649C241.348 28.36 241.479 28.4317 241.621 28.476C241.762 28.5202 241.911 28.5362 242.059 28.5228Z"
              fill="#00BA77"
            />
            <path
              d="M249.296 27.8641C249.519 27.8444 249.731 27.7591 249.905 27.6189C250.079 27.4788 250.207 27.2902 250.274 27.077C250.341 26.8637 250.343 26.6355 250.28 26.421C250.218 26.2066 250.092 26.0156 249.921 25.8723C249.75 25.729 249.539 25.6397 249.317 25.6158C249.095 25.592 248.871 25.6345 248.673 25.7381C248.475 25.8418 248.312 26.0018 248.205 26.198C248.098 26.3941 248.051 26.6177 248.071 26.8402C248.098 27.1384 248.242 27.4137 248.472 27.6056C248.702 27.7976 248.998 27.8906 249.296 27.8641Z"
              fill="#00BA77"
            />
            <path
              d="M256.543 27.2145C256.766 27.1947 256.978 27.1094 257.152 26.9693C257.326 26.8292 257.455 26.6406 257.521 26.4273C257.588 26.2141 257.59 25.9858 257.527 25.7714C257.465 25.5569 257.339 25.366 257.168 25.2226C256.997 25.0793 256.786 24.9901 256.564 24.9662C256.342 24.9423 256.118 24.9849 255.92 25.0885C255.722 25.1921 255.559 25.3521 255.452 25.5483C255.345 25.7445 255.298 25.968 255.319 26.1906C255.345 26.4887 255.489 26.764 255.719 26.956C255.949 27.148 256.245 27.2409 256.543 27.2145Z"
              fill="#00BA77"
            />
            <path
              d="M263.771 26.5634C263.994 26.5433 264.205 26.4578 264.378 26.3177C264.552 26.1776 264.68 25.9892 264.746 25.7763C264.813 25.5633 264.815 25.3355 264.752 25.1215C264.689 24.9076 264.564 24.7171 264.392 24.5742C264.221 24.4313 264.011 24.3423 263.79 24.3186C263.568 24.2949 263.344 24.3375 263.146 24.441C262.949 24.5446 262.786 24.7043 262.68 24.9001C262.573 25.096 262.527 25.3191 262.547 25.5412C262.56 25.6889 262.602 25.8325 262.67 25.9638C262.739 26.0952 262.833 26.2117 262.947 26.3066C263.061 26.4016 263.192 26.4731 263.333 26.5172C263.475 26.5613 263.624 26.577 263.771 26.5634Z"
              fill="#00BA77"
            />
            <path
              d="M271.008 25.9137C271.23 25.8937 271.442 25.8081 271.615 25.668C271.789 25.5279 271.917 25.3395 271.983 25.1266C272.05 24.9137 272.052 24.6858 271.989 24.4719C271.926 24.2579 271.801 24.0674 271.629 23.9245C271.458 23.7816 271.248 23.6927 271.026 23.669C270.805 23.6453 270.581 23.6879 270.383 23.7914C270.186 23.8949 270.023 24.0547 269.916 24.2505C269.81 24.4463 269.763 24.6694 269.783 24.8916C269.797 25.0391 269.839 25.1826 269.908 25.3138C269.976 25.4451 270.07 25.5615 270.184 25.6564C270.298 25.7513 270.429 25.8229 270.571 25.8671C270.712 25.9112 270.861 25.9271 271.008 25.9137Z"
              fill="#00BA77"
            />
            <path
              d="M278.247 25.2641C278.469 25.244 278.68 25.1585 278.853 25.0184C279.027 24.8783 279.155 24.6899 279.222 24.4769C279.288 24.264 279.29 24.0362 279.227 23.8222C279.164 23.6082 279.039 23.4178 278.868 23.2749C278.696 23.132 278.487 23.043 278.265 23.0193C278.043 22.9956 277.819 23.0382 277.622 23.1417C277.424 23.2452 277.262 23.405 277.155 23.6008C277.048 23.7967 277.002 24.0197 277.022 24.2419C277.035 24.3895 277.077 24.533 277.146 24.6642C277.215 24.7954 277.309 24.9118 277.422 25.0067C277.536 25.1017 277.667 25.1732 277.809 25.2174C277.95 25.2616 278.099 25.2774 278.247 25.2641Z"
              fill="#00BA77"
            />
            <path
              d="M285.476 24.6146C285.698 24.5949 285.91 24.5095 286.084 24.3694C286.258 24.2293 286.387 24.0407 286.454 23.8275C286.52 23.6142 286.523 23.386 286.46 23.1715C286.397 22.9571 286.272 22.7661 286.1 22.6228C285.929 22.4795 285.719 22.3902 285.496 22.3663C285.274 22.3425 285.05 22.385 284.852 22.4886C284.654 22.5923 284.491 22.7523 284.384 22.9485C284.277 23.1446 284.231 23.3682 284.251 23.5907C284.277 23.8889 284.422 24.1641 284.651 24.3561C284.881 24.5481 285.177 24.6411 285.476 24.6146Z"
              fill="#00BA77"
            />
            <path
              d="M292.721 23.9642C292.944 23.9445 293.155 23.8591 293.33 23.719C293.504 23.5789 293.632 23.3903 293.699 23.1771C293.766 22.9638 293.768 22.7356 293.705 22.5211C293.642 22.3067 293.517 22.1157 293.346 21.9724C293.174 21.8291 292.964 21.7398 292.742 21.7159C292.52 21.6921 292.296 21.7346 292.098 21.8382C291.9 21.9419 291.737 22.1019 291.63 22.2981C291.523 22.4942 291.476 22.7178 291.496 22.9403C291.509 23.088 291.551 23.2317 291.62 23.3632C291.689 23.4946 291.783 23.6112 291.896 23.7063C292.01 23.8014 292.142 23.8731 292.283 23.9174C292.425 23.9616 292.573 23.9776 292.721 23.9642Z"
              fill="#00BA77"
            />
            <path
              d="M40.0633 53.9504C40.2853 53.9303 40.4965 53.8449 40.67 53.7049C40.8436 53.5649 40.9717 53.3766 41.0383 53.1637C41.1048 52.9509 41.1068 52.7231 41.044 52.5092C40.9811 52.2953 40.8563 52.1048 40.6852 51.9617C40.5141 51.8187 40.3045 51.7296 40.0828 51.7057C39.8611 51.6818 39.6373 51.7242 39.4396 51.8274C39.242 51.9306 39.0794 52.0901 38.9724 52.2858C38.8654 52.4814 38.8187 52.7043 38.8384 52.9265C38.8514 53.0742 38.8935 53.2179 38.9622 53.3493C39.0308 53.4807 39.1247 53.5973 39.2385 53.6924C39.3523 53.7875 39.4837 53.8593 39.6252 53.9035C39.7667 53.9478 39.9156 53.9637 40.0633 53.9504Z"
              fill="#00BA77"
            />
            <path
              d="M47.3007 53.2975C47.5233 53.2777 47.7351 53.1924 47.9091 53.0523C48.0832 52.9122 48.2118 52.7236 48.2787 52.5103C48.3456 52.2971 48.3477 52.0688 48.2848 51.8544C48.2219 51.6399 48.0968 51.449 47.9254 51.3057C47.7539 51.1623 47.5438 51.0731 47.3216 51.0492C47.0994 51.0253 46.8751 51.0679 46.6771 51.1715C46.4791 51.2751 46.3163 51.4351 46.2093 51.6313C46.1022 51.8275 46.0558 52.051 46.0758 52.2736C46.1027 52.5717 46.2467 52.847 46.4764 53.039C46.7061 53.231 47.0026 53.3239 47.3007 53.2975Z"
              fill="#00BA77"
            />
            <path
              d="M54.5336 52.6513C54.7562 52.6315 54.968 52.5462 55.1421 52.406C55.3161 52.2659 55.4448 52.0773 55.5116 51.8641C55.5785 51.6509 55.5806 51.4226 55.5177 51.2081C55.4548 50.9937 55.3297 50.8027 55.1583 50.6594C54.9868 50.5161 54.7767 50.4268 54.5545 50.403C54.3323 50.3791 54.108 50.4216 53.91 50.5253C53.712 50.6289 53.5492 50.7889 53.4422 50.9851C53.3352 51.1812 53.2887 51.4048 53.3087 51.6273C53.3356 51.9255 53.4797 52.2008 53.7093 52.3928C53.939 52.5847 54.2355 52.6777 54.5336 52.6513Z"
              fill="#00BA77"
            />
            <path
              d="M61.7753 51.9997C61.9975 51.9796 62.2087 51.8941 62.3822 51.754C62.5558 51.6139 62.6839 51.4255 62.7504 51.2125C62.8168 50.9996 62.8187 50.7718 62.7557 50.5578C62.6927 50.3438 62.5677 50.1534 62.3964 50.0104C62.2251 49.8675 62.0154 49.7786 61.7936 49.7549C61.5718 49.7312 61.348 49.7738 61.1504 49.8773C60.9528 49.9808 60.7903 50.1406 60.6836 50.3364C60.5768 50.5322 60.5304 50.7553 60.5504 50.9775C60.5635 51.1251 60.6056 51.2687 60.6742 51.4001C60.7429 51.5314 60.8369 51.6479 60.9507 51.7429C61.0644 51.8379 61.1959 51.9094 61.3374 51.9535C61.4789 51.9975 61.6277 52.0132 61.7753 51.9997Z"
              fill="#00BA77"
            />
            <path
              d="M69.0116 51.3505C69.2338 51.3304 69.445 51.2449 69.6185 51.1048C69.7921 50.9647 69.9202 50.7763 69.9867 50.5634C70.0532 50.3505 70.055 50.1226 69.992 49.9086C69.929 49.6947 69.804 49.5042 69.6327 49.3613C69.4615 49.2184 69.2517 49.1294 69.0299 49.1057C68.8081 49.0821 68.5843 49.1247 68.3867 49.2282C68.1891 49.3317 68.0267 49.4914 67.9199 49.6873C67.8131 49.8831 67.7668 50.1062 67.7868 50.3283C67.8 50.4759 67.8423 50.6194 67.911 50.7506C67.9798 50.8818 68.0737 50.9982 68.1875 51.0932C68.3012 51.1881 68.4325 51.2597 68.5739 51.3038C68.7154 51.348 68.8641 51.3638 69.0116 51.3505Z"
              fill="#00BA77"
            />
            <path
              d="M76.2504 50.6986C76.4726 50.6786 76.6838 50.5931 76.8573 50.453C77.0309 50.3128 77.159 50.1244 77.2255 49.9115C77.2919 49.6986 77.2938 49.4708 77.2308 49.2568C77.1678 49.0428 77.0427 48.8523 76.8715 48.7094C76.7002 48.5665 76.4905 48.4776 76.2687 48.4539C76.0469 48.4302 75.8231 48.4728 75.6255 48.5763C75.4279 48.6798 75.2654 48.8396 75.1586 49.0354C75.0519 49.2312 75.0055 49.4543 75.0255 49.6765C75.0388 49.824 75.081 49.9675 75.1498 50.0988C75.2185 50.23 75.3125 50.3464 75.4262 50.4413C75.54 50.5362 75.6713 50.6078 75.8127 50.652C75.9541 50.6961 76.1029 50.712 76.2504 50.6986Z"
              fill="#00BA77"
            />
            <path
              d="M83.4868 50.0507C83.7094 50.0309 83.9211 49.9456 84.0952 49.8055C84.2693 49.6653 84.3979 49.4767 84.4648 49.2635C84.5316 49.0503 84.5337 48.822 84.4708 48.6076C84.4079 48.3931 84.2828 48.2022 84.1114 48.0588C83.9399 47.9155 83.7298 47.8262 83.5076 47.8024C83.2854 47.7785 83.0611 47.821 82.8631 47.9247C82.6651 48.0283 82.5023 48.1883 82.3953 48.3845C82.2883 48.5807 82.2418 48.8042 82.2619 49.0268C82.2887 49.3249 82.4328 49.6002 82.6625 49.7922C82.8921 49.9841 83.1886 50.0771 83.4868 50.0507Z"
              fill="#00BA77"
            />
            <path
              d="M90.7255 49.401C90.9481 49.3813 91.1599 49.2959 91.3339 49.1558C91.508 49.0157 91.6366 48.8271 91.7035 48.6138C91.7704 48.4006 91.7725 48.1723 91.7096 47.9579C91.6467 47.7435 91.5216 47.5525 91.3502 47.4092C91.1787 47.2658 90.9686 47.1766 90.7464 47.1527C90.5242 47.1288 90.2999 47.1714 90.1019 47.275C89.9039 47.3786 89.7411 47.5387 89.6341 47.7348C89.527 47.931 89.4806 48.1545 89.5006 48.3771C89.5137 48.5248 89.5558 48.6685 89.6244 48.7999C89.6931 48.9314 89.787 49.048 89.9008 49.1431C90.0145 49.2382 90.1459 49.3099 90.2874 49.3542C90.429 49.3984 90.5778 49.4143 90.7255 49.401Z"
              fill="#00BA77"
            />
            <path
              d="M97.9628 48.7516C98.1854 48.7318 98.3972 48.6465 98.5713 48.5064C98.7453 48.3663 98.874 48.1777 98.9408 47.9644C99.0077 47.7512 99.0098 47.5229 98.9469 47.3085C98.884 47.094 98.7589 46.9031 98.5875 46.7598C98.416 46.6164 98.2059 46.5272 97.9837 46.5033C97.7615 46.4794 97.5372 46.522 97.3392 46.6256C97.1412 46.7292 96.9784 46.8892 96.8714 47.0854C96.7644 47.2816 96.7179 47.5051 96.7379 47.7277C96.751 47.8754 96.7931 48.0191 96.8617 48.1505C96.9304 48.2819 97.0243 48.3985 97.1381 48.4936C97.2518 48.5887 97.3832 48.6605 97.5248 48.7047C97.6663 48.749 97.8151 48.7649 97.9628 48.7516Z"
              fill="#00BA77"
            />
            <path
              d="M105.199 48.1C105.421 48.0799 105.632 47.9944 105.806 47.8543C105.98 47.7142 106.108 47.5258 106.174 47.3129C106.241 47.1 106.243 46.8721 106.18 46.6582C106.117 46.4442 105.991 46.2537 105.82 46.1108C105.649 45.9679 105.439 45.879 105.217 45.8553C104.996 45.8316 104.772 45.8742 104.574 45.9777C104.377 46.0812 104.214 46.2409 104.107 46.4368C104.001 46.6326 103.954 46.8557 103.974 47.0778C103.988 47.2254 104.03 47.3689 104.099 47.5001C104.167 47.6314 104.261 47.7478 104.375 47.8427C104.489 47.9376 104.62 48.0092 104.761 48.0533C104.903 48.0975 105.052 48.1134 105.199 48.1Z"
              fill="#00BA77"
            />
            <path
              d="M112.437 47.4501C112.66 47.43 112.871 47.3445 113.044 47.2044C113.218 47.0643 113.346 46.8759 113.412 46.663C113.479 46.4501 113.481 46.2222 113.418 46.0083C113.355 45.7943 113.23 45.6038 113.059 45.4609C112.887 45.318 112.677 45.2291 112.456 45.2054C112.234 45.1817 112.01 45.2243 111.812 45.3278C111.615 45.4313 111.452 45.591 111.346 45.7869C111.239 45.9827 111.193 46.2058 111.213 46.4279C111.226 46.5755 111.268 46.719 111.337 46.8502C111.406 46.9815 111.499 47.0979 111.613 47.1928C111.727 47.2877 111.858 47.3593 112 47.4034C112.141 47.4476 112.29 47.4635 112.437 47.4501Z"
              fill="#00BA77"
            />
            <path
              d="M119.676 46.8005C119.898 46.7804 120.109 46.6949 120.283 46.5548C120.456 46.4147 120.584 46.2262 120.651 46.0133C120.717 45.8004 120.719 45.5726 120.656 45.3586C120.593 45.1446 120.468 44.9542 120.297 44.8112C120.126 44.6683 119.916 44.5794 119.694 44.5557C119.472 44.532 119.248 44.5746 119.051 44.6781C118.853 44.7816 118.691 44.9414 118.584 45.1372C118.477 45.333 118.431 45.5561 118.451 45.7783C118.478 46.0761 118.622 46.351 118.852 46.5426C119.082 46.7342 119.378 46.8269 119.676 46.8005Z"
              fill="#00BA77"
            />
            <path
              d="M126.912 46.1508C127.134 46.1308 127.346 46.0453 127.519 45.9053C127.693 45.7653 127.821 45.577 127.887 45.3642C127.954 45.1514 127.956 44.9236 127.893 44.7097C127.83 44.4957 127.705 44.3052 127.534 44.1622C127.363 44.0192 127.154 43.9301 126.932 43.9062C126.71 43.8823 126.486 43.9246 126.289 44.0278C126.091 44.1311 125.929 44.2906 125.821 44.4862C125.714 44.6818 125.668 44.9048 125.687 45.1269C125.701 45.2746 125.743 45.4183 125.811 45.5497C125.88 45.6812 125.974 45.7978 126.088 45.8929C126.201 45.988 126.333 46.0597 126.474 46.104C126.616 46.1482 126.765 46.1641 126.912 46.1508Z"
              fill="#00BA77"
            />
            <path
              d="M134.149 45.5013C134.371 45.4816 134.583 45.3963 134.757 45.2561C134.931 45.116 135.06 44.9274 135.127 44.7142C135.194 44.5009 135.196 44.2727 135.133 44.0582C135.07 43.8438 134.945 43.6528 134.774 43.5095C134.602 43.3662 134.392 43.2769 134.17 43.2531C133.948 43.2292 133.723 43.2717 133.525 43.3754C133.327 43.479 133.164 43.639 133.057 43.8352C132.95 44.0314 132.904 44.2549 132.924 44.4774C132.937 44.6252 132.979 44.7688 133.048 44.9003C133.116 45.0317 133.21 45.1483 133.324 45.2434C133.438 45.3385 133.569 45.4102 133.711 45.4545C133.852 45.4988 134.001 45.5147 134.149 45.5013Z"
              fill="#00BA77"
            />
            <path
              d="M141.386 44.8517C141.608 44.8319 141.82 44.7466 141.994 44.6065C142.168 44.4664 142.297 44.2778 142.364 44.0645C142.431 43.8513 142.433 43.623 142.37 43.4086C142.307 43.1941 142.182 43.0032 142.01 42.8599C141.839 42.7165 141.629 42.6273 141.407 42.6034C141.184 42.5795 140.96 42.6221 140.762 42.7257C140.564 42.8293 140.401 42.9893 140.294 43.1855C140.187 43.3817 140.141 43.6052 140.161 43.8278C140.188 44.1259 140.332 44.4012 140.561 44.5932C140.791 44.7852 141.088 44.8781 141.386 44.8517Z"
              fill="#00BA77"
            />
            <path
              d="M148.624 44.2001C148.846 44.18 149.057 44.0945 149.231 43.9544C149.404 43.8143 149.533 43.6259 149.599 43.413C149.665 43.2001 149.667 42.9722 149.604 42.7583C149.541 42.5443 149.416 42.3538 149.245 42.2109C149.074 42.068 148.864 41.9791 148.642 41.9554C148.42 41.9317 148.197 41.9743 147.999 42.0778C147.801 42.1813 147.639 42.341 147.532 42.5369C147.425 42.7327 147.379 42.9558 147.399 43.1779C147.412 43.3255 147.455 43.469 147.523 43.6002C147.592 43.7315 147.686 43.8479 147.8 43.9428C147.913 44.0377 148.045 44.1093 148.186 44.1534C148.328 44.1976 148.476 44.2135 148.624 44.2001Z"
              fill="#00BA77"
            />
            <path
              d="M155.861 43.5505C156.083 43.5304 156.294 43.4449 156.468 43.3048C156.641 43.1647 156.769 42.9762 156.836 42.7633C156.902 42.5504 156.904 42.3226 156.841 42.1086C156.778 41.8946 156.653 41.7042 156.482 41.5612C156.311 41.4183 156.101 41.3294 155.879 41.3057C155.657 41.282 155.433 41.3246 155.236 41.4281C155.038 41.5316 154.876 41.6914 154.769 41.8872C154.662 42.083 154.616 42.3061 154.636 42.5283C154.663 42.8261 154.807 43.101 155.037 43.2926C155.267 43.4842 155.563 43.5769 155.861 43.5505Z"
              fill="#00BA77"
            />
            <path
              d="M163.099 42.9025C163.321 42.8824 163.532 42.7969 163.706 42.6568C163.88 42.5167 164.008 42.3283 164.074 42.1154C164.141 41.9025 164.142 41.6746 164.079 41.4607C164.016 41.2467 163.891 41.0562 163.72 40.9133C163.549 40.7704 163.339 40.6814 163.117 40.6578C162.896 40.6341 162.672 40.6767 162.474 40.7802C162.277 40.8837 162.114 41.0434 162.007 41.2393C161.901 41.4351 161.854 41.6582 161.874 41.8803C161.887 42.0279 161.93 42.1714 161.998 42.3026C162.067 42.4338 162.161 42.5502 162.275 42.6452C162.389 42.7401 162.52 42.8117 162.661 42.8558C162.803 42.9 162.952 42.9158 163.099 42.9025Z"
              fill="#00BA77"
            />
            <path
              d="M170.336 42.2511C170.559 42.2314 170.771 42.146 170.945 42.0059C171.119 41.8658 171.248 41.6772 171.314 41.4639C171.381 41.2507 171.383 41.0224 171.32 40.808C171.258 40.5936 171.132 40.4026 170.961 40.2593C170.79 40.1159 170.579 40.0267 170.357 40.0028C170.135 39.9789 169.911 40.0215 169.713 40.1251C169.515 40.2287 169.352 40.3888 169.245 40.5849C169.138 40.7811 169.091 41.0046 169.111 41.2272C169.125 41.3749 169.167 41.5186 169.235 41.65C169.304 41.7814 169.398 41.898 169.512 41.9932C169.625 42.0883 169.757 42.16 169.898 42.2043C170.04 42.2485 170.189 42.2644 170.336 42.2511Z"
              fill="#00BA77"
            />
            <path
              d="M177.572 41.6017C177.795 41.5819 178.007 41.4966 178.181 41.3565C178.355 41.2164 178.483 41.0278 178.55 40.8145C178.617 40.6013 178.619 40.373 178.556 40.1586C178.493 39.9441 178.368 39.7532 178.197 39.6099C178.025 39.4665 177.815 39.3773 177.593 39.3534C177.371 39.3295 177.147 39.3721 176.949 39.4757C176.751 39.5793 176.588 39.7393 176.481 39.9355C176.374 40.1317 176.327 40.3552 176.347 40.5778C176.36 40.7255 176.402 40.8692 176.471 41.0006C176.54 41.132 176.634 41.2486 176.747 41.3437C176.861 41.4388 176.993 41.5106 177.134 41.5548C177.276 41.5991 177.425 41.615 177.572 41.6017Z"
              fill="#00BA77"
            />
            <path
              d="M184.818 40.9518C185.04 40.932 185.252 40.8467 185.426 40.7066C185.6 40.5665 185.729 40.3779 185.796 40.1646C185.863 39.9514 185.865 39.7231 185.802 39.5087C185.739 39.2942 185.614 39.1033 185.442 38.9599C185.271 38.8166 185.061 38.7274 184.839 38.7035C184.616 38.6796 184.392 38.7222 184.194 38.8258C183.996 38.9294 183.833 39.0894 183.726 39.2856C183.619 39.4818 183.573 39.7053 183.593 39.9279C183.62 40.226 183.764 40.5013 183.993 40.6933C184.223 40.8853 184.52 40.9782 184.818 40.9518Z"
              fill="#00BA77"
            />
            <path
              d="M192.047 40.3036C192.269 40.2835 192.481 40.198 192.654 40.0579C192.828 39.9178 192.956 39.7294 193.022 39.5165C193.089 39.3036 193.091 39.0757 193.028 38.8618C192.965 38.6478 192.84 38.4573 192.668 38.3144C192.497 38.1715 192.287 38.0826 192.066 38.0589C191.844 38.0352 191.62 38.0778 191.422 38.1813C191.225 38.2848 191.062 38.4446 190.956 38.6404C190.849 38.8362 190.802 39.0593 190.822 39.2814C190.85 39.5793 190.994 39.8541 191.224 40.0457C191.453 40.2374 191.749 40.3301 192.047 40.3036Z"
              fill="#00BA77"
            />
            <path
              d="M199.286 39.6505C199.508 39.6305 199.719 39.545 199.893 39.4048C200.067 39.2647 200.195 39.0763 200.261 38.8634C200.328 38.6505 200.329 38.4227 200.266 38.2087C200.203 37.9947 200.078 37.8042 199.907 37.6613C199.736 37.5184 199.526 37.4295 199.304 37.4058C199.083 37.3821 198.859 37.4247 198.661 37.5282C198.464 37.6317 198.301 37.7915 198.194 37.9873C198.088 38.1831 198.041 38.4062 198.061 38.6284C198.074 38.7759 198.117 38.9194 198.185 39.0506C198.254 39.1819 198.348 39.2983 198.462 39.3932C198.576 39.4881 198.707 39.5597 198.848 39.6039C198.99 39.648 199.139 39.6639 199.286 39.6505Z"
              fill="#00BA77"
            />
            <path
              d="M206.523 39.0009C206.745 38.9808 206.956 38.8953 207.13 38.7552C207.303 38.6151 207.431 38.4267 207.498 38.2138C207.564 38.0008 207.566 37.773 207.503 37.559C207.44 37.3451 207.315 37.1546 207.144 37.0117C206.973 36.8688 206.763 36.7798 206.541 36.7561C206.319 36.7324 206.096 36.775 205.898 36.8785C205.7 36.9821 205.538 37.1418 205.431 37.3376C205.324 37.5335 205.278 37.7566 205.298 37.9787C205.311 38.1263 205.353 38.2698 205.422 38.401C205.491 38.5322 205.585 38.6486 205.699 38.7436C205.812 38.8385 205.944 38.9101 206.085 38.9542C206.227 38.9984 206.375 39.0142 206.523 39.0009Z"
              fill="#00BA77"
            />
            <path
              d="M213.759 38.3512C213.981 38.3312 214.192 38.2458 214.366 38.1058C214.539 37.9657 214.668 37.7774 214.734 37.5646C214.801 37.3518 214.803 37.124 214.74 36.9101C214.677 36.6961 214.552 36.5056 214.381 36.3626C214.21 36.2196 214 36.1305 213.779 36.1066C213.557 36.0827 213.333 36.125 213.135 36.2283C212.938 36.3315 212.775 36.491 212.668 36.6866C212.561 36.8823 212.515 37.1052 212.534 37.3273C212.547 37.475 212.589 37.6187 212.658 37.7502C212.727 37.8816 212.821 37.9982 212.934 38.0933C213.048 38.1884 213.179 38.2601 213.321 38.3044C213.463 38.3487 213.611 38.3646 213.759 38.3512Z"
              fill="#00BA77"
            />
            <path
              d="M220.998 37.7052C221.221 37.6855 221.432 37.6001 221.606 37.46C221.78 37.3199 221.909 37.1313 221.976 36.918C222.043 36.7048 222.045 36.4765 221.982 36.2621C221.919 36.0477 221.794 35.8567 221.623 35.7134C221.451 35.57 221.241 35.4808 221.019 35.4569C220.797 35.433 220.572 35.4756 220.374 35.5792C220.176 35.6828 220.014 35.8429 219.907 36.039C219.8 36.2352 219.753 36.4587 219.773 36.6813C219.786 36.829 219.828 36.9727 219.897 37.1041C219.966 37.2355 220.059 37.3522 220.173 37.4473C220.287 37.5424 220.418 37.6141 220.56 37.6584C220.701 37.7026 220.85 37.7185 220.998 37.7052Z"
              fill="#00BA77"
            />
            <path
              d="M228.234 37.0516C228.457 37.0319 228.669 36.9466 228.843 36.8064C229.017 36.6663 229.145 36.4777 229.212 36.2645C229.279 36.0512 229.281 35.823 229.218 35.6085C229.155 35.3941 229.03 35.2031 228.859 35.0598C228.687 34.9165 228.477 34.8272 228.255 34.8033C228.033 34.7795 227.809 34.822 227.611 34.9256C227.413 35.0293 227.25 35.1893 227.143 35.3855C227.036 35.5816 226.989 35.8052 227.009 36.0277C227.037 36.3257 227.181 36.6007 227.41 36.7926C227.64 36.9845 227.936 37.0776 228.234 37.0516Z"
              fill="#00BA77"
            />
            <path
              d="M235.473 36.4005C235.695 36.3805 235.906 36.295 236.08 36.1549C236.254 36.0148 236.382 35.8263 236.448 35.6134C236.515 35.4005 236.516 35.1727 236.453 34.9587C236.39 34.7447 236.265 34.5542 236.094 34.4113C235.923 34.2684 235.713 34.1795 235.491 34.1558C235.27 34.1321 235.046 34.1747 234.848 34.2782C234.651 34.3817 234.488 34.5415 234.381 34.7373C234.275 34.9331 234.228 35.1562 234.248 35.3784C234.261 35.5259 234.304 35.6694 234.372 35.8006C234.441 35.9319 234.535 36.0483 234.649 36.1432C234.763 36.2381 234.894 36.3097 235.035 36.3539C235.177 36.398 235.326 36.4139 235.473 36.4005Z"
              fill="#00BA77"
            />
            <path
              d="M242.709 35.7509C242.931 35.7308 243.142 35.6453 243.316 35.5052C243.489 35.3651 243.617 35.1767 243.684 34.9638C243.75 34.7508 243.752 34.523 243.689 34.309C243.626 34.0951 243.501 33.9046 243.33 33.7617C243.159 33.6188 242.949 33.5298 242.727 33.5061C242.505 33.4824 242.282 33.525 242.084 33.6285C241.886 33.7321 241.724 33.8918 241.617 34.0876C241.51 34.2835 241.464 34.5066 241.484 34.7287C241.497 34.8764 241.539 35.02 241.608 35.1513C241.677 35.2827 241.77 35.3991 241.884 35.4941C241.998 35.5891 242.129 35.6606 242.271 35.7047C242.413 35.7488 242.561 35.7645 242.709 35.7509Z"
              fill="#00BA77"
            />
            <path
              d="M249.946 35.1061C250.168 35.086 250.38 35.0005 250.553 34.8604C250.727 34.7203 250.855 34.5319 250.921 34.319C250.988 34.1061 250.99 33.8782 250.927 33.6643C250.864 33.4503 250.739 33.2598 250.567 33.1169C250.396 32.974 250.186 32.8851 249.964 32.8614C249.743 32.8377 249.519 32.8803 249.321 32.9838C249.124 33.0873 248.961 33.247 248.854 33.4429C248.748 33.6387 248.701 33.8618 248.721 34.0839C248.735 34.2315 248.777 34.375 248.846 34.5062C248.914 34.6375 249.008 34.7539 249.122 34.8488C249.236 34.9437 249.367 35.0153 249.509 35.0594C249.65 35.1036 249.799 35.1195 249.946 35.1061Z"
              fill="#00BA77"
            />
            <path
              d="M257.184 34.4513C257.406 34.4313 257.618 34.3459 257.791 34.2059C257.965 34.0658 258.093 33.8775 258.159 33.6647C258.226 33.4519 258.228 33.2241 258.165 33.0102C258.102 32.7962 257.977 32.6057 257.806 32.4627C257.635 32.3197 257.426 32.2306 257.204 32.2067C256.982 32.1828 256.758 32.2251 256.561 32.3284C256.363 32.4316 256.2 32.5911 256.093 32.7867C255.986 32.9824 255.94 33.2053 255.959 33.4274C255.973 33.5751 256.015 33.7188 256.083 33.8503C256.152 33.9817 256.246 34.0983 256.36 34.1934C256.473 34.2885 256.605 34.3602 256.746 34.4045C256.888 34.4488 257.037 34.4647 257.184 34.4513Z"
              fill="#00BA77"
            />
            <path
              d="M264.421 33.8019C264.644 33.7821 264.856 33.6968 265.03 33.5567C265.204 33.4166 265.332 33.228 265.399 33.0147C265.466 32.8015 265.468 32.5732 265.405 32.3588C265.343 32.1443 265.217 31.9534 265.046 31.81C264.875 31.6667 264.664 31.5775 264.442 31.5536C264.22 31.5297 263.996 31.5723 263.798 31.6759C263.6 31.7795 263.437 31.9395 263.33 32.1357C263.223 32.3319 263.176 32.5554 263.196 32.778C263.223 33.0761 263.367 33.3514 263.597 33.5434C263.827 33.7354 264.123 33.8283 264.421 33.8019Z"
              fill="#00BA77"
            />
            <path
              d="M271.66 33.1522C271.882 33.1325 272.094 33.0471 272.268 32.907C272.442 32.7669 272.571 32.5783 272.638 32.3651C272.704 32.1518 272.707 31.9236 272.644 31.7091C272.581 31.4947 272.456 31.3037 272.284 31.1604C272.113 31.0171 271.903 30.9278 271.68 30.9039C271.458 30.8801 271.234 30.9226 271.036 31.0262C270.838 31.1299 270.675 31.2899 270.568 31.486C270.461 31.6822 270.415 31.9057 270.435 32.1283C270.462 32.4264 270.606 32.7017 270.835 32.8937C271.065 33.0857 271.361 33.1787 271.66 33.1522Z"
              fill="#00BA77"
            />
            <path
              d="M278.892 32.5075C279.114 32.4874 279.325 32.4019 279.499 32.2618C279.672 32.1217 279.801 31.9333 279.867 31.7204C279.934 31.5074 279.935 31.2796 279.872 31.0656C279.809 30.8517 279.684 30.6612 279.513 30.5183C279.342 30.3754 279.132 30.2864 278.91 30.2627C278.688 30.239 278.465 30.2816 278.267 30.3851C278.069 30.4886 277.907 30.6484 277.8 30.8442C277.693 31.0401 277.647 31.2632 277.667 31.4853C277.68 31.6329 277.722 31.7766 277.791 31.9079C277.86 32.0392 277.954 32.1557 278.067 32.2507C278.181 32.3457 278.313 32.4172 278.454 32.4613C278.596 32.5054 278.744 32.5211 278.892 32.5075Z"
              fill="#00BA77"
            />
            <path
              d="M286.133 31.8505C286.355 31.8304 286.566 31.7449 286.74 31.6048C286.913 31.4647 287.041 31.2763 287.108 31.0634C287.174 30.8504 287.176 30.6226 287.113 30.4086C287.05 30.1947 286.925 30.0042 286.754 29.8613C286.583 29.7184 286.373 29.6294 286.151 29.6057C285.929 29.5821 285.705 29.6247 285.508 29.7282C285.31 29.8317 285.148 29.9914 285.041 30.1873C284.934 30.3831 284.888 30.6062 284.908 30.8283C284.921 30.9759 284.963 31.1194 285.032 31.2506C285.101 31.3818 285.195 31.4982 285.309 31.5932C285.422 31.6881 285.554 31.7597 285.695 31.8038C285.836 31.848 285.985 31.8638 286.133 31.8505Z"
              fill="#00BA77"
            />
            <path
              d="M293.371 31.2013C293.593 31.1812 293.804 31.0957 293.978 30.9556C294.151 30.8155 294.28 30.6271 294.346 30.4142C294.413 30.2013 294.414 29.9734 294.351 29.7595C294.288 29.5455 294.163 29.355 293.992 29.2121C293.821 29.0692 293.611 28.9803 293.389 28.9566C293.167 28.9329 292.944 28.9755 292.746 29.079C292.548 29.1825 292.386 29.3423 292.279 29.5381C292.172 29.7339 292.126 29.957 292.146 30.1792C292.159 30.3267 292.202 30.4702 292.27 30.6014C292.339 30.7327 292.433 30.8491 292.547 30.944C292.661 31.0389 292.792 31.1105 292.933 31.1547C293.075 31.1988 293.223 31.2147 293.371 31.2013Z"
              fill="#00BA77"
            />
            <path
              d="M40.7133 61.1874C40.9355 61.1673 41.1466 61.0818 41.3202 60.9417C41.4938 60.8016 41.6219 60.6132 41.6883 60.4003C41.7548 60.1874 41.7567 59.9595 41.6937 59.7456C41.6307 59.5316 41.5056 59.3411 41.3344 59.1982C41.1631 59.0553 40.9534 58.9664 40.7316 58.9427C40.5098 58.919 40.2859 58.9616 40.0884 59.0651C39.8908 59.1686 39.7283 59.3283 39.6215 59.5242C39.5148 59.72 39.4684 59.9431 39.4884 60.1652C39.5015 60.3129 39.5436 60.4565 39.6122 60.5878C39.6809 60.7192 39.7748 60.8357 39.8886 60.9306C40.0024 61.0256 40.1339 61.0972 40.2754 61.1412C40.4169 61.1853 40.5657 61.201 40.7133 61.1874Z"
              fill="#00BA77"
            />
            <path
              d="M47.9506 60.5377C48.1728 60.5177 48.384 60.4322 48.5575 60.2921C48.7311 60.1519 48.8592 59.9635 48.9257 59.7506C48.9921 59.5377 48.994 59.3099 48.931 59.0959C48.868 58.8819 48.7429 58.6914 48.5717 58.5485C48.4004 58.4056 48.1907 58.3167 47.9689 58.293C47.7471 58.2693 47.5232 58.3119 47.3257 58.4154C47.1281 58.5189 46.9656 58.6787 46.8589 58.8745C46.7521 59.0703 46.7057 59.2934 46.7257 59.5156C46.739 59.6631 46.7812 59.8066 46.85 59.9379C46.9187 60.0691 47.0127 60.1855 47.1264 60.2804C47.2402 60.3753 47.3715 60.4469 47.5129 60.4911C47.6543 60.5352 47.8031 60.5511 47.9506 60.5377Z"
              fill="#00BA77"
            />
            <path
              d="M55.1884 59.881C55.4105 59.8609 55.6218 59.7754 55.7953 59.6353C55.9689 59.4952 56.097 59.3068 56.1635 59.0939C56.2299 58.881 56.2318 58.6531 56.1688 58.4392C56.1058 58.2252 55.9807 58.0347 55.8095 57.8918C55.6382 57.7489 55.4284 57.66 55.2067 57.6363C54.9849 57.6126 54.761 57.6552 54.5635 57.7587C54.3659 57.8622 54.2034 58.0219 54.0966 58.2178C53.9899 58.4136 53.9435 58.6367 53.9635 58.8588C53.9768 59.0064 54.019 59.1499 54.0878 59.2811C54.1565 59.4124 54.2505 59.5288 54.3642 59.6237C54.478 59.7186 54.6093 59.7902 54.7507 59.8343C54.8921 59.8785 55.0409 59.8944 55.1884 59.881Z"
              fill="#00BA77"
            />
            <path
              d="M62.4247 59.2384C62.6473 59.2186 62.8591 59.1333 63.0332 58.9932C63.2073 58.8531 63.3359 58.6645 63.4027 58.4512C63.4696 58.238 63.4717 58.0097 63.4088 57.7953C63.3459 57.5808 63.2208 57.3899 63.0494 57.2466C62.8779 57.1032 62.6678 57.014 62.4456 56.9901C62.2234 56.9662 61.9991 57.0088 61.8011 57.1124C61.6031 57.216 61.4403 57.3761 61.3333 57.5722C61.2263 57.7684 61.1798 57.9919 61.1999 58.2145C61.2267 58.5126 61.3708 58.7879 61.6004 58.9799C61.8301 59.1719 62.1266 59.2649 62.4247 59.2384Z"
              fill="#00BA77"
            />
            <path
              d="M69.6623 58.5887C69.885 58.5692 70.0969 58.4842 70.2713 58.3442C70.4456 58.2043 70.5745 58.0158 70.6416 57.8025C70.7088 57.5893 70.7111 57.3609 70.6484 57.1464C70.5856 56.9318 70.4607 56.7407 70.2892 56.5972C70.1178 56.4537 69.9077 56.3643 69.6854 56.3403C69.4632 56.3163 69.2388 56.3588 69.0407 56.4624C68.8426 56.566 68.6797 56.726 68.5726 56.9223C68.4655 57.1185 68.4191 57.3421 68.4391 57.5647C68.466 57.8626 68.6098 58.1376 68.8391 58.3295C69.0684 58.5214 69.3644 58.6146 69.6623 58.5887Z"
              fill="#00BA77"
            />
            <path
              d="M76.8998 57.9369C77.122 57.9168 77.3332 57.8313 77.5067 57.6912C77.6803 57.5511 77.8084 57.3627 77.8749 57.1498C77.9413 56.9369 77.9432 56.709 77.8802 56.4951C77.8172 56.2811 77.6922 56.0906 77.5209 55.9477C77.3497 55.8048 77.1399 55.7159 76.9181 55.6922C76.6963 55.6685 76.4725 55.7111 76.2749 55.8146C76.0773 55.9181 75.9148 56.0779 75.8081 56.2737C75.7013 56.4695 75.655 56.6926 75.675 56.9147C75.688 57.0624 75.7301 57.206 75.7988 57.3373C75.8674 57.4687 75.9614 57.5852 76.0752 57.6801C76.189 57.7751 76.3204 57.8467 76.4619 57.8907C76.6034 57.9348 76.7522 57.9505 76.8998 57.9369Z"
              fill="#00BA77"
            />
            <path
              d="M84.1366 57.2821C84.3588 57.2621 84.57 57.1766 84.7435 57.0364C84.9171 56.8963 85.0452 56.7079 85.1117 56.495C85.1782 56.2821 85.18 56.0543 85.117 55.8403C85.054 55.6263 84.929 55.4358 84.7577 55.2929C84.5865 55.15 84.3767 55.0611 84.1549 55.0374C83.9331 55.0137 83.7093 55.0563 83.5117 55.1598C83.3141 55.2633 83.1517 55.4231 83.0449 55.6189C82.9381 55.8147 82.8918 56.0378 82.9118 56.26C82.925 56.4075 82.9673 56.551 83.036 56.6823C83.1048 56.8135 83.1987 56.9299 83.3125 57.0248C83.4262 57.1197 83.5575 57.1913 83.6989 57.2355C83.8404 57.2796 83.9891 57.2955 84.1366 57.2821Z"
              fill="#00BA77"
            />
            <path
              d="M91.3754 56.6376C91.5976 56.6175 91.8088 56.532 91.9823 56.3919C92.1559 56.2518 92.284 56.0634 92.3505 55.8505C92.4169 55.6376 92.4188 55.4097 92.3558 55.1957C92.2928 54.9818 92.1677 54.7913 91.9965 54.6484C91.8252 54.5055 91.6155 54.4166 91.3937 54.3929C91.1719 54.3692 90.9481 54.4118 90.7505 54.5153C90.5529 54.6188 90.3904 54.7785 90.2836 54.9744C90.1769 55.1702 90.1305 55.3933 90.1505 55.6154C90.1638 55.763 90.206 55.9065 90.2748 56.0377C90.3435 56.1689 90.4375 56.2854 90.5512 56.3803C90.665 56.4752 90.7963 56.5468 90.9377 56.5909C91.0791 56.6351 91.2279 56.651 91.3754 56.6376Z"
              fill="#00BA77"
            />
            <path
              d="M98.6122 55.9884C98.8344 55.9684 99.0456 55.8829 99.2191 55.7427C99.3927 55.6026 99.5208 55.4142 99.5873 55.2013C99.6538 54.9884 99.6556 54.7605 99.5926 54.5466C99.5296 54.3326 99.4046 54.1421 99.2333 53.9992C99.0621 53.8563 98.8523 53.7674 98.6305 53.7437C98.4087 53.72 98.1849 53.7626 97.9873 53.8661C97.7897 53.9696 97.6273 54.1294 97.5205 54.3252C97.4137 54.521 97.3674 54.7441 97.3874 54.9663C97.4146 55.2641 97.5589 55.5389 97.7885 55.7306C98.0181 55.9222 98.3143 56.0149 98.6122 55.9884Z"
              fill="#00BA77"
            />
            <path
              d="M105.849 55.3384C106.072 55.319 106.283 55.2339 106.458 55.094C106.632 54.9541 106.761 54.7655 106.828 54.5523C106.895 54.3391 106.898 54.1107 106.835 53.8961C106.772 53.6816 106.647 53.4904 106.476 53.3469C106.304 53.2034 106.094 53.114 105.872 53.09C105.65 53.066 105.425 53.1085 105.227 53.2121C105.029 53.3157 104.866 53.4758 104.759 53.672C104.652 53.8683 104.606 54.0919 104.626 54.3145C104.639 54.462 104.681 54.6056 104.749 54.7369C104.818 54.8682 104.912 54.9847 105.025 55.0798C105.139 55.1749 105.27 55.2467 105.411 55.291C105.553 55.3354 105.701 55.3515 105.849 55.3384Z"
              fill="#00BA77"
            />
            <path
              d="M113.093 54.6832C113.315 54.6635 113.527 54.5781 113.701 54.438C113.875 54.2979 114.004 54.1093 114.071 53.8961C114.138 53.6828 114.14 53.4546 114.077 53.2401C114.014 53.0257 113.889 52.8347 113.717 52.6914C113.546 52.5481 113.336 52.4588 113.114 52.4349C112.891 52.4111 112.667 52.4536 112.469 52.5572C112.271 52.6609 112.108 52.8209 112.001 53.0171C111.894 53.2132 111.848 53.4368 111.868 53.6593C111.895 53.9575 112.039 54.2327 112.268 54.4247C112.498 54.6167 112.795 54.7097 113.093 54.6832Z"
              fill="#00BA77"
            />
            <path
              d="M120.324 54.0389C120.546 54.0192 120.758 53.9339 120.932 53.7937C121.106 53.6536 121.235 53.465 121.302 53.2518C121.369 53.0385 121.371 52.8103 121.308 52.5958C121.245 52.3814 121.12 52.1904 120.948 52.0471C120.777 51.9038 120.567 51.8145 120.345 51.7906C120.122 51.7668 119.898 51.8093 119.7 51.913C119.502 52.0166 119.339 52.1766 119.232 52.3728C119.125 52.5689 119.079 52.7925 119.099 53.015C119.126 53.3132 119.27 53.5885 119.499 53.7804C119.729 53.9724 120.026 54.0654 120.324 54.0389Z"
              fill="#00BA77"
            />
            <path
              d="M127.562 53.3876C127.784 53.3675 127.995 53.282 128.169 53.1419C128.342 53.0018 128.471 52.8134 128.537 52.6005C128.603 52.3876 128.605 52.1597 128.542 51.9457C128.479 51.7318 128.354 51.5413 128.183 51.3984C128.012 51.2555 127.802 51.1666 127.58 51.1429C127.358 51.1192 127.135 51.1618 126.937 51.2653C126.739 51.3688 126.577 51.5285 126.47 51.7244C126.363 51.9202 126.317 52.1433 126.337 52.3654C126.35 52.513 126.393 52.6565 126.461 52.7877C126.53 52.919 126.624 53.0354 126.738 53.1303C126.851 53.2252 126.983 53.2968 127.124 53.3409C127.266 53.3851 127.414 53.401 127.562 53.3876Z"
              fill="#00BA77"
            />
            <path
              d="M134.799 52.738C135.021 52.7179 135.232 52.6324 135.406 52.4923C135.579 52.3522 135.707 52.1637 135.774 51.9508C135.84 51.7379 135.842 51.5101 135.779 51.2961C135.716 51.0821 135.591 50.8916 135.42 50.7487C135.249 50.6058 135.039 50.5169 134.817 50.4932C134.595 50.4695 134.371 50.5121 134.174 50.6156C133.976 50.7191 133.814 50.8789 133.707 51.0747C133.6 51.2705 133.554 51.4936 133.574 51.7158C133.601 52.0136 133.745 52.2885 133.975 52.4801C134.205 52.6717 134.501 52.7644 134.799 52.738Z"
              fill="#00BA77"
            />
            <path
              d="M142.037 52.0849C142.259 52.0648 142.47 51.9793 142.643 51.8392C142.817 51.6991 142.945 51.5107 143.012 51.2977C143.078 51.0848 143.08 50.857 143.017 50.643C142.954 50.4291 142.829 50.2386 142.658 50.0957C142.486 49.9528 142.277 49.8638 142.055 49.8401C141.833 49.8164 141.609 49.859 141.412 49.9625C141.214 50.066 141.052 50.2258 140.945 50.4216C140.838 50.6175 140.792 50.8405 140.812 51.0627C140.825 51.2103 140.867 51.3538 140.936 51.485C141.005 51.6162 141.099 51.7326 141.212 51.8275C141.326 51.9225 141.457 51.9941 141.599 52.0382C141.74 52.0824 141.889 52.0982 142.037 52.0849Z"
              fill="#00BA77"
            />
            <path
              d="M149.274 51.4386C149.496 51.4189 149.708 51.3335 149.882 51.1934C150.056 51.0533 150.185 50.8647 150.252 50.6514C150.319 50.4382 150.321 50.2099 150.258 49.9955C150.195 49.7811 150.07 49.5901 149.899 49.4468C149.727 49.3034 149.517 49.2142 149.295 49.1903C149.073 49.1664 148.848 49.209 148.65 49.3126C148.452 49.4162 148.289 49.5762 148.182 49.7724C148.075 49.9686 148.029 50.1921 148.049 50.4147C148.062 50.5624 148.104 50.7061 148.173 50.8375C148.241 50.9689 148.335 51.0856 148.449 51.1807C148.563 51.2758 148.694 51.3475 148.836 51.3917C148.977 51.436 149.126 51.4519 149.274 51.4386Z"
              fill="#00BA77"
            />
            <path
              d="M156.51 50.7889C156.733 50.7692 156.945 50.6839 157.119 50.5437C157.293 50.4036 157.421 50.215 157.488 50.0018C157.555 49.7885 157.557 49.5603 157.494 49.3458C157.431 49.1314 157.306 48.9404 157.135 48.7971C156.963 48.6538 156.753 48.5645 156.531 48.5406C156.309 48.5168 156.085 48.5593 155.887 48.663C155.689 48.7666 155.526 48.9266 155.419 49.1228C155.312 49.3189 155.265 49.5425 155.285 49.765C155.312 50.0632 155.456 50.3385 155.686 50.5304C155.916 50.7224 156.212 50.8154 156.51 50.7889Z"
              fill="#00BA77"
            />
            <path
              d="M163.749 50.1374C163.971 50.1173 164.182 50.0318 164.356 49.8917C164.529 49.7516 164.658 49.5631 164.724 49.3502C164.79 49.1373 164.792 48.9095 164.729 48.6955C164.666 48.4815 164.541 48.2911 164.37 48.1481C164.199 48.0052 163.989 47.9163 163.767 47.8926C163.545 47.8689 163.322 47.9115 163.124 48.015C162.926 48.1185 162.764 48.2783 162.657 48.4741C162.55 48.6699 162.504 48.893 162.524 49.1152C162.537 49.2628 162.579 49.4064 162.648 49.5378C162.717 49.6691 162.81 49.7856 162.924 49.8806C163.038 49.9755 163.17 50.0471 163.311 50.0912C163.453 50.1352 163.601 50.1509 163.749 50.1374Z"
              fill="#00BA77"
            />
            <path
              d="M170.985 49.486C171.207 49.4659 171.419 49.3804 171.592 49.2403C171.766 49.1002 171.894 48.9118 171.96 48.6989C172.027 48.486 172.029 48.2581 171.966 48.0441C171.903 47.8302 171.778 47.6397 171.606 47.4968C171.435 47.3539 171.225 47.2649 171.004 47.2412C170.782 47.2176 170.558 47.2601 170.36 47.3637C170.163 47.4672 170 47.6269 169.894 47.8228C169.787 48.0186 169.74 48.2417 169.76 48.4638C169.788 48.7616 169.932 49.0365 170.162 49.2281C170.391 49.4197 170.687 49.5125 170.985 49.486Z"
              fill="#00BA77"
            />
            <path
              d="M178.222 48.8382C178.445 48.8185 178.656 48.7332 178.83 48.5933C179.004 48.4534 179.132 48.265 179.199 48.0521C179.265 47.8392 179.268 47.6113 179.205 47.3972C179.142 47.1831 179.017 46.9924 178.846 46.8493C178.675 46.7062 178.465 46.6172 178.243 46.5934C178.021 46.5695 177.797 46.6121 177.599 46.7156C177.402 46.8191 177.239 46.9788 177.132 47.1747C177.025 47.3706 176.979 47.5938 176.999 47.816C177.026 48.1137 177.17 48.3886 177.399 48.5802C177.629 48.7719 177.925 48.8647 178.222 48.8382Z"
              fill="#00BA77"
            />
            <path
              d="M185.46 48.1884C185.683 48.1683 185.894 48.0828 186.067 47.9427C186.241 47.8026 186.369 47.6142 186.435 47.4013C186.502 47.1883 186.504 46.9605 186.441 46.7465C186.378 46.5326 186.253 46.3421 186.081 46.1992C185.91 46.0563 185.7 45.9673 185.479 45.9436C185.257 45.9199 185.033 45.9625 184.835 46.066C184.638 46.1696 184.475 46.3293 184.369 46.5251C184.262 46.721 184.216 46.9441 184.235 47.1662C184.249 47.3138 184.291 47.4573 184.36 47.5885C184.428 47.7197 184.522 47.8361 184.636 47.9311C184.75 48.026 184.881 48.0976 185.023 48.1417C185.164 48.1859 185.313 48.2017 185.46 48.1884Z"
              fill="#00BA77"
            />
            <path
              d="M192.697 47.5387C192.92 47.519 193.132 47.4336 193.306 47.2935C193.48 47.1534 193.608 46.9648 193.675 46.7515C193.742 46.5383 193.744 46.31 193.681 46.0956C193.618 45.8811 193.493 45.6902 193.322 45.5469C193.15 45.4035 192.94 45.3143 192.718 45.2904C192.496 45.2665 192.272 45.3091 192.074 45.4127C191.876 45.5163 191.713 45.6764 191.606 45.8725C191.499 46.0687 191.452 46.2922 191.472 46.5148C191.485 46.6625 191.527 46.8062 191.596 46.9376C191.665 47.069 191.759 47.1856 191.872 47.2808C191.986 47.3759 192.118 47.4476 192.259 47.4918C192.401 47.5361 192.55 47.552 192.697 47.5387Z"
              fill="#00BA77"
            />
            <path
              d="M199.936 46.8873C200.159 46.8676 200.37 46.7822 200.544 46.6421C200.718 46.502 200.847 46.3134 200.914 46.1002C200.981 45.8869 200.983 45.6587 200.92 45.4442C200.857 45.2298 200.732 45.0388 200.561 44.8955C200.389 44.7522 200.179 44.6629 199.957 44.639C199.735 44.6152 199.51 44.6577 199.312 44.7613C199.114 44.865 198.952 45.025 198.845 45.2212C198.738 45.4173 198.691 45.6409 198.711 45.8634C198.738 46.1616 198.882 46.4369 199.112 46.6288C199.341 46.8208 199.638 46.9138 199.936 46.8873Z"
              fill="#00BA77"
            />
            <path
              d="M207.167 46.2375C207.389 46.2174 207.6 46.1319 207.774 45.9918C207.947 45.8517 208.075 45.6632 208.142 45.4503C208.208 45.2374 208.21 45.0096 208.147 44.7956C208.084 44.5816 207.959 44.3912 207.788 44.2483C207.617 44.1053 207.407 44.0164 207.185 43.9927C206.963 43.969 206.74 44.0116 206.542 44.1151C206.344 44.2186 206.182 44.3784 206.075 44.5742C205.968 44.77 205.922 44.9931 205.942 45.2153C205.955 45.3628 205.998 45.5063 206.066 45.6376C206.135 45.7688 206.229 45.8852 206.343 45.9801C206.456 46.0751 206.588 46.1466 206.729 46.1908C206.871 46.2349 207.019 46.2508 207.167 46.2375Z"
              fill="#00BA77"
            />
            <path
              d="M214.408 45.588C214.631 45.5682 214.842 45.483 215.016 45.343C215.19 45.2031 215.318 45.0148 215.385 44.8019C215.451 44.5889 215.454 44.361 215.391 44.1469C215.328 43.9328 215.203 43.7422 215.032 43.5991C214.861 43.456 214.651 43.3669 214.429 43.3431C214.207 43.3193 213.983 43.3618 213.785 43.4653C213.588 43.5688 213.425 43.7286 213.318 43.9245C213.212 44.1204 213.165 44.3436 213.185 44.5658C213.212 44.8635 213.356 45.1383 213.585 45.33C213.815 45.5216 214.111 45.6144 214.408 45.588Z"
              fill="#00BA77"
            />
            <path
              d="M221.647 44.9382C221.869 44.9181 222.08 44.8326 222.254 44.6925C222.427 44.5523 222.555 44.3639 222.622 44.151C222.688 43.9381 222.69 43.7103 222.627 43.4963C222.564 43.2823 222.439 43.0918 222.268 42.9489C222.097 42.806 221.887 42.7171 221.665 42.6934C221.443 42.6697 221.22 42.7123 221.022 42.8158C220.824 42.9193 220.662 43.0791 220.555 43.2749C220.448 43.4707 220.402 43.6938 220.422 43.916C220.435 44.0635 220.478 44.207 220.546 44.3383C220.615 44.4695 220.709 44.5859 220.823 44.6808C220.936 44.7757 221.068 44.8473 221.209 44.8915C221.351 44.9356 221.499 44.9515 221.647 44.9382Z"
              fill="#00BA77"
            />
            <path
              d="M228.884 44.2885C229.106 44.2684 229.318 44.1829 229.491 44.0428C229.665 43.9027 229.793 43.7143 229.859 43.5014C229.926 43.2884 229.928 43.0606 229.865 42.8466C229.802 42.6327 229.677 42.4422 229.505 42.2993C229.334 42.1564 229.124 42.0674 228.902 42.0437C228.681 42.02 228.457 42.0626 228.259 42.1661C228.062 42.2697 227.899 42.4294 227.792 42.6252C227.686 42.8211 227.639 43.0442 227.659 43.2663C227.673 43.4139 227.715 43.5574 227.784 43.6886C227.852 43.8198 227.946 43.9362 228.06 44.0312C228.174 44.1261 228.305 44.1977 228.447 44.2418C228.588 44.286 228.737 44.3018 228.884 44.2885Z"
              fill="#00BA77"
            />
            <path
              d="M236.122 43.639C236.345 43.6193 236.557 43.534 236.731 43.3938C236.905 43.2537 237.034 43.0651 237.1 42.8519C237.167 42.6386 237.169 42.4104 237.107 42.1959C237.044 41.9815 236.919 41.7905 236.747 41.6472C236.576 41.5039 236.366 41.4146 236.143 41.3907C235.921 41.3669 235.697 41.4094 235.499 41.513C235.301 41.6167 235.138 41.7767 235.031 41.9729C234.924 42.1691 234.878 42.3926 234.898 42.6151C234.911 42.7628 234.953 42.9065 235.021 43.038C235.09 43.1694 235.184 43.286 235.298 43.3811C235.411 43.4762 235.543 43.5479 235.684 43.5922C235.826 43.6365 235.975 43.6524 236.122 43.639Z"
              fill="#00BA77"
            />
            <path
              d="M243.359 42.9889C243.582 42.9691 243.794 42.8838 243.968 42.7437C244.142 42.6036 244.27 42.415 244.337 42.2017C244.404 41.9885 244.406 41.7602 244.343 41.5458C244.281 41.3313 244.155 41.1404 243.984 40.9971C243.812 40.8537 243.602 40.7645 243.38 40.7406C243.158 40.7167 242.934 40.7593 242.736 40.8629C242.538 40.9665 242.375 41.1266 242.268 41.3227C242.161 41.5189 242.114 41.7424 242.134 41.965C242.162 42.2629 242.306 42.538 242.535 42.7298C242.765 42.9217 243.061 43.0149 243.359 42.9889Z"
              fill="#00BA77"
            />
            <path
              d="M250.596 42.3377C250.818 42.318 251.03 42.2327 251.203 42.0928C251.377 41.9529 251.506 41.7645 251.572 41.5516C251.639 41.3387 251.641 41.1108 251.578 40.8967C251.515 40.6826 251.39 40.4919 251.219 40.3489C251.048 40.2058 250.838 40.1167 250.616 40.0929C250.395 40.0691 250.171 40.1116 249.973 40.2151C249.775 40.3186 249.613 40.4784 249.506 40.6742C249.399 40.8701 249.353 41.0933 249.373 41.3155C249.4 41.6132 249.543 41.8881 249.773 42.0797C250.002 42.2714 250.298 42.3642 250.596 42.3377Z"
              fill="#00BA77"
            />
            <path
              d="M257.834 41.6899C258.057 41.6698 258.268 41.5843 258.441 41.4442C258.615 41.3041 258.743 41.1156 258.809 40.9027C258.876 40.6898 258.878 40.462 258.815 40.248C258.752 40.034 258.627 39.8436 258.455 39.7006C258.284 39.5577 258.074 39.4688 257.853 39.4451C257.631 39.4214 257.407 39.464 257.209 39.5675C257.012 39.671 256.849 39.8308 256.743 40.0266C256.636 40.2224 256.59 40.4455 256.61 40.6677C256.623 40.8153 256.665 40.9589 256.733 41.0903C256.802 41.2216 256.896 41.3381 257.01 41.4331C257.124 41.528 257.255 41.5996 257.396 41.6437C257.538 41.6877 257.687 41.7034 257.834 41.6899Z"
              fill="#00BA77"
            />
            <path
              d="M265.071 41.0385C265.293 41.0184 265.504 40.9329 265.678 40.7928C265.851 40.6527 265.979 40.4643 266.046 40.2514C266.112 40.0384 266.114 39.8106 266.051 39.5966C265.988 39.3827 265.863 39.1922 265.692 39.0493C265.521 38.9064 265.311 38.8174 265.089 38.7937C264.867 38.77 264.643 38.8126 264.446 38.9161C264.248 39.0197 264.086 39.1794 263.979 39.3753C263.872 39.5711 263.826 39.7942 263.846 40.0163C263.859 40.1639 263.901 40.3074 263.97 40.4386C264.039 40.5698 264.133 40.6862 264.247 40.7812C264.36 40.8761 264.492 40.9477 264.633 40.9918C264.774 41.036 264.923 41.0518 265.071 41.0385Z"
              fill="#00BA77"
            />
            <path
              d="M272.309 40.3886C272.531 40.3685 272.742 40.283 272.916 40.1429C273.089 40.0028 273.218 39.8144 273.284 39.6015C273.351 39.3885 273.352 39.1607 273.289 38.9467C273.226 38.7328 273.101 38.5423 272.93 38.3994C272.759 38.2565 272.549 38.1675 272.327 38.1438C272.105 38.1201 271.882 38.1627 271.684 38.2662C271.486 38.3697 271.324 38.5295 271.217 38.7254C271.11 38.9212 271.064 39.1443 271.084 39.3664C271.097 39.514 271.14 39.6575 271.208 39.7887C271.277 39.9199 271.371 40.0363 271.485 40.1313C271.599 40.2262 271.73 40.2978 271.871 40.3419C272.013 40.3861 272.161 40.4019 272.309 40.3886Z"
              fill="#00BA77"
            />
            <path
              d="M279.546 39.7387C279.768 39.7189 279.98 39.6336 280.154 39.4935C280.328 39.3533 280.457 39.1647 280.524 38.9515C280.591 38.7383 280.593 38.51 280.53 38.2955C280.467 38.0811 280.342 37.8901 280.17 37.7468C279.999 37.6035 279.789 37.5142 279.567 37.4904C279.345 37.4665 279.12 37.509 278.922 37.6127C278.724 37.7163 278.561 37.8763 278.454 38.0725C278.347 38.2687 278.301 38.4922 278.321 38.7147C278.348 39.0129 278.492 39.2882 278.722 39.4802C278.951 39.6721 279.248 39.7651 279.546 39.7387Z"
              fill="#00BA77"
            />
            <path
              d="M286.784 39.0912C287.007 39.0714 287.218 38.9861 287.393 38.846C287.567 38.7059 287.695 38.5173 287.762 38.304C287.829 38.0908 287.831 37.8625 287.768 37.6481C287.705 37.4336 287.58 37.2427 287.409 37.0994C287.237 36.956 287.027 36.8668 286.805 36.8429C286.583 36.819 286.359 36.8616 286.161 36.9652C285.963 37.0688 285.8 37.2288 285.693 37.425C285.586 37.6212 285.539 37.8447 285.559 38.0673C285.586 38.3654 285.73 38.6407 285.96 38.8327C286.189 39.0247 286.486 39.1176 286.784 39.0912Z"
              fill="#00BA77"
            />
            <path
              d="M294.02 38.4379C294.243 38.4178 294.454 38.3323 294.627 38.1922C294.801 38.0521 294.929 37.8637 294.995 37.6508C295.062 37.4378 295.064 37.21 295.001 36.996C294.938 36.7821 294.813 36.5916 294.642 36.4487C294.47 36.3058 294.26 36.2168 294.039 36.1932C293.817 36.1695 293.593 36.2121 293.395 36.3156C293.198 36.4191 293.035 36.5788 292.929 36.7747C292.822 36.9705 292.776 37.1936 292.796 37.4157C292.809 37.5634 292.851 37.707 292.919 37.8383C292.988 37.9697 293.082 38.0862 293.196 38.1811C293.31 38.2761 293.441 38.3477 293.583 38.3917C293.724 38.4358 293.873 38.4515 294.02 38.4379Z"
              fill="#00BA77"
            />
            <path
              d="M41.3671 68.4257C41.5897 68.4059 41.8015 68.3206 41.9756 68.1805C42.1496 68.0403 42.2783 67.8517 42.3451 67.6385C42.412 67.4253 42.4141 67.197 42.3512 66.9826C42.2883 66.7681 42.1632 66.5772 41.9918 66.4338C41.8203 66.2905 41.6102 66.2012 41.388 66.1774C41.1658 66.1535 40.9415 66.196 40.7435 66.2997C40.5455 66.4033 40.3827 66.5633 40.2757 66.7595C40.1686 66.9557 40.1222 67.1792 40.1422 67.4018C40.1553 67.5495 40.1974 67.6931 40.266 67.8246C40.3347 67.956 40.4286 68.0726 40.5424 68.1677C40.6561 68.2628 40.7875 68.3345 40.9291 68.3788C41.0706 68.4231 41.2194 68.439 41.3671 68.4257Z"
              fill="#00BA77"
            />
            <path
              d="M48.5998 67.7759C48.8225 67.7565 49.0344 67.6714 49.2088 67.5315C49.3831 67.3916 49.512 67.203 49.5791 66.9898C49.6463 66.7766 49.6486 66.5482 49.5859 66.3336C49.5231 66.1191 49.3982 65.9279 49.2267 65.7844C49.0553 65.6409 48.8452 65.5515 48.6229 65.5275C48.4007 65.5035 48.1763 65.546 47.9782 65.6496C47.7801 65.7532 47.6172 65.9133 47.5101 66.1095C47.403 66.3058 47.3566 66.5294 47.3766 66.752C47.4035 67.0498 47.5473 67.3248 47.7766 67.5168C48.0059 67.7087 48.3019 67.8019 48.5998 67.7759Z"
              fill="#00BA77"
            />
            <path
              d="M55.8378 67.1244C56.06 67.1043 56.2712 67.0188 56.4447 66.8787C56.6183 66.7386 56.7464 66.5502 56.8129 66.3373C56.8793 66.1244 56.8812 65.8965 56.8182 65.6826C56.7552 65.4686 56.6302 65.2781 56.4589 65.1352C56.2876 64.9923 56.0779 64.9034 55.8561 64.8797C55.6343 64.856 55.4105 64.8986 55.2129 65.0021C55.0153 65.1056 54.8528 65.2654 54.7461 65.4612C54.6393 65.657 54.5929 65.8801 54.6129 66.1022C54.6262 66.2498 54.6684 66.3933 54.7372 66.5245C54.8059 66.6558 54.8999 66.7722 55.0136 66.8671C55.1274 66.962 55.2587 67.0336 55.4001 67.0778C55.5415 67.1219 55.6903 67.1378 55.8378 67.1244Z"
              fill="#00BA77"
            />
            <path
              d="M63.0746 66.4748C63.2968 66.4547 63.508 66.3692 63.6815 66.2291C63.8551 66.089 63.9832 65.9006 64.0497 65.6876C64.1162 65.4747 64.118 65.2469 64.055 65.0329C63.992 64.8189 63.867 64.6285 63.6957 64.4856C63.5245 64.3426 63.3147 64.2537 63.0929 64.23C62.8711 64.2063 62.6473 64.2489 62.4497 64.3524C62.2521 64.4559 62.0897 64.6157 61.9829 64.8115C61.8761 65.0073 61.8298 65.2304 61.8498 65.4526C61.863 65.6001 61.9053 65.7436 61.974 65.8749C62.0428 66.0061 62.1367 66.1225 62.2504 66.2174C62.3642 66.3124 62.4955 66.3839 62.6369 66.4281C62.7784 66.4723 62.9271 66.4881 63.0746 66.4748Z"
              fill="#00BA77"
            />
            <path
              d="M70.3139 65.8254C70.536 65.8053 70.7472 65.7198 70.9208 65.5797C71.0943 65.4396 71.2225 65.2511 71.2889 65.0382C71.3554 64.8253 71.3573 64.5975 71.2943 64.3835C71.2313 64.1695 71.1062 63.9791 70.935 63.8361C70.7637 63.6932 70.5539 63.6043 70.3321 63.5806C70.1104 63.5569 69.8865 63.5995 69.6889 63.703C69.4914 63.8065 69.3289 63.9663 69.2221 64.1621C69.1153 64.3579 69.069 64.581 69.089 64.8032C69.1023 64.9507 69.1445 65.0942 69.2133 65.2255C69.282 65.3567 69.3759 65.4731 69.4897 65.568C69.6034 65.6629 69.7348 65.7345 69.8762 65.7787C70.0176 65.8228 70.1663 65.8387 70.3139 65.8254Z"
              fill="#00BA77"
            />
            <path
              d="M77.5502 65.1754C77.7728 65.1557 77.9846 65.0703 78.1586 64.9302C78.3327 64.7901 78.4614 64.6015 78.5282 64.3883C78.5951 64.175 78.5972 63.9467 78.5343 63.7323C78.4714 63.5179 78.3463 63.3269 78.1749 63.1836C78.0034 63.0403 77.7933 62.951 77.5711 62.9271C77.3489 62.9032 77.1246 62.9458 76.9266 63.0494C76.7286 63.153 76.5658 63.3131 76.4588 63.5093C76.3517 63.7054 76.3053 63.9289 76.3253 64.1515C76.3522 64.4496 76.4963 64.7249 76.7259 64.9169C76.9556 65.1089 77.2521 65.2019 77.5502 65.1754Z"
              fill="#00BA77"
            />
            <path
              d="M84.7868 64.5257C85.0095 64.5062 85.2214 64.4212 85.3958 64.2812C85.5701 64.1413 85.699 63.9528 85.7661 63.7395C85.8333 63.5263 85.8356 63.2979 85.7729 63.0834C85.7102 62.8688 85.5852 62.6777 85.4138 62.5342C85.2423 62.3907 85.0322 62.3013 84.8099 62.2773C84.5877 62.2533 84.3633 62.2958 84.1652 62.3994C83.9671 62.503 83.8042 62.663 83.6971 62.8593C83.59 63.0555 83.5436 63.2791 83.5636 63.5018C83.5767 63.6493 83.6187 63.7928 83.6873 63.9241C83.7558 64.0555 83.8496 64.172 83.9631 64.2671C84.0767 64.3621 84.2079 64.4339 84.3492 64.4783C84.4906 64.5227 84.6392 64.5388 84.7868 64.5257Z"
              fill="#00BA77"
            />
            <path
              d="M92.0248 63.8763C92.2474 63.8566 92.4592 63.7713 92.6333 63.6312C92.8073 63.491 92.936 63.3024 93.0028 63.0892C93.0697 62.8759 93.0718 62.6477 93.0089 62.4332C92.946 62.2188 92.8209 62.0278 92.6495 61.8845C92.478 61.7412 92.2679 61.6519 92.0457 61.6281C91.8235 61.6042 91.5992 61.6467 91.4012 61.7504C91.2032 61.854 91.0404 62.014 90.9334 62.2102C90.8264 62.4064 90.7799 62.6299 90.8 62.8524C90.8268 63.1506 90.9709 63.4259 91.2005 63.6178C91.4302 63.8098 91.7267 63.9028 92.0248 63.8763Z"
              fill="#00BA77"
            />
            <path
              d="M99.2621 63.2245C99.4843 63.2044 99.6955 63.1189 99.869 62.9788C100.043 62.8387 100.171 62.6503 100.237 62.4374C100.304 62.2245 100.306 61.9966 100.243 61.7827C100.18 61.5687 100.054 61.3782 99.8832 61.2353C99.712 61.0924 99.5022 61.0035 99.2804 60.9798C99.0586 60.9561 98.8348 60.9987 98.6372 61.1022C98.4396 61.2057 98.2772 61.3655 98.1704 61.5613C98.0636 61.7571 98.0173 61.9802 98.0373 62.2024C98.0505 62.3499 98.0928 62.4934 98.1615 62.6246C98.2303 62.7559 98.3242 62.8723 98.4379 62.9672C98.5517 63.0621 98.683 63.1337 98.8244 63.1779C98.9659 63.222 99.1146 63.2379 99.2621 63.2245Z"
              fill="#00BA77"
            />
            <path
              d="M106.509 62.5749C106.731 62.5548 106.943 62.4693 107.116 62.3292C107.29 62.1891 107.418 62.0006 107.484 61.7877C107.551 61.5748 107.553 61.347 107.49 61.133C107.427 60.919 107.302 60.7286 107.13 60.5857C106.959 60.4427 106.749 60.3538 106.527 60.3301C106.306 60.3064 106.082 60.349 105.884 60.4525C105.687 60.556 105.524 60.7158 105.417 60.9116C105.311 61.1075 105.264 61.3305 105.284 61.5527C105.298 61.7003 105.34 61.8437 105.409 61.975C105.477 62.1062 105.571 62.2226 105.685 62.3175C105.799 62.4125 105.93 62.484 106.072 62.5282C106.213 62.5723 106.362 62.5882 106.509 62.5749Z"
              fill="#00BA77"
            />
            <path
              d="M113.737 61.9252C113.959 61.9051 114.17 61.8196 114.344 61.6795C114.517 61.5394 114.645 61.351 114.712 61.1381C114.778 60.9252 114.78 60.6973 114.717 60.4834C114.654 60.2694 114.529 60.0789 114.358 59.936C114.187 59.7931 113.977 59.7042 113.755 59.6805C113.533 59.6568 113.309 59.6994 113.112 59.8029C112.914 59.9064 112.752 60.0661 112.645 60.262C112.538 60.4578 112.492 60.6809 112.512 60.903C112.525 61.0506 112.567 61.1941 112.636 61.3253C112.705 61.4566 112.799 61.573 112.913 61.6679C113.026 61.7628 113.158 61.8344 113.299 61.8785C113.44 61.9227 113.589 61.9386 113.737 61.9252Z"
              fill="#00BA77"
            />
            <path
              d="M120.973 61.2758C121.196 61.256 121.407 61.1707 121.582 61.0306C121.756 60.8904 121.884 60.7018 121.951 60.4886C122.018 60.2754 122.02 60.0471 121.957 59.8326C121.894 59.6182 121.769 59.4272 121.598 59.2839C121.426 59.1406 121.216 59.0513 120.994 59.0275C120.772 59.0036 120.547 59.0462 120.349 59.1498C120.151 59.2534 119.989 59.4134 119.882 59.6096C119.775 59.8058 119.728 60.0293 119.748 60.2519C119.761 60.3996 119.803 60.5432 119.872 60.6747C119.941 60.8061 120.035 60.9227 120.148 61.0178C120.262 61.1129 120.393 61.1847 120.535 61.2289C120.677 61.2732 120.825 61.2891 120.973 61.2758Z"
              fill="#00BA77"
            />
            <path
              d="M128.212 60.6261C128.434 60.6064 128.646 60.521 128.82 60.3809C128.994 60.2408 129.123 60.0522 129.19 59.8389C129.257 59.6257 129.259 59.3974 129.196 59.183C129.133 58.9686 129.008 58.7776 128.836 58.6343C128.665 58.4909 128.455 58.4017 128.233 58.3778C128.011 58.3539 127.786 58.3965 127.588 58.5001C127.39 58.6037 127.227 58.7638 127.12 58.9599C127.013 59.1561 126.967 59.3796 126.987 59.6022C127 59.7499 127.042 59.8936 127.111 60.025C127.179 60.1564 127.273 60.273 127.387 60.3682C127.501 60.4633 127.632 60.535 127.774 60.5793C127.915 60.6235 128.064 60.6394 128.212 60.6261Z"
              fill="#00BA77"
            />
            <path
              d="M135.441 59.9762C135.664 59.9564 135.876 59.8711 136.05 59.731C136.224 59.5909 136.352 59.4023 136.419 59.189C136.486 58.9758 136.488 58.7475 136.425 58.5331C136.363 58.3186 136.237 58.1277 136.066 57.9844C135.895 57.841 135.684 57.7518 135.462 57.7279C135.24 57.704 135.016 57.7466 134.818 57.8502C134.62 57.9538 134.457 58.1139 134.35 58.31C134.243 58.5062 134.196 58.7297 134.216 58.9523C134.243 59.2504 134.387 59.5257 134.617 59.7177C134.847 59.9097 135.143 60.0027 135.441 59.9762Z"
              fill="#00BA77"
            />
            <path
              d="M142.687 59.3249C142.909 59.3048 143.12 59.2193 143.294 59.0792C143.467 58.9391 143.596 58.7506 143.662 58.5377C143.728 58.3248 143.73 58.097 143.667 57.883C143.604 57.669 143.479 57.4786 143.308 57.3356C143.137 57.1927 142.927 57.1038 142.705 57.0801C142.483 57.0564 142.26 57.099 142.062 57.2025C141.864 57.306 141.702 57.4658 141.595 57.6616C141.488 57.8574 141.442 58.0805 141.462 58.3027C141.475 58.4502 141.518 58.5937 141.586 58.725C141.655 58.8562 141.749 58.9726 141.863 59.0675C141.976 59.1625 142.108 59.234 142.249 59.2782C142.391 59.3223 142.539 59.3382 142.687 59.3249Z"
              fill="#00BA77"
            />
            <path
              d="M149.924 58.6679C150.146 58.6478 150.358 58.5623 150.531 58.4222C150.705 58.2821 150.833 58.0937 150.899 57.8808C150.966 57.6678 150.968 57.44 150.905 57.226C150.842 57.0121 150.717 56.8216 150.545 56.6787C150.374 56.5358 150.164 56.4468 149.943 56.4231C149.721 56.3994 149.497 56.442 149.299 56.5455C149.102 56.649 148.939 56.8088 148.832 57.0046C148.726 57.2005 148.679 57.4236 148.699 57.6457C148.712 57.7933 148.754 57.937 148.823 58.0683C148.892 58.1996 148.986 58.3161 149.1 58.4111C149.213 58.5061 149.345 58.5776 149.486 58.6217C149.628 58.6658 149.777 58.6815 149.924 58.6679Z"
              fill="#00BA77"
            />
            <path
              d="M157.16 58.0255C157.383 58.0057 157.594 57.9205 157.768 57.7805C157.942 57.6406 158.07 57.4523 158.137 57.2394C158.203 57.0265 158.206 56.7985 158.143 56.5844C158.08 56.3703 157.955 56.1797 157.784 56.0366C157.612 55.8935 157.403 55.8044 157.181 55.7806C156.959 55.7568 156.735 55.7993 156.537 55.9028C156.34 56.0063 156.177 56.1661 156.07 56.362C155.963 56.5579 155.917 56.7811 155.937 57.0033C155.964 57.301 156.108 57.5758 156.337 57.7675C156.567 57.9591 156.863 58.0519 157.16 58.0255Z"
              fill="#00BA77"
            />
            <path
              d="M164.398 57.3758C164.621 57.3561 164.833 57.2708 165.007 57.1307C165.181 56.9905 165.31 56.8019 165.376 56.5887C165.443 56.3755 165.445 56.1472 165.382 55.9327C165.32 55.7183 165.194 55.5273 165.023 55.384C164.852 55.2407 164.641 55.1514 164.419 55.1276C164.197 55.1037 163.973 55.1462 163.775 55.2499C163.577 55.3535 163.414 55.5135 163.307 55.7097C163.2 55.9059 163.153 56.1294 163.173 56.3519C163.187 56.4997 163.229 56.6433 163.297 56.7748C163.366 56.9062 163.46 57.0228 163.574 57.1179C163.687 57.213 163.819 57.2847 163.96 57.329C164.102 57.3733 164.251 57.3892 164.398 57.3758Z"
              fill="#00BA77"
            />
            <path
              d="M171.635 56.7262C171.858 56.7064 172.07 56.6211 172.244 56.481C172.418 56.3409 172.546 56.1523 172.613 55.939C172.68 55.7258 172.682 55.4975 172.619 55.2831C172.556 55.0686 172.431 54.8777 172.26 54.7344C172.088 54.591 171.878 54.5018 171.656 54.4779C171.434 54.454 171.21 54.4966 171.012 54.6002C170.814 54.7038 170.651 54.8638 170.544 55.06C170.437 55.2562 170.39 55.4797 170.41 55.7023C170.423 55.85 170.465 55.9937 170.534 56.1251C170.603 56.2565 170.697 56.3731 170.81 56.4682C170.924 56.5633 171.056 56.6351 171.197 56.6793C171.339 56.7236 171.488 56.7395 171.635 56.7262Z"
              fill="#00BA77"
            />
            <path
              d="M178.874 56.0692C179.097 56.0495 179.308 55.9641 179.482 55.824C179.656 55.6839 179.785 55.4953 179.852 55.2821C179.919 55.0688 179.921 54.8405 179.858 54.6261C179.795 54.4117 179.67 54.2207 179.499 54.0774C179.327 53.9341 179.117 53.8448 178.895 53.8209C178.673 53.797 178.448 53.8396 178.25 53.9432C178.052 54.0468 177.89 54.2069 177.783 54.4031C177.675 54.5992 177.629 54.8227 177.649 55.0453C177.676 55.3434 177.82 55.6187 178.05 55.8107C178.279 56.0027 178.576 56.0957 178.874 56.0692Z"
              fill="#00BA77"
            />
            <path
              d="M186.11 55.425C186.332 55.4049 186.544 55.3194 186.717 55.1793C186.891 55.0392 187.019 54.8507 187.085 54.6378C187.152 54.4249 187.154 54.1971 187.091 53.9831C187.028 53.7691 186.903 53.5787 186.731 53.4357C186.56 53.2928 186.35 53.2039 186.129 53.1802C185.907 53.1565 185.683 53.1991 185.485 53.3026C185.288 53.4061 185.125 53.5659 185.019 53.7617C184.912 53.9575 184.865 54.1806 184.885 54.4028C184.913 54.7006 185.057 54.9755 185.287 55.1671C185.516 55.3587 185.812 55.4514 186.11 55.425Z"
              fill="#00BA77"
            />
            <path
              d="M193.346 54.7752C193.569 54.7555 193.78 54.6702 193.954 54.5303C194.128 54.3904 194.256 54.202 194.323 53.9891C194.389 53.7762 194.392 53.5483 194.329 53.3342C194.266 53.1201 194.141 52.9294 193.97 52.7863C193.799 52.6433 193.589 52.5542 193.367 52.5304C193.145 52.5066 192.921 52.5491 192.723 52.6526C192.526 52.7561 192.363 52.9159 192.256 53.1117C192.15 53.3076 192.103 53.5308 192.123 53.753C192.15 54.0507 192.294 54.3256 192.523 54.5172C192.753 54.7089 193.049 54.8017 193.346 54.7752Z"
              fill="#00BA77"
            />
            <path
              d="M200.583 54.1254C200.806 54.1053 201.017 54.0198 201.19 53.8797C201.364 53.7396 201.492 53.5512 201.558 53.3383C201.625 53.1253 201.627 52.8975 201.564 52.6835C201.501 52.4696 201.376 52.2791 201.204 52.1362C201.033 51.9933 200.823 51.9043 200.602 51.8807C200.38 51.857 200.156 51.8996 199.958 52.0031C199.761 52.1066 199.598 52.2663 199.492 52.4622C199.385 52.658 199.339 52.8811 199.359 53.1032C199.372 53.2508 199.414 53.3943 199.483 53.5255C199.552 53.6567 199.645 53.7731 199.759 53.8681C199.873 53.963 200.004 54.0346 200.146 54.0787C200.287 54.1229 200.436 54.1387 200.583 54.1254Z"
              fill="#00BA77"
            />
            <path
              d="M207.822 53.4709C208.044 53.4508 208.255 53.3654 208.429 53.2254C208.602 53.0854 208.731 52.8971 208.797 52.6843C208.864 52.4714 208.866 52.2437 208.803 52.0297C208.74 51.8158 208.615 51.6253 208.444 51.4822C208.273 51.3392 208.063 51.2501 207.842 51.2262C207.62 51.2023 207.396 51.2447 207.198 51.3479C207.001 51.4511 206.838 51.6107 206.731 51.8063C206.624 52.0019 206.578 52.2249 206.597 52.447C206.624 52.7451 206.768 53.0204 206.998 53.2124C207.227 53.4044 207.524 53.4973 207.822 53.4709Z"
              fill="#00BA77"
            />
            <path
              d="M215.06 52.8263C215.283 52.8065 215.495 52.7212 215.669 52.5811C215.843 52.441 215.972 52.2524 216.038 52.0391C216.105 51.8259 216.107 51.5976 216.045 51.3832C215.982 51.1687 215.857 50.9778 215.685 50.8345C215.514 50.6911 215.304 50.6019 215.081 50.578C214.859 50.5541 214.635 50.5967 214.437 50.7003C214.239 50.8039 214.076 50.9639 213.969 51.1601C213.862 51.3563 213.816 51.5798 213.836 51.8024C213.862 52.1005 214.007 52.3758 214.236 52.5678C214.466 52.7598 214.762 52.8528 215.06 52.8263Z"
              fill="#00BA77"
            />
            <path
              d="M222.297 52.1766C222.52 52.1569 222.732 52.0716 222.906 51.9314C223.08 51.7913 223.208 51.6027 223.275 51.3895C223.342 51.1762 223.344 50.948 223.281 50.7335C223.218 50.5191 223.093 50.3281 222.922 50.1848C222.75 50.0415 222.54 49.9522 222.318 49.9283C222.096 49.9045 221.872 49.947 221.674 50.0506C221.476 50.1543 221.313 50.3143 221.206 50.5105C221.099 50.7066 221.052 50.9302 221.072 51.1527C221.1 51.4507 221.244 51.7257 221.473 51.9176C221.703 52.1095 221.999 52.2026 222.297 52.1766Z"
              fill="#00BA77"
            />
            <path
              d="M229.534 51.5253C229.756 51.5052 229.967 51.4197 230.141 51.2796C230.315 51.1395 230.443 50.9511 230.509 50.7382C230.576 50.5253 230.577 50.2974 230.514 50.0834C230.451 49.8695 230.326 49.679 230.155 49.5361C229.984 49.3932 229.774 49.3042 229.552 49.2806C229.331 49.2569 229.107 49.2995 228.909 49.403C228.712 49.5065 228.549 49.6662 228.442 49.8621C228.336 50.0579 228.289 50.281 228.309 50.5031C228.323 50.6507 228.365 50.7942 228.433 50.9254C228.502 51.0566 228.596 51.173 228.71 51.268C228.824 51.3629 228.955 51.4345 229.096 51.4786C229.238 51.5228 229.387 51.5386 229.534 51.5253Z"
              fill="#00BA77"
            />
            <path
              d="M236.772 50.872C236.995 50.8519 237.206 50.7664 237.379 50.6263C237.553 50.4862 237.681 50.2978 237.747 50.0849C237.814 49.8719 237.816 49.6441 237.753 49.4301C237.69 49.2162 237.565 49.0257 237.393 48.8828C237.222 48.7399 237.012 48.6509 236.791 48.6272C236.569 48.6035 236.345 48.6461 236.147 48.7496C235.95 48.8531 235.787 49.0129 235.681 49.2087C235.574 49.4046 235.528 49.6277 235.548 49.8498C235.561 49.9974 235.603 50.1409 235.672 50.2721C235.741 50.4033 235.834 50.5197 235.948 50.6147C236.062 50.7096 236.193 50.7812 236.335 50.8253C236.476 50.8695 236.625 50.8853 236.772 50.872Z"
              fill="#00BA77"
            />
            <path
              d="M244.009 50.2257C244.231 50.2057 244.442 50.1202 244.616 49.9801C244.789 49.8399 244.917 49.6515 244.984 49.4386C245.05 49.2257 245.052 48.9978 244.989 48.7839C244.926 48.5699 244.801 48.3794 244.63 48.2365C244.459 48.0936 244.249 48.0047 244.027 47.981C243.805 47.9573 243.581 47.9999 243.384 48.1034C243.186 48.2069 243.024 48.3667 242.917 48.5625C242.81 48.7583 242.764 48.9814 242.784 49.2036C242.797 49.3511 242.839 49.4946 242.908 49.6259C242.977 49.7571 243.071 49.8735 243.185 49.9684C243.298 50.0633 243.43 50.1349 243.571 50.1791C243.712 50.2232 243.861 50.2391 244.009 50.2257Z"
              fill="#00BA77"
            />
            <path
              d="M251.248 49.5763C251.47 49.5565 251.682 49.4712 251.856 49.3311C252.03 49.191 252.159 49.0024 252.225 48.7891C252.292 48.5759 252.294 48.3476 252.232 48.1332C252.169 47.9187 252.044 47.7278 251.872 47.5845C251.701 47.4411 251.491 47.3519 251.268 47.328C251.046 47.3041 250.822 47.3467 250.624 47.4503C250.426 47.5539 250.263 47.7139 250.156 47.9101C250.049 48.1063 250.003 48.3298 250.023 48.5524C250.036 48.7001 250.078 48.8438 250.146 48.9752C250.215 49.1066 250.309 49.2232 250.423 49.3183C250.536 49.4135 250.668 49.4852 250.809 49.5294C250.951 49.5737 251.1 49.5896 251.248 49.5763Z"
              fill="#00BA77"
            />
            <path
              d="M258.484 48.9266C258.706 48.9069 258.918 48.8216 259.092 48.6814C259.266 48.5413 259.395 48.3527 259.462 48.1395C259.529 47.9262 259.531 47.698 259.468 47.4835C259.405 47.2691 259.28 47.0781 259.108 46.9348C258.937 46.7915 258.727 46.7022 258.505 46.6783C258.282 46.6545 258.058 46.697 257.86 46.8006C257.662 46.9043 257.499 47.0643 257.392 47.2605C257.285 47.4566 257.239 47.6802 257.259 47.9027C257.272 48.0504 257.314 48.1941 257.383 48.3256C257.451 48.457 257.545 48.5736 257.659 48.6687C257.773 48.7638 257.904 48.8355 258.046 48.8798C258.187 48.924 258.336 48.94 258.484 48.9266Z"
              fill="#00BA77"
            />
            <path
              d="M265.726 48.2732C265.948 48.2538 266.16 48.1687 266.335 48.0288C266.509 47.8889 266.638 47.7003 266.705 47.4871C266.772 47.2739 266.775 47.0455 266.712 46.8309C266.649 46.6164 266.524 46.4252 266.353 46.2817C266.181 46.1382 265.971 46.0488 265.749 46.0248C265.527 46.0008 265.302 46.0433 265.104 46.1469C264.906 46.2505 264.743 46.4106 264.636 46.6068C264.529 46.8031 264.483 47.0267 264.503 47.2493C264.529 47.5471 264.673 47.8221 264.903 48.0141C265.132 48.206 265.428 48.2992 265.726 48.2732Z"
              fill="#00BA77"
            />
            <path
              d="M272.958 47.6252C273.181 47.6051 273.392 47.5196 273.565 47.3795C273.739 47.2394 273.867 47.0509 273.933 46.838C274 46.6251 274.002 46.3973 273.939 46.1833C273.876 45.9693 273.751 45.7788 273.579 45.6359C273.408 45.493 273.198 45.4041 272.977 45.3804C272.755 45.3567 272.531 45.3993 272.333 45.5028C272.136 45.6063 271.973 45.7661 271.867 45.9619C271.76 46.1577 271.714 46.3808 271.734 46.603C271.747 46.7506 271.789 46.8942 271.857 47.0256C271.926 47.1569 272.02 47.2734 272.134 47.3684C272.248 47.4633 272.379 47.5349 272.52 47.579C272.662 47.623 272.811 47.6387 272.958 47.6252Z"
              fill="#00BA77"
            />
            <path
              d="M280.196 46.9755C280.418 46.9554 280.629 46.8699 280.803 46.7298C280.976 46.5897 281.104 46.4013 281.171 46.1884C281.237 45.9755 281.239 45.7476 281.176 45.5336C281.113 45.3197 280.988 45.1292 280.817 44.9863C280.646 44.8434 280.436 44.7544 280.214 44.7307C279.992 44.7071 279.768 44.7497 279.571 44.8532C279.373 44.9567 279.211 45.1164 279.104 45.3123C278.997 45.5081 278.951 45.7312 278.971 45.9533C278.984 46.1009 279.026 46.2444 279.095 46.3756C279.164 46.5068 279.258 46.6232 279.372 46.7182C279.485 46.8131 279.617 46.8847 279.758 46.9288C279.899 46.973 280.048 46.9888 280.196 46.9755Z"
              fill="#00BA77"
            />
            <path
              d="M287.435 46.3261C287.657 46.306 287.868 46.2205 288.041 46.0804C288.215 45.9403 288.343 45.7519 288.41 45.539C288.476 45.326 288.478 45.0982 288.415 44.8842C288.352 44.6703 288.227 44.4798 288.056 44.3369C287.884 44.194 287.675 44.105 287.453 44.0813C287.231 44.0576 287.007 44.1002 286.81 44.2037C286.612 44.3072 286.45 44.467 286.343 44.6628C286.236 44.8587 286.19 45.0818 286.21 45.3039C286.223 45.4515 286.265 45.595 286.334 45.7262C286.403 45.8574 286.497 45.9738 286.61 46.0688C286.724 46.1637 286.855 46.2353 286.997 46.2794C287.138 46.3236 287.287 46.3394 287.435 46.3261Z"
              fill="#00BA77"
            />
            <path
              d="M294.671 45.6745C294.893 45.6544 295.104 45.569 295.277 45.429C295.451 45.289 295.579 45.1007 295.646 44.8879C295.712 44.6751 295.714 44.4473 295.651 44.2333C295.589 44.0194 295.464 43.8289 295.293 43.6859C295.122 43.5428 294.912 43.4538 294.69 43.4298C294.469 43.4059 294.245 43.4483 294.047 43.5515C293.849 43.6548 293.687 43.8143 293.58 44.0099C293.473 44.2055 293.426 44.4285 293.446 44.6506C293.459 44.7983 293.501 44.942 293.57 45.0734C293.638 45.2048 293.732 45.3214 293.846 45.4165C293.96 45.5116 294.091 45.5834 294.233 45.6276C294.374 45.6719 294.523 45.6878 294.671 45.6745Z"
              fill="#00BA77"
            />
            <path
              d="M42.0126 75.6625C42.2348 75.6424 42.446 75.5569 42.6195 75.4168C42.7931 75.2767 42.9212 75.0883 42.9877 74.8754C43.0541 74.6625 43.056 74.4346 42.993 74.2207C42.93 74.0067 42.805 73.8162 42.6337 73.6733C42.4625 73.5304 42.2527 73.4415 42.0309 73.4178C41.8091 73.3941 41.5853 73.4367 41.3877 73.5402C41.1901 73.6437 41.0276 73.8034 40.9209 73.9993C40.8141 74.1951 40.7678 74.4182 40.7877 74.6403C40.801 74.7879 40.8432 74.9314 40.912 75.0626C40.9807 75.1938 41.0747 75.3103 41.1884 75.4052C41.3022 75.5001 41.4335 75.5717 41.5749 75.6158C41.7163 75.66 41.8651 75.6759 42.0126 75.6625Z"
              fill="#00BA77"
            />
            <path
              d="M49.2517 75.0129C49.4738 74.9928 49.685 74.9074 49.8585 74.7674C50.0321 74.6274 50.1602 74.4391 50.2268 74.2262C50.2933 74.0134 50.2953 73.7856 50.2325 73.5717C50.1696 73.3578 50.0448 73.1673 49.8737 73.0242C49.7026 72.8812 49.493 72.7921 49.2713 72.7682C49.0496 72.7443 48.8258 72.7867 48.6281 72.8899C48.4305 72.9931 48.2679 73.1526 48.1609 73.3483C48.0538 73.5439 48.0072 73.7668 48.0268 73.989C48.0399 74.1367 48.082 74.2804 48.1506 74.4118C48.2193 74.5432 48.3132 74.6598 48.427 74.7549C48.5407 74.85 48.6721 74.9218 48.8137 74.966C48.9552 75.0103 49.104 75.0262 49.2517 75.0129Z"
              fill="#00BA77"
            />
            <path
              d="M56.4882 74.3634C56.7108 74.3437 56.9226 74.2583 57.0966 74.1182C57.2707 73.9781 57.3993 73.7895 57.4662 73.5762C57.5331 73.363 57.5352 73.1347 57.4723 72.9203C57.4094 72.7059 57.2843 72.5149 57.1129 72.3716C56.9414 72.2282 56.7313 72.139 56.5091 72.1151C56.2869 72.0912 56.0626 72.1338 55.8646 72.2374C55.6666 72.341 55.5038 72.5011 55.3968 72.6972C55.2897 72.8934 55.2433 73.1169 55.2633 73.3395C55.2764 73.4872 55.3185 73.6309 55.3871 73.7623C55.4558 73.8938 55.5497 74.0104 55.6635 74.1055C55.7772 74.2006 55.9086 74.2723 56.0501 74.3166C56.1917 74.3608 56.3405 74.3767 56.4882 74.3634Z"
              fill="#00BA77"
            />
            <path
              d="M63.7241 73.7133C63.9467 73.6935 64.1584 73.6082 64.3325 73.4681C64.5066 73.3279 64.6352 73.1393 64.7021 72.9261C64.7689 72.7129 64.771 72.4846 64.7081 72.2701C64.6452 72.0557 64.5202 71.8647 64.3487 71.7214C64.1772 71.5781 63.9671 71.4888 63.7449 71.465C63.5227 71.4411 63.2985 71.4836 63.1005 71.5873C62.9025 71.6909 62.7396 71.8509 62.6326 72.0471C62.5256 72.2433 62.4791 72.4668 62.4992 72.6893C62.526 72.9875 62.6701 73.2628 62.8998 73.4548C63.1294 73.6467 63.4259 73.7397 63.7241 73.7133Z"
              fill="#00BA77"
            />
            <path
              d="M70.9628 73.0617C71.185 73.0416 71.3962 72.9561 71.5697 72.816C71.7433 72.6759 71.8714 72.4875 71.9379 72.2745C72.0043 72.0616 72.0062 71.8338 71.9432 71.6198C71.8802 71.4059 71.7551 71.2154 71.5839 71.0725C71.4126 70.9295 71.2029 70.8406 70.9811 70.8169C70.7593 70.7932 70.5355 70.8358 70.3379 70.9393C70.1403 71.0428 69.9778 71.2026 69.8711 71.3984C69.7643 71.5943 69.7179 71.8174 69.7379 72.0395C69.7512 72.1871 69.7934 72.3306 69.8622 72.4618C69.9309 72.593 70.0249 72.7094 70.1386 72.8043C70.2524 72.8993 70.3837 72.9709 70.5251 73.015C70.6665 73.0592 70.8153 73.075 70.9628 73.0617Z"
              fill="#00BA77"
            />
            <path
              d="M78.2001 72.412C78.4223 72.3919 78.6335 72.3064 78.807 72.1663C78.9806 72.0262 79.1087 71.8378 79.1752 71.6249C79.2416 71.412 79.2435 71.1841 79.1805 70.9702C79.1175 70.7562 78.9925 70.5657 78.8212 70.4228C78.6499 70.2799 78.4402 70.191 78.2184 70.1673C77.9966 70.1436 77.7728 70.1862 77.5752 70.2897C77.3776 70.3932 77.2151 70.553 77.1084 70.7488C77.0016 70.9446 76.9553 71.1677 76.9752 71.3898C77.0025 71.6877 77.1468 71.9625 77.3764 72.1541C77.606 72.3458 77.9022 72.4385 78.2001 72.412Z"
              fill="#00BA77"
            />
            <path
              d="M85.4379 71.7629C85.6601 71.7428 85.8713 71.6573 86.0448 71.5172C86.2184 71.3771 86.3465 71.1886 86.413 70.9757C86.4794 70.7628 86.4813 70.535 86.4183 70.321C86.3553 70.107 86.2302 69.9165 86.059 69.7736C85.8877 69.6307 85.678 69.5418 85.4562 69.5181C85.2344 69.4944 85.0106 69.537 84.813 69.6405C84.6154 69.744 84.4529 69.9038 84.3462 70.0996C84.2394 70.2954 84.193 70.5185 84.213 70.7407C84.2263 70.8882 84.2685 71.0317 84.3373 71.163C84.406 71.2942 84.5 71.4106 84.6137 71.5055C84.7275 71.6004 84.8588 71.672 85.0002 71.7162C85.1416 71.7603 85.2904 71.7762 85.4379 71.7629Z"
              fill="#00BA77"
            />
            <path
              d="M92.6747 71.113C92.8969 71.0929 93.1081 71.0074 93.2816 70.8673C93.4552 70.7272 93.5833 70.5387 93.6498 70.3258C93.7163 70.1129 93.7181 69.8851 93.6551 69.6711C93.5921 69.4571 93.4671 69.2666 93.2958 69.1237C93.1246 68.9808 92.9148 68.8919 92.693 68.8682C92.4712 68.8445 92.2474 68.8871 92.0498 68.9906C91.8522 69.0941 91.6898 69.2539 91.583 69.4497C91.4762 69.6455 91.4299 69.8686 91.4499 70.0908C91.4631 70.2383 91.5054 70.3818 91.5741 70.5131C91.6429 70.6443 91.7368 70.7607 91.8505 70.8556C91.9643 70.9505 92.0956 71.0221 92.237 71.0663C92.3784 71.1104 92.5272 71.1263 92.6747 71.113Z"
              fill="#00BA77"
            />
            <path
              d="M99.9111 70.463C100.134 70.4433 100.345 70.3579 100.519 70.2178C100.694 70.0777 100.822 69.8891 100.889 69.6759C100.956 69.4626 100.958 69.2343 100.895 69.0199C100.832 68.8055 100.707 68.6145 100.536 68.4712C100.364 68.3278 100.154 68.2386 99.9319 68.2147C99.7097 68.1908 99.4855 68.2334 99.2875 68.337C99.0895 68.4406 98.9267 68.6007 98.8196 68.7968C98.7126 68.993 98.6662 69.2165 98.6862 69.4391C98.6993 69.5868 98.7413 69.7305 98.81 69.8619C98.8786 69.9934 98.9725 70.11 99.0863 70.2051C99.2001 70.3002 99.3315 70.3719 99.473 70.4162C99.6145 70.4604 99.7634 70.4764 99.9111 70.463Z"
              fill="#00BA77"
            />
            <path
              d="M107.15 69.8134C107.372 69.7936 107.584 69.7083 107.758 69.5682C107.932 69.428 108.061 69.2394 108.128 69.0262C108.195 68.813 108.197 68.5847 108.134 68.3702C108.071 68.1558 107.946 67.9648 107.774 67.8215C107.603 67.6782 107.393 67.5889 107.171 67.5651C106.949 67.5412 106.724 67.5837 106.526 67.6874C106.328 67.791 106.165 67.951 106.058 68.1472C105.951 68.3434 105.905 68.5669 105.925 68.7894C105.952 69.0876 106.096 69.3629 106.326 69.5549C106.555 69.7468 106.852 69.8398 107.15 69.8134Z"
              fill="#00BA77"
            />
            <path
              d="M114.386 69.1623C114.608 69.1422 114.82 69.0567 114.993 68.9166C115.167 68.7765 115.295 68.5881 115.361 68.3751C115.428 68.1622 115.43 67.9344 115.367 67.7204C115.304 67.5064 115.178 67.316 115.007 67.173C114.836 67.0301 114.626 66.9412 114.404 66.9175C114.183 66.8938 113.959 66.9364 113.761 67.0399C113.564 67.1434 113.401 67.3032 113.294 67.499C113.188 67.6948 113.141 67.9179 113.161 68.1401C113.175 68.2876 113.217 68.4311 113.286 68.5624C113.354 68.6936 113.448 68.81 113.562 68.9049C113.676 68.9999 113.807 69.0714 113.948 69.1156C114.09 69.1598 114.239 69.1756 114.386 69.1623Z"
              fill="#00BA77"
            />
            <path
              d="M121.625 68.5126C121.847 68.4925 122.058 68.407 122.232 68.2669C122.405 68.1268 122.533 67.9384 122.6 67.7255C122.666 67.5126 122.668 67.2847 122.605 67.0707C122.542 66.8568 122.417 66.6663 122.246 66.5234C122.075 66.3805 121.865 66.2916 121.643 66.2679C121.421 66.2442 121.198 66.2868 121 66.3903C120.802 66.4938 120.64 66.6535 120.533 66.8494C120.426 67.0452 120.38 67.2683 120.4 67.4904C120.413 67.638 120.456 67.7815 120.524 67.9127C120.593 68.044 120.687 68.1604 120.801 68.2553C120.914 68.3502 121.046 68.4218 121.187 68.4659C121.329 68.5101 121.477 68.526 121.625 68.5126Z"
              fill="#00BA77"
            />
            <path
              d="M128.858 67.8625C129.08 67.8424 129.291 67.7569 129.465 67.6168C129.638 67.4767 129.766 67.2882 129.833 67.0753C129.899 66.8624 129.901 66.6346 129.838 66.4206C129.775 66.2066 129.65 66.0162 129.479 65.8732C129.308 65.7303 129.098 65.6414 128.876 65.6177C128.654 65.594 128.43 65.6366 128.233 65.7401C128.035 65.8436 127.873 66.0034 127.766 66.1992C127.659 66.395 127.613 66.6181 127.633 66.8403C127.646 66.9878 127.688 67.1313 127.757 67.2626C127.826 67.3938 127.92 67.5102 128.034 67.6051C128.147 67.7001 128.279 67.7716 128.42 67.8158C128.562 67.8599 128.71 67.8758 128.858 67.8625Z"
              fill="#00BA77"
            />
            <path
              d="M136.098 67.2128C136.32 67.1928 136.532 67.1073 136.705 66.9673C136.879 66.8273 137.007 66.639 137.073 66.4262C137.14 66.2134 137.142 65.9856 137.079 65.7717C137.016 65.5577 136.891 65.3672 136.72 65.2242C136.549 65.0812 136.34 64.9921 136.118 64.9682C135.896 64.9443 135.672 64.9866 135.475 65.0898C135.277 65.1931 135.115 65.3526 135.008 65.5482C134.901 65.7438 134.854 65.9668 134.874 66.1889C134.887 66.3366 134.929 66.4803 134.997 66.6117C135.066 66.7432 135.16 66.8598 135.274 66.9549C135.387 67.05 135.519 67.1217 135.66 67.166C135.802 67.2102 135.951 67.2262 136.098 67.2128Z"
              fill="#00BA77"
            />
            <path
              d="M143.336 66.5634C143.559 66.5436 143.771 66.4583 143.945 66.3182C144.119 66.178 144.247 65.9894 144.314 65.7762C144.381 65.563 144.383 65.3347 144.32 65.1202C144.258 64.9058 144.132 64.7148 143.961 64.5715C143.79 64.4282 143.579 64.3389 143.357 64.3151C143.135 64.2912 142.911 64.3337 142.713 64.4374C142.515 64.541 142.352 64.701 142.245 64.8972C142.138 65.0934 142.091 65.3169 142.111 65.5394C142.125 65.6872 142.167 65.8308 142.235 65.9623C142.304 66.0937 142.398 66.2103 142.512 66.3054C142.625 66.4005 142.757 66.4722 142.898 66.5165C143.04 66.5608 143.189 66.5767 143.336 66.5634Z"
              fill="#00BA77"
            />
            <path
              d="M150.573 65.9137C150.796 65.894 151.008 65.8086 151.182 65.6685C151.356 65.5284 151.484 65.3398 151.551 65.1265C151.618 64.9133 151.62 64.685 151.557 64.4706C151.494 64.2561 151.369 64.0652 151.198 63.9219C151.026 63.7785 150.816 63.6893 150.594 63.6654C150.372 63.6415 150.148 63.6841 149.95 63.7877C149.752 63.8913 149.589 64.0514 149.482 64.2475C149.375 64.4437 149.328 64.6672 149.348 64.8898C149.375 65.1879 149.519 65.4632 149.749 65.6552C149.979 65.8472 150.275 65.9402 150.573 65.9137Z"
              fill="#00BA77"
            />
            <path
              d="M157.811 65.2621C158.034 65.242 158.245 65.1565 158.418 65.0164C158.592 64.8763 158.72 64.6879 158.786 64.475C158.853 64.2621 158.855 64.0342 158.792 63.8203C158.729 63.6063 158.604 63.4158 158.433 63.2729C158.261 63.13 158.051 63.0411 157.83 63.0174C157.608 62.9937 157.384 63.0363 157.186 63.1398C156.989 63.2433 156.826 63.403 156.72 63.5989C156.613 63.7947 156.567 64.0178 156.587 64.2399C156.6 64.3875 156.642 64.531 156.711 64.6622C156.78 64.7935 156.873 64.9099 156.987 65.0048C157.101 65.0997 157.232 65.1713 157.374 65.2155C157.515 65.2596 157.664 65.2755 157.811 65.2621Z"
              fill="#00BA77"
            />
            <path
              d="M165.048 64.6122C165.27 64.5921 165.482 64.5066 165.655 64.3665C165.829 64.2264 165.957 64.038 166.023 63.8251C166.09 63.6122 166.092 63.3843 166.029 63.1704C165.966 62.9564 165.841 62.7659 165.669 62.623C165.498 62.4801 165.288 62.3912 165.067 62.3675C164.845 62.3438 164.621 62.3864 164.423 62.4899C164.226 62.5934 164.063 62.7531 163.957 62.949C163.85 63.1448 163.803 63.3679 163.823 63.59C163.836 63.7377 163.879 63.8813 163.947 64.0126C164.016 64.144 164.11 64.2605 164.224 64.3554C164.337 64.4504 164.469 64.522 164.61 64.566C164.752 64.6101 164.901 64.6258 165.048 64.6122Z"
              fill="#00BA77"
            />
            <path
              d="M172.284 63.963C172.507 63.9432 172.718 63.858 172.892 63.718C173.066 63.5781 173.194 63.3898 173.261 63.1769C173.327 62.9639 173.33 62.736 173.267 62.5219C173.204 62.3078 173.079 62.1172 172.908 61.9741C172.737 61.831 172.527 61.7419 172.305 61.7181C172.083 61.6943 171.859 61.7368 171.661 61.8403C171.464 61.9438 171.301 62.1036 171.194 62.2995C171.088 62.4954 171.041 62.7186 171.061 62.9408C171.088 63.2385 171.232 63.5133 171.461 63.705C171.691 63.8966 171.987 63.9894 172.284 63.963Z"
              fill="#00BA77"
            />
            <path
              d="M179.523 63.3132C179.746 63.2931 179.957 63.2076 180.13 63.0675C180.304 62.9273 180.432 62.7389 180.498 62.526C180.565 62.3131 180.567 62.0853 180.504 61.8713C180.441 61.6573 180.316 61.4668 180.144 61.3239C179.973 61.181 179.763 61.0921 179.542 61.0684C179.32 61.0447 179.096 61.0873 178.898 61.1908C178.701 61.2943 178.538 61.4541 178.432 61.6499C178.325 61.8457 178.278 62.0688 178.298 62.291C178.312 62.4385 178.354 62.582 178.423 62.7133C178.491 62.8445 178.585 62.9609 178.699 63.0558C178.813 63.1507 178.944 63.2223 179.086 63.2665C179.227 63.3106 179.376 63.3265 179.523 63.3132Z"
              fill="#00BA77"
            />
            <path
              d="M186.76 62.6635C186.982 62.6437 187.194 62.5584 187.368 62.4183C187.542 62.2781 187.671 62.0895 187.738 61.8763C187.805 61.6631 187.807 61.4348 187.744 61.2203C187.681 61.0059 187.556 60.8149 187.384 60.6716C187.213 60.5283 187.003 60.439 186.781 60.4152C186.558 60.3913 186.334 60.4338 186.136 60.5375C185.938 60.6411 185.775 60.8011 185.668 60.9973C185.561 61.1935 185.515 61.417 185.535 61.6396C185.562 61.9377 185.706 62.213 185.935 62.405C186.165 62.5969 186.462 62.6899 186.76 62.6635Z"
              fill="#00BA77"
            />
            <path
              d="M194 62.0133C194.223 61.9936 194.434 61.9082 194.608 61.7681C194.782 61.628 194.911 61.4394 194.978 61.2261C195.045 61.0129 195.047 60.7846 194.984 60.5702C194.921 60.3558 194.796 60.1648 194.625 60.0215C194.453 59.8781 194.243 59.7889 194.021 59.765C193.799 59.7411 193.574 59.7837 193.376 59.8873C193.178 59.9909 193.016 60.151 192.908 60.3471C192.801 60.5433 192.755 60.7668 192.775 60.9894C192.802 61.2875 192.946 61.5628 193.176 61.7548C193.405 61.9468 193.702 62.0398 194 62.0133Z"
              fill="#00BA77"
            />
            <path
              d="M201.234 61.3622C201.456 61.3421 201.668 61.2566 201.841 61.1165C202.015 60.9764 202.143 60.788 202.209 60.5751C202.276 60.3622 202.278 60.1343 202.215 59.9204C202.152 59.7064 202.027 59.5159 201.855 59.373C201.684 59.2301 201.474 59.1412 201.253 59.1175C201.031 59.0938 200.807 59.1364 200.609 59.2399C200.412 59.3434 200.249 59.5032 200.143 59.699C200.036 59.8948 199.989 60.1179 200.009 60.3401C200.023 60.4876 200.065 60.6311 200.134 60.7623C200.202 60.8936 200.296 61.01 200.41 61.1049C200.524 61.1998 200.655 61.2714 200.797 61.3155C200.938 61.3597 201.087 61.3756 201.234 61.3622Z"
              fill="#00BA77"
            />
            <path
              d="M208.472 60.7126C208.694 60.6925 208.905 60.607 209.079 60.4669C209.252 60.3268 209.38 60.1383 209.447 59.9254C209.513 59.7125 209.515 59.4847 209.452 59.2707C209.389 59.0567 209.264 58.8663 209.093 58.7233C208.921 58.5804 208.712 58.4915 208.49 58.4678C208.268 58.4441 208.044 58.4867 207.847 58.5902C207.649 58.6937 207.487 58.8535 207.38 59.0493C207.273 59.2452 207.227 59.4682 207.247 59.6904C207.26 59.8379 207.302 59.9814 207.371 60.1127C207.44 60.2439 207.534 60.3603 207.647 60.4552C207.761 60.5502 207.892 60.6217 208.034 60.6659C208.175 60.71 208.324 60.7259 208.472 60.7126Z"
              fill="#00BA77"
            />
            <path
              d="M215.71 60.0629C215.933 60.0428 216.144 59.9573 216.317 59.8172C216.491 59.6771 216.619 59.4887 216.685 59.2758C216.752 59.0629 216.754 58.835 216.691 58.6211C216.628 58.4071 216.503 58.2166 216.331 58.0737C216.16 57.9308 215.95 57.8418 215.729 57.8182C215.507 57.7945 215.283 57.8371 215.085 57.9406C214.888 58.0441 214.725 58.2038 214.619 58.3997C214.512 58.5955 214.466 58.8186 214.485 59.0407C214.499 59.1883 214.541 59.3318 214.61 59.463C214.678 59.5942 214.772 59.7107 214.886 59.8056C215 59.9005 215.131 59.9721 215.273 60.0162C215.414 60.0604 215.563 60.0762 215.71 60.0629Z"
              fill="#00BA77"
            />
            <path
              d="M222.947 59.413C223.169 59.3929 223.38 59.3074 223.554 59.1673C223.727 59.0272 223.855 58.8388 223.922 58.6259C223.988 58.413 223.99 58.1851 223.927 57.9711C223.864 57.7572 223.739 57.5667 223.568 57.4238C223.397 57.2809 223.187 57.1919 222.965 57.1682C222.743 57.1446 222.519 57.1872 222.322 57.2907C222.124 57.3942 221.962 57.5539 221.855 57.7498C221.748 57.9456 221.702 58.1687 221.722 58.3908C221.749 58.6887 221.893 58.9635 222.123 59.1551C222.353 59.3467 222.649 59.4395 222.947 59.413Z"
              fill="#00BA77"
            />
            <path
              d="M230.185 58.7636C230.408 58.7438 230.62 58.6585 230.794 58.5184C230.968 58.3782 231.097 58.1896 231.163 57.9764C231.23 57.7632 231.232 57.5349 231.17 57.3204C231.107 57.106 230.982 56.915 230.81 56.7717C230.639 56.6284 230.429 56.5391 230.206 56.5153C229.984 56.4914 229.76 56.5339 229.562 56.6376C229.364 56.7412 229.201 56.9012 229.094 57.0974C228.987 57.2936 228.941 57.5171 228.961 57.7397C228.974 57.8874 229.016 58.031 229.084 58.1625C229.153 58.2939 229.247 58.4105 229.361 58.5056C229.474 58.6007 229.606 58.6724 229.747 58.7167C229.889 58.761 230.038 58.7769 230.185 58.7636Z"
              fill="#00BA77"
            />
            <path
              d="M237.422 58.1139C237.644 58.0941 237.856 58.0088 238.03 57.8687C238.204 57.7286 238.333 57.54 238.4 57.3267C238.467 57.1135 238.469 56.8852 238.406 56.6708C238.343 56.4563 238.218 56.2654 238.046 56.1221C237.875 55.9787 237.665 55.8895 237.443 55.8656C237.22 55.8417 236.996 55.8843 236.798 55.9879C236.6 56.0915 236.437 56.2516 236.33 56.4477C236.223 56.6439 236.177 56.8674 236.197 57.09C236.21 57.2377 236.252 57.3814 236.321 57.5128C236.389 57.6442 236.483 57.7608 236.597 57.8559C236.711 57.951 236.842 58.0228 236.984 58.0671C237.125 58.1113 237.274 58.1272 237.422 58.1139Z"
              fill="#00BA77"
            />
            <path
              d="M244.659 57.4552C244.881 57.4352 245.092 57.3497 245.266 57.2095C245.439 57.0694 245.567 56.881 245.634 56.6681C245.7 56.4552 245.702 56.2274 245.639 56.0134C245.576 55.7994 245.451 55.6089 245.28 55.466C245.108 55.3231 244.899 55.2342 244.677 55.2105C244.455 55.1868 244.231 55.2294 244.034 55.3329C243.836 55.4364 243.674 55.5962 243.567 55.792C243.46 55.9878 243.414 56.2109 243.434 56.4331C243.447 56.5806 243.489 56.7241 243.558 56.8553C243.627 56.9866 243.721 57.103 243.834 57.1979C243.948 57.2928 244.079 57.3644 244.221 57.4086C244.362 57.4527 244.511 57.4686 244.659 57.4552Z"
              fill="#00BA77"
            />
            <path
              d="M251.896 56.8129C252.119 56.7928 252.33 56.7073 252.503 56.5672C252.677 56.4271 252.805 56.2387 252.871 56.0258C252.938 55.8128 252.94 55.585 252.877 55.371C252.814 55.1571 252.689 54.9666 252.517 54.8237C252.346 54.6808 252.136 54.5918 251.915 54.5682C251.693 54.5445 251.469 54.5871 251.271 54.6906C251.074 54.7941 250.911 54.9538 250.805 55.1497C250.698 55.3455 250.652 55.5686 250.672 55.7907C250.685 55.9383 250.727 56.0818 250.796 56.213C250.865 56.3442 250.958 56.4606 251.072 56.5556C251.186 56.6505 251.317 56.7221 251.459 56.7662C251.6 56.8104 251.749 56.8262 251.896 56.8129Z"
              fill="#00BA77"
            />
            <path
              d="M259.142 56.163C259.364 56.1429 259.575 56.0574 259.749 55.9173C259.922 55.7772 260.051 55.5888 260.117 55.3759C260.184 55.1629 260.185 54.9351 260.122 54.7211C260.059 54.5072 259.934 54.3167 259.763 54.1738C259.592 54.0309 259.382 53.9419 259.16 53.9182C258.938 53.8946 258.715 53.9372 258.517 54.0407C258.319 54.1442 258.157 54.3039 258.05 54.4998C257.943 54.6956 257.897 54.9187 257.917 55.1408C257.93 55.2884 257.973 55.4319 258.041 55.5631C258.11 55.6943 258.204 55.8107 258.318 55.9057C258.432 56.0006 258.563 56.0722 258.704 56.1163C258.846 56.1605 258.994 56.1763 259.142 56.163Z"
              fill="#00BA77"
            />
            <path
              d="M266.372 55.5133C266.594 55.4933 266.805 55.4077 266.978 55.2676C267.152 55.1275 267.28 54.9391 267.347 54.7262C267.413 54.5133 267.415 54.2854 267.352 54.0715C267.289 53.8575 267.164 53.667 266.993 53.5241C266.821 53.3812 266.612 53.2923 266.39 53.2686C266.168 53.2449 265.944 53.2875 265.747 53.391C265.549 53.4945 265.387 53.6543 265.28 53.8501C265.173 54.0459 265.127 54.269 265.147 54.4912C265.16 54.6387 265.202 54.7822 265.271 54.9134C265.34 55.0447 265.434 55.1611 265.547 55.256C265.661 55.3509 265.792 55.4225 265.934 55.4667C266.075 55.5108 266.224 55.5267 266.372 55.5133Z"
              fill="#00BA77"
            />
            <path
              d="M273.608 54.8566C273.83 54.8368 274.042 54.7515 274.216 54.6114C274.39 54.4712 274.519 54.2826 274.586 54.0694C274.653 53.8562 274.655 53.6279 274.592 53.4135C274.529 53.199 274.404 53.0081 274.232 52.8647C274.061 52.7214 273.851 52.6322 273.629 52.6083C273.407 52.5844 273.182 52.627 272.984 52.7306C272.786 52.8342 272.623 52.9942 272.516 53.1904C272.409 53.3866 272.363 53.6101 272.383 53.8327C272.396 53.9804 272.438 54.1241 272.507 54.2555C272.575 54.3869 272.669 54.5035 272.783 54.5986C272.897 54.6937 273.028 54.7655 273.17 54.8097C273.311 54.854 273.46 54.8699 273.608 54.8566Z"
              fill="#00BA77"
            />
            <path
              d="M280.845 54.2142C281.067 54.1945 281.279 54.1091 281.453 53.969C281.627 53.8289 281.756 53.6403 281.823 53.4271C281.89 53.2138 281.892 52.9856 281.829 52.7711C281.766 52.5567 281.641 52.3657 281.469 52.2224C281.298 52.0791 281.088 51.9898 280.866 51.9659C280.643 51.9421 280.419 51.9846 280.221 52.0882C280.023 52.1919 279.86 52.3519 279.753 52.5481C279.646 52.7442 279.6 52.9678 279.62 53.1903C279.647 53.4885 279.791 53.7637 280.02 53.9557C280.25 54.1477 280.547 54.2407 280.845 54.2142Z"
              fill="#00BA77"
            />
            <path
              d="M288.084 53.5627C288.306 53.5426 288.517 53.4571 288.691 53.317C288.864 53.1769 288.992 52.9884 289.059 52.7755C289.125 52.5626 289.127 52.3348 289.064 52.1208C289.001 51.9068 288.876 51.7163 288.705 51.5734C288.534 51.4305 288.324 51.3416 288.102 51.3179C287.88 51.2942 287.657 51.3368 287.459 51.4403C287.261 51.5438 287.099 51.7036 286.992 51.8994C286.885 52.0952 286.839 52.3183 286.859 52.5405C286.872 52.688 286.915 52.8315 286.983 52.9628C287.052 53.094 287.146 53.2104 287.26 53.3053C287.373 53.4002 287.505 53.4718 287.646 53.516C287.788 53.5601 287.936 53.576 288.084 53.5627Z"
              fill="#00BA77"
            />
            <path
              d="M295.32 52.9128C295.542 52.8927 295.754 52.8072 295.927 52.6671C296.101 52.527 296.229 52.3385 296.295 52.1256C296.362 51.9127 296.364 51.6849 296.301 51.4709C296.238 51.2569 296.113 51.0664 295.941 50.9235C295.77 50.7806 295.56 50.6917 295.339 50.668C295.117 50.6443 294.893 50.6869 294.695 50.7904C294.498 50.8939 294.335 51.0537 294.228 51.2495C294.122 51.4453 294.075 51.6684 294.095 51.8906C294.109 52.0381 294.151 52.1816 294.22 52.3129C294.288 52.4441 294.382 52.5605 294.496 52.6554C294.61 52.7503 294.741 52.8219 294.883 52.8661C295.024 52.9102 295.173 52.9261 295.32 52.9128Z"
              fill="#00BA77"
            />
            <path
              d="M42.662 82.8991C42.8842 82.879 43.0954 82.7935 43.2689 82.6534C43.4425 82.5133 43.5706 82.3249 43.6371 82.112C43.7036 81.899 43.7054 81.6712 43.6424 81.4572C43.5794 81.2433 43.4544 81.0528 43.2831 80.9099C43.1119 80.767 42.9021 80.678 42.6803 80.6543C42.4585 80.6306 42.2347 80.6732 42.0371 80.7767C41.8395 80.8802 41.6771 81.04 41.5703 81.2358C41.4635 81.4317 41.4172 81.6548 41.4372 81.8769C41.4504 82.0245 41.4927 82.168 41.5614 82.2992C41.6302 82.4304 41.7241 82.5468 41.8378 82.6418C41.9516 82.7367 42.0829 82.8083 42.2243 82.8524C42.3658 82.8966 42.5145 82.9124 42.662 82.8991Z"
              fill="#00BA77"
            />
            <path
              d="M49.9013 82.2489C50.1234 82.2289 50.3346 82.1434 50.5082 82.0032C50.6818 81.8631 50.8099 81.6747 50.8763 81.4618C50.9428 81.2489 50.9447 81.0211 50.8817 80.8071C50.8187 80.5931 50.6936 80.4026 50.5224 80.2597C50.3511 80.1168 50.1413 80.0279 49.9196 80.0042C49.6978 79.9805 49.4739 80.0231 49.2764 80.1266C49.0788 80.2301 48.9163 80.3899 48.8095 80.5857C48.7027 80.7815 48.6564 81.0046 48.6764 81.2268C48.6897 81.3743 48.7319 81.5178 48.8007 81.649C48.8694 81.7803 48.9633 81.8967 49.0771 81.9916C49.1908 82.0865 49.3222 82.1581 49.4636 82.2023C49.605 82.2464 49.7537 82.2623 49.9013 82.2489Z"
              fill="#00BA77"
            />
            <path
              d="M57.1323 81.5998C57.3544 81.5797 57.5656 81.4942 57.7392 81.3541C57.9127 81.214 58.0408 81.0256 58.1073 80.8126C58.1738 80.5997 58.1756 80.3719 58.1126 80.1579C58.0496 79.9439 57.9246 79.7535 57.7533 79.6106C57.5821 79.4676 57.3723 79.3787 57.1505 79.355C56.9287 79.3313 56.7049 79.3739 56.5073 79.4774C56.3097 79.5809 56.1473 79.7407 56.0405 79.9365C55.9337 80.1324 55.8874 80.3555 55.9074 80.5776C55.9346 80.8754 56.0789 81.1503 56.3085 81.3419C56.5381 81.5335 56.8344 81.6262 57.1323 81.5998Z"
              fill="#00BA77"
            />
            <path
              d="M64.3759 80.9501C64.598 80.93 64.8092 80.8445 64.9828 80.7044C65.1564 80.5643 65.2845 80.3759 65.3509 80.163C65.4174 79.9501 65.4193 79.7222 65.3563 79.5083C65.2933 79.2943 65.1682 79.1038 64.997 78.9609C64.8257 78.818 64.616 78.7291 64.3942 78.7054C64.1724 78.6817 63.9485 78.7243 63.751 78.8278C63.5534 78.9313 63.3909 79.091 63.2841 79.2869C63.1774 79.4827 63.131 79.7058 63.151 79.9279C63.1643 80.0755 63.2065 80.219 63.2753 80.3502C63.344 80.4815 63.438 80.5979 63.5517 80.6928C63.6655 80.7877 63.7968 80.8593 63.9382 80.9034C64.0796 80.9476 64.2284 80.9635 64.3759 80.9501Z"
              fill="#00BA77"
            />
            <path
              d="M71.6122 80.3002C71.8348 80.2804 72.0466 80.1951 72.2207 80.055C72.3948 79.9148 72.5234 79.7262 72.5902 79.513C72.6571 79.2998 72.6592 79.0715 72.5963 78.8571C72.5334 78.6426 72.4083 78.4517 72.2369 78.3083C72.0654 78.165 71.8553 78.0758 71.6331 78.0519C71.4109 78.028 71.1866 78.0706 70.9886 78.1742C70.7906 78.2778 70.6278 78.4378 70.5208 78.634C70.4138 78.8302 70.3673 79.0537 70.3874 79.2763C70.4004 79.424 70.4425 79.5676 70.5111 79.6991C70.5798 79.8305 70.6737 79.9471 70.7875 80.0422C70.9012 80.1373 71.0326 80.2091 71.1742 80.2533C71.3157 80.2976 71.4646 80.3135 71.6122 80.3002Z"
              fill="#00BA77"
            />
            <path
              d="M78.8495 79.6505C79.0721 79.6308 79.2839 79.5454 79.458 79.4053C79.6321 79.2652 79.7607 79.0766 79.8275 78.8633C79.8944 78.6501 79.8965 78.4218 79.8336 78.2074C79.7707 77.993 79.6456 77.802 79.4742 77.6587C79.3027 77.5153 79.0926 77.4261 78.8704 77.4022C78.6482 77.3783 78.4239 77.4209 78.2259 77.5245C78.0279 77.6281 77.8651 77.7882 77.7581 77.9843C77.6511 78.1805 77.6046 78.404 77.6247 78.6266C77.6515 78.9247 77.7956 79.2 78.0252 79.392C78.2549 79.584 78.5514 79.677 78.8495 79.6505Z"
              fill="#00BA77"
            />
            <path
              d="M86.0878 78.9994C86.31 78.9793 86.5212 78.8938 86.6947 78.7537C86.8683 78.6136 86.9964 78.4252 87.0629 78.2123C87.1293 77.9994 87.1312 77.7715 87.0682 77.5576C87.0052 77.3436 86.8801 77.1531 86.7089 77.0102C86.5376 76.8673 86.3279 76.7784 86.1061 76.7547C85.8843 76.731 85.6605 76.7736 85.4629 76.8771C85.2653 76.9806 85.1028 77.1404 84.9961 77.3362C84.8893 77.532 84.8429 77.7551 84.8629 77.9772C84.876 78.1249 84.9181 78.2685 84.9868 78.3998C85.0554 78.5312 85.1494 78.6477 85.2632 78.7426C85.377 78.8376 85.5084 78.9092 85.6499 78.9532C85.7914 78.9973 85.9402 79.013 86.0878 78.9994Z"
              fill="#00BA77"
            />
            <path
              d="M93.3241 78.3493C93.5463 78.3292 93.7575 78.2437 93.931 78.1036C94.1046 77.9635 94.2327 77.7751 94.2992 77.5621C94.3657 77.3492 94.3675 77.1214 94.3045 76.9074C94.2415 76.6935 94.1165 76.503 93.9452 76.3601C93.774 76.2171 93.5642 76.1282 93.3424 76.1045C93.1206 76.0808 92.8968 76.1234 92.6992 76.2269C92.5016 76.3304 92.3392 76.4902 92.2324 76.686C92.1256 76.8819 92.0793 77.105 92.0993 77.3271C92.1265 77.6249 92.2708 77.8998 92.5004 78.0914C92.73 78.283 93.0263 78.3757 93.3241 78.3493Z"
              fill="#00BA77"
            />
            <path
              d="M100.563 77.6996C100.785 77.6795 100.996 77.594 101.17 77.4539C101.343 77.3138 101.471 77.1254 101.538 76.9125C101.604 76.6996 101.606 76.4717 101.543 76.2578C101.48 76.0438 101.355 75.8533 101.184 75.7104C101.013 75.5675 100.803 75.4786 100.581 75.4549C100.359 75.4312 100.136 75.4738 99.938 75.5773C99.7404 75.6808 99.5779 75.8405 99.4712 76.0364C99.3644 76.2322 99.318 76.4553 99.338 76.6774C99.3513 76.825 99.3935 76.9685 99.4623 77.0997C99.531 77.231 99.625 77.3474 99.7387 77.4423C99.8525 77.5372 99.9838 77.6088 100.125 77.653C100.267 77.6971 100.415 77.713 100.563 77.6996Z"
              fill="#00BA77"
            />
            <path
              d="M107.799 77.05C108.021 77.0299 108.233 76.9444 108.406 76.8043C108.58 76.6642 108.708 76.4757 108.774 76.2628C108.841 76.0499 108.843 75.8221 108.78 75.6081C108.717 75.3941 108.592 75.2037 108.42 75.0607C108.249 74.9178 108.039 74.8289 107.817 74.8052C107.596 74.7815 107.372 74.8241 107.174 74.9276C106.977 75.0311 106.814 75.1909 106.707 75.3867C106.601 75.5825 106.554 75.8056 106.574 76.0278C106.588 76.1753 106.63 76.3188 106.699 76.4501C106.767 76.5813 106.861 76.6977 106.975 76.7926C107.089 76.8876 107.22 76.9591 107.362 77.0033C107.503 77.0474 107.652 77.0633 107.799 77.05Z"
              fill="#00BA77"
            />
            <path
              d="M115.036 76.4005C115.259 76.3808 115.47 76.2954 115.644 76.1553C115.819 76.0152 115.947 75.8266 116.014 75.6134C116.081 75.4001 116.083 75.1718 116.02 74.9574C115.957 74.743 115.832 74.552 115.661 74.4087C115.489 74.2653 115.279 74.1761 115.057 74.1522C114.835 74.1283 114.61 74.1709 114.412 74.2745C114.214 74.3781 114.052 74.5382 113.945 74.7343C113.838 74.9305 113.791 75.154 113.811 75.3766C113.824 75.5243 113.866 75.668 113.935 75.7994C114.004 75.9309 114.098 76.0475 114.211 76.1426C114.325 76.2377 114.456 76.3094 114.598 76.3537C114.74 76.3979 114.888 76.4138 115.036 76.4005Z"
              fill="#00BA77"
            />
            <path
              d="M122.274 75.7506C122.497 75.7309 122.709 75.6455 122.883 75.5054C123.057 75.3653 123.185 75.1767 123.252 74.9635C123.319 74.7502 123.321 74.5219 123.258 74.3075C123.196 74.0931 123.07 73.9021 122.899 73.7588C122.728 73.6154 122.517 73.5262 122.295 73.5023C122.073 73.4784 121.849 73.521 121.651 73.6246C121.453 73.7282 121.29 73.8883 121.183 74.0844C121.076 74.2806 121.029 74.5041 121.049 74.7267C121.076 75.0248 121.22 75.3001 121.45 75.4921C121.68 75.6841 121.976 75.7771 122.274 75.7506Z"
              fill="#00BA77"
            />
            <path
              d="M129.512 75.1009C129.734 75.0812 129.946 74.9959 130.12 74.8557C130.294 74.7156 130.423 74.527 130.49 74.3138C130.557 74.1006 130.559 73.8723 130.496 73.6578C130.433 73.4434 130.308 73.2524 130.136 73.1091C129.965 72.9658 129.755 72.8765 129.533 72.8527C129.31 72.8288 129.086 72.8713 128.888 72.975C128.69 73.0786 128.527 73.2386 128.42 73.4348C128.313 73.631 128.267 73.8545 128.287 74.077C128.314 74.375 128.458 74.65 128.688 74.8419C128.917 75.0338 129.214 75.1269 129.512 75.1009Z"
              fill="#00BA77"
            />
            <path
              d="M136.749 74.4499C136.972 74.4298 137.183 74.3443 137.356 74.2042C137.53 74.0641 137.658 73.8756 137.724 73.6627C137.791 73.4498 137.793 73.222 137.73 73.008C137.667 72.794 137.542 72.6036 137.371 72.4606C137.199 72.3177 136.989 72.2288 136.768 72.2051C136.546 72.1814 136.322 72.224 136.124 72.3275C135.927 72.431 135.764 72.5908 135.658 72.7866C135.551 72.9824 135.505 73.2055 135.525 73.4277C135.538 73.5752 135.58 73.7187 135.649 73.85C135.718 73.9812 135.811 74.0976 135.925 74.1925C136.039 74.2875 136.17 74.359 136.312 74.4032C136.453 74.4473 136.602 74.4632 136.749 74.4499Z"
              fill="#00BA77"
            />
            <path
              d="M143.986 73.8002C144.208 73.7801 144.42 73.6946 144.593 73.5545C144.767 73.4144 144.895 73.226 144.961 73.0131C145.028 72.8002 145.03 72.5723 144.967 72.3583C144.904 72.1444 144.779 71.9539 144.607 71.811C144.436 71.6681 144.226 71.5792 144.005 71.5555C143.783 71.5318 143.559 71.5744 143.361 71.6779C143.164 71.7814 143.001 71.9411 142.894 72.137C142.788 72.3328 142.741 72.5559 142.761 72.778C142.775 72.9256 142.817 73.0691 142.886 73.2003C142.954 73.3315 143.048 73.448 143.162 73.5429C143.276 73.6378 143.407 73.7094 143.549 73.7535C143.69 73.7977 143.839 73.8136 143.986 73.8002Z"
              fill="#00BA77"
            />
            <path
              d="M151.223 73.1501C151.445 73.13 151.656 73.0445 151.829 72.9044C152.003 72.7643 152.131 72.5758 152.198 72.3629C152.264 72.15 152.266 71.9222 152.203 71.7082C152.14 71.4942 152.015 71.3038 151.844 71.1608C151.672 71.0179 151.463 70.929 151.241 70.9053C151.019 70.8816 150.795 70.9242 150.598 71.0277C150.4 71.1312 150.238 71.291 150.131 71.4868C150.024 71.6826 149.978 71.9057 149.998 72.1279C150.011 72.2754 150.053 72.4189 150.122 72.5502C150.191 72.6814 150.285 72.7978 150.398 72.8927C150.512 72.9877 150.643 73.0592 150.785 73.1034C150.926 73.1475 151.075 73.1634 151.223 73.1501Z"
              fill="#00BA77"
            />
            <path
              d="M158.461 72.5004C158.684 72.4806 158.896 72.3953 159.07 72.2552C159.244 72.115 159.372 71.9264 159.439 71.7132C159.506 71.5 159.508 71.2717 159.445 71.0573C159.383 70.8428 159.257 70.6518 159.086 70.5085C158.915 70.3652 158.704 70.276 158.482 70.2521C158.26 70.2282 158.036 70.2708 157.838 70.3744C157.64 70.478 157.477 70.638 157.37 70.8342C157.263 71.0304 157.216 71.2539 157.236 71.4765C157.25 71.6242 157.292 71.7679 157.36 71.8993C157.429 72.0307 157.523 72.1473 157.637 72.2424C157.75 72.3375 157.882 72.4093 158.023 72.4535C158.165 72.4978 158.314 72.5137 158.461 72.5004Z"
              fill="#00BA77"
            />
            <path
              d="M165.698 71.851C165.92 71.8312 166.132 71.7459 166.306 71.6057C166.48 71.4656 166.609 71.277 166.676 71.0638C166.743 70.8506 166.745 70.6223 166.682 70.4078C166.619 70.1934 166.494 70.0024 166.322 69.8591C166.151 69.7158 165.941 69.6265 165.719 69.6027C165.496 69.5788 165.272 69.6213 165.074 69.725C164.876 69.8286 164.713 69.9886 164.606 70.1848C164.499 70.381 164.453 70.6045 164.473 70.827C164.5 71.125 164.644 71.4 164.874 71.5919C165.103 71.7838 165.4 71.8769 165.698 71.851Z"
              fill="#00BA77"
            />
            <path
              d="M172.936 71.1994C173.158 71.1793 173.369 71.0938 173.543 70.9537C173.716 70.8136 173.845 70.6252 173.911 70.4122C173.977 70.1993 173.979 69.9715 173.916 69.7575C173.853 69.5435 173.728 69.3531 173.557 69.2102C173.386 69.0672 173.176 68.9783 172.954 68.9546C172.732 68.9309 172.509 68.9735 172.311 69.077C172.113 69.1805 171.951 69.3403 171.844 69.5361C171.737 69.732 171.691 69.955 171.711 70.1772C171.724 70.3248 171.766 70.4685 171.835 70.5998C171.904 70.7311 171.998 70.8476 172.111 70.9426C172.225 71.0376 172.357 71.1091 172.498 71.1532C172.64 71.1973 172.788 71.213 172.936 71.1994Z"
              fill="#00BA77"
            />
            <path
              d="M180.173 70.5497C180.395 70.5296 180.606 70.4441 180.78 70.304C180.953 70.1639 181.081 69.9755 181.148 69.7626C181.214 69.5497 181.216 69.3218 181.153 69.1079C181.09 68.8939 180.965 68.7034 180.794 68.5605C180.623 68.4176 180.413 68.3287 180.191 68.305C179.969 68.2813 179.745 68.3239 179.548 68.4274C179.35 68.5309 179.188 68.6906 179.081 68.8865C178.974 69.0823 178.928 69.3054 178.948 69.5275C178.961 69.6752 179.003 69.8188 179.072 69.9501C179.14 70.0815 179.234 70.198 179.348 70.2929C179.462 70.3879 179.593 70.4595 179.735 70.5035C179.876 70.5476 180.025 70.5633 180.173 70.5497Z"
              fill="#00BA77"
            />
            <path
              d="M187.416 69.8998C187.639 69.8797 187.85 69.7942 188.023 69.6541C188.197 69.514 188.325 69.3256 188.391 69.1127C188.458 68.8998 188.46 68.6719 188.397 68.458C188.334 68.244 188.209 68.0535 188.037 67.9106C187.866 67.7677 187.656 67.6788 187.435 67.6551C187.213 67.6314 186.989 67.674 186.791 67.7775C186.594 67.881 186.431 68.0407 186.325 68.2366C186.218 68.4324 186.172 68.6555 186.192 68.8776C186.205 69.0252 186.247 69.1687 186.316 69.2999C186.385 69.4312 186.478 69.5476 186.592 69.6425C186.706 69.7374 186.837 69.809 186.979 69.8531C187.12 69.8973 187.269 69.9132 187.416 69.8998Z"
              fill="#00BA77"
            />
            <path
              d="M194.648 69.2504C194.871 69.2303 195.082 69.1448 195.255 69.0047C195.429 68.8646 195.557 68.6762 195.623 68.4633C195.69 68.2503 195.692 68.0225 195.629 67.8085C195.566 67.5946 195.441 67.4041 195.269 67.2612C195.098 67.1183 194.888 67.0293 194.667 67.0057C194.445 66.982 194.221 67.0246 194.023 67.1281C193.826 67.2316 193.663 67.3913 193.557 67.5872C193.45 67.783 193.403 68.0061 193.423 68.2282C193.437 68.3758 193.479 68.5193 193.548 68.6505C193.616 68.7817 193.71 68.8981 193.824 68.9931C193.938 69.088 194.069 69.1596 194.211 69.2037C194.352 69.2479 194.501 69.2637 194.648 69.2504Z"
              fill="#00BA77"
            />
            <path
              d="M201.885 68.6007C202.107 68.581 202.319 68.4956 202.493 68.3555C202.667 68.2154 202.796 68.0268 202.863 67.8135C202.93 67.6003 202.932 67.372 202.869 67.1576C202.806 66.9432 202.681 66.7522 202.509 66.6089C202.338 66.4655 202.128 66.3763 201.906 66.3524C201.683 66.3285 201.459 66.3711 201.261 66.4747C201.063 66.5783 200.9 66.7384 200.793 66.9345C200.686 67.1307 200.64 67.3542 200.66 67.5768C200.687 67.8749 200.831 68.1502 201.06 68.3422C201.29 68.5342 201.587 68.6272 201.885 68.6007Z"
              fill="#00BA77"
            />
            <path
              d="M209.123 67.9511C209.346 67.9313 209.558 67.846 209.732 67.7058C209.906 67.5657 210.035 67.3771 210.101 67.1639C210.168 66.9507 210.17 66.7224 210.108 66.5079C210.045 66.2935 209.92 66.1025 209.748 65.9592C209.577 65.8159 209.367 65.7266 209.144 65.7028C208.922 65.6789 208.698 65.7214 208.5 65.8251C208.302 65.9287 208.139 66.0887 208.032 66.2849C207.925 66.4811 207.879 66.7046 207.899 66.9271C207.925 67.2253 208.07 67.5006 208.299 67.6926C208.529 67.8845 208.825 67.9775 209.123 67.9511Z"
              fill="#00BA77"
            />
            <path
              d="M216.36 67.3014C216.582 67.2816 216.794 67.1963 216.968 67.0562C217.142 66.9161 217.271 66.7275 217.338 66.5142C217.405 66.301 217.407 66.0727 217.344 65.8583C217.281 65.6438 217.156 65.4529 216.984 65.3095C216.813 65.1662 216.603 65.077 216.381 65.0531C216.158 65.0292 215.934 65.0718 215.736 65.1754C215.538 65.279 215.375 65.439 215.268 65.6352C215.161 65.8314 215.115 66.0549 215.135 66.2775C215.148 66.4252 215.19 66.5689 215.259 66.7003C215.327 66.8317 215.421 66.9483 215.535 67.0434C215.649 67.1385 215.78 67.2103 215.922 67.2545C216.063 67.2988 216.212 67.3147 216.36 67.3014Z"
              fill="#00BA77"
            />
            <path
              d="M223.597 66.6498C223.819 66.6297 224.03 66.5442 224.204 66.4041C224.377 66.264 224.505 66.0756 224.572 65.8627C224.638 65.6498 224.64 65.4219 224.577 65.208C224.514 64.994 224.389 64.8035 224.218 64.6606C224.046 64.5177 223.837 64.4288 223.615 64.4051C223.393 64.3814 223.169 64.424 222.972 64.5275C222.774 64.631 222.612 64.7907 222.505 64.9866C222.398 65.1824 222.352 65.4055 222.372 65.6276C222.385 65.7752 222.427 65.9187 222.496 66.0499C222.565 66.1812 222.659 66.2976 222.772 66.3925C222.886 66.4874 223.017 66.559 223.159 66.6031C223.3 66.6473 223.449 66.6632 223.597 66.6498Z"
              fill="#00BA77"
            />
            <path
              d="M230.835 66.0002C231.057 65.9801 231.268 65.8946 231.442 65.7545C231.615 65.6144 231.743 65.4259 231.81 65.213C231.876 65.0001 231.878 64.7723 231.815 64.5583C231.752 64.3443 231.627 64.1538 231.456 64.0109C231.285 63.868 231.075 63.7791 230.853 63.7554C230.631 63.7317 230.408 63.7743 230.21 63.8778C230.012 63.9813 229.85 64.1411 229.743 64.3369C229.636 64.5327 229.59 64.7558 229.61 64.978C229.623 65.1255 229.666 65.269 229.734 65.4003C229.803 65.5315 229.897 65.6479 230.011 65.7428C230.124 65.8377 230.256 65.9093 230.397 65.9535C230.539 65.9976 230.687 66.0135 230.835 66.0002Z"
              fill="#00BA77"
            />
            <path
              d="M238.072 65.3505C238.294 65.3304 238.505 65.2449 238.679 65.1048C238.852 64.9647 238.98 64.7763 239.047 64.5634C239.113 64.3505 239.115 64.1226 239.052 63.9086C238.989 63.6947 238.864 63.5042 238.693 63.3613C238.522 63.2184 238.312 63.1294 238.09 63.1057C237.868 63.0821 237.644 63.1247 237.447 63.2282C237.249 63.3317 237.087 63.4914 236.98 63.6873C236.873 63.8831 236.827 64.1062 236.847 64.3283C236.874 64.6261 237.018 64.901 237.248 65.0926C237.478 65.2842 237.774 65.377 238.072 65.3505Z"
              fill="#00BA77"
            />
            <path
              d="M245.31 64.7011C245.532 64.6813 245.744 64.596 245.918 64.4558C246.092 64.3157 246.221 64.1271 246.288 63.9139C246.354 63.7007 246.357 63.4724 246.294 63.2579C246.231 63.0435 246.106 62.8525 245.934 62.7092C245.763 62.5659 245.553 62.4766 245.33 62.4528C245.108 62.4289 244.884 62.4714 244.686 62.5751C244.488 62.6787 244.325 62.8387 244.218 63.0349C244.111 63.2311 244.065 63.4546 244.085 63.6771C244.098 63.8248 244.14 63.9685 244.208 64.1C244.277 64.2314 244.371 64.348 244.485 64.4431C244.599 64.5382 244.73 64.6099 244.871 64.6542C245.013 64.6985 245.162 64.7144 245.31 64.7011Z"
              fill="#00BA77"
            />
            <path
              d="M252.546 64.0514C252.768 64.0316 252.98 63.9463 253.154 63.8062C253.328 63.6661 253.457 63.4775 253.524 63.2642C253.591 63.051 253.593 62.8227 253.53 62.6083C253.467 62.3938 253.342 62.2029 253.17 62.0596C252.999 61.9162 252.789 61.827 252.567 61.8031C252.345 61.7792 252.12 61.8218 251.922 61.9254C251.724 62.029 251.561 62.1891 251.454 62.3852C251.347 62.5814 251.301 62.8049 251.321 63.0275C251.334 63.1752 251.376 63.3189 251.445 63.4503C251.513 63.5817 251.607 63.6983 251.721 63.7934C251.835 63.8885 251.966 63.9603 252.108 64.0045C252.249 64.0488 252.398 64.0647 252.546 64.0514Z"
              fill="#00BA77"
            />
            <path
              d="M259.783 63.4015C260.006 63.3817 260.217 63.2964 260.392 63.1563C260.566 63.0162 260.694 62.8276 260.761 62.6143C260.828 62.4011 260.83 62.1728 260.767 61.9584C260.704 61.7439 260.579 61.553 260.408 61.4097C260.236 61.2663 260.026 61.1771 259.804 61.1532C259.582 61.1293 259.358 61.1719 259.16 61.2755C258.962 61.3791 258.799 61.5391 258.692 61.7353C258.585 61.9315 258.538 62.155 258.558 62.3776C258.585 62.6757 258.729 62.951 258.959 63.143C259.189 63.335 259.485 63.428 259.783 63.4015Z"
              fill="#00BA77"
            />
            <path
              d="M267.022 62.7499C267.244 62.7298 267.455 62.6443 267.629 62.5042C267.802 62.3641 267.93 62.1757 267.997 61.9628C268.063 61.7499 268.065 61.522 268.002 61.3081C267.939 61.0941 267.814 60.9036 267.643 60.7607C267.472 60.6178 267.262 60.5289 267.04 60.5052C266.818 60.4815 266.595 60.5241 266.397 60.6276C266.199 60.7311 266.037 60.8908 265.93 61.0867C265.823 61.2825 265.777 61.5056 265.797 61.7277C265.81 61.8753 265.853 62.0188 265.921 62.15C265.99 62.2813 266.084 62.3977 266.198 62.4926C266.311 62.5875 266.443 62.6591 266.584 62.7032C266.726 62.7474 266.874 62.7633 267.022 62.7499Z"
              fill="#00BA77"
            />
            <path
              d="M274.258 62.1005C274.48 62.0804 274.692 61.9949 274.865 61.8548C275.039 61.7147 275.167 61.5263 275.233 61.3134C275.3 61.1005 275.302 60.8726 275.239 60.6586C275.176 60.4447 275.051 60.2542 274.879 60.1113C274.708 59.9684 274.498 59.8794 274.276 59.8557C274.055 59.8321 273.831 59.8747 273.633 59.9782C273.436 60.0817 273.273 60.2414 273.166 60.4373C273.06 60.6331 273.013 60.8562 273.033 61.0783C273.061 61.3761 273.205 61.651 273.434 61.8426C273.664 62.0342 273.96 62.127 274.258 62.1005Z"
              fill="#00BA77"
            />
            <path
              d="M281.492 61.4508C281.714 61.4308 281.925 61.3453 282.099 61.2052C282.272 61.065 282.4 60.8766 282.467 60.6637C282.533 60.4508 282.535 60.223 282.472 60.009C282.409 59.795 282.284 59.6045 282.113 59.4616C281.941 59.3187 281.732 59.2298 281.51 59.2061C281.288 59.1824 281.064 59.225 280.867 59.3285C280.669 59.432 280.507 59.5918 280.4 59.7876C280.293 59.9834 280.247 60.2065 280.267 60.4287C280.28 60.5762 280.322 60.7197 280.391 60.851C280.46 60.9822 280.554 61.0986 280.667 61.1935C280.781 61.2884 280.913 61.36 281.054 61.4042C281.195 61.4483 281.344 61.4642 281.492 61.4508Z"
              fill="#00BA77"
            />
            <path
              d="M288.733 60.8012C288.956 60.7814 289.168 60.6961 289.342 60.556C289.516 60.4158 289.644 60.2272 289.711 60.014C289.778 59.8008 289.78 59.5725 289.717 59.358C289.655 59.1436 289.529 58.9526 289.358 58.8093C289.187 58.666 288.976 58.5767 288.754 58.5529C288.532 58.529 288.308 58.5715 288.11 58.6752C287.912 58.7788 287.749 58.9388 287.642 59.135C287.535 59.3312 287.488 59.5547 287.508 59.7772C287.522 59.925 287.564 60.0686 287.632 60.2001C287.701 60.3315 287.795 60.4481 287.909 60.5432C288.022 60.6383 288.154 60.71 288.295 60.7543C288.437 60.7986 288.586 60.8145 288.733 60.8012Z"
              fill="#00BA77"
            />
            <path
              d="M295.97 60.151C296.193 60.1313 296.404 60.0459 296.579 59.9058C296.753 59.7657 296.881 59.5771 296.948 59.3638C297.015 59.1506 297.017 58.9223 296.954 58.7079C296.891 58.4935 296.766 58.3025 296.595 58.1592C296.423 58.0158 296.213 57.9266 295.991 57.9027C295.769 57.8788 295.545 57.9214 295.347 58.025C295.149 58.1286 294.986 58.2887 294.879 58.4848C294.772 58.681 294.725 58.9045 294.745 59.1271C294.772 59.4252 294.916 59.7005 295.146 59.8925C295.376 60.0845 295.672 60.1775 295.97 60.151Z"
              fill="#00BA77"
            />
            <path
              d="M139.134 178.112C215.975 178.112 278.268 152.117 278.268 120.051C278.268 87.9842 215.975 61.9893 139.134 61.9893C62.2923 61.9893 0 87.9842 0 120.051C0 152.117 62.2923 178.112 139.134 178.112Z"
              fill="url(#paint0_radial_3167_5766)"
            />
            <path
              d="M270.694 36.1598L17.1538 59.323L23.471 128.47L277.012 105.307L270.694 36.1598Z"
              fill="#00BA77"
            />
            <path
              d="M271.499 127.491L92.3833 110.118L85.68 179.229L264.796 196.602L271.499 127.491Z"
              fill="#156649"
            />
            <path
              d="M32.2089 111.856C28.6135 110.365 25.5017 107.906 23.219 104.753C20.909 101.579 19.5588 97.8672 19.1684 93.618C18.778 89.3687 19.4334 85.4735 21.1348 81.9323C22.8085 78.4178 25.4231 75.4356 28.6885 73.3165C32.0253 71.1139 35.8997 69.8117 40.3118 69.4097C44.1626 69.0632 47.6946 69.434 50.9078 70.522C54.0445 71.5675 56.8756 73.3692 59.1511 75.768L52.2037 83.3789C49.1718 80.3031 45.7115 78.9425 41.8226 79.2971C39.538 79.5062 37.5491 80.1992 35.8558 81.3761C34.178 82.5326 32.8715 84.1509 32.0946 86.0348C31.2803 87.966 30.9823 90.1253 31.2006 92.5126C31.4189 94.9 32.1032 96.9692 33.2536 98.7202C34.3578 100.432 35.9352 101.787 37.7945 102.62C39.6725 103.469 41.7544 103.789 44.0402 103.58C47.9302 103.224 51.0874 101.261 53.5118 97.6894L61.7238 103.926C59.9199 106.697 57.462 108.981 54.5669 110.577C51.602 112.236 48.1947 113.241 44.3451 113.592C39.9341 113.989 35.8888 113.411 32.2089 111.856Z"
              fill="white"
            />
            <path
              d="M74.6166 107.944C70.9931 106.446 67.8514 103.98 65.5348 100.817C63.2005 97.6451 61.8422 93.9549 61.4599 89.7461C61.0776 85.5372 61.7447 81.6611 63.461 78.1175C65.1665 74.5843 67.8101 71.5877 71.103 69.455C74.478 67.2305 78.3917 65.9143 82.8442 65.5066C87.2967 65.0989 91.3837 65.6845 95.1051 67.2634C98.7281 68.7633 101.87 71.2286 104.189 74.3909C106.518 77.5648 107.877 81.2556 108.263 85.4633C108.65 89.671 107.983 93.5466 106.261 97.0901C104.554 100.62 101.911 103.614 98.6187 105.744C95.2437 107.97 91.33 109.286 86.8775 109.692C82.4249 110.099 78.338 109.516 74.6166 107.944ZM91.6089 97.7034C93.2493 96.5246 94.5304 94.9137 95.3096 93.0499C96.1423 91.1176 96.4507 88.9578 96.2347 86.5704C96.0187 84.183 95.3257 82.1144 94.1557 80.3646C93.0523 78.6735 91.5014 77.3217 89.6755 76.4595C87.8223 75.6264 85.7854 75.2856 83.7619 75.4701C81.7384 75.6546 79.7968 76.3583 78.1249 77.5129C76.4845 78.6917 75.2034 80.3026 74.4243 82.1664C73.5892 84.0998 73.2814 86.2603 73.5008 88.6477C73.7203 91.035 74.4133 93.1031 75.5798 94.8517C76.6822 96.5438 78.2334 97.8958 80.0601 98.7568C81.9131 99.5896 83.9496 99.9302 85.9727 99.7457C87.9959 99.5612 89.9372 98.8578 91.6089 97.7034Z"
              fill="white"
            />
            <path
              d="M153.274 102.781L151.098 80.2744L141.944 99.5935L136.654 100.075L124.269 83.3374L126.259 105.247L115.249 106.253L111.375 63.7671L121.181 62.8696L138.214 86.0419L150.412 60.2033L160.218 59.3076L164.219 101.782L153.274 102.781Z"
              fill="white"
            />
            <path
              d="M167.562 58.6356L179.473 57.5476L183.354 100.035L171.443 101.115L167.562 58.6356Z"
              fill="white"
            />
            <path
              d="M226.819 53.2231L230.698 95.711L220.892 96.6067L200.237 75.666L202.304 98.3029L190.634 99.3701L186.753 56.8822L196.559 55.9882L217.216 76.929L215.149 54.2904L226.819 53.2231Z"
              fill="white"
            />
            <path
              d="M264.108 70.3212L274.637 69.3614L276.245 86.9619C273.888 88.9877 271.204 90.5987 268.308 91.7263C265.348 92.921 262.229 93.6797 259.051 93.9785C254.638 94.3805 250.581 93.803 246.88 92.246C243.277 90.7594 240.158 88.3 237.871 85.1428C235.561 81.9677 234.211 78.2555 233.821 74.0062C233.43 69.757 234.086 65.8617 235.787 62.3205C237.485 58.7793 240.033 55.905 243.431 53.6978C246.829 51.4906 250.773 50.1814 255.264 49.7703C259.191 49.4122 262.781 49.7587 266.035 50.8098C269.184 51.7965 272.047 53.5297 274.382 55.8617L267.435 63.4709C264.299 60.5753 260.746 59.3088 256.775 59.6715C253.166 60.0007 250.36 61.3694 248.355 63.7775C246.35 66.1857 245.516 69.2297 245.853 72.9096C246.067 75.2554 246.76 77.3038 247.932 79.0547C249.064 80.7718 250.661 82.1325 252.535 82.9789C254.434 83.8451 256.547 84.1726 258.873 83.9612C261.097 83.7723 263.251 83.0943 265.182 81.9757L264.108 70.3212Z"
              fill="white"
            />
            <path
              d="M93.5625 165.408C90.4255 164.241 87.9192 162.85 86.0435 161.236L90.8321 152.721C92.7385 154.246 94.8556 155.487 97.1176 156.406C99.4271 157.394 101.873 158.029 104.372 158.287C108.959 158.732 111.366 157.797 111.591 155.482C111.706 154.265 111.14 153.297 109.891 152.58C108.643 151.863 106.6 151.039 103.762 150.11C100.643 149.112 98.0584 148.113 96.0071 147.114C93.9609 146.126 92.198 144.636 90.8823 142.783C89.5229 140.889 88.987 138.459 89.2746 135.494C89.4884 132.986 90.4634 130.602 92.0691 128.663C93.6861 126.709 95.9499 125.258 98.8605 124.311C101.771 123.364 105.262 123.081 109.333 123.464C112.097 123.73 114.821 124.311 117.452 125.196C120.088 126.089 122.373 127.244 124.308 128.661L119.756 137.26C115.945 134.676 112.146 133.201 108.362 132.835C105.986 132.604 104.22 132.791 103.065 133.396C101.91 134.001 101.275 134.872 101.16 136.009C101.05 137.148 101.611 138.064 102.844 138.757C104.076 139.45 106.094 140.242 108.899 141.135C112.052 142.137 114.646 143.136 116.683 144.134C118.719 145.13 120.48 146.609 121.811 148.443C123.197 150.319 123.744 152.739 123.453 155.704C123.239 158.183 122.266 160.537 120.666 162.443C119.056 164.379 116.783 165.829 113.847 166.794C110.911 167.758 107.429 168.047 103.401 167.66C100.038 167.34 96.7303 166.583 93.5625 165.408Z"
              fill="#F1F1F1"
            />
            <path
              d="M135.95 167.92C132.656 165.762 130.019 162.741 128.327 159.186C126.621 155.618 125.973 151.723 126.383 147.5C126.793 143.278 128.179 139.582 130.541 136.412C132.887 133.251 136.056 130.794 139.702 129.309C143.449 127.745 147.557 127.18 152.026 127.615C156.494 128.049 160.417 129.393 163.793 131.646C167.085 133.804 169.722 136.824 171.416 140.378C173.121 143.947 173.769 147.843 173.361 152.066C172.954 156.288 171.568 159.984 169.203 163.154C166.859 166.316 163.69 168.774 160.044 170.257C156.296 171.821 152.188 172.385 147.72 171.951C143.253 171.517 139.329 170.173 135.95 167.92ZM154.631 161C156.47 160.144 158.035 158.796 159.153 157.104C160.338 155.354 161.047 153.281 161.279 150.884C161.511 148.487 161.214 146.317 160.387 144.372C159.614 142.496 158.337 140.872 156.698 139.678C155.026 138.509 153.081 137.791 151.05 137.594C149.019 137.397 146.972 137.727 145.106 138.554C143.267 139.41 141.702 140.759 140.584 142.452C139.398 144.202 138.689 146.275 138.457 148.672C138.225 151.068 138.522 153.238 139.349 155.182C140.12 157.058 141.397 158.683 143.037 159.876C144.71 161.045 146.656 161.763 148.687 161.96C150.718 162.157 152.765 161.826 154.631 161Z"
              fill="#F1F1F1"
            />
            <path
              d="M185.821 172.757C182.528 170.599 179.891 167.578 178.198 164.024C176.492 160.455 175.844 156.559 176.254 152.336C176.664 148.113 178.05 144.415 180.412 141.239C182.757 138.077 185.925 135.62 189.572 134.136C193.319 132.571 197.426 132.006 201.895 132.44C206.364 132.874 210.286 134.218 213.662 136.472C216.954 138.63 219.591 141.651 221.285 145.205C222.991 148.774 223.64 152.67 223.231 156.893C222.822 161.115 221.436 164.811 219.073 167.981C216.728 171.143 213.558 173.6 209.911 175.084C206.164 176.649 202.057 177.214 197.59 176.78C193.122 176.346 189.199 175.005 185.821 172.757ZM204.502 165.827C206.341 164.973 207.907 163.624 209.024 161.932C210.209 160.181 210.918 158.108 211.152 155.713C211.385 153.317 211.087 151.146 210.258 149.2C209.486 147.325 208.209 145.701 206.569 144.509C204.898 143.339 202.952 142.62 200.921 142.423C198.89 142.225 196.843 142.556 194.977 143.383C193.139 144.238 191.573 145.587 190.455 147.279C189.269 149.03 188.56 151.103 188.328 153.499C188.096 155.894 188.393 158.065 189.22 160.011C189.992 161.887 191.268 163.511 192.908 164.704C194.58 165.875 196.526 166.594 198.558 166.79C200.589 166.987 202.637 166.655 204.502 165.827Z"
              fill="#F1F1F1"
            />
            <path
              d="M270.577 139.962L266.441 182.604L256.599 181.65L240.14 157.119L237.937 179.841L226.223 178.704L230.359 136.064L240.201 137.017L256.66 161.547L258.862 138.826L270.577 139.962Z"
              fill="#F1F1F1"
            />
            <path
              d="M240.181 188.351L113.654 189.5L113.857 211.867L240.384 210.719L240.181 188.351Z"
              fill="#00BA77"
            />
            <path
              d="M116.384 207.837C115.509 207.638 114.675 207.286 113.922 206.797L115.123 204.008C115.762 204.416 116.457 204.73 117.186 204.94C117.933 205.171 118.709 205.287 119.491 205.286C120.923 205.275 121.637 204.907 121.632 204.183C121.632 203.802 121.425 203.521 121.01 203.341C120.37 203.093 119.709 202.905 119.035 202.779C118.189 202.613 117.355 202.388 116.54 202.107C115.873 201.868 115.277 201.464 114.808 200.932C114.324 200.391 114.078 199.657 114.071 198.732C114.054 197.95 114.277 197.18 114.711 196.528C115.197 195.827 115.876 195.282 116.667 194.961C117.538 194.571 118.608 194.371 119.877 194.359C120.741 194.351 121.602 194.443 122.445 194.633C123.228 194.798 123.982 195.082 124.679 195.477L123.553 198.285C122.283 197.612 121.06 197.28 119.884 197.291C119.142 197.291 118.604 197.414 118.266 197.637C118.114 197.725 117.987 197.851 117.899 198.003C117.812 198.155 117.765 198.328 117.765 198.504C117.765 198.859 117.968 199.124 118.375 199.299C119.006 199.535 119.658 199.713 120.322 199.831C121.172 199.995 122.009 200.22 122.827 200.503C123.492 200.743 124.087 201.143 124.56 201.667C125.048 202.201 125.298 202.934 125.307 203.859C125.322 204.632 125.098 205.391 124.666 206.033C124.173 206.732 123.49 207.277 122.699 207.603C121.823 207.998 120.756 208.201 119.499 208.212C118.449 208.223 117.402 208.097 116.384 207.837Z"
              fill="white"
            />
            <path
              d="M129.218 197.516L125.163 197.552L125.136 194.565L136.962 194.458L136.988 197.447L132.951 197.483L133.045 207.814L129.311 207.849L129.218 197.516Z"
              fill="white"
            />
            <path
              d="M146.262 205.106L140.679 205.158L139.665 207.757L135.854 207.792L141.571 194.418L145.249 194.385L151.214 207.651L147.33 207.686L146.262 205.106ZM145.143 202.334L143.411 198.086L141.751 202.364L145.143 202.334Z"
              fill="white"
            />
            <path
              d="M158.086 202.812L158.129 207.587L154.394 207.622L154.35 202.788L149.238 194.347L153.179 194.313L156.358 199.573L159.442 194.256L163.063 194.223L158.086 202.812Z"
              fill="white"
            />
            <path
              d="M171.575 197.133L167.521 197.169L167.493 194.183L179.319 194.077L179.347 197.064L175.31 197.1L175.404 207.431L171.668 207.466L171.575 197.133Z"
              fill="white"
            />
            <path
              d="M181.755 206.025C180.659 204.955 180.102 203.431 180.083 201.453L180.018 194.07L183.751 194.037L183.817 201.314C183.835 203.445 184.681 204.503 186.355 204.488C188.029 204.473 188.849 203.4 188.815 201.269L188.749 193.992L192.427 193.959L192.493 201.342C192.513 203.321 191.983 204.855 190.903 205.943C189.823 207.031 188.304 207.584 186.345 207.603C184.383 207.619 182.854 207.093 181.755 206.025Z"
              fill="white"
            />
            <path
              d="M207.138 193.824L207.258 207.144L204.185 207.171L198.294 200.127L198.358 207.23L194.699 207.263L194.58 193.944L197.653 193.916L203.544 200.96L203.479 193.857L207.138 193.824Z"
              fill="white"
            />
            <path
              d="M220.094 204.117L220.12 207.027L209.523 207.124L209.401 193.805L219.755 193.711L219.781 196.622L213.125 196.683L213.145 198.935L219.01 198.881L219.034 201.698L213.17 201.75L213.192 204.186L220.094 204.117Z"
              fill="white"
            />
            <path
              d="M221.699 193.694L227.936 193.637C229.264 193.601 230.582 193.869 231.791 194.42C232.869 194.916 233.78 195.715 234.414 196.719C235.029 197.788 235.359 198.997 235.37 200.23C235.381 201.463 235.074 202.679 234.478 203.758C233.863 204.774 232.966 205.589 231.896 206.104C230.698 206.678 229.384 206.969 228.055 206.956L221.818 207.014L221.699 193.694ZM227.882 203.951C229.015 203.941 229.921 203.61 230.6 202.956C231.279 202.302 231.614 201.405 231.603 200.264C231.593 199.123 231.242 198.232 230.55 197.591C229.858 196.95 228.947 196.634 227.816 196.645L225.46 196.665L225.526 203.973L227.882 203.951Z"
              fill="white"
            />
            <path
              d="M236.295 193.563L240.557 193.523L239.912 201.998L237.064 202.022L236.295 193.563ZM236.997 206.466C236.801 206.287 236.644 206.069 236.538 205.826C236.432 205.583 236.378 205.32 236.38 205.054C236.37 204.79 236.418 204.527 236.52 204.282C236.622 204.038 236.776 203.819 236.971 203.641C237.393 203.261 237.945 203.058 238.513 203.076C239.077 203.046 239.63 203.239 240.054 203.613C240.25 203.79 240.406 204.006 240.51 204.249C240.615 204.491 240.666 204.752 240.661 205.016C240.668 205.281 240.619 205.545 240.517 205.79C240.415 206.035 240.263 206.256 240.07 206.439C239.869 206.634 239.631 206.787 239.369 206.889C239.108 206.991 238.829 207.04 238.549 207.033C238.265 207.046 237.982 207.003 237.716 206.906C237.449 206.809 237.205 206.659 236.997 206.466Z"
              fill="white"
            />
            <defs>
              <radialGradient
                id="paint0_radial_3167_5766"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(138.736 121.846) scale(138.661 58.4358)"
              >
                <stop stop-color="#00264D" />
                <stop offset="0.09" stop-color="#2B4B6B" />
                <stop offset="0.23" stop-color="#637A92" />
                <stop offset="0.37" stop-color="#93A3B3" />
                <stop offset="0.5" stop-color="#BAC4CF" />
                <stop offset="0.63" stop-color="#D8DEE4" />
                <stop offset="0.76" stop-color="#EEF0F3" />
                <stop offset="0.89" stop-color="#FBFBFC" />
                <stop offset="1" stop-color="white" />
              </radialGradient>
            </defs>
          </svg>

          <p
            style={{
              fontSize: `${window.innerWidth < 800 ? "1.3rem" : "1.875rem"}`,
              fontWeight: 700,
              color: "#156649",
            }}
          >
            Live trading is coming soon
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: `${window.innerWidth < 800 ? "column" : "row"}`,
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "2rem",
              paddingBottom: `${window.innerWidth < 1000 ? "20px" : 0}`,
              gap: "20px",
              height: "250px",
            }}
          >
            <div
              style={{
                backgroundColor: "#252424",
                width: `${window.innerWidth < 800 ? "100%" : "50%"}`,
                height: "100%",
                padding: `${
                  window.innerWidth < 1000
                    ? "24px 0 25px 27px"
                    : "24px 0 0 27px"
                }`,
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: "22px",
                  marginBottom: "20px",
                }}
              >
                Join the Waitlist
              </h1>
              <p
                style={{
                  color: "#C5C5C5",
                  fontSize: "16px",
                  marginBottom: "45px",
                }}
              >
                You'll be the first to know when we launch live trading.
              </p>
              {!params.get("trader_email") && (
                <button
                  onClick={SendToWaitlist}
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    borderRadius: "3px",
                    fontWeight: 700,
                    padding: "13px",
                  }}
                >
                  {loading ? "Please wait..." : "Join Waitlist"}
                </button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                backgroundColor: "#156649",
                width: `${window.innerWidth < 800 ? "100%" : "50%"}`,
                padding: "14px 10px 0 17px",
                height: "100%",
              }}
            >
              <div>
                <h1
                  style={{
                    color: "white",
                    fontSize: "22px",
                    marginBottom: "20px",
                  }}
                >
                  Join the Hamma Competition
                </h1>
                <p
                  style={{
                    color: "#C5C5C5",
                    fontSize: "16px",
                    marginBottom: "25px",
                  }}
                >
                  Stand a chance to win up to 1 million Naira
                </p>
                {!params.get("trader_email") && (
                  <button
                    onClick={JoinedUsers}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      borderRadius: "3px",
                      fontWeight: 700,
                      padding: "13px",
                      whiteSpace: "nowrap",
                      marginBottom: "20px",
                    }}
                  >
                    {joinLoading ? "Please wait..." : "Join Competition here"}
                  </button>
                )}
              </div>
              <div style={{ height: "max-content" }}>
                <svg
                  width="131"
                  height="195"
                  viewBox="0 0 171 195"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M146.25 146.25C173.174 119.326 173.174 75.6739 146.25 48.75C119.326 21.8261 75.6739 21.8261 48.75 48.75C21.8261 75.6739 21.8261 119.326 48.75 146.25C75.6739 173.174 119.326 173.174 146.25 146.25Z"
                    fill="white"
                    fill-opacity="0.2"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M96.6862 119.702C91.4779 119.709 86.3277 118.608 81.5776 116.472L114.548 173.58L123.12 144.79L152.339 151.761L125.686 105.597C122.24 109.995 117.838 113.551 112.813 115.995C107.788 118.438 102.273 119.706 96.6862 119.702Z"
                    fill="#D59E30"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M91.5688 133.777L99.1084 146.837L117.161 115.571C111.691 118.827 105.468 120.603 99.1042 120.726L91.5688 133.777Z"
                    fill="#C08C31"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M115.695 115.854L133.887 147.368L152.34 151.77L126.743 107.438C123.625 110.915 119.876 113.771 115.695 115.854Z"
                    fill="#E2634E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80.46 114.539L114.548 173.58L119.679 156.352L98.5168 119.696C92.1524 119.572 85.9297 117.795 80.46 114.539Z"
                    fill="#E2634E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M99.1042 120.725L91.5688 133.776L99.1084 146.837L106.649 133.776L99.114 120.725H99.1042Z"
                    fill="#C0412E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M69.2259 107.431L43.6309 151.763L72.8479 144.792L81.4211 173.579L115.51 114.536C108.165 118.894 99.5357 120.568 91.0946 119.271C82.6534 117.975 74.924 113.789 69.2259 107.428V107.431Z"
                    fill="#E4AC3E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M69.2259 107.431L43.6309 151.763L62.0841 147.361L80.274 115.847C76.0933 113.764 72.3446 110.908 69.2259 107.431Z"
                    fill="#E2634E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M76.2896 156.352L81.4214 173.579L115.51 114.536C110.04 117.792 103.816 119.568 97.4516 119.69L76.2896 156.352Z"
                    fill="#E2634E"
                  />
                  <path
                    opacity="0.7"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M78.3068 91.7017L64.6045 115.434C83.1123 133.642 112.926 132.782 130.393 113.753L117.662 91.7031L78.3068 91.7017Z"
                    fill="#8C6E35"
                  />
                  <path
                    d="M125.516 111.662C141.438 95.7398 141.438 69.9252 125.516 54.0032C109.594 38.0813 83.7791 38.0813 67.8571 54.0032C51.9352 69.9252 51.9352 95.7398 67.8571 111.662C83.7791 127.584 109.594 127.584 125.516 111.662Z"
                    fill="#E4AC3E"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M96.687 42.061V123.603C119.205 123.603 137.458 105.348 137.458 82.8318C137.458 60.3154 119.205 42.061 96.687 42.061Z"
                    fill="#D69F30"
                  />
                  <path
                    d="M87.2852 71.695H94.3387V104.293H101.961V63.8501H87.2852V71.695Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          {success === true && (
            <p
              style={{
                fontSize: `${window.innerWidth < 800 ? "1rem" : "1.5rem"}`,
                fontWeight: 700,
                color: "#156649",
                textAlign: "center",
              }}
            >
              Your email has been received!
            </p>
          )}
          {success === false && (
            <p
              style={{
                fontSize: `${window.innerWidth < 800 ? "1rem" : "1.5rem"}`,
                fontWeight: 700,
                color: "red",
                textAlign: "center",
              }}
            >
              Waitlist with this email already exists, or try again later.
            </p>
          )}
        </div>
      </Modal>
    </>
  );
}

export default App;
