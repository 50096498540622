import React from "react";
import ReactApexChart from "react-apexcharts";

function ColumnChart({ overviewPort, overviewMark, labels, small }) {
  // console.log(overviewPort);
  // console.log(overviewMark);
  // console.log(labels);
  let series = [
    {
      name: "NGX all share index",
      data: overviewMark,
    },
    {
      name: "Portfolio",
      data: overviewPort,
    },
  ];

  let options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#00ba77", "#ffa500"],
    xaxis: {
      categories: labels,
      tickAmount: small ? 5 : 10,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      title: {
        text: "% (percent)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  };

  return labels.length > 0 ? (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "50px",
        gap: "5px",
       marginTop:"50px"
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0003 18.3332C14.5837 18.3332 18.3337 14.5832 18.3337 9.99984C18.3337 5.4165 14.5837 1.6665 10.0003 1.6665C5.41699 1.6665 1.66699 5.4165 1.66699 9.99984C1.66699 14.5832 5.41699 18.3332 10.0003 18.3332Z"
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 6.6665V10.8332"
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.99512 13.3335H10.0026"
          stroke="#515151"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{ fontSize: "16px", color: "#515151" }}>
        Start trading to see your performance
      </span>
    </div>
  );
}

export default ColumnChart;
