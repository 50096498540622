import React from "react";

function AiQuestion({
  title,
  question,
  options,
  aiAnswers,
  setAiAnswers,
  number,
}) {
  return (
    <div className="relative shadow-lg p-4 rounded-[5px] border border-[#cccccc] lg:w-[785px]">
      <p className="absolute top-4 right-4">{number}/7</p>
      <p className="font-semibold text-[#161616] mb-2">{title}</p>
      <p className="text-[#16161699] text-[0.875rem] lg:text-[1rem] mb-4">{question}</p>
      <div className="flex flex-col gap-4">
        {options.map((opt) => (
          <p key={opt} className={`${aiAnswers.some(
                (answer) =>
                  answer.question === question && answer.answer === opt
              ) && "font-semibold"} flex items-center gap-2 text-[0.875rem] lg:text-[1rem]`}>
            <input
              checked={aiAnswers.some(
                (answer) =>
                  answer.question === question && answer.answer === opt
              )}
              className="accent-[#161616] w-[20px] h-[20px] shrink-0"
              type="radio"
              onClick={() => {
                // Check if the question already exists in aiAnswers
                const existingAnswerIndex = aiAnswers.findIndex(
                  (answer) => answer.question === question
                );

                if (existingAnswerIndex !== -1) {
                  // If the question already exists, update the answer
                  const updatedAnswers = [...aiAnswers];
                  updatedAnswers[existingAnswerIndex].answer = opt;
                  setAiAnswers(updatedAnswers);
                } else {
                  // If the question doesn't exist, add it
                  setAiAnswers([...aiAnswers, { question, answer: opt }]);
                }
              }}
            />
            {opt}
          </p>
        ))}
      </div>
    </div>
  );
}

export default AiQuestion;
